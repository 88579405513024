import { h } from 'preact';
import AmountHelper from '../common/helpers/AmountHelper';
import { LanguageSymbol } from '../common/types';
import {isNullOrUndefined, isUndefined} from "util";
import Spinner from "../common/components/Spinner";

interface OrderTotalTemplateProps {
    total: number;
    currency: string;
    lang: LanguageSymbol;
    wrapperExtraClasses?: string

    getStringTranslationByKey: (key: string) => string;
}

export default function OrderTotalTemplate(props: OrderTotalTemplateProps): JSX.Element {
    return (
            <div className={prepareClasses(props.wrapperExtraClasses)}>
                    <p class="mt-5 mb-5">{props.getStringTranslationByKey('total_amount')}:
                    {isNullOrUndefined(props.total) || props.total <= 0 ?
                        <span class="ml-5"><Spinner/></span> : <span class="basket-total ml-5">{AmountHelper.getFullAmountWithCurrency(props.total, props.currency, props.lang)}</span>
                    }
                    </p>
            </div>
    );
}

function prepareClasses(classes: string | undefined) {
    let defaultClasses = "order-total-wrapper output-field-theme mt-5";
    if(isUndefined(classes)) {
        return defaultClasses + " float-left";
    } else {
        if(classes.indexOf("float-right") !== -1) {
            return defaultClasses + " " + classes;
        } else {
            return defaultClasses + " float-left " + classes;
        }
    }
}