import {Component, h} from 'preact';
import {AppStage, CommonProps, ErrorMessageKey, Intent, MainButtonType, WizardFlow,} from '../../../common/types';
import {exitSmartCheckout, exitSmartCheckoutDirectly, goToSuccessPage} from "../../../common/utils";
import PaymentMethodHeadlineWithAssigmentAndScoring
    from "../../../templates/PaymentMethodHeadlineWithAssigmentAndScoring";
import {ProjectNameAndLogo} from "../../../templates/ProjectNameAndLogo";
import {BasketOrOrderTotal} from "../../../templates/BasketOrOrderTotal";
import {PaymentHints} from "../../../templates/PaymentHints";
import {isNull} from "util";

interface InvoiceComponentProps extends CommonProps {
    total: number;
    currency: string;
}

export interface InvoiceComponentState {
}

export default class InvoiceComponent
    extends Component<InvoiceComponentProps, InvoiceComponentState> {

    public constructor(props: InvoiceComponentProps) {
        super(props);
        this.confirmButton = this.confirmButton.bind(this);
        this.cancelButton = this.cancelButton.bind(this);

        let label: string = 'pay_now';
        if (this.props.sessionData && this.props.sessionData.transaction.intent === Intent.AUTHORIZATION) {
            label = 'continue';
        }
        this.props.setButton(this.confirmButton, label);
    }

    public componentDidMount() {
        this.showBackButton();
    }

    public render() {
        return (
            <div>
                <ProjectNameAndLogo {...this.props}/>
                <BasketOrOrderTotal {...this.props} total={this.props.total} currency={this.props.currency}/>
                <PaymentHints {...this.props} />
                <PaymentMethodHeadlineWithAssigmentAndScoring {...this.props} />
            </div>

        );
    }

    private confirmButton(): void {
        this.props.setLoading(true, false);
        this.props.callBackend(
            '/prepare',
            () => {
                if (this.props.sessionData && this.props.sessionData.transaction.intent === Intent.AUTHORIZATION) {
                    if (this.context.returnUrls.urlSuccess) {
                        exitSmartCheckoutDirectly(this.props.viewMode, this.context.returnUrls.urlSuccess);
                    } else {
                        goToSuccessPage(this.props);
                    }
                } else {
                    this.props.callBackend(
                        '/start',
                        () => {
                            if (this.context.returnUrls.urlSuccess) {
                                exitSmartCheckoutDirectly(this.props.viewMode, this.context.returnUrls.urlSuccess);
                            } else {
                                goToSuccessPage(this.props);
                            }
                        },
                        'POST',
                        {},
                        () => {
                            if (this.context.returnUrls.urlError) {
                                exitSmartCheckoutDirectly(this.props.viewMode, this.context.returnUrls.urlError);
                            } else {
                                this.props.setStageWithEmptySteps(AppStage.STAGE_ERROR);
                            }
                        }
                    );
                }
            },
            'POST',
            {},
            () => {
                if (this.context.returnUrls.urlError) {
                    exitSmartCheckoutDirectly(this.props.viewMode, this.context.returnUrls.urlError);
                } else {
                    this.props.setStageWithEmptySteps(AppStage.STAGE_ERROR, ErrorMessageKey.NO_PAYMENT_METHOD_REMAINING);
                }
            }
        );
    }

    private cancelButton(): void {
        let abortUrl = this.context.returnUrls.urlAbort;

        if (this.props.getAvailablePaymentMethods().length > 1 && isNull(this.props.initPaymentMethod)) {
            // in this case we can skip /session-data request
            this.props.setStage(AppStage.STAGE_PAYMENT_SELECTION, undefined, true, false);
            this.props.setButton(() => undefined, '', false, false, true, MainButtonType.CANCEL);
        } else {
            exitSmartCheckout(abortUrl, {}, this.props.viewMode, '/abort', this.props.setLoading);
        }
    }

    private showBackButton() {
        if (this.props.wizardFlow === WizardFlow.PAYMENT) {
            // started from "general" payment link or abort url is available
            if ((this.props.getAvailablePaymentMethods().length > 1 && isNull(this.props.initPaymentMethod)) || this.context.returnUrls.urlAbort) {
                this.props.setButton(this.cancelButton, 'back', false, false, false, MainButtonType.CANCEL);
            }
        }
    }
}
