import {h} from 'preact';
import {
    AppStage,
    BasketType,
    CommonProps,
    LegalDetail,
    LegalDetailType,
    MerchantFromSession,
    OrderOption,
    SessionData,
    WizardFlow,
} from '../types';
import {isNull, isNullOrUndefined, isUndefined} from 'util';
import InfoMerchantLinks from './InfoMerchantLinks';
import {createHeadlineElement} from "../utils";

interface Props extends CommonProps {
    isShippingEnabled: boolean;
    additionalWrapperClass?: string;
}

export default function InfoMerchantComponent(props: Props) {
    return (
        <div id="merchant-info" class={"container" + (props.additionalWrapperClass ? ' ' + props.additionalWrapperClass : '')}>
            <div class="col-xs-12">
                {createHeadlineElement(props, !isNull(props.sessionData) ? createMerchantTitle(props).toUpperCase() : '')}
                <div class="row">
                    <div class="col-xs-12" id="merchant-logo-name">
                        {!isNull(props.sessionData) ? createMerchantName(props.sessionData.merchant) : ''}
                        {!isNull(props.sessionData) ? showMerchantLogo(props) : null}
                    </div>
                </div>
            </div>
            {
                !isNull(props.sessionData) ?
                    <InfoMerchantLinks
                        getStringTranslationByKey={props.getStringTranslationByKey}
                        legalDetails={props.sessionData.merchant.legal_details}
                        currentLang={props.currentLang}
                    /> : null
            }
        </div>
    );
}

function setMerchantLogoClass(event: Event) {
    let img: HTMLImageElement = event.target as HTMLImageElement;
    let width = img.width;
    let height = img.height;
    let ratio = width / height;
    let wrapper = document.getElementById('merchant-logo-name');
    let shape: string;

    if(ratio > 0.75 && ratio < 1.25) {
        shape = 'square';
    } else if(ratio <= 0.75) {
        shape = 'vertical'
    } else {
        shape = 'horizontal';
    }

    if(wrapper) {
        (wrapper as HTMLElement).classList.add(shape);
    }
}

function showMerchantLogo(props: CommonProps) {
    const legalDetails: LegalDetail[] | null = props.sessionData ? props.sessionData.merchant.legal_details : null;
    const logoDetail: LegalDetail | undefined = legalDetails
        ? legalDetails.find((legalDetail: LegalDetail) => legalDetail.type === LegalDetailType.TYPE_LOGO)
        : undefined;
    return isUndefined(logoDetail)
        ? null
        : (
            <div id="merchant-logo">
            <img src={(logoDetail as LegalDetail).url} class="img-responsive"
                 onLoad={setMerchantLogoClass}/>
            </div>
        );
}

function createMerchantTitle(props: Props) {
    let translationKey: string = '';
    let sessionData = props.sessionData as SessionData;
    const basket = sessionData.transaction.basket;
    let basketType = BasketType.DEFAULT;
    if (!isNullOrUndefined(basket)) {
        basketType = basket.type;
    }
    let stage = props.stageData.stage;
    let shippingEnabled = props.isShippingEnabled;
    let orderOption = sessionData.transaction.order_option;

    if(props.wizardFlow === WizardFlow.PAYMENT) {
        translationKey = 'payment_to';
    } else {
        if (basketType === BasketType.MIXED) {
            translationKey = 'arranged_by';
        } else {
            if (stage === AppStage.STAGE_CHECKIN) {
                if (shippingEnabled) {
                    translationKey = 'sales_and_shipping_by';
                } else {
                    translationKey = 'sales_by';
                }
            } else if (stage === AppStage.STAGE_CONFIRMATION && orderOption === OrderOption.ORDER_OPTION_SHIPPING) {
                translationKey = 'sales_and_shipping_by';
            } else {
                translationKey = 'sales_by';
            }
        }
    }

    return props.getStringTranslationByKey(translationKey);
}

function createMerchantName(merchant: MerchantFromSession) {
    return <div id="merchant-name">
        {merchant.company.companyname}
        <br/>
        {merchant.company.address ? <span>
            {merchant.company.address.address_details.street + ' ' + merchant.company.address.address_details.street_number}
            <br />
            {merchant.company.address.address_details.zip_code + ' ' + merchant.company.address.address_details.city}
            <br />
            {merchant.company.address.address_details.country}
        </span> : ''}
    </div>
}
