import { h, Component } from 'preact';
import {BasketType, CommonProps, LegalDetailType} from '../types';
import {isNull} from "util";
import {handleKeyboardEvent} from "../utils";

interface InfoMerchantAcceptProps extends CommonProps {
}

export default class InfoMerchantAccept extends Component<InfoMerchantAcceptProps, {}> {
    public render(): JSX.Element | null {
        return (
            !isNull(this.props.sessionData) ? (
            this.props.sessionData.transaction.basket.type === BasketType.DEFAULT ?
            <div>{this.props.getStringTranslationByKey('confirm_text_1')}
                <label class="info-link-theme" for={LegalDetailType.TYPE_REVOCATION_INSTRUCTION} onKeyUp={handleKeyboardEvent} tabIndex={0}>
                    {this.props.getStringTranslationByKey(LegalDetailType.TYPE_REVOCATION_INSTRUCTION)}
                </label>
                {this.props.getStringTranslationByKey('confirm_text_2')}
                <label class="info-link-theme" for={LegalDetailType.TYPE_TERMS_AND_CONDITIONS} onKeyUp={handleKeyboardEvent} tabIndex={0}>
                    {this.props.getStringTranslationByKey('terms')}
                </label>
                {this.props.getStringTranslationByKey('confirm_text_3')}
                <label class="info-link-theme" for={LegalDetailType.TYPE_PRIVACY_POLICY} onKeyUp={handleKeyboardEvent} tabIndex={0}>
                    {this.props.getStringTranslationByKey(LegalDetailType.TYPE_PRIVACY_POLICY)}
                </label>
                {this.props.getStringTranslationByKey('confirm_text_4')}
            </div>
                :
                <div>
                    {this.props.getStringTranslationByKey('confirm_text_mixed_basket')}
                </div>
                )
                : null
        );
    }
}