import { LanguageSymbol } from '../types';
import { decimalSeparators } from '../../translations/translations';

export default class AmountHelper {
    public static getFullAmountWithCurrency(centAmount: number, currency: string, lang: LanguageSymbol): string {
        return AmountHelper.convertCentsToEuro(centAmount, lang) + AmountHelper.getCurrency(currency);
    }

    public static convertCentsToEuro(centAmount: number, lang: LanguageSymbol): string {
        return (centAmount / 100).toFixed(2).replace('.', decimalSeparators[lang]);
    }

    private static getCurrency(currency: string): string {
        // in the future here implement different currencies
        return ' ' + AmountHelper.getCurrencySignByISO4217(currency);
    }

    private static getCurrencySignByISO4217(code: string): string {
        switch (code) {
            case 'EUR':
                return '€';
            case 'CHF':
                return 'CHF';
            case 'GBP':
                return '£';
            case 'USD':
                return '$';
            default:
                throw new Error('Unknown ISO 4217 code "' + code + '"');
        }
    }
}
