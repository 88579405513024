import {LanguageSymbol, LegalDetailType, LegalDetail, CommonProps} from '../types';
import InfoModal from './InfoModal';
import {h} from 'preact';
import {isUndefined} from 'util';
import {handleKeyboardEvent} from "../utils";

interface MerchantLinksProps {
    currentLang: LanguageSymbol;
    getStringTranslationByKey: (key: string) => string;
    legalDetails: LegalDetail[];
    additionalClasses?: string;
    idIndex?: number;
}

function findUrlByTypeAndLanguage(legalDetails: LegalDetail[], type: LegalDetailType, language: LanguageSymbol) {
    for (let detail of legalDetails) {
        if (detail.type === type && detail.language === language) {
            return detail.url;
        }
    }
    return '';
}

function isPdfFile(legalDetails: LegalDetail[], type: LegalDetailType, language: LanguageSymbol): boolean {
    let pdf = "application/pdf";

    for (let detail of legalDetails) {
        if (detail.type === type && detail.language === language && detail.content_type === pdf) {
            return true;
        }
    }
    return false;
}

function generateLegalDetailLink(props: MerchantLinksProps, val: LegalDetailType, idx: number) {
    if (!props.legalDetails) {
        return null;
    }
    let link = findUrlByTypeAndLanguage(props.legalDetails, val, props.currentLang);
    if(link === '') {
        return null;
    } else {
        if (isPdfFile(props.legalDetails, val, props.currentLang)) {
            return <div key={idx} className="link-text-theme">
                <a href={findUrlByTypeAndLanguage(props.legalDetails, val, props.currentLang) + '?disposition=attachment'}
                   download={val + '.pdf'}>{props.getStringTranslationByKey(val)}</a> {idx < 3 ? '| ' : ''}
            </div>
        } else {
            return <div key={idx} className="link-text-theme">
                <label
                    tabIndex={0}
                    onKeyUp={handleKeyboardEvent}
                    for={val + (!isUndefined(props.idIndex) ? props.idIndex : '')}>
                    {props.getStringTranslationByKey(val)}
                </label>
                {idx < 3 ? ' | ' : ''}
                <InfoModal modalId={val + (!isUndefined(props.idIndex) ? props.idIndex : '')}
                           srcIsIframe={true}>
                    <iframe
                        height="100%"
                        width="100%"
                        className="no-border"
                        src={findUrlByTypeAndLanguage(props.legalDetails, val, props.currentLang)}
                    />
                </InfoModal>
            </div>
        }
    }

}

export default function InfoMerchantLinks(props: MerchantLinksProps) {
    return (
        <div class={'col-xs-12 ' + (props.additionalClasses || '')} id="merchant-links">
            {
                [
                    LegalDetailType.TYPE_IMPRINT,
                    LegalDetailType.TYPE_REVOCATION_INSTRUCTION,
                    LegalDetailType.TYPE_TERMS_AND_CONDITIONS,
                    LegalDetailType.TYPE_PRIVACY_POLICY
                ].map((val, idx) => generateLegalDetailLink(props, val, idx))
            }
        </div>
    );
}