import { h } from 'preact';
import {AppStage, CommonProps, ErrorMessageKey, PaymentWindow} from '../types';
import CheckInComponent from '../../components/checkin/CheckInComponent';
import AddressComponent from '../../components/address/AddressComponent';
import PaymentInputComponent from '../../components/payment-input/PaymentInputComponent';
import PaymentMethodsComponent from '../../components/payment-selection/PaymentMethodsComponent';
import ConfirmationComponent from '../../components/confirmation/ConfirmationComponent';
import SuccessComponent from '../../components/success/SuccessComponent';
import ErrorComponent from '../../components/error/ErrorComponent';
import ShippingCollectComponent from '../../components/shippingcollection/ShippingCollectionComponent';
import Spinner from '../components/Spinner';

interface ComponentToDisplayProps {
    currentStage: AppStage;
    commonPropsBundle: CommonProps;
    paymentWindow: PaymentWindow;
    errorMessageKey?: ErrorMessageKey;
    isFullscreen: boolean;
    dataKey: string;
    isInitialized: boolean;

    setInitialized(isInitialized: boolean): void;
    setPayPalExpressAvailability(state: boolean): void;
    getPayPalExpressAvailability(): boolean;
}

export default function ComponentToDisplay(props: ComponentToDisplayProps): JSX.Element | null {
    switch (props.currentStage) {
        case AppStage.STAGE_CHECKIN:
            return (
                <CheckInComponent
                    {...props.commonPropsBundle}
                    isFullscreen={props.isFullscreen}
                    dataKey={props.dataKey}
                    isInitialized={props.isInitialized}
                    setInitialized={props.setInitialized}
                    setPayPalExpressAvailability={props.setPayPalExpressAvailability}
                    getPayPalExpressAvailability={props.getPayPalExpressAvailability}
                />
            );
        case AppStage.STAGE_ADDRESS:
            return (
                <form target="remember-entered-data" action="about:blank" noValidate={true}>
                    <AddressComponent
                        {...props.commonPropsBundle}
                    />
                </form>
            );
        case AppStage.STAGE_SHIPPING_COLLECTION:
            return (
                <ShippingCollectComponent
                    {...props.commonPropsBundle}
                />
            );
        case AppStage.STAGE_PAYMENT_SELECTION:
            return (<PaymentMethodsComponent {...props.commonPropsBundle} />);
        case AppStage.STAGE_PAYMENT_INPUT:
            return <PaymentInputComponent {...props.commonPropsBundle} paymentWindow={props.paymentWindow}/>;
        case AppStage.STAGE_CONFIRMATION:
            return (
                <ConfirmationComponent {...props.commonPropsBundle}
                      isInitialized={props.isInitialized}
                      setInitialized={props.setInitialized}
                />
            );
        case AppStage.STAGE_SUCCESS:
            return (<SuccessComponent {...props.commonPropsBundle} />);
        case AppStage.STAGE_ERROR:
            return (
                <ErrorComponent {...props.commonPropsBundle}
                                   errorMessageKey={props.errorMessageKey}/>
            );
        case AppStage.STAGE_LOADING:
            // TODO: should we define a timeout for loading?
            return null;
        default:
            return null;
    }
}
