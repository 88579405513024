import {h} from 'preact';
import {CommonProps, SessionData} from "../common/types";
import OrderTotalTemplate from "./OrderTotalTemplate";
import InfoMerchantComponent from "../common/info/InfoMerchantComponent";

interface Props extends CommonProps {
    isShippingEnabled: boolean;
    additionalWrapperClass?: string;
}

export function PaymentLinkInfoHeader(props: Props): JSX.Element | null {
    let sessionData = props.sessionData as SessionData;
    let total = sessionData.transaction.basket_info.sum;
    let currency = sessionData.transaction.basket_info.currency;

    if (props.isPaymentLinkFlow()) {
        return <div>
            <div class="container">
                <div class="col-xs-12">
                    <OrderTotalTemplate getStringTranslationByKey={props.getStringTranslationByKey}
                                        total={total}
                                        lang={props.currentLang}
                                        currency={currency}/>
                </div>
            </div>
            <InfoMerchantComponent
                isShippingEnabled={props.isShippingEnabled}
                {...props as CommonProps}
                additionalWrapperClass={props.additionalWrapperClass}
            />
        </div>
    } else {
        return null;
    }
}
