import {h} from 'preact';
import {CommonProps, WizardFlow} from "../common/types";
import OrderTotalTemplate from "./OrderTotalTemplate";
import InfoBasket from "../common/info/InfoBasketComponent";
import {isNullOrUndefined} from 'util';
import {createHeadlineElement} from "../common/utils";

interface Props extends CommonProps {
    total: number;
    currency: string;
}

export function BasketOrOrderTotal(props: Props): JSX.Element | null {
    let projectTitle: string | null = null;
    if (props.sessionData) {
        if (props.isPaymentLinkFlow()) {
            return null;
        } else
            if (props.wizardFlow === WizardFlow.PAYMENT) {
            const iframeOpts = props.sessionData.payment_wizard_options;
            let isBasketShown = !isNullOrUndefined(iframeOpts) && iframeOpts.is_basket_shown;
            projectTitle = !isNullOrUndefined(iframeOpts) ? iframeOpts.project_title : null;
            if (isBasketShown) {
                return <InfoBasket {...props}/>;
            } else {
                return OrderTotalWithProjectName(props, projectTitle);
            }
        } else {
            return OrderTotalWithProjectName(props, projectTitle);
        }
    } else {
        return null;
    }
}

function OrderTotalWithProjectName(props: Props, projectName: string | null) {
    return <div class="container">
        <div class="col-xs-12">
            {projectName ?
                createHeadlineElement(props, props.getStringTranslationByKey('overview').toUpperCase())
                : null}
            <OrderTotalTemplate getStringTranslationByKey={props.getStringTranslationByKey}
                                total={props.total}
                                lang={props.currentLang}
                                currency={props.currency}/>
        </div>
    </div>
}
