import { h } from 'preact';

export let imprint_en = (
<div>
    <h1>Imprint</h1>
    <p>secupay AG<br/>Goethestraße 6<br/>01896 Pulsnitz</p>
    <p>Management Board: Hans-Peter Weber, Katja Hartmann</p>
    <p>Chairman of the supervisory Board: Peter Rauscher</p>
    <p>Contact:</p>
    <p>Phone: +49 (0) 35955 75 50 0<br/>Fax: +49 (0) 35955 75 50 99<br/>E-Mail: <a
        href="mailto:info@secupay.com">info@secupay.com</a></p>
    <p>secupay AG is a payment institute within the meaning of the Payment Service Supervision Act (ZAG) and is licensed
        and supervised by Germany's federal financial services supervisory body (BaFin), Graurheindorfer Str. 108, 53117
        Bonn (<a href="https://portal.mvp.bafin.de/database/ZahlInstInfo/zahlinst.do?id=126737">Registernummer:
            126737</a>).</p>
    <p>secupay AG has the following permissions:</p>
    <ul>
        <li>Acquisition business (§ 1 para. 1 sentence 2 no. 5 ZAG)</li>
        <li>Financial transfer business (§ 1 para. 1 sentence 2 no. 6 ZAG)</li>
        <li>Direct debit business without granting of credit (§ 1 para. 1 sentence 2 no. 3 a ZAG)</li>
    </ul>
    <p>District Court Dresden HRB 27612</p>
    <p>Tax Identification Number: DE209853849</p>
</div>
);

export let imprint_de = (
<div>
    <h1>Impressum</h1>
    <p>secupay AG<br/>Goethestraße 6<br/>01896 Pulsnitz</p>
    <p>Vorstand: Hans-Peter Weber, Katja Hartmann</p>
    <p>Aufsichtsratsvorsitzender: Peter Rauscher</p>
    <p>Kontakt:</p>
    <p>Telefon: +49 (0) 35955 75 50 0<br/>Fax: +49 (0) 35955 75 50 99<br/>E-Mail: <a
        href="mailto:info@secupay.com">info@secupay.com</a></p>
    <p>Die secupay AG ist ein Zahlungsinstitut im Sinne des Zahlungsdiensteaufsichtsgesetz (ZAG) und bei der
        Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin), Graurheindorfer Str. 108, 53117 Bonn registriert (<a
            href="https://portal.mvp.bafin.de/database/ZahlInstInfo/zahlinst.do?id=126737">Registernummer: 126737</a>).
    </p>
    <p>Die secupay AG verfügt über folgende Erlaubnisse:</p>
    <ul>
        <li>Akquisitionsgeschäft (§ 1 Abs. 1 Satz 2 Nr. 5 ZAG)</li>
        <li>Finanztransfergeschäft (§ 1 Abs. 1 Satz 2 Nr. 6 ZAG)</li>
        <li>Lastschriftgeschäft ohne Kreditgewährung (§ 1 Abs. 1 Satz 2 Nr. 3 a ZAG)</li>
    </ul>
    <p>Amtsgericht Dresden HRB 27612</p>
    <p>Umsatzsteuer-Identifikationsnummer: DE209853849</p>
</div>
);