import {h} from 'preact';
import {CommonProps, AppStage} from '../common/types';
import InfoAddressComponent from '../common/info/InfoAddressComponent';
import {isNull} from "util";
import ChangePageButton from "../common/buttons/ChangePageButton";
import {createHeadlineElement} from "../common/utils";

export default function ShippingOptionTemplate(props: CommonProps): JSX.Element | null {
    return (
        !isNull(props.sessionData) ? (
                !props.sessionData.merchant.order_options  || !props.sessionData.merchant.order_options.collection.enabled ?
                    <div>
                        <div id="invoice-shipping-address" class="delivery-options container">
                            <div className="col-xs-12">
                                {createHeadlineElement(props, props.getStringTranslationByKey('delivery_address_data_header').toUpperCase())}
                            </div>
                            <InfoAddressComponent {... props as CommonProps}/>
                        </div>
                        {
                            props.stageData.stage === AppStage.STAGE_CONFIRMATION
                                ? props.stageData.direct.indexOf(AppStage.STAGE_ADDRESS) !== -1
                                    ? <ChangePageButton {...props} stage={AppStage.STAGE_ADDRESS}
                                                    title={props.getStringTranslationByKey('change_invoice_address')}
                                />
                                    : <div class="col-xs-12 mt-30"/>
                                : null
                        }
                    </div>
                    :
                    <div>
                        <div id="invoice-address" className="delivery-options container">
                            <div className="col-xs-12">
                                {createHeadlineElement(props, props.getStringTranslationByKey('your_invoice_address_header').toUpperCase())}
                            </div>
                            <InfoAddressComponent {... props as CommonProps}/>
                        </div>
                        {
                            props.stageData.stage === AppStage.STAGE_CONFIRMATION
                                ? props.stageData.direct.indexOf(AppStage.STAGE_ADDRESS) !== -1
                                    ? <ChangePageButton {...props} stage={AppStage.STAGE_ADDRESS}
                                                    title={props.getStringTranslationByKey('change_invoice_address')}
                                />
                                    : <div class="col-xs-12 mt-30"/>
                                : null
                        }
                        <div id="shipping-address" className="delivery-options container">
                            <div className="col-xs-12">
                                {createHeadlineElement(props, props.getStringTranslationByKey('your_shipment_address_header').toUpperCase())}
                            </div>
                            <InfoAddressComponent {... props as CommonProps}/>
                        </div>
                        {props.stageData.stage === AppStage.STAGE_CONFIRMATION ?
                            <ChangePageButton {...props} stage={AppStage.STAGE_SHIPPING_COLLECTION}
                                              title={props.getStringTranslationByKey('change_delivery_options_button_text')}/> : null}
                    </div>
            )
            : null
    );
}