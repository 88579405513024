import { h, Component } from 'preact';
import {handleKeyboardEvent} from "../utils";

interface InfoModalProps {
    modalId: string;
    srcIsIframe?: boolean;
}

interface InfoModalState {
    loadContent: boolean;
}

export default class InfoModal extends Component<InfoModalProps, InfoModalState> {

    constructor() {
        super();

        this.state = {
            loadContent: false
        };

        this.handleChange = this.handleChange.bind(this);
    }

    render() {
        return (
            <div class="modal-container">
                <input class="modal-state" id={this.props.modalId} type="checkbox" onChange={this.handleChange}/>
                <div class="modal">
                    <label class="modal__bg" for={this.props.modalId}/>
                    <div class={'modal__inner' + this.setClassesBaseOnSrc()}>
                        <label class="modal__close" for={this.props.modalId} tabIndex={0} onKeyUp={handleKeyboardEvent}/>
                        {this.state.loadContent ? this.props.children : null}
                    </div>
                </div>
            </div>
        );
    }

    private handleChange(event: Event) {
        const target = event.target as HTMLInputElement;
        this.setState({loadContent: target.checked});
    }

    private setClassesBaseOnSrc(): string {
        return this.props.srcIsIframe ? ' overflow-hidden pr-0' : '';
    }
}