import {FilterInstance} from './input/FilterInstances';
import {ValidationInstance} from './input/ValidationInstances';

export interface ResponseHttp {
    headers: {};
    status: number;
    text: string;
}

export interface RequestOptions {
    url: string;
    method?: string; // default: 'GET'
    contentType?: string;
    headers?: {};
    withCredentials?: boolean; // default: false
    data?: {};
}

export enum PaymentMethod {
    SECUCARD = 'secucard',
    DIRECT_DEBIT = 'Debit',
    CREDIT_CARD = 'Creditcard',
    PAYPAL = 'Paypal',
    SOFORT = 'sofort',
    INVOICE = 'Invoice',
    PAY_IN_ADVANCE = 'Prepay',
    ERROR = 'error'
}

export enum AppStage {
    STAGE_LOADING = 'loading_page',
    STAGE_CHECKIN = 'checkin_page',
    STAGE_ADDRESS = 'address_page',
    STAGE_SHIPPING_COLLECTION = 'delivery_options_page',
    STAGE_PAYMENT_SELECTION = 'payment_selection_page',
    STAGE_PAYMENT_INPUT = 'payment_input_page',
    STAGE_SECURITY_CHECK = 'security_check_page',
    STAGE_PAYPAL_CHECKOUT = 'paypal_checkout_page',
    STAGE_CONFIRMATION = 'confirmation_page',
    STAGE_ERROR = 'error_page',
    STAGE_SUCCESS = 'success_page',
}

export enum PaymentWindow {
    WINDOW_LOADING,
    WINDOW_SECUCARD,
    WINDOW_DIRECT_DEBIT,
    WINDOW_CREDIT_CARD,
    WINDOW_PAY_IN_ADVANCE,
    WINDOW_PAY_ON_INVOICE
}

export enum LanguageSymbol {
    EN_GB = 'en',
    DE_DE = 'de'
}

export enum Country {
    DE = 1,
    AT = 2,
}

export enum LegalDetailType {
    TYPE_IMPRINT = 'imprint',
    TYPE_REVOCATION_INSTRUCTION = 'revocation',
    TYPE_TERMS_AND_CONDITIONS = 'terms',
    TYPE_PRIVACY_POLICY = 'policy',
    TYPE_LOGO = 'logo',
}

export interface ErrorMessage {
    messageHeaderTranslationKey?: string;
    messageBodyTranslationKey?: string;
}

export interface CommonProps {
    // stage?: AppStage;
    currentLang: LanguageSymbol;
    isLoading: boolean;
    isInitialized: boolean;
    viewMode: ViewMode;
    checkoutFromStx: boolean;
    stageData: StageData;
    paymentMethod: PaymentMethod;
    sessionData: SessionData | null;
    wizardFlow: WizardFlow;
    intent: Intent | null;
    ccTheme: string;
    initPaymentMethod: PaymentMethod | null;

    setLoading(isLoading: boolean, isPreloaderDelayed?: boolean): void;

    setButton(onClick: () => void, text: string, submit?: boolean, disabled?: boolean, hidden?: boolean, buttonType?: MainButtonType): void;

    setStage(stage: AppStage, additionalStageData?: PaymentWindow | ErrorMessageKey, skipSessionRequest?: boolean, initializedAtTheEnd?: boolean): void;

    getStringTranslationByKey(key: string): string;

    getJSXTranslationByKey(key: string): JSX.Element;

    showMessageBox(visible: boolean, message?: string, isMessageBoxTranslated?: boolean): void;

    getAvailablePaymentMethods(removeRestrictedMethods?: PaymentMethod[]): PaymentMethod[];

    setAvailablePaymentMethods(paymentMethods: PaymentMethod[]): void;

    setBoxTextMessageAtTheBeginning(message: string | ((lang: LanguageSymbol) => string)): void;

    getBoxTextMessageAtTheBeginning(): string | (() => void);

    setStageWithEmptySteps(stage: AppStage, customErrorMessage?: ErrorMessageKey): void;

    setPaymentMethod(paymentMethod: PaymentMethod): void;

    setOrderOptions(checkoutOptions: CheckoutOptions): void;

    fetchSessionDataFromBackend(callback: () => void, showLoader?: boolean): void;

    handleMessageAtTheBeginning(): void;

    callBackend(path: string, successCallback: (response: ResponseHttp) => void, method?: string, payload?: object, errorCallback?: (response: ResponseHttp) => void): void

    isPaymentLinkFlow(): boolean;
}

export enum OrderOption {
    ORDER_OPTION_NONE = 'none',
    ORDER_OPTION_SHIPPING = 'shipping',
    ORDER_OPTION_COLLECTION = 'collection',
}

// TODO @deprecated
export interface CheckoutOptions {
    shipping: {
        enabled: boolean;
    };
    collection: {
        enabled: boolean;
        preparation_time?: string;
        collection_hours: CollectionHours;
    };
}

export interface Button {
    onClick: (event: Event) => void;
    buttonText: string;
    loading: boolean;
    submit?: boolean;
    disabled?: boolean;
    hidden?: boolean;
    type: MainButtonType;
}

export interface ReturnUrls {
    urlSuccess: string;
    urlError: string;
    urlAbort: string;
}

export interface MerchantUrl {
    type: string;
    url: string;
}

export enum Salutation {
    SALUTATION_MR = 'Mr',
    SALUTATION_MRS = 'Mrs',
}

export interface Merchant {
    legalDetails: LegalDetail[];
    companyName: string;
    companyAddress: string;
}

export interface Customer {
    firstName: string;
    surname: string;
    street: string;
    zipCode: string;
    city: string;
}

export interface Creditor {
    identifier: string;
    mandateReference: string;
    mandateDate: string;
}

export interface Bank {
    account_owner: string;
    reference: string;
    bic: string;
    iban: string;
    bankName: string;
}

export interface LegalDetail {
    url: string;
    type: LegalDetailType;
    content_type: string; // snake_case because such is the SDK model on backend
    language: LanguageSymbol;
}

export enum FieldId {
    ID_IBAN = 'iban'
}

export interface BasketInfo {
    sum: number;
    currency: string;
}

export interface Basket { // similar to SDK model on backend
    products: Product[];
    basket_info: BasketInfo;
    type: BasketType;
}

export interface MixedBasket {
    type: BasketType;
    sub_baskets: SubBasket[];
    basket_info: BasketInfo;
}

export enum BasketType {
    MIXED = 'mixed',
    DEFAULT = 'default'
}

export interface SubBasket {
    products: Product[];
    merchant_data: {
        merchant_name: string;
        merchant_address: string;
        legal_details: LegalDetail[];
    };
}

export interface Product {
    quantity: number;
    desc: string;
    priceOne: number;
    tax: number;
    item_type: ProductType
}

export enum ProductType {
    ARTICLE = 'article',
    SHIPPING = 'shipping',
    COUPON = 'coupon',
}

export interface Store {
    name: string;
    address_formatted: string;
}

// structure from session-data endpoint
export interface TransactionInfoFromSession {
    basket: {
        products: Product[],
        type: BasketType
    };
    basket_info: BasketInfo;
    order_option: OrderOption;
    pickup_options: {
        date: string,
        code: string;
        store: Store;
    } | null;
    customer: {
        contact: AddressFromSession;
    };
    application_context: ApplicationContext;
    intent: Intent;
}

export interface ApplicationContext {
    locks: Locks;
    return_urls: CheckoutLinks;
    iframe_opts?: IframeOpts;
}

export interface Locks {
    customer: boolean;
}

// from STX
export interface IframeOpts {
    payment_hint_title: string | null;
    payment_hint: PaymentHintElement[] | null;
    project_title: string | null;
    submit_button_title: string | null;
    cancel_button_title: string | null;
    language: string | null;
    basket_title: string | null;
    hide_disclaimer: boolean | null;
    has_accepted_disclaimer: boolean | null;
}

// from GCR
export interface PaymentWizardOptions {
    project_title: string | null;
    project_logo: string | null;
    payment_hint: PaymentHintElement[] | null;
    submit_button_title: string | null;
    primary_colour: string | null;
    language: string | null;
    basket_headline: string | null;
    is_basket_shown: boolean;
    language_formality: LanguageFormality | null;
    hide_disclaimer: boolean | null;
    has_accepted_disclaimer: boolean | null;
}

export interface PaymentHintElement {
    type: string,
    value: {
        name: string;
        value: string
    }
}

export enum LanguageFormality {
    FORMAL = 'formal',
    INFORMAL = 'persönlich'
}

export interface CheckoutLinks {
    url_checkout?: string;
    url_success?: string;
    url_error?: string;
    url_abort?: string;
}

export enum WeekDay {
    DAY_MONDAY = 'Mon',
    DAY_TUESDAY = 'Tue',
    DAY_WEDNESDAY = 'Wed',
    DAY_THURSDAY = 'Thu',
    DAY_FRIDAY = 'Fri',
    DAY_SATURDAY = 'Sat',
    DAY_SUNDAY = 'Sun',
}

export type CollectionTimeInterval = { from: string, till: string };
export type CollectionHours = { [day in WeekDay]: CollectionTimeInterval[] };

export interface DeliveryOptions {
    isShippingEnabled: boolean;
    isCollectionEnabled: boolean;
    preparationTime?: string;
    collectionHours?: CollectionHours;
}

export interface MerchantFromSession {
    merchant_urls: MerchantUrl[];
    legal_details: LegalDetail[];
    company: {
        companyname: string;
        address: {
            address_formatted: string;
            address_details: {
                street: string,
                street_number: string,
                city: string,
                country: string,
                zip_code: string
            }
        }
    };
    order_options: CheckoutOptions;
}

export interface SessionData {
    merchant: MerchantFromSession;
    transaction: TransactionInfoFromSession;
    payment_methods: PaymentMethod[];
    payment_method: PaymentMethod;
    payment_wizard_options: PaymentWizardOptions;
    contract: Contract;
}

export interface Contract {
    pay_in_advance_account: object;
    payment_link_options?: PaymentLinkOptions;
}

export interface PaymentLinkOptions {
    background?: {
        enabled: boolean;
        image_url: string;
    }
}

export interface Address {
    country: Country;
    companyname: string;
    salutation: number;
    first_name: string;
    surname: string;
    town: string;
    street_with_number: string;
    zipcode: string;
    additional_address: string;
    phone: string;
}

export interface AddressFromSession {
    address: {
        country: string;
        city: string;
        street: string;
        street_number: string;
        postal_code: string;
        additional_address_data: string;
    };
    companyname: string;
    salutation: string;
    forename: string;
    surname: string;
    phone: string;
    email: string;
}

export interface InputAttributes extends JSX.DOMAttributes, JSX.HTMLAttributes {
    containerClassName: string;
    name: string; // it is required, because it is also used as field reference (key), so it can't be undefined
    validationInstance?: ValidationInstance;
    onSubmitFilter?: FilterInstance;
    optionsList?: string[];
    onBlurPaste?: FilterInstance; // set when default (trim) behavior should be override
    customProps?: {}; // unexpected props that may be unique to a certain input
}

export enum HttpResponseCode {
    HTTP_OK = 200,
    HTTP_MULTIPLE_CHOICES = 300,
    HTTP_BAD_REQUEST = 400,
    HTTP_UNAUTHORIZED = 401,
    HTTP_INTERNAL_SERVER_ERROR = 500,
}

export interface CcField {
    value: string;
    isValid: boolean;
    errorMessage: string;
    company?: object;
}

export interface CcData {
    owner: CcField;
    cardNumber: CcField;
    expDate: CcField;
    encryptedData: CcEcryptedData;
    allFieldsAreValid: boolean;
}

export interface CcEcryptedData {
    container_hash: string;
    crypted_container: string;
    crypted_skey: string;
    key_filename: string;
    masked: {
        card_number: string;
        card_owner: string;
    };
}

export enum CheckoutAction {
    SEND_THE_BASKET = 'send-the-basket',
}

export enum CcAction {
    GET_ENCRYPTED_DATA = 'get-cc-data',
    PREPARED_ENCRYPTED_DATA = 'prepared-cc-data',
    HANDLE_SUCCESS_PAGE = 'handle-success-page',
    HANDLE_ERROR_PAGE = 'handle-error-page',
    SET_LANG = 'set-lang',
    SET_OWNER = 'set-owner',
    SET_MANDATORY_COLOR = 'set-mandatory-color',
    CC_IFRAME_HEIGHT = 'cc-iframe-height',
    SET_THEME = 'set-theme'
}

export enum CcIssuer {
    VISA = 'VISA',
    MASTER_CARD = 'MasterCard',
    AMERICAN_EXPRESS = 'American Express',
    JCB = 'JCB',
    CHINA_UNION_PAY = 'China UnionPay',
    DINERS = 'Diners',
    DISCOVER = 'Discover'
}

interface BasePackage {
    action: CcAction | CheckoutAction | null;
    data: { data: string } | any;
}

// credit card communication standard
export interface CcPackage extends BasePackage {
    data: CcData | null;
    lang: LanguageSymbol;
    owner?: string;
    color?: string;
    documentHeight?: number;
    theme?: string;
}

export interface CheckoutPackage extends BasePackage {
    basket: Basket;
    contractId: string;
    isDemo: boolean;
    merchantUrls: ReturnUrls;
    server: string;
    storage: StorageMethod;
}

export enum Payment3dsStatus {
    SUCCESS = 'payment-3ds-success',
    FAIL = 'payment-3ds-fail'
}

// accepted params set in Url
export enum ParamFromUrl {
    VIEW_MODE = 'viewMode',
    LANG = 'lang',
    URL_TOKEN = 'token',
    STX = 'stx',
    SERVER = 'server',
    WIZARD_FLOW = 'wizardFlow',
    INIT_PAYMENT_METHOD = 'initPaymentMethod',
    TRACE_NO = 'TraceNo',
    CONFIRM_IDENT = 'ConfirmIdent',
    THREE_D_SECURE_STATUS = 'threeDSecureResult',
    CC_THEME = 'ccTheme'
}

export enum ViewMode {
    FULLSCREEN = 'fullscreen',
    LAYER = 'layer'
}

export enum StorageMethod {
    CACHE = 'cache',
    SESSION = 'session',
}

// export interface StageData {
//     stage: AppStage;
//     message: string;
// }

export interface StageData {
    back: AppStage | '';
    direct: AppStage[];
    next: AppStage | '';
    stage: AppStage;
    message?: string;
}

export enum InputName {
    DATE = 'date',
    SALUTATION = 'salutation',
    FIRST_NAME = 'fname',
    SURNAME = 'lname',
    COUNTRY = 'country',
    STREET_AND_STREET_NUMBER = 'address',
    TOWN = 'city',
    PHONE = 'phone',
    ZIP_CODE = 'zip',
    EMAIL = 'email',
    ADDITIONAL_ADDRESS_DATA = 'additional',
    OWNER = 'name',
    IBAN = 'iban',
    COMPANY_NAME = 'org',
    EMPTY = 'empty'
}

export enum TransactionStatus {
    STATUS_APPROVED = 'approved',
    STATUS_CREATED = 'created'
}

export enum ParamInAttr {
    PREFIX = 'data-sc-',
}

export enum ExternalProcessResultType {
    TYPE_PAYPAL_EXPRESS = 'paypalExpressResult',
    TYPE_PAYPAL_REGULAR = 'paypalRegularResult',
    TYPE_THREE_D_SECURE = 'threeDSecureResult',
}

export enum ExternalProcessResult {
    RESULT_SUCCESS = 'success',
    RESULT_FAILURE = 'failure',
}

export enum PayPalMode {
    MODE_CHECKOUT = 'checkout',
    MODE_PAYMENT = 'payment',
}

export enum PayPalFlowType {
    FLOW_TYPE_EXPRESS = 'express',
    FLOW_TYPE_REGULAR = 'regular',
}

export enum WizardFlow {
    CHECKOUT = 'checkout',
    PAYMENT = 'payment'
}

export enum BasketDisplay {
    FULL = 'full',
    ORDER_TOTAL = 'orderTotal'
}

export enum MainButtonType {
    SUBMIT = 'submit',
    CANCEL = 'cancel'
}

export interface CheckoutOptions {
    background: {
        enabled: boolean;
        image_url: string;
    }
}

export enum ProgressStep {
    ADDRESS = 'progress-address',
    DELIVERY = 'progress=delivery',
    PAYMENT = 'progress-payment',
    SUMMARY = 'progress-summary',
    UNDEFINED = 'progress-undefined'
}

export enum Intent {
    SALE = 'sale',
    POS = 'pos',
    AUTHORIZATION = 'authorization',
    ORDER = 'order',
    CASHREG = 'cashreg',
    CHECKOUT = 'checkout',
}

export enum ErrorMessageKey {
    SESSION_TIMEOUT = 'session_timeout',
    ARBITRARY_SESSION_PROBLEM = 'arbitrary_session_problem',
    BOOT_PROBLEM = 'boot_problem',
    COLLECTION_MISCONFIGURATION = 'collection_misconfiguration',
    NO_PAYMENT_METHOD_REMAINING = 'no_payment_method_remaining',
    STX_START_PROBLEM = 'stx_start_problem',
    STX_ALREADY_STARTED = 'stx_already_started',
    STX_DELETED = 'stx_deleted',
    MISCELLANEOUS_ERROR = 'miscellaneous_error'
}
