import {h} from 'preact';
import {CommonProps, LanguageFormality, PaymentMethod, SessionData, WizardFlow} from '../common/types';
import {isNull} from "util";
import {createHeadlineElement} from "../common/utils";

export default function PaymentMethodHeadlineWithAssigmentAndScoring(props: CommonProps): JSX.Element | null {
    let assignmentType = 'formal';

    if (
        props.sessionData
        && props.sessionData.payment_wizard_options
        && props.sessionData.payment_wizard_options.language_formality
        && props.sessionData.payment_wizard_options.language_formality === LanguageFormality.INFORMAL
    ) {
        assignmentType = 'informal';
    }
    return (
        props.wizardFlow === WizardFlow.PAYMENT
            ? <div class="container">
                <div class="col-xs-12">
                    {createHeadlineElement(props, createHeadlineText(props), 'payment-method-headline')}

                    {/*removed due to: SPPAYIF-1421*/}
                    {/*{createAssignmentNote(props, assignmentType)}*/}

                    {createScoringNote(props, assignmentType)}
                </div>
            </div>
            : null
    );
}

function createHeadlineText(props: CommonProps): string {
    let msg: string;

    switch (props.paymentMethod) {
        case PaymentMethod.DIRECT_DEBIT:
            msg = props.getStringTranslationByKey('payment_method_direct_debit_payment');
            break;
        case PaymentMethod.CREDIT_CARD:
            msg = props.getStringTranslationByKey('payment_method_credit_card_payment');
            break;
        case PaymentMethod.INVOICE:
            msg = props.getStringTranslationByKey('payment_method_invoice_payment');
            break;
        case PaymentMethod.PAY_IN_ADVANCE:
            msg = props.getStringTranslationByKey('payment_method_advance_payment');
            break;
        default:
            msg = '';
    }
    return msg.toUpperCase();
}

function createAssignmentNote(props: CommonProps, assignmentType: string): JSX.Element | string | null {
    let msg: JSX.Element | string | null;
    const iframeOpts = (props.sessionData as SessionData).transaction.application_context.iframe_opts;

    if(iframeOpts && iframeOpts.has_accepted_disclaimer && iframeOpts.hide_disclaimer) {
        return null;
    }

    switch (props.paymentMethod) {
        case PaymentMethod.DIRECT_DEBIT:
            msg = props.getJSXTranslationByKey('direct_debit_assignment_' + assignmentType);
            break;
        case PaymentMethod.CREDIT_CARD:
            msg = props.getJSXTranslationByKey('credit_card_assignment_' + assignmentType);
            break;
        case PaymentMethod.INVOICE:
            msg = props.getJSXTranslationByKey('invoice_assignment_' + assignmentType);
            break;
        case PaymentMethod.PAY_IN_ADVANCE:
            msg = props.getJSXTranslationByKey('pay_in_advance_assignment_' + assignmentType);
            break;
        default:
            msg = null;
    }
    return isNull(msg) ? null : <p id="assignment-note"> {msg} </p>
}

function createScoringNote(props: CommonProps, assignmentType: string): JSX.Element | string | null {
    let msg: JSX.Element | string | null;


    switch (props.paymentMethod) {
        case PaymentMethod.DIRECT_DEBIT:
            // due to SPPAYIF-1423
            // msg = props.getJSXTranslationByKey('direct_debit_scoring_' + assignmentType);
            msg = null;
            break;
        case PaymentMethod.CREDIT_CARD:
            // due to SPPAYIF-1423
            // msg = props.getJSXTranslationByKey('credit_card_scoring_' + assignmentType);
            msg = null;
            break;
        case PaymentMethod.INVOICE:
            msg = props.getJSXTranslationByKey('invoice_scoring_' + assignmentType);
            break;
        case PaymentMethod.PAY_IN_ADVANCE:
            msg = null;
            break;
        default:
            msg = null;
    }
    return isNull(msg) ? null : <p id="scoring-note"> {msg} </p>
}