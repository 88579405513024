import {h} from 'preact';
import {ApplicationContext, Button, CommonProps, MainButtonType, PaymentWizardOptions, WizardFlow} from '../types';
import Spinner from '../components/Spinner';
import {getPrimaryColor, handleKeyboardEvent} from "../utils";

interface ButtonProps extends CommonProps {
    buttonInfo: Button;
    name?: string
}

export default function MainButton(props: ButtonProps): JSX.Element | null {
    let primaryColour: string = getPrimaryColor(props);

    if (props.buttonInfo.hidden || !props.isInitialized) {
        return null;
    } else {
            return     ((
                    <a onClick={props.buttonInfo.loading ? () => false : props.buttonInfo.onClick}
                       onKeyUp={props.buttonInfo.loading ? () => false : handleKeyboardEvent}
                       type={props.buttonInfo.submit ? 'submit' : ''}
                       disabled={props.buttonInfo.loading || props.buttonInfo.disabled}
                       class="btn col-xs-12 mt-10 mb-10 no-border next-button"
                       tabIndex={0}
                       name={props.name || "next-button"}
                       style={primaryColour && props.buttonInfo.type === MainButtonType.SUBMIT ? "background: " + primaryColour + " !important;" : ""}
                    >
                        {props.buttonInfo.buttonText ? createButtonLabel(props).toUpperCase() : ''}
                    </a>
                )
        );
    }
}

function createButtonLabel(props: ButtonProps): string {
    let appContext: ApplicationContext;
    let optionsFromTransaction: {cancel_button_title: string | null, submit_button_title: string | null};
    let optionsFromContract: PaymentWizardOptions;
    let defaultTranslation = props.getStringTranslationByKey(props.buttonInfo.buttonText);

    if (props.sessionData && props.wizardFlow === WizardFlow.PAYMENT) {
        appContext = props.sessionData.transaction.application_context;
        optionsFromTransaction = appContext.iframe_opts || {submit_button_title: '', cancel_button_title: ''};
        optionsFromContract = props.sessionData.payment_wizard_options;
        switch (props.buttonInfo.type) {
            case MainButtonType.CANCEL:
                return labelChooser(optionsFromTransaction.cancel_button_title, null, defaultTranslation);
            default:
                return labelChooser(optionsFromTransaction.submit_button_title, optionsFromContract.submit_button_title, defaultTranslation);
        }
    } else {
        return props.getStringTranslationByKey(props.buttonInfo.buttonText);
    }
}

// choose label base on priority: STX > GCR > local
function labelChooser(labelSTX: string | null, labelGCR: string | null, defaultTranslation: string): string {
    return labelSTX || (labelGCR || defaultTranslation);
}
