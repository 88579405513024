import {Component, h} from 'preact';
import DateTimePicker from '../../common/helpers/DateTimePicker';
import ShippingCollectionChoice from './ShippingCollectionChoice';
import {
    AppStage,
    CommonProps,
    ErrorMessageKey,
    OrderOption,
    PaymentMethod,
    PaymentWindow,
    SessionData
} from '../../common/types';
import InfoAddressComponent from '../../common/info/InfoAddressComponent';
import {isNull} from 'util';
import {AppContext} from "../app/AppComponent";
import {createHeadlineElement} from "../../common/utils";

interface ShippingCollectionComponentProps extends CommonProps {
}

interface ShippingCollectionComponentState {
    shipping: {
        isSet: boolean
    };
    collection: {
        isSet: boolean
    };
}

export default class ShippingCollectionComponent extends
    Component<ShippingCollectionComponentProps, ShippingCollectionComponentState> {
    private dtp: DateTimePicker;

    constructor(props: ShippingCollectionComponentProps, context: AppContext) {
        super(props);
        const onlyCollectionEnabled =
            !context.deliveryOptions.isShippingEnabled
            && context.deliveryOptions.isCollectionEnabled;
        this.state = {
            shipping: {
                isSet: !onlyCollectionEnabled
            },
            collection: {
                isSet: onlyCollectionEnabled
            },
        };
        this.goToNextStage = this.goToNextStage.bind(this);
        this.deliveryChoice = this.deliveryChoice.bind(this);
        this.collectionView = this.collectionView.bind(this);
        this.shippingView = this.shippingView.bind(this);
        this.props.setButton(this.goToNextStage, 'next_step_button');
    }

    public componentDidMount() {
        let sessionData: SessionData | null = this.props.sessionData;
        if(!isNull(sessionData)) {
            let orderOption: {type: OrderOption | null, time: string} = {type: null, time: ''};
            orderOption.type = sessionData.transaction.order_option;
            if (isNull(orderOption.type)) {
                return;
            }

            if(sessionData.transaction.order_option === OrderOption.ORDER_OPTION_COLLECTION) {
                orderOption.time = (sessionData.transaction.pickup_options as {date: string}).date;
            }

            switch (orderOption.type) {
                case OrderOption.ORDER_OPTION_SHIPPING:
                    this.chooseShipping();
                    break;
                case OrderOption.ORDER_OPTION_COLLECTION:
                    this.chooseCollection();
                    break;
                default:
                    this.props.setStage(AppStage.STAGE_ERROR, ErrorMessageKey.COLLECTION_MISCONFIGURATION);
            }
        }

        // this.callBackend('/chosen-order-option', (response: ResponseHttp) => {
        //     const orderOption: {type: OrderOption | null, time: string} = JSON.parse(response.text);
        //     if (isNull(orderOption.type)) {
        //         return;
        //     }
        //     switch (orderOption.type) {
        //         case OrderOption.ORDER_OPTION_SHIPPING:
        //             this.chooseShipping();
        //             break;
        //         case OrderOption.ORDER_OPTION_COLLECTION:
        //             this.chooseCollection(new Date(Date.parse(orderOption.time)));
        //             break;
        //         default:
        //             this.props.setStage(AppStage.STAGE_ERROR);
        //     }
        // });
    }

    public render(): JSX.Element|null {
        if (this.state.collection.isSet) {
            return this.collectionView();
        } else if (this.state.shipping.isSet) {
            return this.shippingView();
        }
        return null;
    }

    private deliveryChoice(event: Event) {
        let target: HTMLInputElement;
        target = event.target as HTMLInputElement;
        if (target.classList.contains('disabled')) {
            return;
        } else {
            if (target.id === 'shipping-button') {
                this.chooseShipping();
            } else if (target.id === 'collection-button') {
                this.chooseCollection();
            }
        }
    }

    private goToNextStage(): void {
        if (this.state.collection.isSet && !this.dtp.validateTime()) {
            parent.window.postMessage('JumpToTheTop', '*');

            if (window.scrollY) {
                window.scroll(0, 0);  // reset the scroll position to the top left of the document.
            }

            (document.getElementById('content') as HTMLInputElement).scrollTop = 0;
            return;
        }

        this.props.setLoading(true);

        this.props.callBackend(
            '/order-option',
            () => {
                if (this.props.stageData.next !== AppStage.STAGE_PAYMENT_INPUT) {
                    this.props.setStage(this.props.stageData.next as AppStage);
                } else {
                    const paymentMethods = this.props.getAvailablePaymentMethods();
                    this.props.callBackend(
                        '/payment-method',
                        () => {
                            switch (paymentMethods[0]) {
                                case PaymentMethod.DIRECT_DEBIT:
                                    this.props.setStage(AppStage.STAGE_PAYMENT_INPUT, PaymentWindow.WINDOW_DIRECT_DEBIT);
                                    break;
                                case PaymentMethod.CREDIT_CARD:
                                    this.props.setStage(AppStage.STAGE_PAYMENT_INPUT, PaymentWindow.WINDOW_CREDIT_CARD);
                                    break;
                                case PaymentMethod.INVOICE:
                                    this.props.callBackend(
                                        '/prepare',
                                        () => this.props.setStage(AppStage.STAGE_CONFIRMATION),
                                        'POST',
                                        {},
                                        () => {
                                            this.props.setBoxTextMessageAtTheBeginning(this.props.getStringTranslationByKey(
                                                'payment_method_one_available_scoring_failed')
                                            );
                                        }
                                    );
                                    break;
                                case PaymentMethod.PAY_IN_ADVANCE:
                                    this.props.setStage(AppStage.STAGE_CONFIRMATION);
                                    break;
                                default:
                                    break;
                            }
                        },
                        'POST',
                        {type: paymentMethods[0]}
                    );
                }
            },
            'POST',
            this.state.collection.isSet ? {
                orderOption: OrderOption.ORDER_OPTION_COLLECTION,
                collectionTimestamp: this.dtp.getTimestamp() / 1000
            } : {
                orderOption: OrderOption.ORDER_OPTION_SHIPPING
            }
        );
    }

    private chooseShipping() {
        this.setState({
            shipping: {
                isSet: true
            },
            collection: {
                isSet: false
            }
        });
    }

    private chooseCollection() {
        this.setState({
            shipping: {
                isSet: false
            },
            collection: {
                isSet: true
            },
        });
    }

    private collectionView(): JSX.Element {
        return (
            <div>
                <div class="container">
                    <div class="col-sm-12">
                        {createHeadlineElement(this.props, this.props.getStringTranslationByKey('delivery_header'))}
                    </div>
                </div>
                <ShippingCollectionChoice
                    shipping={
                        {isEnabled: this.context.deliveryOptions.isShippingEnabled,
                            isSet: this.state.shipping.isSet}
                    }
                    collection={
                        {isEnabled: this.context.deliveryOptions.isCollectionEnabled,
                            isSet: this.state.collection.isSet}
                    }
                    chosenOption={this.deliveryChoice}
                    {...this.props as CommonProps}
                />
                <div class="container">
                    <p class="content-headline-theme col-xs-12">
                        {this.props.getStringTranslationByKey('delivery_time')}
                    </p>
                </div>
                <div class="container">
                    <DateTimePicker
                        ref={(dtp) => { this.dtp = dtp; }}
                        {...this.props as CommonProps} />
                </div>
            </div>
        );
    }

    private shippingView(): JSX.Element {
        return (
            <div>
                <div class="container">
                    <div class="col-sm-12">
                        {createHeadlineElement(this.props, this.props.getStringTranslationByKey('delivery_header'))}
                    </div>
                </div>
                <ShippingCollectionChoice
                    shipping={{isEnabled: this.context.deliveryOptions.isShippingEnabled, isSet: this.state.shipping.isSet}}
                    collection={{isEnabled: this.context.deliveryOptions.isCollectionEnabled, isSet: this.state.collection.isSet}}
                    chosenOption={this.deliveryChoice}
                    {...this.props as CommonProps}
                />
                <div class="container mb-10">
                    <p class="content-headline-theme col-xs-12 no-margin-bottom">
                        {this.props.getStringTranslationByKey('shipping_headline')}
                    </p>
                    <InfoAddressComponent {...this.props as CommonProps}/>
                </div>
            </div>
        );
    }
}