import {h, Component} from 'preact';
import {
    AppStage,
    CommonProps,
    OrderOption, ParamFromUrl,
    PaymentMethod,
    ViewMode
} from '../../common/types';
import DirectDebitTemplate from './DirectDebitTemplate';
import PayInAdvanceTemplate from './PayInAdvanceTemplate';
import InfoBasket from '../../common/info/InfoBasketComponent';
import InfoMerchantAccept from '../../common/info/InfoMerchantAccept';
import InfoMerchantComponent from '../../common/info/InfoMerchantComponent';
import PaymentContainer from '../../common/payment/PaymentContainer';
import CollectionOptionTemplate from '../../templates/CollectionOptionTemplate';
import ShippingOptionTemplate from '../../templates/ShippingOptionTemplate';
import CreditCardTemplate from './CreditCardTemplate';
import InvoiceTemplate from './InvoiceTemplate';
import ChangePaymentMethodButton from '../../common/buttons/ChangePaymentMethodButton';
import Spinner from '../../common/components/Spinner';
import {isNull} from 'util';
import {createHeadlineElement, exitSmartCheckout, getParamFromUrl} from '../../common/utils';
import PayPalTemplate from './PayPalTemplate';

interface ConfirmationComponentProps extends CommonProps {
    isInitialized: boolean;

    setInitialized(isInitialized: boolean): void;
}

interface ConfirmationComponentState {
    canPay: boolean;
    orderOption: {
        type: OrderOption | null,
        time: string,
    };
}

export default class ConfirmationComponent
    extends Component<ConfirmationComponentProps, ConfirmationComponentState> {

    private paymentContainer: PaymentContainer = new PaymentContainer();

    constructor(props: ConfirmationComponentProps) {
        super(props);
        this.state = {
            canPay: false,
            orderOption: {
                type: null,
                time: ''
            },
        };

        this.goToNextStage = this.goToNextStage.bind(this);
        this.goBackToAddress = this.goBackToAddress.bind(this);
        this.renderDeliveryOptionArea = this.renderDeliveryOptionArea.bind(this);
        this.props.setButton(this.goToNextStage, 'confirm_button_text', false, false, !this.state.canPay);
        this.isChangePaymentButtonShown = this.isChangePaymentButtonShown.bind(this);
        this.showPayButton = this.showPayButton.bind(this);
    }

    componentDidMount() {
        this.props.handleMessageAtTheBeginning();
        this.paymentContainer.retrieveData().then(
            () => {
                let paymentMethod = this.paymentContainer.getType();
                this.forceUpdate();
                this.props.setPaymentMethod(paymentMethod);
                // because this is async call
                this.showPayButton(paymentMethod);
            }
        ).catch(
            () => this.props.setStage(AppStage.STAGE_ERROR)
        );
    }

    public render(): JSX.Element {
        let paymentInfo: JSX.Element;
        const resultArray: JSX.Element[] = [];
        switch (this.props.paymentMethod) {
            case PaymentMethod.SECUCARD:
                break;
            case PaymentMethod.DIRECT_DEBIT:
                const directDebit = this.paymentContainer.getDirectDebit();
                if (directDebit.iban) {
                    resultArray.push(<DirectDebitTemplate {...directDebit} currentLang={this.props.currentLang}/>);
                } else {
                    resultArray.push(<div>Can't charge more that 1 direct debit account</div>);
                }
                break;
            case PaymentMethod.CREDIT_CARD:
                const creditCard = this.paymentContainer.getCreditCard();
                resultArray.push(
                    <CreditCardTemplate
                        {...creditCard}
                        getStringTranslationByKey={this.props.getStringTranslationByKey}
                    />
                );
                break;
            case PaymentMethod.PAY_IN_ADVANCE:
                resultArray.push(<PayInAdvanceTemplate {...this.props as CommonProps}/>);
                break;
            case PaymentMethod.INVOICE:
                resultArray.push(<InvoiceTemplate {...this.props as CommonProps}/>);
                break;
            case PaymentMethod.PAYPAL:
                resultArray.push(<PayPalTemplate {...this.props as CommonProps}/>);
                break;
            default:
                break;
        }

        paymentInfo = resultArray.length === 0 ? <Spinner/> : <div> {...resultArray} </div>;

        return (
            <div>
                {this.props.isInitialized ? <InfoMerchantComponent
                    isShippingEnabled={this.context.deliveryOptions.isShippingEnabled}
                    {...this.props as CommonProps}
                    additionalWrapperClass={'mb-30'}
                /> : null}

                {this.props.isInitialized ? <InfoBasket
                    {...this.props as CommonProps}
                    additionalWrapperClass={'mb-30'}
                /> : null}

                {!isNull(this.props.sessionData) ? this.renderDeliveryOptionArea(this.props.sessionData.transaction.order_option) : null}

                <div class="container" id="payment-method">
                    <div className="col-sm-12">
                        {createHeadlineElement(this.props, this.props.getStringTranslationByKey('payment_method_headline'))}
                    </div>
                    <div className="col-sm-12 payment-info">
                        {paymentInfo}
                    </div>
                    {this.isChangePaymentButtonShown() ? <ChangePaymentMethodButton {...this.props} /> : null}
                </div>

                <div class="container">
                    <div class="col-sm-12 mt-30">
                        <InfoMerchantAccept {...this.props as CommonProps} />
                    </div>
                </div>
            </div>
        );
    }

    private renderDeliveryOptionArea(orderOption: OrderOption): JSX.Element | null {
        switch (orderOption) {
            case OrderOption.ORDER_OPTION_COLLECTION:
                return <CollectionOptionTemplate {...this.props} />;
            default:
                return <ShippingOptionTemplate {...this.props} />;
        }
    }

    private goToNextStage(): void {
        let data: {traceNo?: string; confirmIdent?: string} = {};
        this.props.showMessageBox(false);

        this.props.setLoading(true, false);

        this.props.setLoading(true);
        this.props.callBackend(
            '/start',
            () => {
                // We don't use here "next" stage from stageData
                // We set fixed Success stage
                this.props.fetchSessionDataFromBackend(() => {
                    this.props.setStageWithEmptySteps(AppStage.STAGE_SUCCESS);
                    this.props.setButton(
                        () => {
                            this.props.setLoading(true);
                            exitSmartCheckout(this.context.returnUrls.urlSuccess, {}, this.props.viewMode);
                        },
                        'success_back_to_the_shop_button',
                        false,
                        false,
                        this.context.returnUrls.urlSuccess === ''
                    );
                    this.props.setLoading(false);
                });
            },
            'POST',
            data
        );
    }

    private goBackToAddress(): void {
        this.props.setStage(AppStage.STAGE_ADDRESS);
    }

    /**
     * Helper function which tells us, does "Change payment method" button have to be shown
     *
     * @returns {boolean}
     */
    private isChangePaymentButtonShown() {
        return this.props.stageData.direct.indexOf(AppStage.STAGE_PAYMENT_SELECTION) !== -1
            || this.props.stageData.direct.indexOf(AppStage.STAGE_PAYMENT_INPUT) !== -1;
    }

    /**
     * Function which set Pay button visibility base on payment conditions
     * Later we can expand condition with other rules
     * @param paymentMethod
     */
    private showPayButton(paymentMethod: PaymentMethod) {
        if (paymentMethod === PaymentMethod.DIRECT_DEBIT && !this.paymentContainer.getDirectDebit().iban) {
            this.setState({canPay: false});
        } else {
            this.setState({canPay: true});
        }
        this.props.setButton(this.goToNextStage, 'confirm_button_text', false, false, !this.state.canPay);
    }
}