import { h } from 'preact';
import {AddressFromSession, CommonProps, Country} from '../types';
import { isNull } from 'util';
import {getCountryTranslationByIsoCode} from "../utils";

    export default function InfoAddressComponent(props: CommonProps) {

    const showAddressData = (fields: (string | null)[], separator: string, additionalClass?: string) => {
        return (
            <div class={additionalClass}>{fields.filter(function (field: string) {
                    return !isNull(field);
                }).join(separator)}
            </div>
        );
    };

    const showFormattedAddress = (contact: AddressFromSession) => {
        if (isNull(contact)) {
            return null;
        }
        return (
            <div class="address-info">
                {contact.companyname ? showAddressData(
                    [contact.companyname],
                    ' ',
                    'text-bold company-name'
                ) : null}
                {showAddressData(
                    [contact.forename, contact.surname],
                    ' '
                )}
                {showAddressData(
                    [contact.address.street, contact.address.street_number],
                    ' '
                )}
                {showAddressData(
                    [contact.address.additional_address_data],
                    ', '
                )}
                {showAddressData(
                    [contact.address.postal_code, contact.address.city],
                    ' '
                )}
                {showAddressData(
                    [getCountryTranslationByIsoCode(contact.address.country, props.currentLang)],
                    ' '
                )}
                {contact.phone ? showAddressData(
                    [contact.phone],
                    ' ',
                    'phone'
                ) : null}
            </div>
        );
    }

        return (
            !isNull(props.sessionData) && !isNull(props.sessionData.transaction.customer.contact) && !isNull(props.sessionData.transaction.customer.contact.address) ?
                <div className="col-sm-12 customer-address overflow-auto">
                    {showFormattedAddress(props.sessionData.transaction.customer.contact)}
                </div>
                : null
        );
}
