import { h, Component } from 'preact';
import { SecucardWindow } from './SecucardMainComponent';
import { CommonProps } from '../../../common/types';

interface ShowSecuCreditComponentProps extends CommonProps {
    getChosenCards: () => boolean[];
    toggleCardStatus: (idx: number, val?: boolean) => void;
    setSecucardStage: (stage: SecucardWindow) => void;
    goToConfirmation: () => void;
    total: number;
}

interface ShowSecuCreditComponentState {
    credit: number;
    currency: string;
}

export default class ShowSecuCreditComponent extends Component<ShowSecuCreditComponentProps,
    ShowSecuCreditComponentState> {

    public constructor() {
        super();
        this.showButtons = this.showButtons.bind(this);
        this.calculateSum = this.calculateSum.bind(this);
    }

    public showButtons(): JSX.Element | undefined {
        if (this.state.credit - this.props.total > 0) {
            return (
                <button
                    onClick={this.props.goToConfirmation}
                    class="btn col-xs-12 mv-30 no-border next-button">
                    {this.props.getStringTranslationByKey('pay_secucard_button_text')}
                </button>);
        } else {
            return (
                <div>
                    <button onClick={() => this.props.setSecucardStage(SecucardWindow.WINDOW_INPUT)}
                            class="btn col-xs-12 mv-30 no-border change-button">
                        {this.props.getStringTranslationByKey('change_secucard_button_text')}</button>
                    <button class="btn col-xs-12 mv-30 no-border change-button">
                        {this.props.getStringTranslationByKey('add_another_method_button_text')}</button>
                </div>);
        }
    }

    public render() {

        const chosenSecucards: number[] = [];
        const notChosenSecucards: number[] = [];

        for (let i = 0; i < this.props.getChosenCards().length; i++) {
            if (this.props.getChosenCards()[i]) {
                chosenSecucards.push(i);
            } else {
                notChosenSecucards.push(i);
            }
        }

        this.calculateSum();

        /* tslint:disable:max-line-length */
        return (
            <div class="content-text-theme">
                <section class="scrollbar">

                    <div class="container">
                        <div className="col-sm-12"><h3
                            class="headline-theme">{this.props.getStringTranslationByKey('payment_amount_header')}</h3>
                            <div class="output-field-theme col-sm-6 border-total mt-10">
                                <div class="display-inline ml-5">{this.props.getStringTranslationByKey('order_total')}:
                                </div>
                                <div class="position-absolute display-inline right-20"> {this.props.total + ' €'} </div>
                            </div>
                        </div>
                    </div>

                    <div class="container">
                        <hr/>
                        <div className="col-sm-12"><h2 class="headline-theme">SECUCARD</h2></div>

                        <div class="col-sm-6">
                            <div class="output-field-theme border-total mt-10 pl-15 pr-15">
                                <div class="display-inline ml-5">{this.props.getStringTranslationByKey('credit')}:</div>
                                <div
                                    class="display-inline position-absolute right-30"> {this.state.credit + ' €'} </div>
                            </div>
                        </div>

                        <div class=" col-sm-6">
                            <div class="output-field-theme border-total mt-10 pl-15 pr-15">
                                <div
                                    class="display-inline ml-5">{this.props.getStringTranslationByKey('remaining_total_amount')}:
                                </div>
                                <div
                                    class="display-inline position-absolute right-30"> {this.state.credit - this.props.total + ' €'} </div>
                            </div>
                        </div>

                    </div>

                    <div class="container">
                        <hr/>
                        <div className="col-sm-12"><h2 class="headline-theme">CHOSEN SECUCARDS</h2></div>
                        {chosenSecucards.map(
                            (val, idx) => (
                                <a
                                    onClick={() => this.props.toggleCardStatus(val)}
                                >
                                    {/*<SecuCardTemplate*/}
                                        {/*{...this.props.paymentContainer.getSecucards()[val]}*/}
                                        {/*additionalClass="payment-item-unchecked"*/}
                                    {/*/>*/}
                                </a>
                            )
                        )}
                    </div>

                    <div class="container">
                        <hr/>
                        <div className="col-sm-12"><h2 class="headline-theme">REMAINING SECUCARDS</h2></div>
                        {notChosenSecucards.map(
                            (val, idx) => (
                                <a
                                    onClick={() => this.props.toggleCardStatus(val)}
                                >
                                    {/*<SecuCardTemplate*/}
                                        {/*{...this.props.paymentContainer.getSecucards()[val]}*/}
                                        {/*additionalClass="payment-item-unchecked"*/}
                                    {/*/>*/}
                                </a>
                            )
                        )}
                    </div>

                    <div class="container">

                        {this.showButtons()}

                    </div>
                </section>
            </div>

        );
        /* tslint:enable:max-line-length */
    }

    private calculateSum() {
        let sum: number = 0;

        this.props.getChosenCards().forEach((val, idx) => {
            if (val) {
                // sum += this.props.paymentContainer.getSecucards()[idx].balance / 100;
            }
        });

        this.setState({credit: sum});
    }
}