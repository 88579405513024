import {Component, h} from 'preact';
import {CommonProps, ErrorMessageKey, Intent, ViewMode} from '../../common/types';
import {exitSmartCheckout, getEnumValues} from '../../common/utils';

interface ErrorComponentProps extends CommonProps {
    errorMessageKey?: ErrorMessageKey;
}

export default class ErrorComponent extends Component<ErrorComponentProps, {}> {
    constructor(props: ErrorComponentProps) {
        super(props);
        this.quit = this.quit.bind(this);
    }

    public componentDidMount(): void {
        this.props.setButton(
            this.quit,
            'error_back_to_the_shop_button',
            false,
            false,
            this.context.returnUrls.urlError === ''
        );
    }

    public render(): JSX.Element {
        let errorMessage = this.createErrorMessage();

        return (
            <div id="error-page">
                <div class="mt-50"/>
                <div class="container mt-50 mb-50 text-center">
                    <h2 id="error-headline">{errorMessage.headline}</h2>
                    <div id="error-message">
                    {errorMessage.body}
                    </div>
                    <div class="mt-150"/>
                </div>
            </div>
        );
    }

    private quit(): void {
        this.props.setLoading(true);
        switch (this.props.viewMode) {
            case ViewMode.LAYER:
                exitSmartCheckout(this.context.returnUrls.urlError, {}, this.props.viewMode);
                break;
            case ViewMode.FULLSCREEN:
                exitSmartCheckout(this.context.returnUrls.urlError, {}, this.props.viewMode);
                break;
            default:
                break;
        }
    }

    private createErrorMessage(): {headline: string, body: string } {
        let errorMessageKey: string = ErrorMessageKey.MISCELLANEOUS_ERROR;

        if (this.props.errorMessageKey && getEnumValues(ErrorMessageKey).includes(this.props.errorMessageKey)) {
            errorMessageKey = this.props.errorMessageKey;

            // TODO
            // Currently we have only different translation for Sale and STX_ALREADY_STARTED
            if(this.props.intent === Intent.SALE && this.props.errorMessageKey === ErrorMessageKey.STX_ALREADY_STARTED) {
                errorMessageKey = errorMessageKey + '_' + Intent.SALE;
            }
        }

        return {
            headline: this.props.getStringTranslationByKey('error_headline_' + errorMessageKey),
            body: this.props.getStringTranslationByKey('error_message_' + errorMessageKey)

        }
    }
}