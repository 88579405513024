import {h} from 'preact';
import {ApplicationContext, CommonProps, PaymentHintElement, PaymentWizardOptions, WizardFlow} from "../common/types";
import {isNull, isNullOrUndefined} from "util";
import {createHeadlineElement} from "../common/utils";

export function PaymentHints(props: CommonProps): JSX.Element | null {
    let appContext: ApplicationContext;
    let optionsFromTransaction: { payment_hint: PaymentHintElement[] | null, payment_hint_title: string | null };
    let optionsFromContract: PaymentWizardOptions;
    let paymentHints: PaymentHintElement[];

    if (props.sessionData && props.wizardFlow === WizardFlow.PAYMENT) {
        appContext = props.sessionData.transaction.application_context;
        optionsFromTransaction = appContext.iframe_opts || {payment_hint: null, payment_hint_title: null};
        optionsFromContract = props.sessionData.payment_wizard_options;

        paymentHints = paymentHintChooser(optionsFromTransaction.payment_hint, optionsFromContract.payment_hint);

        return paymentHints.length > 0 ? <div class="container mb-10" id="payment-hints-wrapper">
            <div class="col-xs-12">
                {createHeadlineElement(props, createPaymentHintHeader(props, optionsFromTransaction.payment_hint_title).toUpperCase())}
                <div id="payment-hints">
                    {paymentHints.map(element => createPaymentHintElement(element))}
                </div>
            </div>
        </div> : null
    } else {
        return null;
    }
}

// choose label base on priority: STX > GCR > local
function paymentHintChooser(hintSTX: PaymentHintElement[] | null, hintGCR: PaymentHintElement[] | null): PaymentHintElement[] {
    return !isNullOrUndefined(hintSTX) ? hintSTX : (!isNullOrUndefined(hintGCR) ? hintGCR : []);
}

function createPaymentHintElement(ph: PaymentHintElement): JSX.Element {
    return <div class="element">
        <span class="name">{ph.value.name}</span><span class="value">{ph.value.value}</span>
    </div>
}

function createPaymentHintHeader(props: CommonProps, paymentHintTitle: string | null) {
    return paymentHintTitle || props.getStringTranslationByKey('payment_hint');
}

