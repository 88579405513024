import {Component, h} from 'preact';
import {isNull, isNullOrUndefined} from 'util';
import {
    Address,
    AppStage,
    CcAction,
    CcData,
    CcPackage,
    CommonProps,
    Intent,
    MainButtonType, PaymentWindow,
    ResponseHttp,
    TransactionStatus,
    ViewMode,
    WizardFlow
} from '../../../common/types';
import {
    createHeadlineElement,
    exitSmartCheckout,
    exitSmartCheckoutDirectly,
    getOwnerFromSessionData,
    getPrimaryColor,
    goToSuccessPage,
    isIE11
} from '../../../common/utils';
import PaymentMethodHeadlineWithAssigmentAndScoring
    from "../../../templates/PaymentMethodHeadlineWithAssigmentAndScoring";
import {ProjectNameAndLogo} from "../../../templates/ProjectNameAndLogo";
import {BasketOrOrderTotal} from "../../../templates/BasketOrOrderTotal";
import {PaymentLinkInfoHeader} from "../../../templates/PaymentLinkInfoHeader";
import {PaymentHints} from "../../../templates/PaymentHints";

const config = require('./../../../common/config.json');
const ccPath = config.creditCardPath;

interface CreditCardComponentProps extends CommonProps {
    total: number;
    currency: string;
}

interface CreditCardComponentState {
    iframeUrl: string;
    iframeHeight: number;
    isIframeLoaded: boolean;
    is3DSecure: boolean;
}

// all isIE11() checks are to prevent setState execution
// because on IE11 iframe will be reloaded


export default class CreditCardComponent
    extends Component<CreditCardComponentProps, CreditCardComponentState> {
    private creditCardIframe: HTMLIFrameElement | null = null;
    private langFlag: HTMLIFrameElement | null = null;
    private ccPackage: CcPackage | null = null;

    public constructor(props: CreditCardComponentProps) {
        super(props);

        this.state = {
            iframeUrl: ccPath,
            iframeHeight: this.getDefaultIframeHeight(),
            isIframeLoaded: false,
            is3DSecure: false
        };

        this.getEncryptedDataFromCcIframe = this.getEncryptedDataFromCcIframe.bind(this);
        this.setPostMessageCommunication = this.setPostMessageCommunication.bind(this);
        this.langFlagClickEvent = this.langFlagClickEvent.bind(this);
        this.sendCcPackage = this.sendCcPackage.bind(this);
        this.goToNextStage = this.goToNextStage.bind(this);

        switch (this.props.wizardFlow) {
            case WizardFlow.CHECKOUT:
                this.props.setButton(this.goToNextStage, 'confirm_payment');
                break;
            case WizardFlow.PAYMENT:
                this.cancelButton = this.cancelButton.bind(this);
                let label: string = 'pay_now';
                if (this.props.sessionData && this.props.sessionData.transaction.intent === Intent.AUTHORIZATION) {
                    label = 'continue';
                }
                this.props.setButton(this.goToNextStage, label);
        }
    }

    // workaround for IE11 (rerender also reload iframe)
    shouldComponentUpdate(nextProps: CreditCardComponentProps, nextState: CreditCardComponentState) {
        if (isIE11() && nextState.isIframeLoaded && (this.state.iframeUrl === nextState.iframeUrl)) {
            return false;
        } else {
            return true;
        }
    }

    public componentDidMount() {
        this.showBackButton();
        this.setPostMessageCommunication();
        this.langFlag = document.querySelector('.img-lang-switch');

        if (!isNull(this.langFlag)) {
            this.langFlag.addEventListener('click', this.langFlagClickEvent);
        }
    }

    public componentWillUnmount() {
        if (!isNull(this.langFlag)) {
            this.langFlag.removeEventListener('click', this.langFlagClickEvent);
        }
    }

    public render() {
        return (
            <div>
                <PaymentLinkInfoHeader {...this.props}
                                       isShippingEnabled={this.context.deliveryOptions.isShippingEnabled}
                                       additionalWrapperClass={'mb-30'}/>
                <ProjectNameAndLogo {...this.props}/>
                {
                    this.props.wizardFlow === WizardFlow.CHECKOUT
                        ? <div class="container">
                            <div class="col-xs-12">
                                {createHeadlineElement(this.props, this.props.getStringTranslationByKey('credit_card').toUpperCase())}
                            </div>
                        </div>
                        : null
                }
                <BasketOrOrderTotal {...this.props} total={this.props.total} currency={this.props.currency}/>
                <PaymentHints {...this.props} />
                <PaymentMethodHeadlineWithAssigmentAndScoring {...this.props} />
                <div class="container">
                    <div class="col-xs-12">
                        <iframe
                            class="center-block pl-0 pr-0 mt-10"
                            id="credit-card-iframe"
                            name="credit-card-iframe"
                            data-3dsecure={this.state.is3DSecure.toString()}
                            src={this.state.iframeUrl}
                            frameBorder="0"
                            style={`height: ${this.state.iframeHeight}px`}
                            ref={(ref) => this.creditCardIframe = ref}
                            sandbox="allow-scripts allow-forms allow-top-navigation"
                        />
                    </div>
                </div>
            </div>
        );
    }

    private langFlagClickEvent() {
        this.setLanguageInCcIrame();
    }

    // set owner in cc iframe
    private setOwner(): void {
        const ccPackage: CcPackage = {
            action: CcAction.SET_OWNER,
            lang: this.props.currentLang,
            data: null,
        };

        if (this.props.sessionData) {
            ccPackage.owner = getOwnerFromSessionData(this.props.sessionData);
            this.sendCcPackage(ccPackage);
        } else {
            this.props.callBackend(
                '/address',
                (response: ResponseHttp) => {
                    const address: Address = JSON.parse(response.text) as Address;
                    if (address.first_name && address.surname) {
                        ccPackage.owner = address.first_name + ' ' + address.surname;
                        this.sendCcPackage(ccPackage);
                    }
                }
            );
        }
    }

    private goToNextStage(): void {
        // Credit card preparation is known to be slow, so show preloader immediately
        this.props.showMessageBox(false, '');
        this.props.setLoading(true, false);
        if (!isNull(this.creditCardIframe) && !isNull(this.creditCardIframe.contentWindow)
        ) {
            // two-way communication

            this.getEncryptedDataFromCcIframe().then(
                (ccPackage) => {
                    if (ccPackage.data) {
                        if (ccPackage.data.allFieldsAreValid) {
                            this.prepareOrder(ccPackage.data);
                        } else {
                            this.props.setLoading(false);
                            return;
                        }
                    }
                },
                () => {
                    throw new Error('ERROR: No data from cc iframe');
                }
            );
        }
    }

    private getEncryptedDataFromCcIframe(): Promise<CcPackage> {
        // wrap all this action in Promise, so it looks cool and is *partially* reusable
        return new Promise<CcPackage>((resolve: (ccPackage: CcPackage) => void, reject) => {
            // silly timer
            // maybe later we can solve it in a better way
            let currentTry = 0;
            const maxTries = 5,
                interval = 100;

            const intervalId = setInterval(
                () => {
                    if ((!isNull(this.ccPackage)) || currentTry >= maxTries) {
                        clearInterval(intervalId);
                        if (isNull(this.ccPackage)) {
                            reject();
                        } else {
                            resolve(this.ccPackage);
                        }
                        this.ccPackage = null;
                    } else {
                        this.sendCcPackage({
                            action: CcAction.GET_ENCRYPTED_DATA,
                            lang: this.props.currentLang,
                            data: null
                        });
                        currentTry++;
                    }
                },
                interval
            );
        });
    }

    // set communication with cc iframe and script executed after 3d Secure process
    private setPostMessageCommunication() {
        window.addEventListener('message', (event) => {
            let postMessageData = event.data as CcPackage;

            switch (postMessageData.action) {
                case CcAction.CC_IFRAME_HEIGHT:
                    if (!isIE11()) {
                        this.setState({iframeHeight: postMessageData.documentHeight as number})
                    }
                    break;
                case CcAction.PREPARED_ENCRYPTED_DATA:
                    this.ccPackage = postMessageData;
                    break;
                case CcAction.HANDLE_SUCCESS_PAGE:
                    switch (this.props.wizardFlow) {
                        case WizardFlow.CHECKOUT:
                            this.props.setStage(AppStage.STAGE_CONFIRMATION);
                            break;
                        case WizardFlow.PAYMENT:
                            if (this.props.sessionData && this.props.sessionData.transaction.intent === Intent.AUTHORIZATION) {
                                this.proceedAfterSuccessPayment();
                            } else {
                                this.props.callBackend(
                                    '/start',
                                    () => {
                                        this.proceedAfterSuccessPayment();
                                    },
                                    'POST',
                                    {}
                                );
                            }
                    }
                    break;
                case CcAction.HANDLE_ERROR_PAGE:
                    this.props.showMessageBox(true, 'payment_method_more_available_scoring_failed', true);
                    if (this.props.sessionData && this.props.sessionData.payment_methods.length === 1) {
                        this.props.setStage(AppStage.STAGE_PAYMENT_INPUT, PaymentWindow.WINDOW_CREDIT_CARD);
                    } else {
                        this.props.setStage(AppStage.STAGE_PAYMENT_SELECTION);
                    }
                    break;
            }
        });

        if (!isNull(this.creditCardIframe)) {
            this.creditCardIframe.addEventListener(
                'load',
                () => {
                    this.setState({
                        iframeHeight: this.getDefaultIframeHeight()
                    })
                    if (!this.state.isIframeLoaded) {
                        this.setState({isIframeLoaded: true});
                    }
                    if (!this.state.is3DSecure) {
                        // only for embed cc iframe
                        this.setOwner();
                        this.setLanguageInCcIrame();
                        this.setMandatoryColor(getPrimaryColor(this.props));
                        this.setTheme(this.props.ccTheme);
                    }
                    this.props.setLoading(false);
                }
            );
        }
    }

    private setMandatoryColor(color: string) {
        this.sendCcPackage({
            action: CcAction.SET_MANDATORY_COLOR,
            lang: this.props.currentLang,
            color: color,
            data: null
        });
    }

    private setLanguageInCcIrame() {
        this.sendCcPackage({
            action: CcAction.SET_LANG,
            lang: this.props.currentLang,
            data: null
        });
    }

    private setTheme(themeNumber: string) {
        this.sendCcPackage({
            action: CcAction.SET_THEME,
            lang: this.props.currentLang,
            data: null,
            theme: themeNumber
        })
    }

    private sendCcPackage(ccPackage: CcPackage) {
        if (!isNull(this.creditCardIframe) && !isNull(this.creditCardIframe.contentWindow)) {
            this.creditCardIframe.contentWindow.postMessage(ccPackage, '*');
        }
    }

    private handleBadRequest(response?: ResponseHttp): void {
        let responseErrorMsg;
        if (response && (responseErrorMsg = JSON.parse(response.text).error_message_key)) {
            this.props.setStage(AppStage.STAGE_ERROR, responseErrorMsg);
            return;
        }
        if (this.props.wizardFlow === WizardFlow.PAYMENT) {
            if (this.context.returnUrls.urlError) {
                exitSmartCheckoutDirectly(this.props.viewMode, this.context.returnUrls.urlError);
            } else {
                this.props.setStageWithEmptySteps(AppStage.STAGE_ERROR);
            }
        } else {
            const paymentMethods = this.props.getAvailablePaymentMethods();
            if (paymentMethods.length > 1) {
                this.props.showMessageBox(true, 'payment_method_more_available_scoring_failed', true);
                this.props.setStage(AppStage.STAGE_PAYMENT_SELECTION);
            } else {
                this.props.showMessageBox(true, 'payment_method_one_available_scoring_failed', true);
                this.props.setLoading(false);
            }
        }

    }

    private prepareOrder(ccPackageData: CcData): void {
        let prepareExpDate = function(date: string) {
            let splitDate = date.split('/');

            return `${splitDate[0]}/20${splitDate[1]}`;
        }

        this.props.callBackend(
            '/prepare',
            (response: ResponseHttp) => {
                switch (this.props.wizardFlow) {
                    case WizardFlow.CHECKOUT:
                        this.handleCreditCardResponseCheckout(response);
                        break;
                    case WizardFlow.PAYMENT:
                        this.handleCreditCardResponsePayment(response);
                }
            },
            'POST',
            {
                owner: ccPackageData.owner.value,
                expDate: prepareExpDate(ccPackageData.expDate.value),
                company: ccPackageData.cardNumber.company,
                encryptedData: {
                    cardNumber: ccPackageData.encryptedData.masked.card_number,
                    containerHash: ccPackageData.encryptedData.container_hash,
                    container: ccPackageData.encryptedData.crypted_container,
                    sessionKey: ccPackageData.encryptedData.crypted_skey,
                    sessionKeyName: ccPackageData.encryptedData.key_filename,
                }
            },
            (response: ResponseHttp) => this.handleBadRequest(response)
        );
    }

    private handleCreditCardResponseCheckout(response: ResponseHttp): void {
        const body = JSON.parse(response.text);
        if (body.status === TransactionStatus.STATUS_APPROVED) {
            this.props.setStage(AppStage.STAGE_CONFIRMATION);
        } else {
            if (isNullOrUndefined(body.iframe_url)) {
                this.handleBadRequest();
                return;
            } else if (body.iframe_url === '') {
                this.props.setStage(AppStage.STAGE_ERROR);
            } else {
                this.open3DSecure(body.iframe_url);
            }
        }
    }

    private handleCreditCardResponsePayment(response: ResponseHttp): void {
        const body = JSON.parse(response.text);
        if (body.status === TransactionStatus.STATUS_APPROVED) {
            if (this.props.sessionData && this.props.sessionData.transaction.intent === Intent.AUTHORIZATION) {
                this.proceedAfterSuccessPayment();
            } else {
                this.props.callBackend(
                    '/start',
                    () => {
                        this.proceedAfterSuccessPayment();
                    },
                    'POST',
                    {}
                );
            }
        } else {
            if (isNullOrUndefined(body.iframe_url)) {
                this.handleBadRequest();
                return;
            } else if (body.iframe_url === '') {
                this.props.setStage(AppStage.STAGE_ERROR);
            } else {
                this.open3DSecure(body.iframe_url);
            }
        }
    }

    private open3DSecure(url: string) {
        // set last visited page to security check page
        this.props.callBackend(
            '/stage',
            () => {
                if (this.props.viewMode === ViewMode.LAYER && (!this.props.sessionData || this.props.sessionData.transaction.intent !== Intent.AUTHORIZATION)) {
                    url = url + '&RedirSameWindow=1';
                }

                this.setState({
                    iframeUrl: url,
                    is3DSecure: true
                });
                this.props.setLoading(false);
            },
            'POST',
            {oldStage: AppStage.STAGE_PAYMENT_INPUT, newStage: AppStage.STAGE_SECURITY_CHECK}
        );

        // hide button
        this.props.setButton(() => undefined, '', false, false, true);
    }

    private cancelButton() {
        let abortUrl = this.context.returnUrls.urlAbort;

        if (this.props.getAvailablePaymentMethods().length > 1 && isNull(this.props.initPaymentMethod)) {
            // in this case we can skip /session-data request
            this.props.setStage(AppStage.STAGE_PAYMENT_SELECTION, undefined, true, false);
            this.props.setButton(() => undefined, '', false, false, true, MainButtonType.CANCEL);
        } else {
            exitSmartCheckout(abortUrl, {}, this.props.viewMode, '/abort', this.props.setLoading);
        }
    }

    private proceedAfterSuccessPayment() {
        if (this.context.returnUrls.urlSuccess) {
            exitSmartCheckoutDirectly(this.props.viewMode, this.context.returnUrls.urlSuccess);
        } else {
            goToSuccessPage(this.props);
        }
    }

    // prevent "jumping" page (with scrollbar) before iframe is loaded
    // later, cc iframe sends height via postMessage
    private getDefaultIframeHeight() {
        if(this.state.is3DSecure) {
            return 600;
        } else {
            return document.body.clientWidth > 576 ? 160 : 240;
        }
    }

    private showBackButton() {
        if (this.props.wizardFlow === WizardFlow.PAYMENT) {
            // started from "general" payment link or abort url is available
            if ((this.props.getAvailablePaymentMethods().length > 1 && isNull(this.props.initPaymentMethod)) || this.context.returnUrls.urlAbort) {
                this.props.setButton(this.cancelButton, 'back', false, false, false, MainButtonType.CANCEL);
            }
        }
    }
}
