import { h } from 'preact';
import {Button, CommonProps} from "../types";
import {getPrimaryColor, lightenDarkenColor} from "../utils";

interface Props extends CommonProps {
    visible: boolean
}

export default function Preloader(props: Props): JSX.Element | null {
    let primaryColour: string = getPrimaryColor(props);
    let lighterColour: string = primaryColour ? lightenDarkenColor(primaryColour, 30) : "";

    return props.visible ? (
        <div class="preloader-sticky-container">
            <div class="bg-cover"/>
            <div class="preloader">
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle fill="none" stroke-width="13" stroke-linecap="round" cx="50" cy="50" r="40" />
                </svg>
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle fill="none" stroke-width="13" stroke-linecap="round" cx="50" cy="50" r="40" />
                </svg>
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle
                        fill="none"
                        stroke-width="13"
                        stroke-linecap="round"
                        cx="50"
                        cy="50"
                        r="40"
                        style={primaryColour ? "stroke: " + primaryColour + " !important;" : ""}
                    />
                </svg>
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle
                        fill="none"
                        stroke-width="14"
                        stroke-linecap="round"
                        cx="50"
                        cy="50"
                        r="40"
                        style={primaryColour ? "stroke: " + lighterColour + " !important;" : ""}
                    />
                </svg>
            </div>
        </div>
        )
        : null;
}