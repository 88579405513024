import {h, Component} from 'preact';
import {CommonProps, LanguageSymbol} from '../types';

interface InfoMessageBoxComponentProps extends CommonProps {
    message: string | ((lang: LanguageSymbol) => string);
    isTranslated: boolean;
}

interface InfoMessageBoxComponentState {
    isVisible: boolean;
}

export default class InfoMessageBoxComponent
    extends Component<InfoMessageBoxComponentProps, InfoMessageBoxComponentState> {

    public state: InfoMessageBoxComponentState = {
        isVisible: false,
    };

    public constructor(props: InfoMessageBoxComponentProps) {
        super(props);

        this.changeVisibility = this.changeVisibility.bind(this);
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
    }

    public render() {
        const {isTranslated, getStringTranslationByKey, message} = this.props;

        if (this.state.isVisible) {
            return (
                <div className="container">
                    <div className="col-xs-12 col-mob-12">
                        <div className="mv-30 message-box">
                            {isTranslated && (typeof message === 'string') ? getStringTranslationByKey(message) : (typeof message === 'string' ? message : message(this.props.currentLang))}
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    public show() {
        this.changeVisibility(true);
    }

    public hide() {
        this.changeVisibility(false);
    }

    private changeVisibility(isVisible: boolean): void {
        this.setState({isVisible});
    }
}
