import HTMLInputElementDecorator from './HTMLInputElementDecorator';
import { transformYear, isIBANsyntaxCorrect, parseDate } from '../utils';
import { isNull, isUndefined } from 'util';
import { Country } from '../types';

export interface ValidationResult {
    valid: boolean;
    warningKeyTranslation?: string;
}

export interface ValidationInstance {
    validatingFunction: (inputDecorator: HTMLInputElementDecorator) => ValidationResult;
}

export const streetRegex = /^(.*\b\.?)[, ]+((?:[1-9]\d{0,3}\/[1-9]\d{0,1})|(?:[1-9]\d{0,3}(?:[\-+&][1-9]\d{0,3})?(?: [1-9]\d{0,1}\/[1-9]\d{0,1})?(?: ?[A-Za-z](?:[,\-\/+&][A-Za-z])?)*))$/;
const eighteenYears: number = 568025136000;

export default class ValidationInstances {

    public static getEmailValidationInstance(): ValidationInstance {
        return {
            validatingFunction: function (inputDecorator: HTMLInputElementDecorator): ValidationResult {
                /*
                 * The regex below is based on a regex by Michael Rushton.
                 * However, it is not identical.  I changed it to only consider routeable
                 * addresses as valid.  Michael's regex considers a@b a valid address
                 * which conflicts with section 2.3.5 of RFC 5321 which states that:
                 *
                 *   Only resolvable, fully-qualified domain names (FQDNs) are permitted
                 *   when domain names are used in SMTP.  In other words, names that can
                 *   be resolved to MX RRs or address (i.e., A or AAAA) RRs (as discussed
                 *   in Section 5) are permitted, as are CNAME RRs whose targets can be
                 *   resolved, in turn, to MX or address RRs.  Local nicknames or
                 *   unqualified names MUST NOT be used.
                 *
                 * This regex does not handle comments and folding whitespace.  While
                 * this is technically valid in an email address, these parts aren't
                 * actually part of the address itself.
                 *
                 * Michael's regex carries this copyright:
                 *
                 * Copyright © Michael Rushton 2009-10
                 * http://squiloople.com/
                 * Feel free to use and redistribute this code. But please keep this copyright notice.
                 *
                 */
                const emailRegex = new RegExp([
                    '^(?!(?:(?:\\x22?\\x5C[\\x00-\\x7E]\\x22?)|(?:\\x22?[^\\x5C\\x22]\\x22?)){255,})',
                    '(?!(?:(?:\\x22?\\x5C[\\x00-\\x7E]\\x22?)|(?:\\x22?[^\\x5C\\x22]\\x22?)){65,}@)',
                    '(?:(?:[\\x21\\x23-\\x27\\x2A\\x2B\\x2D\\x2F-\\x39\\x3D\\x3F\\x5E-\\x7E\\pL\\pN]+)',
                    '|(?:\\x22(?:[\\x01-\\x08\\x0B\\x0C\\x0E-\\x1F\\x21\\x23-\\x5B\\x5D-\\x7F\\pL\\pN]',
                    '|(?:\\x5C[\\x00-\\x7F]))*\\x22))',
                    '(?:\\.(?:(?:[\\x21\\x23-\\x27\\x2A\\x2B\\x2D\\x2F-\\x39\\x3D\\x3F\\x5E-\\x7E\\pL\\pN]+)',
                    '|(?:\\x22(?:[\\x01-\\x08\\x0B\\x0C\\x0E-\\x1F\\x21\\x23-\\x5B\\x5D-\\x7F\\pL\\pN]',
                    '|(?:\\x5C[\\x00-\\x7F]))*\\x22)))*@(?:(?:(?!.*[^.]{64,})(?:(?:(?:xn--)',
                    '?[a-z0-9]+(?:-+[a-z0-9]+)*\\.){1,126}){1,}(?:(?:[a-z][a-z0-9]*)',
                    '|(?:(?:xn--)[a-z0-9]+))(?:-+[a-z0-9]+)*)',
                    '|(?:\\[(?:(?:IPv6:(?:(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){7})',
                    '|(?:(?!(?:.*[a-f0-9][:\\]]){7,})(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,5})?::',
                    '(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,5})?)))|(?:(?:IPv6:(?:(?:[a-f0-9]{1,4}',
                    '(?::[a-f0-9]{1,4}){5}:)|(?:(?!(?:.*[a-f0-9]:){5,})(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,3})',
                    '?::(?:[a-f0-9]{1,4}(?::[a-f0-9]{1,4}){0,3}:)?)))?(?:(?:25[0-5])|(?:2[0-4][0-9])',
                    '|(?:1[0-9]{2})|(?:[1-9]?[0-9]))(?:\\.(?:(?:25[0-5])|(?:2[0-4][0-9])|(?:1[0-9]{2})',
                    '|(?:[1-9]?[0-9]))){3}))\\]))$'
                ].join(''));
                return {valid: !!inputDecorator.getField().value.match(emailRegex)};
            }
        };
    }

    public static getBirthdateValidationInstance(): ValidationInstance {
        return {
            validatingFunction: function (inputDecorator: HTMLInputElementDecorator): ValidationResult {

                if (!inputDecorator.getField().value) {
                    return {valid: true};
                }

                let parsedDate: string[];
                parsedDate = parseDate(inputDecorator);

                if (parsedDate.length !== 3) {
                    return {valid: false};
                }

                parsedDate[2] = transformYear(parsedDate[2]);

                const tmpDate: Date = new Date(+parsedDate[2], +parsedDate[1] - 1, +parsedDate[0]);

                if (!(tmpDate.getDate() === +parsedDate[0] &&
                    tmpDate.getMonth() + 1 === +parsedDate[1] &&
                    tmpDate.getFullYear() === +parsedDate[2])) {
                    return {valid: false};
                }

                if (tmpDate.getTime() >= Date.now() - eighteenYears) {
                    return {valid: false, warningKeyTranslation: 'date_too_young_warning'};
                }

                return {valid: true};
            }
        };
    }

    public static getZipcodeValidationInstance(): ValidationInstance {
        return {
            validatingFunction: function (inputDecorator: HTMLInputElementDecorator): ValidationResult {
                switch ((document.getElementById('countries') as HTMLInputElement).value) {
                    case Country.DE.toString():
                        return {valid: !!inputDecorator.getField().value.match(/^(0[1-9]\d{3}|[1-9]\d{4})$/)};
                    case Country.AT.toString():
                        return {valid: !!inputDecorator.getField().value.match(/^[1-9]\d{3}$/)};
                    default:
                        return {valid: false};
                }
            }
        };
    }

    public static getPhoneNumberValidationInstance(): ValidationInstance {
        return {
            validatingFunction: function (inputDecorator: HTMLInputElementDecorator): ValidationResult {
                if (inputDecorator.getField().value !== '') {
                    return {valid: !!inputDecorator.getField().value.match(/^[\+]\d{8,16}$/)};
                } else {
                    return {valid: true};
                }
            }
        };
    }

    public static getNumbersOnlyValidationInstance(): ValidationInstance {
        return {
            validatingFunction: function (inputDecorator: HTMLInputElementDecorator): ValidationResult {
                const regex = /^\d+$/;
                return {valid: !!inputDecorator.getField().value.match(regex)};

            }
        };
    }

    public static getAlphaOnlyValidationInstance(): ValidationInstance {
        return {
            validatingFunction: function (inputDecorator: HTMLInputElementDecorator): ValidationResult {
                const regex = /(^(?!\d))([^\x00-\x7F]|\w)+/; // allows digits in the end!!!
                return {valid: !!inputDecorator.getField().value.match(regex)};
            }
        };
    }

    public static getPinValidationInstance(): ValidationInstance {
        return {
            validatingFunction: function (inputDecorator: HTMLInputElementDecorator): ValidationResult {
                const regex = /^\d{4}$/;
                return {valid: !!inputDecorator.getField().value.match(regex)};

            }
        };
    }

    /**
     * Validation instance for validating IBAN. It follows standard IBAN validation procedure
     * e.g. https://en.wikipedia.org/wiki/International_Bank_Account_Number#Validating_the_IBAN
     * @returns {ValidationInstance}
     */
    public static getIBANValidationInstance(): ValidationInstance {
        return {
            validatingFunction: function (inputDecorator: HTMLInputElementDecorator): ValidationResult {
                let ret: boolean = true;
                let iban: string = inputDecorator.getField().value.toUpperCase().replace(/ /g, '');
                if (isNull(iban) || isUndefined(iban) || iban === '') {
                    ret = false;
                } else if (isIBANsyntaxCorrect(iban)) {

                    let bank: string;
                    let checkSumString: string = '';
                    let checksum: number;
                    let asciiShift: number = 55;

                    iban = iban.replace(' ', '');
                    bank = iban.substr(4, iban.length - 4) + iban.substr(0, 4); // put first 4 characters at the end

                    // convert to integer
                    for (let c of bank) {
                        let v: number;
                        if (c.match(/^[A-Z]$/)) {
                            v = c.charCodeAt(0) - asciiShift;
                        } else {
                            v = +c;
                        }
                        checkSumString = checkSumString.concat(v.toString());
                    }

                    // calculate the checksum using modular arithmetic
                    checksum = +checkSumString.substr(0, 1);
                    for (let i = 1; i < checkSumString.length; i++) {
                        let v: number = +checkSumString.substr(i, 1);
                        checksum *= 10;
                        checksum += v;
                        checksum %= 97;
                    }

                    // IBAN is valid if modulo equals 1
                    ret = checksum === 1;
                } else {
                    ret = false;
                }

                return {valid: ret};
            }
        };
    }

    public static getStreetStreetNumberValidationInstance(): ValidationInstance {
        return {
            validatingFunction: function (inputDecorator: HTMLInputElementDecorator): ValidationResult {
                return {valid: !!inputDecorator.getField().value.match(streetRegex)};
            }
        };
    }

}