import { Component } from 'preact';
import HTMLInputElementDecorator from './HTMLInputElementDecorator';
import {CommonProps, InputName} from '../types';
import { isUndefined } from 'util';

export default class InputContainer {
    private readonly decorators: { [key: string]: HTMLInputElementDecorator };
    private invalidInputCounter: number;
    private parentComponent: Component<CommonProps, {}>;

    constructor(parent: Component<CommonProps, {}>) {

        this.decorators = {};
        this.parentComponent = parent;
        this.invalidInputCounter = 0;
        this.validateInputs = this.validateInputs.bind(this);
        this.highlightInvalidField = this.highlightInvalidField.bind(this);
        this.handleInputFocus = this.handleInputFocus.bind(this);
        this.getDecorators = this.getDecorators.bind(this);
        this.setDecorator = this.setDecorator.bind(this);
        this.getWarningMsg = this.getWarningMsg.bind(this);
        this.getInputFocusHandlerCallback = this.getInputFocusHandlerCallback.bind(this);
    }

    public getDecorators(): { [key: string]: HTMLInputElementDecorator } {
        return this.decorators;
    }

    public setDecorator(key: string, decorator: HTMLInputElementDecorator): void {
        this.decorators[key] = decorator;
    }

    public getWarningMsg(key: string): string {
        if (this.decorators[key]) {
            return this.parentComponent.props.getStringTranslationByKey(
                this.decorators[key].validateContents().warningKeyTranslation || key + '_warning'
            );
        } else {
            return '';
        }
    }

    public getInputFocusHandlerCallback(): (event: Event) => void {
        return this.handleInputFocus;
    }

    /* If fields array is empty, then all inputs will be checked */
    /* if fields array is not empty, then only these inputs will be checked */
    public validateInputs(fields: InputName[] = []): boolean {
        this.invalidInputCounter = 0;
        let flag: boolean = true;
        for (const key in this.decorators) {
            if(fields.length > 0 && !fields.includes(key as InputName)) {
                continue;
            }
            if (!this.decorators[key].validateContents().valid) {
                const base = this.parentComponent.base;
                if (!isUndefined(base)) {
                    let element = base.getElementsByClassName(key + '_span')[0] as HTMLElement;
                    this.highlightInvalidField(this.decorators[key].getField());

                    // span color
                    if (!isUndefined(element)) {
                        element.classList.add('color-red');
                    }
                    flag = false;
                } else {
                    throw new Error(
                        'Could not retrieve base element for component ' + this.parentComponent.constructor.name
                    );
                }
            }
        }
        if (flag) {
            document.body.classList.remove('input-errors');
        } else {
            document.body.classList.add(flag ? '' : 'input-errors');
        }
        this.parentComponent.forceUpdate();
        return flag;
    }

    public filterAllInputs(): void {
        for (const key in this.decorators) {
            if (this.decorators.hasOwnProperty(key)) {
                this.decorators[key].filterContents();
            }
        }
        this.parentComponent.forceUpdate();
    }

    private handleInputFocus(event: Event): void { // needs to be exposed
        const input: HTMLInputElement = event.currentTarget as HTMLInputElement;
        const base = this.parentComponent.base;
        if (!isUndefined(base)) {
            let element = base.getElementsByClassName(input.name + '_span')[0] as HTMLElement;
            input.classList.add('input-valid');
            input.classList.remove('input-invalid');
            if (!isUndefined(element)) {
                element.classList.add('color-grey');
                element.classList.remove('color-red');
            }
            this.invalidInputCounter--;
            if (this.invalidInputCounter < 0) {
                this.invalidInputCounter = 0;
            }
            this.parentComponent.forceUpdate();
        } else {
            throw new Error('Could not retrieve base element for component ' + this.parentComponent.constructor.name);
        }
    }

    private highlightInvalidField(input: HTMLInputElement): void {
        input.classList.add('input-invalid');
        input.classList.remove('input-valid');
        this.invalidInputCounter++;
    }

    public clearAllErrors() {
        let element;
        let label;
        for (const key in this.decorators) {
            element = this.decorators[key].getField();
            element.classList.add('input-valid');
            element.classList.remove('input-invalid');
            //span label
            label = (element.closest('div')as HTMLElement).getElementsByClassName(key + '_span')[0] as HTMLElement;
            if(!isUndefined(label)) {
                label.classList.remove('color-red');
            }
        }
        document.body.classList.remove('input-errors');
    }
}