import {Component, h} from 'preact';
import {CommonProps} from '../types';
import {exitSmartCheckout, getPrimaryColor} from '../utils';

interface InfoExitModalProps extends CommonProps {
}

export default class InfoExitModal extends Component<InfoExitModalProps, {}> {

    public render() {
        let primaryColour: string = getPrimaryColor(this.props);

        return (
            <div class="modal-container" id="exit-modal-wrapper">
                <input class="modal-state opacity-95" id="exitModal" type="checkbox" onChange={event => this.onOffExitModal(event)}/>
                <div class="modal no-background">
                    <label class="modal__bg" for="exitModal"/>
                    <div class="modal__inner height-min-content no-border overflow-visible brown-background scrollbar">
                            <div class="container">
                                <h3 class="col-xs-12 text-center">
                                    {this.props.getStringTranslationByKey('exit_question')}
                                </h3>
                                <label for="exitModal">
                                    <div
                                        class="btn col-xs-12 mb-20 no-border next-button text-center"
                                        style={primaryColour ? "background: " + primaryColour + " !important;" : ""}
                                    >
                                        {this.props.getStringTranslationByKey('exit_no')}
                                    </div>
                                </label>
                                <div onClick={() => {
                                    this.exit();
                                }}
                                     class="btn col-xs-12 mb-20 no-border change-button text-center">
                                    {this.props.getStringTranslationByKey('exit_yes')}
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        );
    }

    // At least one ReturnUrl will be set
    private exit() {
        exitSmartCheckout(this.context.returnUrls.urlAbort, {}, this.props.viewMode);
    }

    private onOffExitModal(event: Event) {
        let checkboxMode = (event.target as HTMLInputElement).checked;
        document.body.setAttribute('data-exit-modal-state', checkboxMode ? 'open' : 'closed');
    }
}