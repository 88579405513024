import {h} from 'preact';
import {CommonProps, WizardFlow} from "../common/types";
import {createHeadlineElement} from "../common/utils";
import {isNull} from "util";

function displayLogo(src: string | null): JSX.Element | null {
    if (src) {
        return <img src={src} alt="project-logo" id="project-logo"/>
    } else return null;
}

export function ProjectNameAndLogo(props: CommonProps): JSX.Element | null {
    let projectTitle;
    let projectLogo;
    if (props.wizardFlow === WizardFlow.PAYMENT && props.sessionData) {
        projectTitle = props.sessionData.payment_wizard_options.project_title;
        if (projectTitle) {
            projectLogo = props.sessionData.payment_wizard_options.project_logo;
            return <div class="container">
                <div class="col-xs-12">
                    <div id="project">
                        {createHeadlineElement(props, props.getStringTranslationByKey('project_title').toUpperCase())}
                        <p class="no-margin-bottom no-margin-top">
                            <span id="project-title">{projectTitle}</span>
                            {displayLogo(projectLogo)}
                        </p>
                    </div>
                </div>
            </div>
        } else {
            return null;
        }
    } else {
        return null;
    }
}
