import HTMLInputElementDecorator from './HTMLInputElementDecorator';
import { Country } from '../types';

export interface FilterInstance {
    filteringFunction: (inputDecorator: HTMLInputElementDecorator) => void;
}

export default class FilterInstances {

    public static trimmingFilterInstance(): FilterInstance {
        return {
            filteringFunction: function (inputDecorator: HTMLInputElementDecorator): void {
                inputDecorator.getField().value = inputDecorator.getField().value.trim();
            }
        };
    }

    public static phoneFilterInstance(country: Country): FilterInstance {
        return {
            filteringFunction: function (inputDecorator: HTMLInputElementDecorator): void {

                inputDecorator.getField().value = inputDecorator.getField().value.replace(/[-&/\\()\s]/g, '');

                if (inputDecorator.getField().value.match(/^[0][0][1-9]\d+$/)) {
                    // check if it matches string with 2 zeros in the beginning
                    inputDecorator.getField().value = inputDecorator.getField().value.replace('00', '+');
                } else if (inputDecorator.getField().value.match(/^[0][1-9][1-9]\d+$/)) {
                    // check if it matches string with 1 zero in the beginning
                    if (country === Country.DE) {
                        inputDecorator.getField().value = inputDecorator.getField().value.replace('0', '+49');
                    } else if (country === Country.AT) {
                        inputDecorator.getField().value = inputDecorator.getField().value.replace('0', '+43');
                    }
                }

            }
        };
    }

    public static emailFilterInstance(): FilterInstance {
        return {
            filteringFunction: function (inputDecorator: HTMLInputElementDecorator): void {
                inputDecorator.getField().value = inputDecorator.getField().value.toLowerCase();
            }
        };
    }

    public static birthDateFilterInstance(): FilterInstance {
        return {
            filteringFunction: function (inputDecorator: HTMLInputElementDecorator): void {
                inputDecorator.setFilterInstance(
                    FilterInstances.removeAllIntermediateWhitespacesAndTrimFilterInstance()
                );
                inputDecorator.filterContents();
                inputDecorator.getField().value = inputDecorator.getField().value.replace(/\b\d\b/g, '0$&');
            }
        };
    }

    public static removeIntermediateWhitespacesAndTrimFilterInstance(): FilterInstance {
        return {
            filteringFunction: function (inputDecorator: HTMLInputElementDecorator): void {
                inputDecorator.getField().value = inputDecorator.getField().value.trim().replace(/\s\s+/g, ' ');
            }
        };
    }

    public static removeAllIntermediateWhitespacesAndTrimFilterInstance(): FilterInstance {
        return {
            filteringFunction: function (inputDecorator: HTMLInputElementDecorator): void {
                while (inputDecorator.getField().value.match(/\s+/g)) {
                    inputDecorator.getField().value = inputDecorator.getField().value.trim().replace(/\s+/g, '');
                }
            }
        };
    }
}