import { h, Component } from 'preact';
import SecucardPaymentComponent from './SecucardPaymentComponent';
import ShowSecuCreditComponent from './ShowSecuCreditComponent';
import { BasketInfo, CommonProps } from '../../../common/types';

export enum SecucardWindow {
  WINDOW_INPUT = 0,
  WINDOW_BALANCE = 1,
}

interface SecucardMainComponentProps extends CommonProps {
  basketInfo: BasketInfo;
}

interface SecucardMainComponentState {
  currentWindow: SecucardWindow;
}

export default class SecucardMainComponent extends Component<SecucardMainComponentProps, SecucardMainComponentState> {
  private chosenCards: boolean[];

  public constructor() {
    super();

    this.chosenCards = [];

    this.state = {
      currentWindow: SecucardWindow.WINDOW_INPUT,
    };
    this.toggleCardStatus = this.toggleCardStatus.bind(this);
    this.getChosenCards = this.getChosenCards.bind(this);
    this.setSecucardStage = this.setSecucardStage.bind(this);
    this.goToConfirmation = this.goToConfirmation.bind(this);
  }

  public componentWillMount() {
    this.chosenCards.fill(false);
  }

  public render() {

    return this.state.currentWindow === SecucardWindow.WINDOW_INPUT
      ? (
        <SecucardPaymentComponent
          {...this.props}
          getChosenCards={this.getChosenCards}
          setSecucardStage={this.setSecucardStage}
          toggleCardStatus={this.toggleCardStatus}
        />
      ) : (
        <ShowSecuCreditComponent
          {...this.props}
          getChosenCards={this.getChosenCards}
          setSecucardStage={this.setSecucardStage}
          toggleCardStatus={this.toggleCardStatus}
          goToConfirmation={this.goToConfirmation}
          total={this.props.basketInfo.sum}
        />
      );
  }

  private setSecucardStage(stage: SecucardWindow) {
    switch (stage) {
      case SecucardWindow.WINDOW_INPUT:
        this.setState({currentWindow: SecucardWindow.WINDOW_INPUT});
        break;
      case SecucardWindow.WINDOW_BALANCE:
        // this.props.paymentContainer.retrieveData().then(() =>
        //   this.setState({currentWindow: SecucardWindow.WINDOW_BALANCE})
        // );
        break;
      default:
        break;
    }
  }

  private goToConfirmation() {
    let chosenElements: number[] = [];
    this.chosenCards.forEach((val, idx) => {
      if (val) {
        chosenElements.push(idx);
      }
    });
    // this.props.setWindow(PaymentWindow.WINDOW_CONFIRMATION, PaymentMethod.SECUCARD, chosenElements);
  }

  private getChosenCards(): boolean[] {
    return this.chosenCards;
  }

  private toggleCardStatus(cardIdx: number) {
    this.chosenCards[cardIdx] = !this.chosenCards[cardIdx];
    this.forceUpdate();
  }
}