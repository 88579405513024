import { h } from 'preact';
import { LanguageSymbol, PaymentMethod } from '../../common/types';
import { isUndefined } from 'util';

interface PaymentIconTemplateProps {
    paymentMethod: PaymentMethod;
    currentLang: LanguageSymbol;
    key?: number;
}

const iconsByPaymentMethod: {[k in PaymentMethod]: string[]} = {
    [PaymentMethod.SECUCARD]: ['secucard'],
    [PaymentMethod.DIRECT_DEBIT]: ['direct-debit-de'],
    [PaymentMethod.CREDIT_CARD]: ['mastercard', 'visa', 'jcb', 'diners', 'discover', 'union-pay'],
    [PaymentMethod.PAYPAL]: ['paypal'],
    [PaymentMethod.SOFORT]: ['sofort'],
    [PaymentMethod.PAY_IN_ADVANCE]: ['pay-in-advance'],
    [PaymentMethod.INVOICE]: ['invoice'],
    [PaymentMethod.ERROR]: []
};

export default function PaymentIconTemplate(props: PaymentIconTemplateProps): JSX.Element | null {
    if (!isUndefined(iconsByPaymentMethod[props.paymentMethod])) {
        let imgClass: string = '';
        let currentLang: LanguageSymbol = props.currentLang;
        imgClass = 'img-responsive ' + (iconsByPaymentMethod[props.paymentMethod].length > 1 ? 'img-multi' : 'img-one');

        return (
            <div>
                {
                    iconsByPaymentMethod[props.paymentMethod].map(function (iconName: string) {
                        if (
                            props.paymentMethod === PaymentMethod.DIRECT_DEBIT
                            && currentLang === LanguageSymbol.EN_GB
                        ) {
                            return <img class={imgClass}
                                        src={require('../../../img/payment-methods/direct-debit-en.png')}/>;
                        } else {
                            return <img class={imgClass}
                                        src={require('../../../img/payment-methods/' + iconName + '.png')}/>;
                        }
                    })
                }
            </div>
        );
    } else {
        return null;
    }
}
