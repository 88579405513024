import {h} from 'preact';
import {AppStage, CommonProps, PaymentMethod, PaymentWindow, WizardFlow} from '../types';
import {handleKeyboardEvent} from "../utils";

export default function BackButton(props: CommonProps): JSX.Element | null {
    return props.stageData.back && (props.wizardFlow !== WizardFlow.PAYMENT) ?
        (
            <a class="back-button"
               tabIndex={0}
               onKeyUp={handleKeyboardEvent}
               onClick={() => {
                props.showMessageBox(false, '');
                if (props.stageData.stage === AppStage.STAGE_CONFIRMATION) {
                    switch (props.paymentMethod) {
                        case PaymentMethod.DIRECT_DEBIT:
                            props.setStage(AppStage.STAGE_PAYMENT_INPUT, PaymentWindow.WINDOW_DIRECT_DEBIT);
                            break;
                        case PaymentMethod.CREDIT_CARD:
                            props.setStage(AppStage.STAGE_PAYMENT_INPUT, PaymentWindow.WINDOW_CREDIT_CARD);
                            break;
                        default:
                            props.setStage(props.stageData.back as AppStage);
                    }
                } else {
                    props.setStage(props.stageData.back as AppStage);
                }
            }}><i className="fa fa-arrow-left"></i></a>
        )
        : null;
}
