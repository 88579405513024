import { h, Component } from 'preact';
import { CommonProps } from '../../common/types';
import {getPrimaryColor, handleKeyboardEvent} from "../../common/utils";

interface ShippingCollectionChoiceProps extends CommonProps {
    shipping: {
        isEnabled: boolean,
        isSet: boolean
    };
    collection: {
        isEnabled: boolean,
        isSet: boolean
    };
    chosenOption: (e: Event) => void;
}

interface ShippingCollectionChoiceState {
}

export default class ShippingCollectionChoice extends
    Component<ShippingCollectionChoiceProps, ShippingCollectionChoiceState> {

    constructor(props: ShippingCollectionChoiceProps) {
        super(props);
    }

    public render(): JSX.Element {
        let primaryColour: string = getPrimaryColor(this.props);
        return (
            <div>
                {
                    this.props.shipping.isEnabled && this.props.collection.isEnabled ?
                    <div class="container" id="shipping-collection-buttons">
                        <div class="col-xs-12">

                            <div
                                class={this.setShippingClasses()}
                                id="shipping-button"
                                tabIndex={0}
                                onClick={this.props.chosenOption}
                                onKeyUp={handleKeyboardEvent}
                                style={primaryColour && this.props.shipping.isSet ? "background: " + primaryColour + " !important;" : ""}
                            >
                                {this.props.getStringTranslationByKey('shipping').toUpperCase()}
                            </div>
                            <div class={this.setCollectionClasses()}
                                 tabIndex={0}
                                 id="collection-button"
                                 onClick={this.props.chosenOption}
                                 onKeyUp={handleKeyboardEvent}
                                 style={primaryColour && this.props.collection.isSet ? "background: " + primaryColour + " !important;" : ""}
                            >
                                {this.props.getStringTranslationByKey('collection')}
                            </div>
                        </div>
                    </div> : <div/>
                }
            </div>
        );
    }

    private setShippingClasses() {
        let shippingClasses = ['col-xs-12', 'col-mob-5', 'text-center'];
        if (!this.props.shipping.isEnabled) {
            shippingClasses.push('disabled');
        }
        if (this.props.shipping.isSet) {
            shippingClasses.push('set');
        }
        return shippingClasses.join(' ');
    }

    private setCollectionClasses() {
        let collectionClasses = ['col-xs-12', 'col-mob-5', 'text-center', 'pull-right'];
        if (!this.props.collection.isEnabled) {
            collectionClasses.push('disabled');
        }
        if (this.props.collection.isSet) {
            collectionClasses.push('set');
        }
        return collectionClasses.join(' ');
    }
}