import { h, Component } from 'preact';
import {
    BasketInfo,
    CommonProps,
    PaymentMethod,
    PaymentWindow,
    ResponseHttp
} from '../../common/types';
import SecucardMainComponent from './Secucard/SecucardMainComponent';
import DirectDebitComponent from './DirectDebit/DirectDebitComponent';
import CreditCardComponent from './CreditCard/CreditCardComponent';
import PrepaidComponent from "./Prepaid/PrepaidComponent";
import InvoiceComponent from './Invoice/InvoiceComponent';

interface PaymentComponentProps extends CommonProps {
    paymentWindow: PaymentWindow;
}

interface PaymentComponentState {
    paymentType: PaymentMethod;
    // basket: Basket | MixedBasket;
    basketInfo: BasketInfo;
}

export default class PaymentInputComponent extends Component<PaymentComponentProps, PaymentComponentState> {

    public constructor(props: PaymentComponentProps) {
        super(props);

        this.state = {
            paymentType: PaymentMethod.ERROR,
                basketInfo: {
                    sum: 0,
                    currency: 'EUR'
            }
        };

    }

    public componentDidMount() {
        this.props.handleMessageAtTheBeginning();
        this.retrieveBasket();
    }

    public render() {
        let componentToDisplay = null;

        switch (this.props.paymentWindow) {
            case PaymentWindow.WINDOW_SECUCARD:
                componentToDisplay = (
                    <SecucardMainComponent
                        {...this.props}
                        basketInfo={this.state.basketInfo} />
                    );
                break;
            case PaymentWindow.WINDOW_DIRECT_DEBIT:
                componentToDisplay = (
                    <DirectDebitComponent
                        {...this.props}
                        total={this.state.basketInfo.sum}
                        currency={this.state.basketInfo.currency}
                    />
                );
                break;
            case PaymentWindow.WINDOW_CREDIT_CARD:
                componentToDisplay = (
                    <CreditCardComponent {...this.props}
                                         total={this.state.basketInfo.sum}
                                         currency={this.state.basketInfo.currency}/>
                );
                break;
            case PaymentWindow.WINDOW_PAY_IN_ADVANCE:
                componentToDisplay = (
                    <PrepaidComponent {...this.props}
                                         total={this.state.basketInfo.sum}
                                         setLoading={this.props.setLoading}
                                         currency={this.state.basketInfo.currency}/>
                );
                break;
            case PaymentWindow.WINDOW_PAY_ON_INVOICE:
                componentToDisplay = (
                    <InvoiceComponent {...this.props}
                        total={this.state.basketInfo.sum}
                        setLoading={this.props.setLoading}
                        currency={this.state.basketInfo.currency}/>
                );
                break;
            default:
                break;
        }

        return componentToDisplay;
    }

    private retrieveBasket(): void {
        this.props.callBackend(
            '/basket',
            (response: ResponseHttp) => {
                const body = JSON.parse(response.text);
                this.setState({
                        basketInfo: {
                            sum: body.basket_info.sum,
                            currency: body.basket_info.currency
                        }
                });
            }
        );
    }
}