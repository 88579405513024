import { h } from 'preact';
import { CcIssuer, CommonProps } from '../../common/types';

export interface CreditCardTemplateProps extends CommonProps {
    pan: string;
    company: string;
}

const ccImages: { [issuer in CcIssuer]: string} = {
    'VISA': 'visa.png',
    'MasterCard': 'mastercard.png',
    'American Express': 'american-express.png',
    'JCB': 'jcb.png',
    'China UnionPay': 'union-pay.png',
    'Diners': 'diners.png',
    'Discover': 'discover.png',
};

export default function CreditCardTemplate(props: CreditCardTemplateProps): JSX.Element {
    return (
        <div class="col-xs-12 col-mob-5">
            <div class="row">
                { props.pan && props.company ?
                <div className="col-xs-12 pl-0">
                    <img src={require('../../../img/payment-methods/' + ccImages[props.company])}
                         class="img-responsive mv-6 max-h-45"/>
                    <p class="mv-6">
                        {props.getStringTranslationByKey('credit_card')} {props.pan.replace(/(.{4})/g, '$1 ')}
                    </p>
                </div>
                    : null }
            </div>
        </div>
    );
}