import {h, render} from 'preact';
// order is important
import './css/bootstrap.css';
import './css/styles/grid.css';
import './css/styles/general.css';
import './css/styles/tools.css';
import './css/styles/buttons.css';
import './css/styles/message_box.css';
import './css/styles/merchant_info.css';
import './css/styles/modal.css';
import './css/styles/checkbox.css';
import './css/styles/text.css';
import './css/styles/tables.css';
import './css/styles/header_footer.css';
import './css/styles/inputs.css';
import './css/styles/fonts.css';
import './css/styles/dropdown_menu.css';
import './css/styles/flex.css';
import './css/styles/preloader.css';
import './../node_modules/font-awesome/css/font-awesome.css';

import AppComponent from './components/app/AppComponent';
import {
    ParamFromUrl,
    ParamInAttr,
    PaymentMethod,
    ViewMode,
    WizardFlow
} from './common/types';
import {
    ArrayFindPolyfill,
    closestPolyfill,
    findIndexPolyfill,
    getParamFromUrl,
    getParamFromUrlOrSetDefault,
    assignPolyfill,
    includesPolyfill,
    isIE11,
    isSafari,
} from './common/utils';
import {isNull} from 'util';
import {polyfill as PromisePolyfill} from 'es6-promise';

const config = require('./common/config.json');
export const apiPath = config.backendPath;

render(init(), document.getElementById('root') as HTMLElement);

function init() {
    // requiredPolyfills method must be always executed first
    requiredPolyfills();
    let viewMode = setViewMode();
    webBrowserDetection();
    isMobileDetection();
    return (
        <AppComponent
            viewMode={viewMode}
            stx={getStx()}
            server={getServer()}
            wizardFlow={getWizardFlow()}
            initPaymentMethod={getInitPaymentMethod()}
            ccTheme={getCcTheme()}
        />
    );
}

function setViewMode(): ViewMode {
    let viewMode;

    if (isSafari() || (!isNull(getParamFromUrl(ParamFromUrl.STX)) && isNull(getParamFromUrl(ParamFromUrl.VIEW_MODE)))) {
        viewMode = ViewMode.FULLSCREEN;
    } else {
        viewMode = getParamFromUrlOrSetDefault(ParamFromUrl.VIEW_MODE, ViewMode, ViewMode.LAYER);
    }
    document.body.setAttribute(ParamInAttr.PREFIX + ParamFromUrl.VIEW_MODE.toLowerCase(), viewMode || '');
    return viewMode as ViewMode;
}

function getStx(): null | string {
    return getParamFromUrl(ParamFromUrl.STX);
}

function getServer(): null | string {
    return getParamFromUrl(ParamFromUrl.SERVER);
}

function getWizardFlow(): any {
    return getParamFromUrlOrSetDefault(ParamFromUrl.WIZARD_FLOW, WizardFlow, WizardFlow.CHECKOUT);
}

function getCcTheme(): any {
    return getParamFromUrl(ParamFromUrl.CC_THEME) || '';
}

function getInitPaymentMethod() {
    return getParamFromUrl(ParamFromUrl.INIT_PAYMENT_METHOD) as PaymentMethod | null;
}

function requiredPolyfills() {
    ArrayFindPolyfill();
    PromisePolyfill();
    findIndexPolyfill();
    closestPolyfill();
    assignPolyfill();
    includesPolyfill();
}

function webBrowserDetection() {
    document.body.setAttribute(('data-safari'), isSafari().toString());
    document.body.setAttribute(('data-ie11'), isIE11().toString());
}

function isMobileDetection() {
    let body = document.body;
    let mobileBreakpoint = 576;

    let setMobileAttr = function() {
        body.setAttribute('data-is-mobile', (body.clientWidth < mobileBreakpoint).toString());
    }

    setMobileAttr();

    window.addEventListener('resize', function () {
        setMobileAttr();
    });
}
