import { h } from 'preact';
import { CommonProps } from '../../common/types';

export default function PayInAdvanceTemplate(props: CommonProps) {
    return (
        <div>
            <img src={require('../../../img/payment-methods/pay-in-advance.png')}/>
            {props.getJSXTranslationByKey('pay_in_advance_section')}
        </div>
    );
}