import { h, Component } from 'preact';
import {AppStage, CommonProps, PayPalFlowType} from '../types';
import {createHeadlineElement, handleKeyboardEvent, startPayPalPayment} from "../utils";

interface PayPalExpressButtonProps extends CommonProps {
    isButtonEnabled: boolean;
}

export default class PayPalExpressButton extends Component<PayPalExpressButtonProps, any> {

    private  payPalButtonId = 'paypal-express-button';

    public constructor(props: PayPalExpressButtonProps) {
        super(props);
    }

    public render() {
        if (this.props.isButtonEnabled && (this.props.stageData.stage === AppStage.STAGE_CHECKIN)) {
            return (
                <div id="paypal-express-button-wrapper" class="mt-20">
                    <div>
                        {createHeadlineElement(this.props, this.props.getStringTranslationByKey('pay_with').toUpperCase())}
                        <p>
                            {this.props.getStringTranslationByKey('pay_pal_confirmation_1')}
                            <label for="privacySecuPay" class="info-link-theme" tabIndex={0} onKeyUp={handleKeyboardEvent}>
                                {this.props.getStringTranslationByKey('policy')}
                            </label>
                            {this.props.getStringTranslationByKey('pay_pal_confirmation_2')}
                        </p>
                        <a id={this.payPalButtonId} class="btn"
                           tabIndex={0}
                           onKeyUp={handleKeyboardEvent}
                           onClick={() => startPayPalPayment(
                               this.props as CommonProps,
                               PayPalFlowType.FLOW_TYPE_EXPRESS
                           )}
                        />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}