import {h} from 'preact';
import {AppStage, CommonProps, PaymentMethod, ProgressStep, WizardFlow} from "../types";
import {getPrimaryColor} from "../utils";

enum StepType {
    OPEN = 'open',
    CURRENT = 'current',
    COMPLETE = 'complete'
}

export function ProgressBar(props: CommonProps) {
    if (props.wizardFlow === WizardFlow.CHECKOUT) {
        if ([AppStage.STAGE_CHECKIN, AppStage.STAGE_ERROR, AppStage.STAGE_SUCCESS, AppStage.STAGE_LOADING].includes(props.stageData.stage)) {
            return null;
        } else {
            let sessionData = props.sessionData;
            if (sessionData) {
                let isShippingEnabled = true;
                let isCollectionEnabled = false;
                let paymentMethods = props.getAvailablePaymentMethods();
                let path = calculatePath(paymentMethods, isShippingEnabled, isCollectionEnabled);
                return (
                    <div class="col-xs-12 mt-20 display-flex justify-content-center">
                        <div id="progress-bar-wrapper" class={"steps-" + path.length}>
                            <div id="progress-bar">
                                {createPath(path, props)}
                            </div>
                            <hr/>
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        }

    } else {
        return null;
    }
}

function createStep(props: CommonProps, stepNumber: number, stepType: StepType, stepDesc: string): JSX.Element {
    let primaryColour: string = getPrimaryColor(props);

    return <div class={"step step-" + stepType}>
        <div class="indicator"
        style={primaryColour && stepType !== StepType.OPEN  ? "border-color: " + primaryColour + " !important;" : ""}
        >
            <span class="step-number"
                  style={primaryColour && stepType !== StepType.OPEN  ? "color: " + primaryColour + " !important;" : ""}
            >
                {stepType === StepType.COMPLETE ?
                <i class="fa fa-check"/> : stepNumber + 1}</span>
        </div>
        <span class="step-desc">{stepDesc}</span>
    </div>
}

function calculatePath(paymentMethods: PaymentMethod[], isShippingEnabled: boolean, isCollectionEnabled: boolean): ProgressStep[] {
    if (isShippingEnabled && isCollectionEnabled) {
        if (onlyPrepaidOrInvoice(paymentMethods)) {
            return [ProgressStep.ADDRESS, ProgressStep.DELIVERY, ProgressStep.SUMMARY];
        } else {
            return [ProgressStep.ADDRESS, ProgressStep.DELIVERY, ProgressStep.PAYMENT, ProgressStep.SUMMARY];
        }
    } else {
        if (isShippingEnabled) {
            if (onlyPrepaidOrInvoice(paymentMethods)) {
                return [ProgressStep.ADDRESS, ProgressStep.SUMMARY];
            } else {
                return [ProgressStep.ADDRESS, ProgressStep.PAYMENT, ProgressStep.SUMMARY];
            }
        }
        if (isCollectionEnabled) {
            if (onlyPrepaidOrInvoice(paymentMethods)) {
                return [ProgressStep.ADDRESS, ProgressStep.DELIVERY, ProgressStep.SUMMARY];
            } else {
                return [ProgressStep.ADDRESS, ProgressStep.DELIVERY, ProgressStep.PAYMENT, ProgressStep.SUMMARY];
            }
        }
    }
    return [];
}

function createPath(path: ProgressStep[], props: CommonProps) {
    let step: ProgressStep;
    let output: JSX.Element[] = [];
    let stepType: StepType;
    for (let i = 0; i < path.length; i++) {
        step = path[i];
        stepType = calculateStepType(step, i, path, props);
        output.push(createStep(props, i, stepType, props.getStringTranslationByKey(step)))
    }
    return output;
}

function calculateStepType(step: ProgressStep, stepIndex: number, path: ProgressStep[], props: CommonProps): StepType {
    let currentStep = appStageToStepType(props.stageData.stage);
    let currentStepIndex = path.indexOf(currentStep);
    let stepType = StepType.OPEN;
    if (currentStepIndex < stepIndex) {
        stepType = StepType.OPEN;
    }
    if (currentStepIndex === stepIndex) {
        stepType = StepType.CURRENT;
    }
    if (currentStepIndex > stepIndex) {
        stepType = StepType.COMPLETE;
    }

    return stepType;
}

function onlyPrepaidOrInvoice(paymentMethods: PaymentMethod[]) {
    return (paymentMethods.length === 1) && (paymentMethods.includes(PaymentMethod.INVOICE) || paymentMethods.includes(PaymentMethod.PAY_IN_ADVANCE));
}

function appStageToStepType(stage: AppStage): ProgressStep {
    switch (stage) {
        case AppStage.STAGE_ADDRESS:
            return ProgressStep.ADDRESS;
        case AppStage.STAGE_SHIPPING_COLLECTION:
            return ProgressStep.DELIVERY;
        case AppStage.STAGE_PAYMENT_SELECTION:
        case AppStage.STAGE_PAYMENT_INPUT:
            return ProgressStep.PAYMENT;
        case AppStage.STAGE_CONFIRMATION:
            return ProgressStep.SUMMARY;
        default:
            return ProgressStep.UNDEFINED;
    }
}

