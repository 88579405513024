import {Component, h} from 'preact';
import {apiPath} from '../../index';
import {
    AppStage,
    BasketType,
    Button,
    CheckoutAction,
    CheckoutLinks,
    CheckoutOptions,
    CheckoutPackage,
    CommonProps,
    Contract,
    DeliveryOptions,
    ErrorMessageKey,
    ExternalProcessResult,
    ExternalProcessResultType,
    HttpResponseCode,
    Intent,
    LanguageSymbol,
    MainButtonType,
    ParamFromUrl,
    PaymentMethod,
    PaymentWindow,
    ProductType,
    RequestOptions,
    ResponseHttp,
    ReturnUrls,
    SessionData,
    StageData,
    StorageMethod,
    ViewMode,
    WizardFlow,
} from '../../common/types';
import {allTranslations} from '../../translations/translations';
import Footer from '../../common/footer/Footer';
import Header from '../../common/header/Header';
import {
    exitSmartCheckout,
    exitSmartCheckoutDirectly,
    getLangFromUrl,
    getParamFromUrl,
    paymentWindowBasedOnFlow,
    setDataAttrInBody,
} from '../../common/utils';
import InfoMessageBoxComponent from '../../common/info/InfoMessageBoxComponent';
import {isNull, isNullOrUndefined, isUndefined} from 'util';
import MainButton from '../../common/buttons/MainButton';
import ComponentToDisplay from '../../common/helpers/ComponentToDisplay';
import Preloader from '../../common/components/Preloader';
import PayPalExpressButton from '../../common/buttons/PayPalExpressButton';

const http = require('iso-http');

interface AppComponentState {
    currentLang: LanguageSymbol;
    mainButton: Button;
    cancelButton: Button;
    paymentWindow: PaymentWindow;
    paymentMethod: PaymentMethod;
    returnUrls: ReturnUrls;
    deliveryOptions: DeliveryOptions;
    errorMessageKey?: ErrorMessageKey;
    boxMessage: string | (() => string);
    isBoxMessageTranslated: boolean;
    availablePaymentMethods: PaymentMethod[];
    isInitialized: boolean;
    isPreloaderVisible: boolean;
    textBoxMessageAtTheBeginning: string | (() => string);
    isPayPalExpressEnabled: boolean;
    stageData: StageData;
    sessionData: SessionData | null;
    wizardFlow: WizardFlow;
    intent: Intent | null;
}

interface AppComponentProps {
    viewMode: ViewMode;
    stx: null | string;
    server: null | string;
    wizardFlow: WizardFlow;
    initPaymentMethod: PaymentMethod | null;
    ccTheme: string;
}

export interface AppContext {
    returnUrls: ReturnUrls;
    deliveryOptions: DeliveryOptions;
}

export default class AppComponent extends Component<AppComponentProps, AppComponentState> {
    private mBox: InfoMessageBoxComponent | null = null;

    public constructor() {
        super();
        this.state = {
            availablePaymentMethods: [],
            // currentStage: AppStage.STAGE_LOADING,
            paymentWindow: PaymentWindow.WINDOW_LOADING,
            paymentMethod: PaymentMethod.ERROR,
            deliveryOptions: {
                isShippingEnabled: false,
                isCollectionEnabled: false,
            },
            currentLang: getLangFromUrl(),
            mainButton: {
                onClick: () => {
                    return;
                },
                buttonText: '',
                submit: false,
                disabled: false,
                loading: false,
                hidden: false,
                type: MainButtonType.SUBMIT
            },
            cancelButton: {
                onClick: () => {
                    return;
                },
                buttonText: '',
                submit: false,
                disabled: false,
                loading: false,
                hidden: true,
                type: MainButtonType.CANCEL
            },
            returnUrls: {
                urlSuccess: '',
                urlError: '',
                urlAbort: '',
            },
            boxMessage: '',
            isBoxMessageTranslated: false,
            isInitialized: false,
            isPreloaderVisible: true,
            textBoxMessageAtTheBeginning: '',
            isPayPalExpressEnabled: false,
            stageData: {
                back: '',
                direct: [],
                next: '',
                stage: AppStage.STAGE_LOADING
            },
            sessionData: null,
            wizardFlow: this.props ? this.props.wizardFlow : WizardFlow.CHECKOUT,
            intent: null,
        };

        this.setStage = this.setStage.bind(this);
        this.callBackend = this.callBackend.bind(this);
        this.setLanguage = this.setLanguage.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.setButton = this.setButton.bind(this);
        this.getTranslationByKey = this.getTranslationByKey.bind(this);
        this.getStringTranslationByKey = this.getStringTranslationByKey.bind(this);
        this.getJSXTranslationByKey = this.getJSXTranslationByKey.bind(this);
        this.showMessageBox = this.showMessageBox.bind(this);
        this.getAvailablePaymentMethods = this.getAvailablePaymentMethods.bind(this);
        this.setAvailablePaymentMethods = this.setAvailablePaymentMethods.bind(this);
        this.setInitialized = this.setInitialized.bind(this);
        this.setBoxTextMessageAtTheBeginning = this.setBoxTextMessageAtTheBeginning.bind(this);
        this.getBoxTextMessageAtTheBeginning = this.getBoxTextMessageAtTheBeginning.bind(this);
        this.setPayPalExpressAvailability = this.setPayPalExpressAvailability.bind(this);
        this.getPayPalExpressAvailability = this.getPayPalExpressAvailability.bind(this);
        this.setStageWithEmptySteps = this.setStageWithEmptySteps.bind(this);
        this.setStageWithEmptySteps = this.setStageWithEmptySteps.bind(this);
        this.setPaymentMethod = this.setPaymentMethod.bind(this);
        this.getPaymentMethod = this.getPaymentMethod.bind(this);
        this.setOrderOptions = this.setOrderOptions.bind(this);
        this.fetchSessionDataFromBackend = this.fetchSessionDataFromBackend.bind(this);
        this.handleMessageAtTheBeginning = this.handleMessageAtTheBeginning.bind(this);
        this.isPaymentLinkFlow = this.isPaymentLinkFlow.bind(this);
    }

    public getChildContext(): AppContext {
        return {
            returnUrls: this.state.returnUrls,
            deliveryOptions: this.state.deliveryOptions,
        };
    }

    public componentDidMount() {
        this.setLoading(true);
        this.initBaseOnStx(this.props.stx, this.props.server);
    }

    public render() {
        const commonPropsBundle: CommonProps = {
            setStage: this.setStage,
            setButton: this.setButton,
            setLoading: this.setLoading,
            getStringTranslationByKey: this.getStringTranslationByKey,
            getJSXTranslationByKey: this.getJSXTranslationByKey,
            currentLang: this.state.currentLang,
            showMessageBox: this.showMessageBox,
            getAvailablePaymentMethods: this.getAvailablePaymentMethods,
            setAvailablePaymentMethods: this.setAvailablePaymentMethods,
            isLoading: this.state.mainButton.loading,
            viewMode: this.props.viewMode,
            setBoxTextMessageAtTheBeginning: this.setBoxTextMessageAtTheBeginning,
            getBoxTextMessageAtTheBeginning: this.getBoxTextMessageAtTheBeginning,
            checkoutFromStx: !isNull(this.props.stx),
            stageData: this.state.stageData,
            setStageWithEmptySteps: this.setStageWithEmptySteps,
            paymentMethod: this.state.paymentMethod,
            setPaymentMethod: this.setPaymentMethod,
            setOrderOptions: this.setOrderOptions,
            fetchSessionDataFromBackend: this.fetchSessionDataFromBackend,
            sessionData: this.state.sessionData,
            handleMessageAtTheBeginning: this.handleMessageAtTheBeginning,
            callBackend: this.callBackend,
            wizardFlow: this.state.wizardFlow,
            isPaymentLinkFlow: this.isPaymentLinkFlow,
            isInitialized: this.state.isInitialized,
            intent: this.state.intent,
            ccTheme: this.props.ccTheme,
            initPaymentMethod: this.props.initPaymentMethod
        };
        return (
            <div class="main_app" id="main_app" data-stage={this.state.stageData.stage.toLowerCase()}>
                <Preloader {...commonPropsBundle} visible={this.state.isPreloaderVisible}/>

                <Header {...commonPropsBundle} />

                <div class="content-text-theme clearfix" id="content">
                    <InfoMessageBoxComponent
                        {...commonPropsBundle}
                        ref={(ref) => {
                            this.mBox = ref;
                        }}
                        message={this.state.boxMessage}
                        isTranslated={this.state.isBoxMessageTranslated}
                    />

                    <ComponentToDisplay currentStage={this.state.stageData.stage}
                                        commonPropsBundle={commonPropsBundle}
                                        paymentWindow={this.state.paymentWindow}
                                        errorMessageKey={this.state.errorMessageKey}
                                        isFullscreen={this.props.viewMode === ViewMode.FULLSCREEN}
                                        dataKey={this.getDataFromUrl()}
                                        isInitialized={this.state.isInitialized}
                                        setInitialized={this.setInitialized}
                                        setPayPalExpressAvailability={this.setPayPalExpressAvailability}
                                        getPayPalExpressAvailability={this.getPayPalExpressAvailability}
                    />

                    <div class="container">
                        <div class="col-xs-12 main-buttons">
                            <MainButton buttonInfo={this.state.cancelButton}
                                        {...commonPropsBundle}
                                        name="cancel-button"/>
                            <MainButton buttonInfo={this.state.mainButton}
                                        {...commonPropsBundle} />
                        </div>
                    </div>

                    <div className="container">
                        <div className="col-xs-12">
                            <PayPalExpressButton
                                {...commonPropsBundle}
                                isButtonEnabled={this.state.isPayPalExpressEnabled}
                            />
                        </div>
                    </div>
                </div>

                <Footer
                    {...commonPropsBundle}
                    setLanguage={this.setLanguage}
                />
            </div>
        );
    }

    private getAvailablePaymentMethods(restrictedPaymentMethods: PaymentMethod[] = []): PaymentMethod[] {
        let paymentMethods = (this.state.sessionData as SessionData).payment_methods;
        let index;
        restrictedPaymentMethods.map(pm => {
            index = paymentMethods.indexOf(pm);
            if (index !== -1) {
                paymentMethods.splice(index, 1);
            }
        });
        return paymentMethods;
    }

    private setAvailablePaymentMethods(paymentMethods: PaymentMethod[]): void {
        if (paymentMethods.length === 1) {
            this.setState({
                availablePaymentMethods: paymentMethods,
                paymentMethod: paymentMethods[0]
            });
        } else {
            this.setState({availablePaymentMethods: paymentMethods});
        }
    }

    private setStage(stage: AppStage, additionalStageData?: PaymentWindow | ErrorMessageKey, skipSessionRequest: boolean = false, initializedAtTheEnd: boolean = false) {
        this.setLoading(true, false);
        // don't need to call backend for error, it is already handled there
        let stageData: StageData;
        let callback = () => {
            http.request(
                {
                    url: apiPath + '/stage',
                    method: 'POST',
                    withCredentials: true,
                    headers: {'Content-Type': 'application/json'},
                    data: {
                        oldStage: this.state.stageData.stage,
                        newStage: stage,
                    }
                },
                (response: ResponseHttp) => {
                    if (response.status === HttpResponseCode.HTTP_OK) {
                        stageData = JSON.parse(response.text);
                        this.setState({stageData: stageData, paymentWindow: additionalStageData as PaymentWindow});
                        this.sendPostMessageStage(stage);
                    } else {
                        this.setStageWithEmptySteps(AppStage.STAGE_ERROR);
                    }
                    this.setLoading(false); // it prevents user clicking on button before response is gotten
                    if(initializedAtTheEnd) {
                        this.setInitialized(true);
                    }
                }
            )
        }

        if (stage === AppStage.STAGE_ERROR) {
            this.setLoading(false);
            this.setInitialized(true);
            this.setButton(() => undefined, '', false, false, true, MainButtonType.CANCEL);
            this.setStageWithEmptySteps(AppStage.STAGE_ERROR, additionalStageData as ErrorMessageKey);
            this.sendPostMessageStage(stage);
            return;
        }
        // can't use callBackend because it uses this function

        if (skipSessionRequest) {
            callback();
        } else {
            this.fetchSessionDataFromBackend(callback, true, true);
        }
    }

    private callBackend(path: string,
                        successCallback: (response: ResponseHttp) => void,
                        method: string = 'GET',
                        payload: object = {},
                        errorCallback: (response: ResponseHttp) => void
                            = (response) => {
                            let res = JSON.parse(response.text);
                            if (!isUndefined(res.transaction)) {
                                this.setState({
                                    returnUrls: this.manageReturnUrls(res.transaction.application_context.return_urls),
                                    intent: res.transaction.intent
                                });
                            }
                            this.setStage(AppStage.STAGE_ERROR, JSON.parse(response.text).error_message_key)
                        }
    ): void {
        const requestParams: RequestOptions = {
            url: apiPath + path,
            method: method,
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            data: payload
        };
        http.request(
            requestParams,
            (response: ResponseHttp) => {
                if (response.status === 0 || (response.status >= HttpResponseCode.HTTP_OK
                    && response.status < HttpResponseCode.HTTP_MULTIPLE_CHOICES)) {
                    // TODO
                    // for further investigation
                    // Please check ordering in your code
                    // FF can cancel request
                    if (response.status === 0) {
                        console.warn('Status 0. Request canceled');
                    }
                    successCallback(response);
                } else {
                    this.setLoading(false);
                    // session timeout will overwrite other error's handling e.g. authorisation
                    if(response.status === 401) {
                        this.setStageWithEmptySteps(AppStage.STAGE_ERROR, ErrorMessageKey.SESSION_TIMEOUT);
                    } else {
                        errorCallback(response);
                    }
                }
            }
        );
    }

    private showMessageBox(visible: boolean, boxMessage: string | (() => string) = '', isBoxMessageTranslated: boolean = false) {
        if (!isNull(this.mBox)) {
            if (visible) {
                this.setState({boxMessage, isBoxMessageTranslated});
                this.mBox.show();
                window.scroll(0, 0);
            } else {
                this.mBox.hide();
            }
        }
    }

    private setLoading(isLoading: boolean, isPreloaderDelayed: boolean = false) {
        // Do nothing if loading state is unchanged
        if (this.state.mainButton.loading === isLoading) {
            return;
        }
        const newState = {
            mainButton: {
                ...this.state.mainButton,
                loading: isLoading
            },
            isPreloaderVisible: this.state.isPreloaderVisible
        };

        if (isLoading) {
            if (isPreloaderDelayed) {
                // Delay pre-loader for slow processes
                setTimeout(
                    () => {
                        // If still loading, show spinner, else don't
                        if (this.state.mainButton.loading) {
                            this.setState({isPreloaderVisible: true});
                        }
                    },
                    2000
                );
            } else {
                newState.isPreloaderVisible = true;
            }
        } else {
            // Hide preloader immediately
            newState.isPreloaderVisible = false;
        }

        this.setState(newState);
    }

    private setButton(
        onClick: () => void,
        text: string,
        submit: boolean = false,
        disabled: boolean = false,
        hidden: boolean = false,
        type: MainButtonType = MainButtonType.SUBMIT
    ) {
        if (type === MainButtonType.CANCEL) {
            this.setState({
                cancelButton: {
                    ...this.state.mainButton,
                    buttonText: text,
                    onClick: onClick,
                    submit: submit,
                    disabled: disabled,
                    hidden: hidden,
                    type: MainButtonType.CANCEL
                }
            });
        } else {
            this.setState({
                mainButton: {
                    ...this.state.mainButton,
                    buttonText: text,
                    onClick: onClick,
                    submit: submit,
                    disabled: disabled,
                    hidden: hidden,
                    type: MainButtonType.SUBMIT
                }
            });
        }
    }

    private setLanguage(lang: LanguageSymbol) {
        this.setState({currentLang: lang});
    }

    private getStringTranslationByKey(key: string): string {
        return this.getTranslationByKey(key) as string;
    }

    private getJSXTranslationByKey(key: string): JSX.Element {
        return this.getTranslationByKey(key) as JSX.Element;
    }

    private getTranslationByKey(key: string): string | JSX.Element {
        if (!(key in allTranslations[this.state.currentLang])) {
            throw new Error('Cannot fetch translation ' + key + ', current language: ' + this.state.currentLang);
        }
        return allTranslations[this.state.currentLang][key];
    }

    // Base on viewmode we determine how connect to backend
    private init() {
        switch (this.props.viewMode) {
            case ViewMode.FULLSCREEN:
            case ViewMode.LAYER:
                window.addEventListener('message', (event) => {
                    const data = event.data as CheckoutPackage;

                    // This function is only for sending basket
                    if (isNullOrUndefined(data.action) || data.action !== CheckoutAction.SEND_THE_BASKET) {
                        return;
                    }
                    data.storage = StorageMethod.SESSION;
                    this.callBackend(
                        '/basket',
                        () => {
                            this.callBackend(
                                '/session-data',
                                (response: ResponseHttp) => {
                                    const sessionData: SessionData = JSON.parse(response.text);

                                    // Process payment methods
                                    const paymentMethods = sessionData.payment_methods;
                                    let paymentMethod = PaymentMethod.ERROR;
                                    if (paymentMethods.length === 1) {
                                        paymentMethod = paymentMethods[0];
                                    }
                                    if (paymentMethod === PaymentMethod.ERROR) {
                                        this.setState({
                                            availablePaymentMethods: paymentMethods,
                                            returnUrls: this.manageReturnUrls(sessionData.transaction.application_context.return_urls),
                                            sessionData: sessionData,
                                            intent: sessionData.transaction.intent
                                        });
                                    } else {
                                        this.setState({
                                            paymentMethod: paymentMethod,
                                            availablePaymentMethods: paymentMethods,
                                            returnUrls: this.manageReturnUrls(sessionData.transaction.application_context.return_urls),
                                            sessionData: sessionData,
                                            intent: sessionData.transaction.intent
                                        });
                                    }
                                    // end process payment method
                                    this.setOrderOptions(sessionData.merchant.order_options);

                                    // Because we must initialize StageData
                                    // so we get info from stage endpoint
                                    this.setStage(AppStage.STAGE_CHECKIN, undefined, true);
                                }
                            );
                        },
                        'POST',
                        AppComponent.parseNewLines(data)
                    );
                });
                break;
            default:
                throw new Error('Viewmode was not set or not recognized');
        }
    }

    // all client data is taken from hash
    private getDataFromUrl(): string {
        const queryToken: string | null = getParamFromUrl(ParamFromUrl.URL_TOKEN);
        return decodeURIComponent(isNull(queryToken) ? '' : queryToken);
    }

    // always put here already translated message or function callback
    private setBoxTextMessageAtTheBeginning(message: string | (() => string)): void {
        this.setState({
            textBoxMessageAtTheBeginning: message
        });
    }

    private getBoxTextMessageAtTheBeginning(): string | (() => string) {
        return this.state.textBoxMessageAtTheBeginning
    }

    /**
     * Callback to control initialized state of app
     *
     * @param {boolean} isInitialized
     */
    private setInitialized(isInitialized: boolean): void {
        this.setState({isInitialized});
    }

    private setPayPalExpressAvailability(isEnabled: boolean): void {
        this.setState({isPayPalExpressEnabled: isEnabled});
    }

    private getPayPalExpressAvailability(): boolean {
        return this.state.isPayPalExpressEnabled;
    }

    private initBaseOnStx(stx: null | string, server: null | string) {
        if (isNull(stx)) {
            this.init();
        } else {
            let query = isNull(server) ? '' : 'server=' + server;
            query += '&' + ParamFromUrl.WIZARD_FLOW + '=' + this.props.wizardFlow;

            if (this.props.wizardFlow === WizardFlow.PAYMENT) {
                query += '&' + ParamFromUrl.INIT_PAYMENT_METHOD + '=' + this.props.initPaymentMethod;
            }

            const typeResultMap = {
                [ExternalProcessResultType.TYPE_PAYPAL_EXPRESS]: 'paypal-express',
                [ExternalProcessResultType.TYPE_PAYPAL_REGULAR]: 'paypal-regular',
                [ExternalProcessResultType.TYPE_THREE_D_SECURE]: 'three-d-secure',
            };

            let base = '', result = '';

            for (let type in typeResultMap) {
                if (typeResultMap.hasOwnProperty(type)) {
                    const localResult = getParamFromUrl(type);
                    if (!isNull(localResult)) {
                        base = typeResultMap[type];
                        result = localResult;
                        break;
                    }
                }
            }

            let successCallback: (() => void) | null = null;

            if (base && result) {
                successCallback = this.callStatusEndpoint.bind(this, base, result, stx, query);
            }
            this.restoreSessionFromStx(stx, query, successCallback);
        }
    }

    private setStageWithEmptySteps(stage: AppStage, errorMessageKey?: ErrorMessageKey) {

        this.sendPostMessageStage(stage);
        this.setLoading(false);
        this.setInitialized(true);
        this.setState({
            stageData: {
                stage: stage,
                direct: [],
                back: '',
                next: ''
            }
        });

        if (errorMessageKey) {
            this.setState({
                errorMessageKey: errorMessageKey
            });
        }
    }

    private setPaymentMethod(paymentMethod: PaymentMethod) {
        this.setState({
            paymentMethod: paymentMethod
        });
    }

    private getPaymentMethod(): PaymentMethod {
        return this.state.paymentMethod;
    }

    private manageReturnUrls(checkoutLinks: CheckoutLinks): ReturnUrls {

        // HINT:
        // - if error URL and abort URL are the same, it is only used as failure URL
        // - if error URL and abort URL differ, error URL is for failure, and abort URL is for abort

        if (isNullOrUndefined(checkoutLinks)) {
            checkoutLinks = {
                url_success: '',
                url_error: '',
                url_abort: '',
            };
        }

        // SPPAYIF-1480
        if (this.props.viewMode === ViewMode.LAYER && checkoutLinks.url_abort === checkoutLinks.url_error) {
            checkoutLinks.url_abort = '';
        }

        return {
            urlSuccess: checkoutLinks.url_success || '',
            urlError: checkoutLinks.url_error || '',
            urlAbort: checkoutLinks.url_abort || '',
        };
    }

    private setOrderOptions(orderOptions: CheckoutOptions) {

        // const shippingOptionEnabled = orderOptions.shipping.enabled;
        // const collectionOptionEnabled = orderOptions.collection.enabled;
        const collectionOptionEnabled = false;

        const deliveryOptions: DeliveryOptions = {
            isShippingEnabled: true,
            isCollectionEnabled: false,
        };

        if (collectionOptionEnabled) {
            // deliveryOptions.preparationTime = orderOptions.collection.preparation_time;
            // deliveryOptions.collectionHours = orderOptions.collection.collection_hours;
        }

        this.setState({
            deliveryOptions: deliveryOptions,
        });
    }

    private fetchSessionDataFromBackend(callback: () => void, showLoader: boolean = false, endlessLoader: boolean = false) {
        if (showLoader) {
            this.setLoading(true, false);
        }
        this.callBackend(
            '/session-data',
            (response: ResponseHttp) => {
                const sessionData = this.manageSessionDataFromResponse(response);

                // end process payment method
                this.setOrderOptions(sessionData.merchant.order_options);

                callback();
                if (showLoader && !endlessLoader) {
                    this.setLoading(false);
                }
            }
        );
    }

    private manageSessionDataFromResponse(response: ResponseHttp): SessionData {
        const sessionData: SessionData = JSON.parse(response.text);

        // Process payment methods
        const paymentMethods = sessionData.payment_methods;
        let paymentMethod = PaymentMethod.ERROR;
        if (paymentMethods.length === 1) {
            paymentMethod = paymentMethods[0];
        }

        if (paymentMethod === PaymentMethod.ERROR) {
            this.setState({
                availablePaymentMethods: paymentMethods,
                returnUrls: this.manageReturnUrls(sessionData.transaction.application_context.return_urls),
                sessionData: sessionData,
                intent: sessionData.transaction.intent
            });
        } else {
            this.setState({
                paymentMethod: paymentMethod,
                availablePaymentMethods: paymentMethods,
                returnUrls: this.manageReturnUrls(sessionData.transaction.application_context.return_urls),
                sessionData: sessionData,
                intent: sessionData.transaction.intent
            });
        }

        return sessionData;
    }

    private handleMessageAtTheBeginning() {
        const messageAtTheBeginning: string | (() => string) = this.getBoxTextMessageAtTheBeginning();
        if (messageAtTheBeginning !== '') {
            this.showMessageBox(true, messageAtTheBeginning);
            this.setBoxTextMessageAtTheBeginning('');
        } else {
            this.showMessageBox(false, '');
        }
    }

    private sendPostMessageStage(stage: AppStage) {
        window.parent.postMessage({action: 'smartcheckout-stage', stage: stage}, '*');
    }

    private restoreSessionFromStx(
        stx: string,
        query: string,
        successCallback: (() => void) | null = null,
    ) {
        this.callBackend(
            `/${ParamFromUrl.STX}/${stx}?${query}`,
            (responseStx: ResponseHttp) => {
                let responseFromStx = JSON.parse(responseStx.text);
                let paymentMethods = responseFromStx.payment_methods;
                let paymentMethod = paymentMethods[0];
                let wizardFlow = this.setWizardFlowBaseOnIntent(responseFromStx.transaction.intent);

                AppComponent.setCustomBackgroundImage(responseFromStx.contract);
                this.setState({
                    returnUrls: this.manageReturnUrls(responseFromStx.transaction.application_context.return_urls)
                });

                // for payment wizard setInitialized is executed in setStage
                if (isNull(successCallback)) {
                    if (wizardFlow === WizardFlow.PAYMENT) {
                        if (!isNull(this.props.initPaymentMethod)) {
                            this.setPaymentMethod(this.props.initPaymentMethod as PaymentMethod);
                            this.setStage(AppStage.STAGE_PAYMENT_INPUT, paymentWindowBasedOnFlow[wizardFlow][this.props.initPaymentMethod as PaymentMethod], false, true);
                        } else {
                            if (isUndefined(paymentMethod)) {
                                this.setStage(AppStage.STAGE_ERROR, ErrorMessageKey.NO_PAYMENT_METHOD_REMAINING, false, true);
                            } else {
                                if (paymentMethods.length === 1) {
                                    this.setPaymentMethod(paymentMethod);
                                    if (paymentMethod === PaymentMethod.PAY_IN_ADVANCE) {
                                        this.callBackend(
                                            '/payment-method',
                                            response => {
                                                this.callBackend(
                                                    '/start',
                                                    () => {
                                                        this.setStage(responseFromStx.route.stage, paymentWindowBasedOnFlow[wizardFlow][paymentMethod], false, true);
                                                    },
                                                    'POST',
                                                    {}
                                                );
                                            },
                                            'POST',
                                            {type: paymentMethod}
                                        )
                                    } else {
                                        this.setStage(responseFromStx.route.stage, paymentWindowBasedOnFlow[wizardFlow][paymentMethod], false, true);
                                    }
                                } else {
                                    this.setStage(responseFromStx.route.stage, paymentWindowBasedOnFlow[wizardFlow][paymentMethod], false, true);
                                }
                            }
                        }
                        // wizardFlow Checkout
                    } else {
                        this.fetchSessionDataFromBackend(
                            () => {
                                this.setState({stageData: responseFromStx.route});
                                this.setLoading(false);
                                this.setInitialized(true);
                            })
                    }
                } else {
                    this.setLoading(true, false);
                    successCallback();
                }
            },
            'GET',
            {},
            (responseStx: ResponseHttp) => {
                let responseFromStx = JSON.parse(responseStx.text);
                if (!isNullOrUndefined(responseFromStx.transaction)) {
                    this.setWizardFlowBaseOnIntent(responseFromStx.transaction.intent);
                    this.setState({
                        returnUrls: this.manageReturnUrls(responseFromStx.transaction.application_context.return_urls),
                        intent: responseFromStx.transaction.intent
                    });
                }
                AppComponent.setCustomBackgroundImage(responseFromStx.contract);
                this.setStage(AppStage.STAGE_ERROR, responseFromStx.error_message_key);
            }
        );
    }

    private callStatusEndpoint(base: string, result: string, stx: string, query: string) {
        this.callBackend(
            `/${base}/${result}/${stx}?${query}`,
            (response: ResponseHttp) => {
                switch (this.state.wizardFlow) {

                    case WizardFlow.CHECKOUT:
                        this.fetchSessionDataFromBackend(
                            () => {
                                //TODO: change response format
                                let data = JSON.parse(response.text);
                                const urls = this.manageReturnUrls(data.transaction.application_context.return_urls);

                                if (data.message !== '') {
                                    this.showMessageBox(true, data.message, true);
                                }
                                this.manageStatusEndpointResponse(data, urls);

                                this.setLoading(false);
                                this.setInitialized(true);
                            });
                        break;

                    case WizardFlow.PAYMENT:
                        let redirectUrl: string;
                        switch (result) {
                            case ExternalProcessResult.RESULT_SUCCESS:
                                redirectUrl = this.state.returnUrls.urlSuccess;
                                break;
                            case ExternalProcessResult.RESULT_FAILURE:
                                redirectUrl = this.state.returnUrls.urlError;
                                break;
                            default:
                                throw new Error('Unknown result');
                        }
                        exitSmartCheckoutDirectly(this.props.viewMode, redirectUrl);
                        this.setInitialized(true);
                        break;
                }
            }
        );
    }

    private manageStatusEndpointResponse(data: any, urls: ReturnUrls) {
        this.setState({
            stageData: data.route,
            // availablePaymentMethods are take now from session-data
            // so without /session-data we will not get proper array
            availablePaymentMethods: data.general.payment_methods,
            paymentMethod: data.general.payment_method,
            returnUrls: urls
        });
        if (urls.urlSuccess) {
            this.setButton(
                () => {
                    this.setLoading(true);
                    exitSmartCheckout(urls.urlSuccess, {}, this.props.viewMode);
                },
                'success_back_to_the_shop_button',
                false,
                false,
                this.props.viewMode !== ViewMode.LAYER && urls.urlSuccess === ''
            );
        } else {
            this.setButton(() => {
            }, '', false, false, true);
        }
    }

    private static setCustomBackgroundImage(contract: Contract) {
        let url;
        if (contract &&
            contract.payment_link_options &&
            contract.payment_link_options.background &&
            contract.payment_link_options.background.enabled) {
            url = contract.payment_link_options.background.image_url;
            if (url) {
                document.body.classList.add('custom-background');
                document.body.style.backgroundImage = `url("${url}")`;
            }
        }
    }

    // new function which now return fixed value
    private isPaymentLinkFlow(): boolean {
        return false;
    }

    private setWizardFlowBaseOnIntent(intent: Intent): WizardFlow {
        let wizardFlow: WizardFlow;
        switch (intent) {
            case Intent.CHECKOUT:
            case Intent.ORDER:
                wizardFlow = WizardFlow.CHECKOUT;
                break;
            case Intent.SALE:
            case Intent.AUTHORIZATION:
                wizardFlow = WizardFlow.PAYMENT;
                break;
            default:
                wizardFlow = this.props.wizardFlow;
        }
        setDataAttrInBody(ParamFromUrl.WIZARD_FLOW, wizardFlow);
        this.setState({wizardFlow: wizardFlow});

        return wizardFlow;
    }

    // prevent escape new lines in item desc
    private static parseNewLines(payload: any): any {
        let items = payload.basket;

        items = items.map((i: any) => {
            i.name = i.name.replace(/\\n/g, "\n");
            return i;
        });

        payload.basket = items;

        return payload;
    }
}
