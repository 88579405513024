import {Component, h} from 'preact';
import ValidationInstances, {streetRegex} from '../../../common/input/ValidationInstances';
import {
    AppStage,
    CommonProps,
    FieldId,
    HttpResponseCode,
    InputAttributes,
    InputName,
    Intent,
    MainButtonType,
    ResponseHttp,
    SessionData,
    WizardFlow
} from '../../../common/types';
import {isNull} from 'util';
import InputContainer from "../../../common/input/InputContainer";
import {InputTemplate} from "../../../templates/InputTemplate";
import HTMLInputElementDecorator from "../../../common/input/HTMLInputElementDecorator";
import {
    createHeadlineElement,
    exitSmartCheckout,
    exitSmartCheckoutDirectly, getOwnerFromSessionData, getPrimaryColor,
    goToSuccessPage,
    setFieldError
} from "../../../common/utils";
import PaymentMethodHeadlineWithAssigmentAndScoring
    from "../../../templates/PaymentMethodHeadlineWithAssigmentAndScoring";
import {ProjectNameAndLogo} from "../../../templates/ProjectNameAndLogo";
import {BasketOrOrderTotal} from "../../../templates/BasketOrOrderTotal";
import FilterInstances from "../../../common/input/FilterInstances";
import {PaymentHints} from "../../../templates/PaymentHints";
import {PaymentLinkInfoHeader} from '../../../templates/PaymentLinkInfoHeader';

interface DirectDebitComponentProps extends CommonProps {
    total: number;
    currency: string;
}

export interface DirectDebitComponentState {
    addressFieldsAreDisplayed: boolean;
}

export default class DirectDebitComponent
    extends Component<DirectDebitComponentProps, DirectDebitComponentState> {

    private inputContainer: InputContainer = new InputContainer(this);
    private inputParams: InputAttributes[] = [];
    private form: HTMLFormElement;
    readonly formRefCallback = (form: HTMLFormElement) => this.form = form;
    private salutations: string[] = [
        this.props.getStringTranslationByKey('salutation_male'),
        this.props.getStringTranslationByKey('salutation_female'),
    ];

    private static ccFormat(value: string): string {
        let v = value.replace(/\s+/g, '');
        let matches = v.match(/\w{4,50}/g);
        let match = matches && matches[0] || '';
        let parts = [];
        for (let i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4));
        }
        if (parts.length) {
            return parts.join(' ');
        } else {
            return value;
        }
    }

    public constructor(props: DirectDebitComponentProps) {
        super(props);

        this.state = {
            addressFieldsAreDisplayed: false
        };

        switch (this.props.wizardFlow) {
            case WizardFlow.CHECKOUT:
                this.goToNextStage = this.goToNextStage.bind(this);
                this.props.setButton(this.goToNextStage, 'confirm_mandate');
                break;
            case WizardFlow.PAYMENT:
                this.cancelButton = this.cancelButton.bind(this);
                this.confirmMandateButton = this.confirmMandateButton.bind(this);
                this.props.setButton(this.confirmMandateButton, 'confirm_mandate');
        }

        this.inputParams = [
            {
                containerClassName: 'col-xs-12 col-mob-6 ',
                name: InputName.OWNER,
                placeholder: this.props.getStringTranslationByKey('direct_debit_cardholder'),
                validationInstance: ValidationInstances.getAlphaOnlyValidationInstance()
            },
            {
                containerClassName: 'col-xs-12 col-mob-6',
                name: InputName.IBAN,
                id: FieldId.ID_IBAN,
                placeholder: 'IBAN',
                onInput: () => this.handleIbanInput(),
                onPaste: () => this.moveCursorToTheEnd(), // without it cursor is left some symbols behind
                validationInstance: ValidationInstances.getIBANValidationInstance()
            },

            // address
            // row
            {
                containerClassName: 'col-xs-12 col-mob-6 non-mandatory address-field',
                name: InputName.COMPANY_NAME,
                placeholder: props.getStringTranslationByKey(InputName.COMPANY_NAME),
                autocomplete: 'org',
                required: false,
            },
            {
                containerClassName: 'col-xs-12 col-mob-6 hidden-xs visibility-hidden address-field',
                name: InputName.EMPTY,
                required: false,
                customProps: {
                    disabled: true
                }
            },
            // row
            {
                containerClassName: 'col-xs-12 col-mob-3 address-field',
                name: InputName.SALUTATION,
                placeholder: props.getStringTranslationByKey(InputName.SALUTATION),
                autocomplete: 'honorific-prefix',
                required: false,
                optionsList: this.salutations,
                list: 'salutations',
                id: 'salutation'
            },
            {
                containerClassName: 'col-xs-12 col-mob-4 address-field',
                name: InputName.FIRST_NAME,
                placeholder: props.getStringTranslationByKey(InputName.FIRST_NAME),
                autocomplete: 'given-name',
                validationInstance: ValidationInstances.getAlphaOnlyValidationInstance(),
            },
            {
                containerClassName: 'col-xs-12 col-mob-5 address-field',
                name: InputName.SURNAME,
                placeholder: props.getStringTranslationByKey(InputName.SURNAME),
                autocomplete: 'family-name',
                validationInstance: ValidationInstances.getAlphaOnlyValidationInstance(),
            },
            // row
            {
                containerClassName: 'col-xs-12 col-mob-3 address-field',
                name: InputName.ZIP_CODE,
                placeholder: props.getStringTranslationByKey(InputName.ZIP_CODE),
                autocomplete: 'postal-code',
                onBlurPaste: FilterInstances.removeAllIntermediateWhitespacesAndTrimFilterInstance(),
                customProps: {
                    inputmode: 'numeric'
                }
            },
            {
                containerClassName: 'col-xs-12 col-mob-4 address-field',
                name: InputName.TOWN,
                placeholder: props.getStringTranslationByKey(InputName.TOWN),
                autocomplete: 'address-level2',
                validationInstance: ValidationInstances.getAlphaOnlyValidationInstance(),
            },
            {
                containerClassName: 'col-xs-12 col-mob-5 address-field',
                name: InputName.STREET_AND_STREET_NUMBER,
                placeholder: props.getStringTranslationByKey(InputName.STREET_AND_STREET_NUMBER),
                autocomplete: 'address-line1',
                validationInstance: ValidationInstances.getStreetStreetNumberValidationInstance(),
            },

            //row
            {
                containerClassName: 'col-xs-12 col-mob-7 address-field',
                name: InputName.ADDITIONAL_ADDRESS_DATA,
                placeholder: props.getStringTranslationByKey(InputName.ADDITIONAL_ADDRESS_DATA),
                autocomplete: 'address-line2',
                required: false,
            }
        ];

        this.goToNextStage = this.goToNextStage.bind(this);
        this.init = this.init.bind(this);
    }

    /**
     * @param {DirectDebitComponentProps} nextProps
     */
    public componentWillReceiveProps(nextProps: DirectDebitComponentProps) {
        const salutations: string[] = [
            nextProps.getStringTranslationByKey('salutation_male'),
            nextProps.getStringTranslationByKey('salutation_female'),
        ];
        this.inputParams[this.inputParams.findIndex(obj => obj.name === InputName.SALUTATION)].optionsList = salutations;
        this.translateLabels(nextProps);
    }

    public componentDidMount() {
        setTimeout(() => this.init(), 0);
    }

    // we moved all code from componentDidMount to init() function
    private init() {
        this.form.reset();
        this.showBackButton();

        // TODO: using sessionStorage workaround because SecuCore is too slow :(
        const ownerInputField: HTMLInputElement = this.inputContainer.getDecorators()[InputName.OWNER].getField();
        const sessionData: SessionData | null = this.props.sessionData;

        if (!isNull(sessionData)) {
            ownerInputField.value = getOwnerFromSessionData(sessionData);
        }
        this.toggleAddressFields();
        (document.querySelector(`[name="${InputName.OWNER}"`) as HTMLInputElement).focus();
    }

    public render() {
        return (
            <div>
                <PaymentLinkInfoHeader {...this.props}
                                       isShippingEnabled={this.context.deliveryOptions.isShippingEnabled}
                                       additionalWrapperClass={'mb-30'}/>
                <ProjectNameAndLogo {...this.props}/>
                {this.props.wizardFlow === WizardFlow.CHECKOUT ?
                    <div class="container">
                        <div class="col-xs-12">
                            {createHeadlineElement(this.props, this.props.getStringTranslationByKey('direct_debit_headline').toUpperCase())}
                        </div>
                    </div>
                    : null}
                <BasketOrOrderTotal {...this.props} total={this.props.total} currency={this.props.currency}/>
                <PaymentHints {...this.props} />
                <PaymentMethodHeadlineWithAssigmentAndScoring {...this.props} />
                <div class="container">
                    <div class="col-xs-12">
                        <p class="content-headline-theme">
                            {this.props.getStringTranslationByKey('direct_debit_header')}
                        </p>
                    </div>
                    <form ref={this.formRefCallback} class="overflow-auto full-width">
                        {this.inputParams.map(
                            (val: InputAttributes, index) => {
                                return (
                                    <InputTemplate
                                        key={index}
                                        inputAttributes={val}
                                        container={this.inputContainer}
                                        colorMandatory={getPrimaryColor(this.props)}/>
                                );
                            })}
                    </form>
                    <div class="col-xs-12">
                        {this.props.getJSXTranslationByKey('direct_debit_agreement')}
                    </div>
                </div>
            </div>

        );
    }

    private constructDDRequestBody(): { [key: string]: string | object } {
        const inputs: { [key: string]: HTMLInputElementDecorator } = this.inputContainer.getDecorators();

        let ownerkey = InputName.OWNER;
        let ibankey = InputName.IBAN;

        return {
            'owner': inputs[ownerkey].getField().value,
            'iban': inputs[ibankey].getField().value.replace(/ /g, '') // value with removed spaces
        };
    }

    private goToNextStage(): void {
        let fieldsForValidation: InputName[];

        this.inputContainer.filterAllInputs();
        if (this.state.addressFieldsAreDisplayed) {
            // all
            fieldsForValidation = [];
        } else {
            // only these
            fieldsForValidation = [InputName.IBAN, InputName.OWNER];
        }
        if (!this.inputContainer.validateInputs(fieldsForValidation)) {
            return;
        }

        this.props.showMessageBox(false, '');
        // Direct debit preparation is known to be slow, so show preloader immediately
        this.props.setLoading(true, false);

        if (this.props.sessionData) {
            if (isNull(this.props.sessionData.transaction.customer)) {
                this.props.callBackend(
                    '/address',
                    () => {
                        this.callPrepareEndpointForCW()
                    },
                    'POST',
                    this.constructAddressRequestBody()
                );
            } else {
                this.callPrepareEndpointForCW();
            }
        }
    }

    private constructAddressRequestBody(): { [key: string]: string | number } {
        const inputs: { [key: string]: HTMLInputElementDecorator } = this.inputContainer.getDecorators();
        const matchedStreet = inputs[InputName.STREET_AND_STREET_NUMBER].getField().value.match(streetRegex);

        return {
            companyname: inputs[InputName.COMPANY_NAME].getField().value,
            salutation: +inputs[InputName.SALUTATION].getField().value,
            first_name: inputs[InputName.FIRST_NAME].getField().value,
            surname: inputs[InputName.SURNAME].getField().value,
            street: matchedStreet![1],
            street_number: matchedStreet![2],
            town: inputs[InputName.TOWN].getField().value,
            additional_address: inputs[InputName.ADDITIONAL_ADDRESS_DATA].getField().value,
            zipcode: inputs[InputName.ZIP_CODE].getField().value
        };
    }

    private callPrepareEndpointForCW() {
        this.props.callBackend(
            '/prepare',
            () => {
                this.props.setLoading(false);
                this.props.setStage(this.props.stageData.next as AppStage);
            },
            'POST',
            this.constructDDRequestBody(),
            (response: ResponseHttp) => {
                if (response.status === HttpResponseCode.HTTP_BAD_REQUEST) {
                    this.handleBadRequest();
                } else if (setFieldError(response)) {
                    this.props.setButton(this.goToNextStage, 'direct_debit_button');
                } else {
                    this.props.setStage(AppStage.STAGE_ERROR);
                }
            }
        );
    }

    private callPrepareEndpointForPW() {
        this.props.callBackend(
            '/prepare',
            () => {
                if (this.props.sessionData && this.props.sessionData.transaction.intent === Intent.AUTHORIZATION) {
                    if (this.context.returnUrls.urlSuccess) {
                        exitSmartCheckoutDirectly(this.props.viewMode, this.context.returnUrls.urlSuccess);
                    } else {
                        goToSuccessPage(this.props);
                    }
                } else {
                    this.props.callBackend(
                        '/start',
                        () => {
                            if (this.context.returnUrls.urlSuccess) {
                                exitSmartCheckoutDirectly(this.props.viewMode, this.context.returnUrls.urlSuccess);
                            } else {
                                goToSuccessPage(this.props);
                            }
                        },
                        'POST',
                        {}
                    );
                }
            },
            'POST',
            this.constructDDRequestBody()
        );
    }

    private handleBadRequest(): void {
        const paymentMethods = this.props.getAvailablePaymentMethods();
        if (paymentMethods.length > 1) {
            this.props.showMessageBox(true, 'payment_method_more_available_scoring_failed', true);
            this.props.setStage(AppStage.STAGE_PAYMENT_SELECTION);
        } else {
            this.props.showMessageBox(true, 'payment_method_one_available_scoring_failed', true);
            this.props.setLoading(false);
        }
    }

    private handleIbanInput(): void {

        const ibanField: HTMLInputElement = this.inputContainer.getDecorators()[InputName.IBAN].getField();
        const selectionStart: number | null = ibanField.selectionStart;
        const selectionEnd: number | null = ibanField.selectionEnd;

        ibanField.value = DirectDebitComponent.ccFormat(ibanField.value);

        if (this.isAddressFormRequired(ibanField.value)) {
            this.toggleAddressFields(false)
        } else {
            this.toggleAddressFields();
        }

        // it is needed for handling cursor behavior
        if (selectionStart && selectionEnd) {
            if (ibanField.value.charAt(selectionStart - 1) === ' ') {
                ibanField.selectionStart = selectionStart + 1;
                ibanField.selectionEnd = selectionEnd + 1;
            } else {
                ibanField.selectionStart = selectionStart;
                ibanField.selectionEnd = selectionEnd;
            }
        }
    }

    private moveCursorToTheEnd(): void {
        const ibanField: HTMLInputElement = this.inputContainer.getDecorators()[InputName.IBAN].getField();
        ibanField.selectionStart = this.inputContainer.getDecorators()[
            InputName.IBAN
            ].getField().value.toString().length;
        ibanField.selectionEnd = this.inputContainer.getDecorators()[
            InputName.IBAN
            ].getField().value.toString().length;
    }

    private cancelButton(): void {
        let abortUrl = this.context.returnUrls.urlAbort;

        if (this.props.getAvailablePaymentMethods().length > 1 && isNull(this.props.initPaymentMethod)) {
            // in this case we can skip /session-data request
            this.props.setStage(AppStage.STAGE_PAYMENT_SELECTION, undefined, true, false);
            this.props.setButton(() => undefined, '', false, false, true, MainButtonType.CANCEL);
        } else {
            exitSmartCheckout(abortUrl, {}, this.props.viewMode, '/abort', this.props.setLoading);
        }
    }

    private confirmMandateButton(): void {
        let fieldsForValidation: InputName[];

        this.inputContainer.filterAllInputs();
        if (this.state.addressFieldsAreDisplayed) {
            // all
            fieldsForValidation = [];
        } else {
            // only these
            fieldsForValidation = [InputName.IBAN, InputName.OWNER];
        }
        if (!this.inputContainer.validateInputs(fieldsForValidation)) {
            return;
        }

        this.props.showMessageBox(false, '');
        // Direct debit preparation is known to be slow, so show preloader immediately
        this.props.setLoading(true, false);

        if (this.props.sessionData) {
            if (isNull(this.props.sessionData.transaction.customer)) {
                this.props.callBackend(
                    '/address',
                    () => {
                        this.callPrepareEndpointForPW();
                    },
                    'POST',
                    this.constructAddressRequestBody()
                );
            } else {
                this.callPrepareEndpointForPW();
            }
        }
    }

    private toggleAddressFields(fieldsShouldBeHidden: boolean = true) {
        let addressFieldClass = 'address-field';
        let addressElements = document.getElementsByClassName(addressFieldClass);
        let element;

        for (let i = 0; i < addressElements.length; i++) {
            element = addressElements[i];
            if (fieldsShouldBeHidden) {
                element.classList.add('display-none');
                this.setState({addressFieldsAreDisplayed: false})
            } else {
                element.classList.remove('display-none');
                this.inputContainer.clearAllErrors();
                this.setState({addressFieldsAreDisplayed: true});
            }
        }
    }

    private isAddressFormRequired(iban: string): boolean {
        let countryCode = iban.substr(0, 2).toUpperCase();
        let specialCountryCodes = ['CH', 'MC', 'SM'];

        if (this.props.sessionData && isNull(this.props.sessionData.transaction.customer)) {
            return specialCountryCodes.includes(countryCode)
        } else {
            return false;
        }
    }

    private translateLabels(props: DirectDebitComponentProps) {
        this.inputParams.forEach(
            (value: InputAttributes, index: number) => {
                if (value.name === InputName.IBAN) {
                    this.inputParams[0].placeholder = this.props.getStringTranslationByKey('direct_debit_cardholder');
                } else {
                    this.inputParams[index].placeholder = props.getStringTranslationByKey(value.name);
                }
            }
        );
    }

    private showBackButton() {
        if (this.props.wizardFlow === WizardFlow.PAYMENT) {
            // started from "general" payment link or abort url is available
            if ((this.props.getAvailablePaymentMethods().length > 1 && isNull(this.props.initPaymentMethod)) || this.context.returnUrls.urlAbort) {
                this.props.setButton(this.cancelButton, 'back', false, false, false, MainButtonType.CANCEL);
            }
        }
    }
}
