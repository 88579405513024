import { SecuCardTemplateProps } from '../../templates/SecuCardTemplate';
import { DirectDebitTemplateProps } from '../../components/confirmation/DirectDebitTemplate';
import { PaymentMethod, RequestOptions, ResponseHttp } from '../types';
import { apiPath } from '../../index';
import { isoHttpRequestToPromise } from '../utils';
import { CreditCardTemplateProps } from '../../components/confirmation/CreditCardTemplate';

export type SecuCardInfo = SecuCardTemplateProps;
type DirectDebitInfo = DirectDebitTemplateProps;
type CreditCardInfo = CreditCardTemplateProps;

interface PaymentMethodInfoAbstract {
  type: PaymentMethod;
  data: SecuCardInfo[] | DirectDebitInfo | CreditCardInfo;
}

export default class PaymentContainer {
  private paymentMethodInfo: PaymentMethodInfoAbstract;

  public constructor() {
    this.paymentMethodInfo = {
      type: PaymentMethod.ERROR,
      data: [],
    };
    this.importInfo = this.importInfo.bind(this);
  }

  public importInfo(info: PaymentMethodInfoAbstract) {
    this.paymentMethodInfo = info;
  }

  public async retrieveData() {
    const sessionRequestOptions: RequestOptions = {
      url: apiPath + '/payment-info',
      method: 'GET',
      withCredentials: true,
    };

    await isoHttpRequestToPromise(
      sessionRequestOptions,
      (sessionResponse: ResponseHttp) => {
        this.paymentMethodInfo = JSON.parse(sessionResponse.text);
      }
    );
  }

  public getType(): PaymentMethod {
    return this.paymentMethodInfo.type;
  }

  public getSecucards(): SecuCardInfo[] {
    return this.paymentMethodInfo.data as SecuCardInfo[];
  }

  public getDirectDebit(): DirectDebitInfo {
    return this.paymentMethodInfo.data as DirectDebitInfo;
  }

  public getCreditCard(): CreditCardInfo {
    return this.paymentMethodInfo.data as CreditCardInfo;
  }
}