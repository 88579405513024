import { h } from 'preact';

export let privacy_en = (
<div>
    <h1>Privacy Policy for secupay AG (hereinafter "secupay")</h1>
    <p>We hereby inform you about the processing of your personal data and the claims and rights to which you are
        entitled in accordance with the data protection regulations.</p>
    <h2>Who is responsible for data processing and who can I contact?</h2>
    <p>secupay AG</p>
    <p>Represented by the Executive Board<br/>Hans-Peter Weber, Katja Hartmann</p>
    <p>Goethestrasse 6<br/>01896 Pulsnitz, Germany</p>
    <p>Contact:</p>
    <p>Phone +49 (0) 35955 7550-0<br/>Fax +49 (0) 35955 7550-99<br/>E-Mail <a
        href="mailto:info@secupay.com">info@secupay.com</a></p>
    <p>Contact to our data protection officer:</p>
    <p>secupay<br/>MR. Axel Hirsch<br/>Goethestrasse 6<br/>01896 Pulsnitz, Germany<br/>E-Mail <a
        href="mailto:privacy@secupay.com">privacy@secupay.com</a></p>
    <h2>For which purposes and on what legal basis do we process your data?</h2>
    <p>We process personal data that we receive from you in the course of a business initiation or business relationship
        in order to carry out our services, the execution of your orders, as well as all activities necessary for the
        operation and administration of a financial transfer service provider. We process personal data in accordance
        with the provisions of the European Data Protection Ordinance (GDPR) and the German Federal Data Protection Act
        (BDSG-neu).</p>
    <p>Insofar as it is necessary for the provision of our services, we process data received from other companies or
        other third parties (e. g. infoscore GmbH) in a permissible manner. In addition, we process personal data that
        we have obtained and are permitted to process from publicly accessible sources (e. g. debtor directories, land
        registers, commercial and association registers, the press and other media).</p>
    <p>Relevant personal data may in particular be relevant:</p>
    <ul>
        <li>Personal data (name, date of birth, place of birth, nationality and similar data)</li>
        <li>Contact details (address, e-mail address, telephone number and similar data)</li>
        <li>Legitimation data (identification, reporting and comparable data)</li>
        <li>Current accounts and credit card data</li>
        <li>Information about your financial situation (creditworthiness data including scoring, i. e. data for
            assessing credit risk)
        </li>
        <li>Data on the use of telemedia offered by us (e. g. time of access to our websites, apps or newsletters,
            clicked pages of us or entries and comparable data)
        </li>
    </ul>
    <h2>Who else gets your data (receiver)?</h2>
    <p>If necessary for the provision of our services, your data will be passed on to the following companies:</p>
    <ul>
        <li>Creditreform Boniversum GmbH</li>
        <li>Concardis GmbH</li>
        <li>infoscore Consumer Data GmbH, Baden-Baden, Germany</li>
        <li>SIX Group AG</li>
        <li>Transact electronic payment systems GmbH</li>
        <li>Bluro GbR ("Serverspot")</li>
        <li>Financial Auditors</li>
        <li>Contractors</li>
    </ul>
    <p>Within secupay AG, those departments receive your data which need to fulfil our contractual and legal
        obligations.</p>
    <p>As far as we commission service providers, your data is subject to the same security standards as with us.</p>
    <p>In the event that the purchase price claim has been assigned to secupay AG, the data may be passed on to the
        following companies for the purpose of enforcing the purchase price claim:</p>
    <ul>
        <li>Creditreform Dresden Aumüller KG</li>
        <li>Creditreform Munich Ganzmüller, Groher & Kollegen KG</li>
    </ul>
    <h2>Is data transferred to a third country or to an international organisation?</h2>
    <p>In connection with the provision of our services, data is transferred to companies in the United States of
        America on the basis of EU standard contractual clauses (it is possible to consult this document) or within the
        framework of the EU - US Privacy Shield.</p>
    <h2>How long will my data be stored?</h2>
    <p>As far as necessary, we process and store your personal data for the duration of our business relationship, which
        also includes the initiation and processing of a contract.</p>
    <p>In addition, we are subject to various storage and documentation obligations arising from the German Commercial
        Code (HGB), the Tax Code (AO), the German Banking Act (KWG) and the Money Laundering Act (GwG). The periods for
        storage and documentation specified there are two to ten years.</p>
    <h2>Which data protection rights do I have?</h2>
    <p>You have</p>
    <ul>
        <li>the right to obtain information about your personal data in accordance with Art. 15 General Data Protection
            Regulation, as well as under certain conditions
        </li>
        <li>the right of correction in accordance with Art.16 General Data Protection Regulation if your personal data
            should be incorrect or
        </li>
        <li>the right for deletion in accordance with Art. 17 General Data Protection Regulation, if the deletion does
            not conflict with any statutory storage obligations or other things or
        </li>
        <li>the right to restrict processing in accordance with Art. 18 General Data Protection Regulation or</li>
        <li>the right to object to the processing of your personal data in accordance with Art. 21 General Data
            Protection Regulation or
        </li>
        <li>the right to transfer data in accordance with Art. 20 General Data Protection Regulation. i.e. the right to
            receive your data in a structured, common and machine-readable format and to transmit it to a third party.
        </li>
    </ul>
    <p>In addition, you have a right of appeal to the data protection supervisory authority (Art. 77 General Data
        Protection Regulation). These can be addressed to the data protection supervisory authority responsible for
        us:</p>
    <p>The Saxon Data Protection Officer<br/>Bernhard von Lindenau Platz 1<br/>01067 Dresden, Germany<br/>Phone:
        0351/493-5401<br/>E-Mail: <a href="mailto:saechsdsb@slt.sachsen.de">saechsdsb@slt.sachsen.de</a></p>
    <h2>Is there automated decision-making in individual cases (including profiling)?</h2>
    <p>We transmit your data (name, address and, if applicable, date of birth) to infoscore Consumer Data GmbH,
        Rheinstr. 99,76532 Baden-Baden, Germany, for the purpose of checking your creditworthiness, obtaining
        information for assessing the risk of non-payment on the basis of mathematical and statistical methods using
        address data and verifying your address (check for deliverability).</p>
    <p>Furthermore, we transmit your data (name, address and date of birth) for credit check and address verification to
        the associations Creditreform e.V., Hammfelddamm 13, 41460 Neuss, Creditreform Boniversum GmbH,
        Hammfelddamm 13, 41460 Neuss, Creditreform München Ganzmüller, Groher & Kollegen KG, Machtlfinger Str.
        13,81379 Munich and Creditreform Dresden Aumüller KG, Augsburger Str. 4, 01309 Dresden.</p>
    <p>The legal basis for these transfers is Art. 6 paragraph 1 Letter b of the General Data Protection Regulation.</p>
    <p>Information on particularly sensitive data in accordance with Art. 9 General Data Protection Regulation is not
        processed.</p>
    <h2>Which data is collected when you visit the website?</h2>
    <p>For technical reasons, exemplary the following data, which your Internet browser transmits to us or to our web
        space provider, is collected (so-called server logfiles):</p>
    <ul>
        <li>Browser type and version</li>
        <li>Operating system used</li>
        <li>Website from which you visit us (referrer URL)</li>
        <li>Website you visit</li>
        <li>Date and time of your access</li>
        <li>Your Internet Protocol (IP) address.</li>
    </ul>
    <p>This anonymous data are stored separately from any personal data you may have provided and thus do not allow any
        conclusions about a specific person. These data is stored for seven days. They are evaluated for statistical
        purposes in order to optimize our website and our offers.</p>
    <h2>Contact Form</h2>
    <p>If you submit us enquiries using the contact form, your details from the contact form, including the contact data
        you have provided there, will be transmitted to us in encrypted form.</p>
    <p>The data entered in the contact form is therefore processed exclusively on the basis of your consent (Art. 6
        para. 1 lit. a DSGVO). You can revoke this consent at any time. All you need to do is send us an informal
        e-mail. The legality of the data processing processes carried out up to the revocation remains unaffected by the
        revocation.</p>
    <p>The data entered by you in the contact form will remain with us until you request us to delete, your consent for
        storage revoked or the purpose for data storage no longer applies (e.g. after your request has been processed).
        Mandatory statutory provisions - in particular retention periods - remain unaffected.</p>
    <h2>Cookies</h2>
    <p>We use so-called cookies on our site to recognize multiple use of our offer by the same user/internet connection
        owner. Cookies are small text files that your Internet browser stores and archives on your computer. They serve
        to optimize our website and our offers. These are usually so-called "session cookies", which are deleted after
        the end of your visit.</p>
    <p>In some cases, however, these cookies provide information in order to recognize you automatically. This
        recognition is based on the IP address stored in the cookies. The information thus obtained is used to optimise
        our offers and to facilitate your access to our site.</p>
    <p>You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that
        if you do this you may not be able to use the full functionality of our website.</p>
    <h2>Use of Google Analytics with anonymization function</h2>
    <p>We use Google Analytics, a web analytics service provided by Google Inc., 1600 Amphitheatre Parkway, Mountain
        View, CA 94043 USA, hereinafter referred to as "Google" on our site. Google uses "cookies", which are text files
        placed on your computer, to help the website analyze how users use the site.</p>
    <p>The information generated by these cookies, such as the time, place and frequency of your website visit,
        including your IP address, is transmitted to and stored by Google in the United States.</p>
    <p>You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that
        if you do this you may not be able to use the full functionality of our website.</p>
    <p>We use Google Analytics on our website with the addition "_gat. _anonymizeIp". In this case, Google will already
        reduce your IP address within Member States of the European Union or in other signatory states to the European
        Economic Area Agreement, thereby making it anonymous.</p>
    <p>Google will use this information for the purpose of evaluating your use of our website, compiling reports on
        website activity for us and providing other services relating to website activity and internet usage. Google may
        also transfer this information to third parties if required by law or if third parties process this data on
        behalf of Google.</p>
    <p>Google will, according to its own information, under no circumstances associate your IP address with other Google
        data.</p>
    <p>In addition, Google offers a deactivation add-on for the most popular browsers, which gives you more control over
        what data Google collects about the websites you visit. The add-on tells Google Analytics' JavaScript (ga. js)
        that no information about the website visit should be submitted to Google Analytics. However, the Google
        Analytics browser disablement add-on does not prevent information from being transmitted to us or to other web
        analytics services we may use. For more information about installing the browser add-on, please follow the link
        below: <a
            href="https://tools.google.com/dlpage/gaoptout?hl=en">https://tools.google.com/dlpage/gaoptout?hl=en</a></p>
    <h2>Use of Google reCaptcha</h2>
    <p>To protect your enquiries via contact form, we use the reCAPTCHA service of Google Inc. (Google). The query is
        used to determine whether the input is made by a human being or misused by automated, automatic processing. The
        query includes sending the IP address and possibly other data required by Google for the service reCAPTCHA to
        Google. For this purpose, your submission will be sent to Google and used there.</p>
    <p>By using reCaptcha, you agree that the recognition you have made will be incorporated into the digitization of
        old works. However, if you opt-in to the IP anonymization on this website, your Google IP address will be
        truncated from time to time by Member States of the European Union or other signatory states to the EEA
        Agreement. Only in exceptional cases the full IP address is transferred to a Google server in the USA and
        shortened there.</p>
    <p>On behalf of the operator of this website, Google will use this information to evaluate your use of this service.
        The IP address transmitted by your browser within the scope of reCaptcha will not be merged with other Google
        data. These data are subject to Google's different data protection regulations.</p>
    <p>If you want to prevent reCAPTCHA, you can do this by either putting the string "<a
        href="https://www.google.com/recaptcha/api.js">https://www.google.com/recaptcha/api.js</a>" on the block list of
        the AdBlocker (which prevents reCAPTCHA scripts from being reloaded from the Google server), or disabling
        JavaScript altogether - of course by accepting the associated disadvantages.</p>
    <h2>Use of facebook components</h2>
    <p>We use components of the provider <a href="http://facebook.com/">facebook.com</a> on our site. Facebook is a
        service of facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA.</p>
    <p>Each time you access our website with such a component, this component will cause the browser you are using to
        download a corresponding representation of the facebook component. Through this process, facebook will be
        informed about which specific page of our website you are currently visiting.</p>
    <p>If you access our site and are logged in to facebook while you are on it, facebook will recognize which specific
        page you are visiting by the information collected by the component and will assign this information to your
        personal account on facebook. For example, if you click on the "Like" button or make comments, this information
        will be transferred to your personal user account on facebook and stored there. In addition, the information
        that you have visited our site will be passed on to facebook. This happens regardless of whether you click on
        the component or not.</p>
    <p>If you wish to prevent this transmission and storage of data about you and your behaviour on our website by
        facebook, you must log out of facebook before you visit our site. The data protection notices of facebook
        provide further information on this, in particular on the collection and use of data by facebook, on your rights
        in this regard and on the setting options for protecting your privacy: <a
            href="https://de-de.facebook.com/about/privacy/">https://de-de.facebook.com/about/privacy/</a></p>
    <p>In addition, external tools are available on the market that can be used to block Facebook social plug-ins with
        add-ons for all common browsers.</p>
    <p>An overview of the Facebook plugins can be found at <a
        href="https://developers.facebook.com/docs/plugins/">https://developers.facebook.com/docs/plugins/</a></p>
    <h2>Use of Twitter Recommendation Components</h2>
    <p>We use components from the provider Twitter on our site. Twitter is a service of Twitter Inc., 795 Folsom St.,
        Suite 600, San Francisco, CA 94107, USA.</p>
    <p>Each time you access our website with such a component, this component will cause the browser you are using to
        download a corresponding representation of the Twitter component. Through this process Twitter will be informed
        about which specific page of our website is currently being visited.</p>
    <p>We have no influence on the data that Twitter collects through this, nor on the extent of such data collected
        through Twitter. To the best of our knowledge, Twitter collects the URL of the website and IP address of the
        user, but does not use it for purposes other than the presentation of the Twitter component. Further information
        can be found in the privacy policy of Twitter at <a
            href="http://twitter.com/privacy">http://twitter.com/privacy</a>.</p>
    <p>You can change your privacy settings in your account settings at <a
        href="http://twitter.com/account/settings">http://twitter.com/account/settings</a></p>
    <h2>Use of unbounce</h2>
    <p>We use the unbounce service for promotions and advertising campaigns (landing pages) on certain pages.</p>
    <p>unbounce Marketing Solutions Inc.<br/>400-401 West Georgia Street<br/>Vancouver, BC<br/>Canada<br/>V6B 5A1</p>
    <p>These pages are hosted by unbounce and the user's browser communicates directly with unbounce, so that the user's
        IP address is transmitted and cookies can be set. All data entered by the user on these pages are also saved in
        unbounce. Secupay will then be provided with an evaluation of the activities. For more information about
        Unbounce and Unbounce's privacy policy, please visit <a
            href="http://unbounce.com/privacy/">http://unbounce.com/privacy/</a></p>
</div>
);

export let privacy_de = (
<div>
    <h1>Die Datenschutzerklärung der secupay AG (im folgenden "secupay")</h1>
    <p>Hiermit informieren wir Sie über die Verarbeitung Ihrer personenbezogenen Daten und die Ihnen nach den
        datenschutzrechtlichen Regelungen zustehenden Ansprüche und Rechte.</p>
    <h2>Wer ist für die Datenverarbeitung verantwortlich und an wen kann ich mich wenden?</h2>
    <p>secupay AG</p>
    <p>Vertreten durch den Vorstand<br/>Hans-Peter Weber, Katja Hartmann</p>
    <p>Goethestrasse 6<br/>01896 Pulsnitz</p>
    <p>Kontakt:</p>
    <p>Telefon +49 (0) 35955 7550-0<br/>Fax +49 (0) 35955 7550-99<br/>E-Mail-Adresse <a
        href="mailto:info@secupay.com">info@secupay.com</a></p>
    <p>Sie erreichen unseren Datenschutzbeauftragten unter:</p>
    <p>secupay<br/>Herr Axel Hirsch<br/>Goethestrasse 6<br/>01896 Pulsnitz<br/>E-Mail-Adresse <a
        href="mailto:privacy@secupay.com">privacy@secupay.com</a></p>
    <h2>Zu welchen Zwecken und auf welcher Rechtsgrundlage verarbeiten wir Ihre Daten?</h2>
    <p>Wir verarbeiten personenbezogene Daten, die wir im Rahmen einer Geschäftsanbahnung bzw. Geschäftsbeziehung von
        Ihnen erhalten zur Durchführung unserer Dienstleistungen, der Ausführung Ihrer Aufträge, sowie aller mit dem
        Betrieb und der Verwaltung eines Finanztransferdienstleisters erforderlichen Tätigkeiten. Die personenbezogenen
        Daten verarbeiten wir im Einklang mit den Bestimmungen der Europäischen Datenschutz-Grundverordnung (DSGVO) und
        dem Bundesdatenschutzgesetz (BDSG-neu).</p>
    <p>Soweit es für die Erbringung unserer Dienstleistungen erforderlich ist, verarbeiten wir von anderen Unternehmen
        oder von sonstigen Dritten (z. B. der infoscore GmbH) zulässigerweise erhaltene Daten. Zudem verarbeiten wir
        personenbezogene Daten, die wir aus öffentlich zugänglichen Quellen (z. B. Schuldnerverzeichnisse, Grundbücher,
        Handels- und Vereinsregister, Presse und andere Medien) gewonnen haben und verarbeiten dürfen.</p>
    <p>Relevante personenbezogene Daten können insbesondere sein:</p>
    <ul>
        <li>Personendaten (Name, Geburtsdatum, Geburtsort, Staatsangehörigkeit und vergleichbare Daten)</li>
        <li>Kontaktdaten (Adresse, E-Mail-Adresse, Telefonnummer und vergleichbare Daten)</li>
        <li>Legitimationsdaten (Ausweis-, Melde- und vergleichbare Daten)</li>
        <li>Girokonten und Kreditkartendaten</li>
        <li>Informationen über Ihre finanzielle Situation (Bonitätsdaten inklusive Scoring, also Daten zur Beurteilung
            des Kreditrisikos)
        </li>
        <li>Daten über die Nutzung von uns angebotenen Telemedien (z.B. Zeitpunkt des Aufrufs unserer Webseiten, Apps
            oder Newsletter, angeklickte Seiten von uns bzw. Einträge und vergleichbare Daten)
        </li>
    </ul>
    <h2>Wer bekommt außerdem noch Ihre Daten (Empfänger)?</h2>
    <p>Falls es für die Erbringung unserer Dienstleistungen notwendig ist, erfolgt eine Weitergabe Ihrer Daten an
        nachfolgende Unternehmen:</p>
    <ul>
        <li>Creditreform Boniversum GmbH</li>
        <li>Concardis GmbH</li>
        <li>infoscore Consumer Data GmbH, Baden-Baden</li>
        <li>SIX Group AG</li>
        <li>Transact elektronische Zahlungssysteme GmbH</li>
        <li>Bluro GbR ("Serverspot")</li>
        <li>Wirtschaftsprüfer</li>
        <li>Auftragsverarbeiter</li>
    </ul>
    <p>Innerhalb der secupay AG erhalten diejenigen Stellen Ihre Daten, die diese zur Erfüllung unserer vertraglichen
        und gesetzlichen Pflichten brauchen.</p>
    <p>Soweit wir Dienstleister beauftragen, unterliegen Ihre Daten dort den gleichen Sicherheitsstandards wie bei
        uns.</p>
    <p>Für den Fall, dass die Kaufpreisforderung an die secupay AG abgetreten wurde, kann zum Zwecke der Durchsetzung
        der Kaufpreisforderung eine Weitergabe der Daten an nachfolgende Unternehmen erfolgen:</p>
    <ul>
        <li>Creditreform Dresden Aumüller KG</li>
        <li>Creditreform München Ganzmüller, Groher & Kollegen KG</li>
    </ul>
    <h2>Werden Daten an ein Drittland oder an eine internationale Organisation übermittelt?</h2>
    <p>Im Zusammenhang mit der Erbringung unserer Dienstleistung erfolgt eine Datenübermittlung an Unternehmen in den
        Vereinigten Staaten von Amerika auf der Grundlage von EU-Standardvertragsklauseln (eine Einsichtnahme in dieses
        Dokument ist möglich) bzw. im Rahmen des EU – US Privacy Shield.</p>
    <h2>Wie lange werden meine Daten gespeichert?</h2>
    <p>Soweit erforderlich, verarbeiten und speichern wir Ihre personenbezogenen Daten für die Dauer unserer
        Geschäftsbeziehung, was auch die Anbahnung und die Abwicklung eines Vertrages umfasst.</p>
    <p>Darüber hinaus unterliegen wir verschiedenen Aufbewahrungs- und Dokumentationspflichten, die sich unter anderem
        aus dem Handelsgesetzbuch (HGB), der Abgabenordnung (AO), dem Kreditwesengesetz (KWG) und dem Geldwäschegesetz
        (GwG) ergeben. Die dort vorgegebenen Fristen zur Aufbewahrung bzw. Dokumentation betragen zwei bis zehn
        Jahre.</p>
    <h2>Welche Datenschutzrechte habe ich?</h2>
    <p>Sie haben uns gegenüber ein</p>
    <ul>
        <li>Recht auf Auskunft über Ihre personenbezogenen Daten nach Art. 15 DSGVO, sowie unter bestimmten
            Voraussetzungen
        </li>
        <li>ein Berichtigungsrecht nach Art. 16 DSGVO, wenn ihre personenbezogenen Daten fehlerhaft sein sollten oder
        </li>
        <li>ein Recht auf Löschung nach Art. 17 DSGVO, wenn der Löschung u.a. keine gesetzlichen Aufbewahrungspflichten
            entgegenstehen oder
        </li>
        <li>ein Recht auf Einschränkung der Verarbeitung nach Art. 18 DSGVO oder</li>
        <li>ein Recht auf Widerspruch der Verarbeitung Ihrer personenbezogenen Daten nach Art. 21 DSGVO und</li>
        <li>das Recht auf Datenübertragbarkeit gemäß Art. 20 DSGVO, also das Recht Ihre Daten in einem strukturierten,
            gängigen und maschinenlesbaren Format zu erhalten und sie einem Dritten zu übermitteln.
        </li>
    </ul>
    <p>Darüber hinaus steht Ihnen ein Beschwerderecht bei der Datenschutzaufsichtsbehörde zu (Art. 77 DSGVO). Diese
        können Sie an die für uns zuständige Datenschutzaufsichtsbehörde richten:</p>
    <p>Der Sächsische Datenschutzbeauftragte<br/>Bernhard-von-Lindenau-Platz 1<br/>01067 Dresden</p>
    <p>Telefon: 0351/493-5401<br/>E-Mail: <a href="mailto:saechsdsb@slt.sachsen.de">saechsdsb@slt.sachsen.de</a></p>
    <h2>Gibt es eine automatisierte Entscheidungsfindung im Einzelfall (einschließlich Profiling)?</h2>
    <p>Wir übermitteln Ihre Daten (Name, Adresse und ggf. Geburtsdatum) zum Zweck der Bonitätsprüfung, dem Bezug von
        Informationen zur Beurteilung des Zahlungsausfallrisikos auf Basis mathematisch-statistischer Verfahren unter
        Verwendung von Anschriftendaten sowie zur Verifizierung Ihrer Adresse (Prüfung auf Zustellbarkeit) an die
        infoscore Consumer Data GmbH, Rheinstr. 99, 76532 Baden-Baden.</p>
    <p>Des Weiteren übermitteln wir Ihre Daten (Name, Adresse und Geburtsdatum) zur Bonitätsprüfung und
        Adressverifikation an die im Verband der Vereine Creditreform e.V., Hammfelddamm 13, 41460 Neuss
        zusammengefassten Unternehmen Creditreform Boniversum GmbH, Hammfelddamm 13, 41460 Neuss, Creditreform
        München Ganzmüller, Groher & Kollegen KG, Machtlfinger Str. 13, 81379 München und Creditreform Dresden Aumüller
        KG, Augsburger Str. 4, 01309 Dresden.</p>
    <p>Rechtsgrundlagen dieser Übermittlungen sind der Art. 6 Absatz 1 Buchstabe b der DSGVO.</p>
    <p>Nicht verarbeitet werden Angaben zu besonders sensiblen Daten nach Art. 9 DSGVO.</p>
    <h2>Welche Daten werden beim Besuch der Webseite erhoben?</h2>
    <p>Aus technischen Gründen werden u.a. folgende Daten, die Ihr Internet-Browser an uns bzw. an unseren
        Webspace-Provider übermittelt, erfasst (sogenannte Server-Logfiles):</p>
    <ul>
        <li>Browsertyp und -version</li>
        <li>verwendetes Betriebssystem</li>
        <li>Webseite, von der aus Sie uns besuchen (Referrer URL)</li>
        <li>Webseite, die Sie besuchen</li>
        <li>Datum und Uhrzeit Ihres Zugriffs</li>
        <li>Ihre Internet Protokoll (IP)-Adresse.</li>
    </ul>
    <p>Diese anonymen Daten werden getrennt von Ihren eventuell angegebenen personenbezogenen Daten für 7 Tage
        gespeichert und lassen so keine Rückschlüsse auf eine bestimmte Person zu. Sie werden zu statistischen Zwecken
        ausgewertet, um unseren Internetauftritt und unsere Angebote optimieren zu können.</p>
    <h2>Kontaktformular</h2>
    <p>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive
        der von Ihnen dort angegebenen Kontaktdaten verschlüsselt an uns übertragen.</p>
    <p>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt somit ausschließlich auf Grundlage Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen. Dazu reicht eine
        formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
        Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.</p>
    <p>Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
        Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach
        abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere
        Aufbewahrungsfristen – bleiben unberührt.</p>
    <h2>Cookies</h2>
    <p>Wir verwenden auf unserer Seite sog. Cookies zum Wiedererkennen mehrfacher Nutzung unseres Angebots, durch
        denselben Nutzer/Internetanschlussinhaber. Cookies sind kleine Textdateien, die Ihr Internet-Browser auf Ihrem
        Rechner ablegt und speichert. Sie dienen dazu, unseren Internetauftritt und unsere Angebote zu optimieren. Es
        handelt sich dabei zumeist um sog. "Session-Cookies", die nach dem Ende Ihres Besuches wieder gelöscht
        werden.</p>
    <p>Teilweise geben diese Cookies jedoch Informationen ab, um Sie automatisch wieder zu erkennen. Diese
        Wiedererkennung erfolgt aufgrund der in den Cookies gespeicherten IP-Adresse. Die so erlangten Informationen
        dienen dazu, unsere Angebote zu optimieren und Ihnen einen leichteren Zugang auf unsere Seite zu
        ermöglichen.</p>
    <p>Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihres Browsers verhindern; wir
        weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen unserer Website
        vollumfänglich nutzen können.</p>
    <h2>Einsatz von Google-Analytics mit Anonymisierungsfunktion</h2>
    <p>Wir setzen auf unserer Seite Google-Analytics, einen Webanalysedienst der Firma Google Inc., 1600 Amphitheatre
        Parkway, Mountain View, CA 94043 USA, nachfolgend „Google“ genannt ein. Google verwendet sog. „Cookies“,
        Textdateien, die auf Ihrem Computer gespeichert werden und hierdurch eine Analyse der Benutzung der Website
        durch Sie ermöglichen.</p>
    <p>Die durch diese Cookies erzeugten Informationen, beispielsweise Zeit, Ort und Häufigkeit Ihres Webseiten-Besuchs
        einschließlich Ihrer IP-Adresse, werden an Google in den USA übertragen und dort gespeichert.</p>
    <p>Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern;
        wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen unserer
        Website vollumfänglich nutzen können.</p>
    <p>Wir verwenden auf unserer Website Google-Analytics mit dem Zusatz "_gat._anonymizeIp". Ihre IP-Adresse wird in
        diesem Fall von Google schon innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
        Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt und dadurch anonymisiert.</p>
    <p>Google wird diese Informationen benutzen, um Ihre Nutzung unserer Seite auszuwerten, um Reports über die
        Websiteaktivitäten für uns zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung
        verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte
        übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google
        verarbeiten.</p>
    <p>Google wird, nach eigenen Angaben, in keinem Fall Ihre IP-Adresse mit anderen Daten von Google in Verbindung
        bringen.</p>
    <p>Des Weiteren bietet Google für die gängigsten Browser ein Deaktivierungs-Add-on an, welches Ihnen mehr Kontrolle
        darüber gibt, welche Daten von Google zu der von Ihnen aufgerufenen Websites erfasst werden. Das Add-on teilt
        dem JavaScript (ga.js) von Google Analytics mit, dass keine Informationen zum Website-Besuch an Google Analytics
        übermittelt werden sollen. Das Deaktivierungs-Add-on für Browser von Google Analytics verhindert aber nicht,
        dass Informationen an uns oder an andere von uns gegebenenfalls eingesetzte Webanalyse-Services übermittelt
        werden. Weitere Informationen zur Installation des Browser Add-on erhalten Sie über nachfolgenden Link: <a
            href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a></p>
    <h2>Einsatz von Google reCaptcha</h2>
    <p>Zum Schutz ihrer Anfragen per Internetformular verwenden wir den Dienst reCAPTCHA des Unternehmens Google Inc.
        (Google). Die Abfrage dient der Unterscheidung, ob die Eingabe durch einen Menschen oder missbräuchlich durch
        automatisierte, maschinelle Verarbeitung erfolgt. Die Abfrage schließt den Versand der IP-Adresse und ggf.
        weiterer von Google für den Dienst reCAPTCHA benötigter Daten an Google ein. Zu diesem Zweck wird Ihre Eingabe
        an Google übermittelt und dort weiter verwendet.</p>
    <p>Durch die Nutzung von reCaptcha erklären Sie sich damit einverstanden, dass die von Ihnen geleistete Erkennung in
        die Digitalisierung alter Werke einfließt. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite
        wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
        Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird
        die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.</p>
    <p>Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung dieses
        Dienstes auszuwerten. Die im Rahmen von reCaptcha von Ihrem Browser übermittelte IP-Adresse wird nicht mit
        anderen Daten von Google zusammengeführt. Für diese Daten gelten die abweichenden Datenschutzbestimmungen des
        Unternehmens Google.</p>
    <p>Will man reCAPTCHA verhindern, so kann man das bewerkstelligen, indem man entweder die Zeichenkette (string) "<a
        href="https://www.google.com/recaptcha/api.js">https://www.google.com/recaptcha/api.js</a>" auf die Sperrliste
        des AdBlockers setzt (was das nachladen der reCAPTCHA-Scripte vom Google-Server unterbindet), oder aber
        JavaScript gleich ganz abschaltet — selbstverständlich unter Inkaufnahme der damit verbundenen Nachteile.</p>
    <h2>Einsatz von facebook-Komponenten</h2>
    <p>Wir setzen auf unserer Seite Komponenten des Anbieters <a href="http://facebook.com/">facebook.com</a> ein.
        Facebook ist ein Service der facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA.</p>
    <p>Bei jedem einzelnen Abruf unserer Webseite, die mit einer solchen Komponente ausgestattet ist, veranlasst diese
        Komponente, dass der von Ihnen verwendete Browser eine entsprechende Darstellung der Komponente von facebook
        herunterlädt. Durch diesen Vorgang wird facebook darüber in Kenntnis gesetzt, welche konkrete Seite unserer
        Internetpräsenz gerade durch Sie besucht wird.</p>
    <p>Wenn Sie unsere Seite aufrufen und währenddessen bei facebook eingeloggt sind, erkennt facebook durch die von der
        Komponente gesammelte Information, welche konkrete Seite Sie besuchen und ordnet diese Informationen Ihrem
        persönlichen Account auf facebook zu. Klicken Sie z.B. den „Gefällt mir“-Button an oder geben Sie entsprechende
        Kommentare ab, werden diese Informationen an Ihr persönliches Benutzerkonto auf facebook übermittelt und dort
        gespeichert. Darüber hinaus wird die Information, dass Sie unsere Seite besucht haben, an facebook weiter
        gegeben. Dies geschieht unabhängig davon, ob Sie die Komponente anklicken oder nicht.</p>
    <p>Wenn Sie diese Übermittlung und Speicherung von Daten über Sie und Ihr Verhalten auf unserer Webseite durch
        facebook unterbinden wollen, müssen Sie sich bei facebook ausloggen und zwar bevor Sie unsere Seite besuchen.
        Die Datenschutzhinweise von facebook geben hierzu nähere Informationen, insbesondere zur Erhebung und Nutzung
        der Daten durch facebook, zu Ihren diesbezüglichen Rechten sowie zu den Einstellungsmöglichkeiten zum Schutz
        Ihrer Privatsphäre: <a
            href="https://de-de.facebook.com/about/privacy/">https://de-de.facebook.com/about/privacy/</a></p>
    <p>Zudem sind am Markt externe Tools erhältlich, mit denen Facebook-Social-Plugins mit Add-ons für alle gängigen
        Browser blockiert werden können.</p>
    <p>Eine Übersicht über die Facebook-Plugins finden Sie unter <a
        href="https://developers.facebook.com/docs/plugins">https://developers.facebook.com/docs/plugins/</a></p>
    <h2>Einsatz der Twitter-Empfehlungs-Komponenten</h2>
    <p>Wir setzen auf unserer Seite Komponenten des Anbieters Twitter ein. Twitter ist ein Service der Twitter Inc., 795
        Folsom St., Suite 600, San Francisco, CA 94107, USA.</p>
    <p>Bei jedem einzelnen Abruf unserer Webseite, die mit einer solchen Komponente ausgestattet ist, veranlasst diese
        Komponente, dass der von Ihnen verwendete Browser eine entsprechende Darstellung der Komponente von Twitter
        herunterlädt. Durch diesen Vorgang wird Twitter darüber in Kenntnis gesetzt, welche konkrete Seite unserer
        Internetpräsenz gerade besucht wird.</p>
    <p>Wir haben weder Einfluss auf die Daten, die Twitter hierdurch erhebt, noch über den Umfang dieser durch Twitter
        erhobenen Daten. Nach unserem Kenntnisstand wird von Twitter die URL der jeweiligen aufgerufenen Webseite sowie
        IP-Adresse des Nutzers erhoben, jedoch nicht für andere Zwecke als die Darstellung der Twitter-Komponente
        genutzt. Weitere Informationen hierzu finden sich in der Datenschutzerklärung von Twitter unter <a
            href="http://twitter.com/privacy">http://twitter.com/privacy</a>.</p>
    <p>Ihre Datenschutzeinstellungen können Sie in den Konto-Einstellungen unter <a
        href="http://twitter.com/account/settings">http://twitter.com/account/settings</a> ändern.</p>
    <h2>Einsatz von unbounce</h2>
    <p>Wir verwenden für Aktionen und Werbekampagnen (Landingpages) auf bestimmten Seiten den Dienst unbounce.</p>
    <p>unbounce Marketing Solutions Inc.<br/>400-401 West Georgia Street<br/>Vancouver, BC<br/>Canada<br/>V6B 5A1</p>
    <p>Diese Seiten werden von unbounce gehostet und der Browser des Nutzers kommuniziert dabei direkt mit unbounce, so
        dass die IP-Adresse des Benutzers übermittelt wird und Cookies gesetzt werden können. Auch alle Angaben die vom
        Benutzer auf diesen Seiten eingegeben werden, werden bei unbounce gespeichert. Secupay wird anschließend eine
        Auswertung der Aktivitäten zur Verfügung gestellt. Weitere Informationen zu Unbounce und dem Datenschutz bei
        Unbounce finden Sie hier: <a href="http://unbounce.com/privacy/">http://unbounce.com/privacy/</a>.</p>
</div>
);
