import {Component, h} from 'preact';
import {AppStage, CommonProps, ErrorMessageKey, PaymentMethod, PaymentWindow} from '../types';
import Spinner from '../components/Spinner';

export default class ChangePaymentMethodButton
    extends Component<CommonProps, any> {

    public constructor(props: CommonProps) {
        super(props);

        this.changePaymentMethodSwitcher = this.changePaymentMethodSwitcher.bind(this);
        this.setButtonTitle = this.setButtonTitle.bind(this);
    }

    public render() {
        return (
            <div className="col-xs-12">
                <button
                    onClick={this.changePaymentMethodSwitcher}
                    class="button-as-link"
                    tabIndex={0}
                    data-action="change-payment-method"
                    disabled={this.props.isLoading}
                >
                    {this.setButtonTitle()}
                </button>
            </div>
        );
    }

    private setButtonTitle(): string {
        let title = this.props.getStringTranslationByKey('change_method_button_text');
        let paymentMethods = this.props.getAvailablePaymentMethods();
        let pmLength = paymentMethods.length;
        if (pmLength > 1) {
            if (pmLength === 2 &&
                (paymentMethods.indexOf(PaymentMethod.PAY_IN_ADVANCE) !== -1) &&
                (paymentMethods.indexOf(PaymentMethod.INVOICE) !== -1)) {
                if (this.props.paymentMethod === PaymentMethod.INVOICE) {
                    title = this.props.getStringTranslationByKey('change_payment_method_button_to_in_advance');
                } else {
                    title = this.props.getStringTranslationByKey('change_payment_method_button_to_invoice');
                }
            } else {
                title = this.props.getStringTranslationByKey('change_payment_method_button_details');
            }
        }
        if (pmLength === 1) {
            if (paymentMethods[0] === PaymentMethod.DIRECT_DEBIT) {
                title = this.props.getStringTranslationByKey('change_payment_method_button_bank_account');
            } else if (paymentMethods[0] === PaymentMethod.CREDIT_CARD) {
                title = this.props.getStringTranslationByKey('change_payment_method_button_credit_card');
            }
        }
        return title.toUpperCase();
    }

    private changePaymentMethodSwitcher(): void {
        this.props.setLoading(true, false);
        let paymentMethods = this.props.getAvailablePaymentMethods();
        // When only invoice and Payment in advance is available,
        // then we switch these two methods directly
        // with skipping PM selection page
        if (paymentMethods.length === 2 &&
            (paymentMethods.indexOf(PaymentMethod.PAY_IN_ADVANCE) !== -1) &&
            (paymentMethods.indexOf(PaymentMethod.INVOICE) !== -1)) {
            if (this.props.paymentMethod === PaymentMethod.INVOICE) {
                this.setPayInAdvancePayment();
            } else {
                this.setInvoicePayment();
            }
        } else if (this.props.stageData.direct.indexOf(AppStage.STAGE_PAYMENT_SELECTION) !== -1) {
            this.props.setStage(AppStage.STAGE_PAYMENT_SELECTION);
        } else if (this.props.stageData.direct.indexOf(AppStage.STAGE_PAYMENT_INPUT) !== -1) {
            switch (this.props.paymentMethod) {
                case PaymentMethod.DIRECT_DEBIT:
                    this.props.setStage(AppStage.STAGE_PAYMENT_INPUT, PaymentWindow.WINDOW_DIRECT_DEBIT);
                    break;
                case PaymentMethod.CREDIT_CARD:
                    this.props.setStage(AppStage.STAGE_PAYMENT_INPUT, PaymentWindow.WINDOW_CREDIT_CARD);
                    break;
                default:
                    this.props.setStage(AppStage.STAGE_ERROR, ErrorMessageKey.NO_PAYMENT_METHOD_REMAINING);
            }
        } else {
            this.props.setStage(AppStage.STAGE_ERROR, ErrorMessageKey.NO_PAYMENT_METHOD_REMAINING);
        }
    }

    private setPayInAdvancePayment() {
        this.props.callBackend(
            '/payment-method',
            () => {
                this.props.setPaymentMethod(PaymentMethod.PAY_IN_ADVANCE);
                this.props.setLoading(false);
            },
            'POST',
            {type: PaymentMethod.PAY_IN_ADVANCE}
        );
    }

    // Set Invoice payment,
    // with bad response handling (automatically switching to Pay in advance)
    private setInvoicePayment() {
        this.props.callBackend(
            '/payment-method',
            () => {
                this.props.callBackend(
                    '/prepare',
                    () => {
                        this.props.setPaymentMethod(PaymentMethod.INVOICE);
                        this.props.setLoading(false);
                    },
                    'POST',
                    {},
                    () => {
                        this.props.setAvailablePaymentMethods([PaymentMethod.PAY_IN_ADVANCE]);
                        this.props.setPaymentMethod(PaymentMethod.PAY_IN_ADVANCE);
                        this.setPayInAdvancePayment();
                        this.props.showMessageBox(true, 'payment_method_one_available_scoring_failed', true);
                    }
                );
            },
            'POST',
            {type: PaymentMethod.INVOICE}
        );
    }
}