import {h, Component} from 'preact';
import {Bank, CommonProps, Intent, ResponseHttp} from '../../common/types';
import AmountHelper from '../../common/helpers/AmountHelper';

interface PayInAdvanceInfoState {
    bank: Bank;
}

interface PayInAdvanceInfoProps extends CommonProps {
    passBankDetails?: (details: Bank) => void;
    showInfoText?: boolean;
    hideRows?: string[];
}

export default class PayInAdvanceInfo extends Component<PayInAdvanceInfoProps, PayInAdvanceInfoState> {
    constructor() {

        super();

        this.state = {
            bank: {
                iban: '',
                bankName: '',
                bic: '',
                account_owner: '',
                reference: ''
            }
        };

        this.getBankDetails = this.getBankDetails.bind(this);
    }

    componentDidMount() {
        this.getBankDetails();
    }

    public render(): JSX.Element {
        return (
            <div>
                {this.props.showInfoText ?
                    <p>
                        this.props.sessionData && this.props.sessionData.transaction.intent === Intent.SALE ?
                        this.props.getStringTranslationByKey('success_pay_in_advance_assign') :
                        this.props.getStringTranslationByKey('success_pay_in_advance_assign_intent_sale')
                    </p>
                    : null}
                <table className="table table-payment-info">
                    <tr>
                        <td>{this.props.getStringTranslationByKey('account_holder')} </td>
                        <td>{this.state.bank.account_owner}</td>
                    </tr>
                    <tr>
                        <td>IBAN</td>
                        <td>{this.state.bank.iban}</td>
                    </tr>
                    <tr>
                        <td>BIC</td>
                        <td> {this.state.bank.bic}</td>
                    </tr>
                    <tr>
                        <td>{this.props.getStringTranslationByKey('bank_name')}</td>
                        <td>{this.state.bank.bankName}</td>
                    </tr>
                    <tr>
                        <td>{this.props.getStringTranslationByKey('reference')}</td>
                        <td>{this.state.bank.reference} </td>
                    </tr>
                     {/*if needed, please add this condition to another rows*/}
                    {this.props.hideRows && this.props.hideRows.indexOf('amount') !== -1 ? null :
                        <tr>
                            <td>{this.props.getStringTranslationByKey('transfer_amount')}</td>
                            <td> {this.props.sessionData ? AmountHelper.getFullAmountWithCurrency(
                                this.props.sessionData.transaction.basket_info.sum, this.props.sessionData.transaction.basket_info.currency, this.props.currentLang
                            ) : null}</td>
                        </tr>
                    }
                </table>
            </div>);
    }

    private getBankDetails(): void {
        this.props.callBackend(
            '/bank-details',
            (response: ResponseHttp) => {
                const bankDetails = JSON.parse(response.text);
                const neededData = {
                    iban: bankDetails.acc.iban,
                    bankName: bankDetails.acc.bankname,
                    bic: bankDetails.acc.bic,
                    account_owner: bankDetails.acc.owner,
                    reference: 'TA\u00A0' + bankDetails.taId
                };
                this.setState({
                    bank: neededData
                });
                if (this.props.passBankDetails) {
                    this.props.passBankDetails(neededData);
                }
            }
        );
    }
}