import { h } from 'preact';
import InputContainer from '../common/input/InputContainer';
import HTMLInputElementDecorator from '../common/input/HTMLInputElementDecorator';
import { isUndefined } from 'util';
import FilterInstances from '../common/input/FilterInstances';
import { InputAttributes } from '../common/types';

interface InputTemplateProps {
    inputAttributes: InputAttributes;
    container: InputContainer;
    key?: number;
    colorMandatory: string;
}

export function InputTemplate(props: InputTemplateProps) {
    const inputProps = {

        className: 'col-xs-12 input-padding input input-valid input-text-theme ',
        name: props.inputAttributes.name,
        type: isUndefined(props.inputAttributes.type) ? 'text' : props.inputAttributes.type,
        required: true,
        autocomplete: isUndefined(props.inputAttributes.autocomplete) ? 'off' : props.inputAttributes.autocomplete,
        onFocus: props.container.getInputFocusHandlerCallback(),
        id: isUndefined(props.inputAttributes.list) ? props.inputAttributes.id : props.inputAttributes.list,
        list: props.inputAttributes.list,
        ref(ref: HTMLInputElement) {
            if (ref) {
                props.container.setDecorator(
                    props.inputAttributes.name,
                    new HTMLInputElementDecorator(ref as HTMLInputElement)
                );

                let backgroundColor =
                    props.container.getDecorators()[props.inputAttributes.name].getField().style.backgroundColor;
                let field;

                if (props.inputAttributes.required !== false && backgroundColor === '') {
                    field = props.container.getDecorators()[props.inputAttributes.name].getField();
                    field.classList.add('input-mandatory');

                    if(props.colorMandatory) {
                        field.setAttribute('style', 'border-left-color: ' + props.colorMandatory + ' !important');
                    }

                    if (props.inputAttributes.validationInstance === undefined) {
                        props.container.getDecorators()[props.inputAttributes.name]
                            .setValidationInstance({
                                validatingFunction: function (inputDecorator: HTMLInputElementDecorator) {
                                    let field = inputDecorator.getField();
                                    switch(field.tagName) {
                                        case 'SELECT':
                                            return {valid: ((field as HTMLElement) as HTMLSelectElement).selectedIndex !== 0};
                                        case 'INPUT':
                                        default:
                                            return {valid: field.value !== ''};
                                    }
                                }
                            });
                    }
                }
                if (props.inputAttributes.validationInstance !== undefined) {
                    props.container.getDecorators()[props.inputAttributes.name]
                        .setValidationInstance(props.inputAttributes.validationInstance);
                }
                if (props.inputAttributes.onSubmitFilter !== undefined) {
                    props.container.getDecorators()[props.inputAttributes.name]
                        .setFilterInstance(props.inputAttributes.onSubmitFilter);
                }
            }
        },
    };
    if (!isUndefined(props.inputAttributes.optionsList)) {
        const isCountryList: boolean = inputProps.id === 'countries';
        return (
            <div class={props.inputAttributes.containerClassName}>
                <div class="custom-dropdown">
                    <select {...inputProps}>
                        {[<option
                              key="0"
                              value="0"
                              disabled={isCountryList}
                              selected={!isCountryList}>
                            {props.inputAttributes.placeholder}
                          </option>].concat(
                            props.inputAttributes.optionsList.map((val, idx) => (
                                <option
                                    key={(idx + 1)}
                                    value={(idx + 1).toString()}>
                                    {val}
                                </option>
                            )
                            ))}
                    </select>
                    <span className={'display-none floating-label' + ' ' + props.inputAttributes.name + '_span'}>
                {props.inputAttributes.placeholder}
            </span>
                    <div class="error-container">
                                <span class="error-message">
          {props.container.getWarningMsg(props.inputAttributes.name)}
        </span>
                    </div>
                </div>
            </div>
        );
    }

    function handleOnBlurPaste(): void {
        if (props.inputAttributes.onBlurPaste) {
            props.inputAttributes.onBlurPaste.filteringFunction(
                props.container.getDecorators()[props.inputAttributes.name]
            );
        } else {
            FilterInstances.removeIntermediateWhitespacesAndTrimFilterInstance().filteringFunction(
                props.container.getDecorators()[props.inputAttributes.name]
            );
        }
    }

    return (
        <div class={props.inputAttributes.containerClassName}>
            <input {...inputProps} {...props.inputAttributes.customProps}
                // setTimeout because onPaste event fires before the input's value is changed
                   onPaste={() => setTimeout(
                       isUndefined(props.inputAttributes.onPaste)
                           ? () => handleOnBlurPaste()
                           : props.inputAttributes.onPaste,
                       0)}
                   onBlur={() => handleOnBlurPaste()}
                   onInput={isUndefined(props.inputAttributes.onInput)
                       ? () => {return; }
                       : props.inputAttributes.onInput}
                   onAnimationStart={isUndefined(props.inputAttributes.onAnimationStart) ? () => {return} : props.inputAttributes.onAnimationStart}
                   onAnimationEnd={isUndefined(props.inputAttributes.onAnimationEnd) ? () => {return} : props.inputAttributes.onAnimationEnd}
            />
            <span class={'floating-label' + ' ' + props.inputAttributes.name + '_span'}>
                {props.inputAttributes.placeholder}
            </span>
            <div class="error-container">
        <span class="error-message">
          {props.container.getWarningMsg(props.inputAttributes.name)}
        </span>
            </div>
        </div>
    );
}
