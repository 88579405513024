import { h, Component } from 'preact';
import InputContainer from '../../../common/input/InputContainer';
import { InputTemplate } from '../../../templates/InputTemplate';
import { apiPath } from '../../../index';
import ValidationInstances from '../../../common/input/ValidationInstances';
import FilterInstances from '../../../common/input/FilterInstances';
import {
    AppStage,
    CommonProps, InputAttributes,
    RequestOptions,
    ResponseHttp
} from '../../../common/types';
import HTMLInputElementDecorator from '../../../common/input/HTMLInputElementDecorator';
import { SecucardWindow } from './SecucardMainComponent';
import {getPrimaryColor} from "../../../common/utils";

const http = require('iso-http');

interface SecucardPaymentComponentProps extends CommonProps {
  getChosenCards: () => boolean[];
  toggleCardStatus: (idx: number) => void;
  setSecucardStage: (stage: SecucardWindow) => void;
}

export interface SecucardPaymentComponentState {
}

const cardHintSrc = require('../../../../img/secucard-en.png');

export default class SecucardPaymentComponent
  extends Component<SecucardPaymentComponentProps, SecucardPaymentComponentState> {
  private inputContainer: InputContainer;
  private inputParams: InputAttributes[];

  public constructor(props: SecucardPaymentComponentProps) {
    super(props);

    this.inputContainer = new InputContainer(this);

    this.inputParams = [
      {
        containerClassName: 'col-sm-6',
        name: 'secucard_number',
        placeholder: props.getStringTranslationByKey('secucard_number'),
        validationInstance: ValidationInstances.getNumbersOnlyValidationInstance(),
        onSubmitFilter: FilterInstances.trimmingFilterInstance()
      },
      {
        containerClassName: 'col-sm-6',
        name: 'csc',
        placeholder: 'CSC',
        validationInstance: ValidationInstances.getPinValidationInstance()
      },
    ];
    this.showSecuCards = this.showSecuCards.bind(this);
    this.goToNextStage = this.goToNextStage.bind(this);
  }

  public showSecuCards(): JSX.Element {
    return (
      <div>

        <hr/>

        <div className="row col-sm-12">
          <h2 class="headline-theme">
            {this.props.getStringTranslationByKey('your_secucards_header')}
            </h2>

        </div>
      </div>);
  }

  public render() {

    /* tslint:disable:max-line-length */
    return (
      <div class="content-text-theme">
      <section style="min-height:625px;" class="scrollbar">

        <div class="container">
          <div className="row col-sm-12"><h2 class="headline-theme">{this.props.getStringTranslationByKey('secucard_header')}</h2>
              {this.inputParams.map(
                  (val: InputAttributes, index) => {
                      return (
                          <InputTemplate
                              key={index}
                              inputAttributes={val}
                              container={this.inputContainer}
                              colorMandatory={getPrimaryColor(this.props)}
                          />
                      );
                  })}
          </div>
        </div>

        <div class="container">
          <div className="mv-30 col-sm-12">{this.props.getStringTranslationByKey('secucard_number_hint1')} <br/> {this.props.getStringTranslationByKey('secucard_number_hint2')} </div>

          <div class="col-sm-12"><img src={cardHintSrc} style="max-width: 100%"/> </div>

          <br/><br/><br/><br/><br/><br/>

          <button
            class="btn col-xs-12 mv-30 no-border change-button"
            onClick={() => this.props.setStage(AppStage.STAGE_PAYMENT_SELECTION)}
          >{this.props.getStringTranslationByKey('enter_another_method')}</button>

          <button onClick={this.goToNextStage} class="btn col-xs-12 mv-30 no-border next-button">{this.props.getStringTranslationByKey('next_step_button')}</button>

        </div>
      </section>
      </div>

    );
    /* tslint:enable:max-line-length */
  }

  private constructSecucardRequestBody(): {[key:  string]: string|object} {
    const inputs: {[key:  string]: HTMLInputElementDecorator} = this.inputContainer.getDecorators();
    let secucardkey = 'secucard_number';
    let csckey = 'csc';

    return {
      'secucardnumber':  inputs[secucardkey].getField().value,
      'csc': inputs[csckey].getField().value,
    };
  }

  private goToNextStage(): void {

    this.inputContainer.filterAllInputs();
    if (!this.inputContainer.validateInputs()) {
      if (this.props.getChosenCards().findIndex((val) => val) === -1) {
        return;
      } else {
        this.props.setSecucardStage(SecucardWindow.WINDOW_BALANCE);
        return;
      }
    }

    let requestOptions: RequestOptions = {
      url:  apiPath + '/secucard',
      method: 'POST',
      headers: {'Content-Type':  'application/json'},
      withCredentials: true,
      data: this.constructSecucardRequestBody()
    };

    http.request(
      requestOptions,
      (response: ResponseHttp) => {
        if  (response.status === 200) {
          this.props.setSecucardStage(SecucardWindow.WINDOW_BALANCE);
        } else if (response.status === 201) {
          this.props.getChosenCards().push(false);
          this.props.setSecucardStage(SecucardWindow.WINDOW_BALANCE);
        } else if (response.status === 400) {
          let secucardkey = 'secucard_number';
          this.inputContainer.getDecorators()[secucardkey].getField().value = 'unknown secucard number';
        }
      }
    );
  }
}