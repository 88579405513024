import { h } from 'preact';
import { CommonProps } from '../../common/types';
import {isNull} from 'util';

export default function PayPalTemplate(props: CommonProps) {
    let email = '(empty)';

    if (!isNull(props.sessionData)) {
        email = props.sessionData.transaction.customer.contact.email;
    }

    return (
        <div>
            <img src={require('../../../img/payment-methods/paypal.png')} class="img-responsive mv-6 max-h-45"/>
        </div>
    );
}