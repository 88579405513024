import {h} from 'preact';
import {AppStage, CommonProps} from '../types';
import Spinner from '../components/Spinner';

export default function ChangePageButton(props: CommonProps & {stage: AppStage, title: string}): JSX.Element {

    return (
        <div class="container">
            <div class="col-xs-12">
                <button
                    class='button-as-link'
                    data-goto-={props.stage}
                    onClick={() => {
                        if (props.stageData.direct.indexOf(props.stage) !== -1) {
                            props.setStage(props.stage);
                        } else {
                            props.setStage(AppStage.STAGE_ERROR);
                        }
                    }}
                    disabled={props.isLoading}
                >
                    {props.title.toUpperCase()}
                </button>
            </div>
        </div>
    )
}