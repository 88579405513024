import { ValidationInstance, ValidationResult } from './ValidationInstances';
import { FilterInstance } from './FilterInstances';

export default class HTMLInputElementDecorator {
    private readonly field: HTMLInputElement;
    private validationInstance: ValidationInstance;
    private filterInstance: FilterInstance;

    constructor(inputReference: HTMLInputElement) {
        this.field = inputReference;
        this.setValidationInstance = this.setValidationInstance.bind(this);
        this.setFilterInstance = this.setFilterInstance.bind(this);

        this.setFilterInstance({
            filteringFunction: () => undefined
        });

        this.setValidationInstance({
            validatingFunction: (inputDecorator: HTMLInputElementDecorator) => {
                return {valid: true};
            }
        });
    }

    public getField(): HTMLInputElement {
        return this.field;
    }

    public setValidationInstance(instance: ValidationInstance): void {
        this.validationInstance = instance;
    }

    public setFilterInstance(instance: FilterInstance): void {
        this.filterInstance = instance;
    }

    public filterContents(): void {
        this.filterInstance.filteringFunction(this);
    }

    public validateContents(): ValidationResult {
        return this.validationInstance.validatingFunction(this);
    }
}