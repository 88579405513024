import {h} from 'preact';
import {CommonProps, Intent, OrderOption, PaymentMethod, WizardFlow,} from '../../common/types';
import {isNull, isNullOrUndefined} from 'util';
import DirectDebitInfo from './DirectDebitInfo';
import PayInAdvanceInfo from './PayInAdvanceInfo';
import {CreditCardInfo} from './CreditCardInfo';
import InvoiceInfo from './InvoiceInfo';
import {PayPalBasicInfo} from './PayPalBasicInfo';
import CollectionOptionTemplate from "../../templates/CollectionOptionTemplate";
import {getPrimaryColor} from "../../common/utils";

function successHeader(props: CommonProps) {
    let primaryColour: string = getPrimaryColor(props);
    return <div class="container">
        <div class="col-xs-12">
            <h1
                class="fs-18-px color-green-2 text-center font-msd-300"
                style={primaryColour ? "color: " + primaryColour + " !important;" : ""}
            >
                {props.sessionData && props.sessionData.transaction.intent === Intent.SALE ?
                    props.getStringTranslationByKey('success_page_header_intent_sale') :
                    props.getStringTranslationByKey('success_page_header')}
            </h1>
            <p class="no-margin-bottom">
                {props.sessionData && props.sessionData.transaction.intent === Intent.SALE ?
                    props.getStringTranslationByKey('success_thanks_intent_sale') :
                    props.getStringTranslationByKey('success_thanks')}
            </p>
        </div>
    </div>
}

function showPickupCode(props: CommonProps) {
    let primaryColour: string = getPrimaryColor(props);
    return (
        !isNull(props.sessionData)
        && props.sessionData.transaction.order_option === OrderOption.ORDER_OPTION_COLLECTION
        && !isNullOrUndefined(props.sessionData.transaction.pickup_options)
            ? (
                <div class="container">
                    <div class="col-xs-12">
                        <div class="pickup-code">
                            <h1
                                class="fs-18-px color-green-2 font-msd-300 text-center mt-20"
                                style={primaryColour ? "color: " + primaryColour + " !important;" : ""}
                            >
                                {props.getStringTranslationByKey('success_your_pick_up_code')}
                            </h1>
                            <div
                                class="fs-36-px color-green-2 font-msd-500 text-center">{props.sessionData.transaction.pickup_options.code}</div>
                        </div>
                    </div>
                </div>
            )
            : null
    );
}

function showCollectionHint(props: CommonProps): JSX.Element | null {
    if (
        !isNull(props.sessionData)
        && props.sessionData.transaction.order_option === OrderOption.ORDER_OPTION_COLLECTION
        && !isNullOrUndefined(props.sessionData.transaction.pickup_options)
    ) {
        return <div>
            <div class="container">
                <div class="col-xs-12 collection-tip">
                    <p>
                        {props.getStringTranslationByKey('success_collection_hint')}
                        {props.sessionData.transaction.pickup_options.store.name}, {props.sessionData.transaction.pickup_options.store.address_formatted}:
                    </p>
                </div>
            </div>
            {<CollectionOptionTemplate {...props}/>}
        </div>;
    } else {
        return null;
    }
}

function showSummaryInfo(props: CommonProps) {
    return (
        <div class="container">
            <div class="col-xs-12">
                {props.sessionData && props.sessionData.transaction.intent !== Intent.SALE ?
                <p class="no-margin-bottom">
                    {props.getStringTranslationByKey('success_email_note')}
                </p> : null}
                <p class="no-margin-bottom">
                    {props.getStringTranslationByKey('good_bye')}
                </p>
            </div>
        </div>
    );
}

function showInfoBody(props: CommonProps) {
    if (!isNull(props.sessionData)) {
        switch (props.sessionData.payment_method) {
            case PaymentMethod.DIRECT_DEBIT:
                return <DirectDebitInfo {...props as CommonProps} />;
            case PaymentMethod.SECUCARD:
                return <div>Secupay payment in progress</div>;
            case PaymentMethod.PAY_IN_ADVANCE:
                return <PayInAdvanceInfo {...props as CommonProps} />;
            case PaymentMethod.CREDIT_CARD:
                return <CreditCardInfo {...props as CommonProps}/>;
            case PaymentMethod.INVOICE:
                return <InvoiceInfo {...props as CommonProps} />;
            case PaymentMethod.PAYPAL:
                return <PayPalBasicInfo getStringTranslationByKey={props.getStringTranslationByKey}/>;
            default:
                return null;
        }
    } else {
        return null;
    }
}

function pageStructure(props: CommonProps): JSX.Element {
    if(props.wizardFlow === WizardFlow.PAYMENT && props.sessionData) {
        switch(props.sessionData.transaction.intent) {
            case Intent.AUTHORIZATION:
                return <div className="mt-10 mb-10 mb-10">
                    <div class="container">
                        <div class="col-xs-12">
                            <h1
                                class="fs-18-px color-green-2 text-center font-msd-300">
                                {props.getJSXTranslationByKey('success_page_header')}
                            </h1>
                        </div>
                    </div>
                </div>;
// CASE for other intent types
        }
    }
    return <div className="mt-10 mb-10 mb-10">
        {successHeader(props)}
        {showPickupCode(props)}
        {showCollectionHint(props)}
        <div class="payment-info container">
            <div class="col-xs-12">
                {showInfoBody(props)}
            </div>
        </div>
        {showSummaryInfo(props)}
    </div>;
}

export default function SuccessComponent(props: CommonProps) {
    return pageStructure(props)
}