import { Component, h } from 'preact';
import {CollectionHours, CollectionTimeInterval, CommonProps, LanguageSymbol, MainButtonType} from '../types';
import { AppContext } from '../../components/app/AppComponent';
import { isNull, isUndefined } from 'util';
import { Moment } from 'moment';
import moment = require('moment');
import {getPrimaryColor} from "../utils";

interface DateTimePickerProps extends CommonProps {
}

interface DateTimePickerState {
    day: string;
    month: string;
    year: string;
    hour: string;
    minute: string;
    valid: boolean;
}

type AvailableTimes = {[year: string]: {[month: string]: {[day: string]: {[hour: string]: string[]}}}};

export default class DateTimePicker extends Component<DateTimePickerProps, DateTimePickerState> {

    public static readonly COLLECTION_TIME_INTERVAL_MINUTES = 5;
    public static readonly DAYS_AHEAD = 7;
    public static readonly MONTHS_MAP = {
        [LanguageSymbol.EN_GB]: {
            '1': 'January',
            '2': 'February',
            '3': 'March',
            '4': 'April',
            '5': 'May',
            '6': 'June',
            '7': 'July',
            '8': 'August',
            '9': 'September',
            '10': 'October',
            '11': 'November',
            '12': 'December'
        },
        [LanguageSymbol.DE_DE]: {
            '1': 'Januar',
            '2': 'Februar',
            '3': 'März',
            '4': 'April',
            '5': 'Mai',
            '6': 'Juni',
            '7': 'Juli',
            '8': 'August',
            '9': 'September',
            '10': 'Oktober',
            '11': 'November',
            '12': 'Dezember'
        }
    };

    public static readonly CASCADE_UPDATE_ORDER = ['year', 'month', 'day', 'hour', 'minute'];

    public context: AppContext;

    private availableTimes: AvailableTimes = {};

    constructor(props: DateTimePickerProps, context: AppContext) {
        super(props);
        if (isUndefined(context.deliveryOptions.collectionHours) || isUndefined(context.deliveryOptions.preparationTime)) {
            throw new Error('DateTimePicker instantiated without proper collection configuration');
        }
        this.state = {
            day: '',
            month: '',
            year: '',
            hour: '',
            minute: '',
            valid: true
        };

        this.parseCollectionHours(context.deliveryOptions.collectionHours, context.deliveryOptions.preparationTime);

        // force cascade to fill from 0 position;
        this.state = this.getCascadeFilledState('nothing');

        this.handleInputChange = this.handleInputChange.bind(this);
        this.getTimestamp = this.getTimestamp.bind(this);
    }

    /**
     * Validate time based on preparation time (chosen time is incorrect, if now + preparation time exceeds it)
     *
     * @returns {boolean}
     */
    public validateTime() {
        if (!isUndefined(this.context.deliveryOptions.preparationTime)) {
            const preparationTimeMinutes: number = this.parsePreparationTime(
                this.context.deliveryOptions.preparationTime
            );

            const earliestAllowedMoment = moment().add(preparationTimeMinutes, 'minutes'),
                isDateCorrect = earliestAllowedMoment.unix() < this.getTimestamp() / 1000;

            this.setState({valid: isDateCorrect});
            return isDateCorrect;
        }

        return false;
    }

    /**
     * Function to return timestamp of chosen time
     *
     * @returns {number}
     */
    public getTimestamp(): number {
        return new Date(
          +this.state.year,
          this.getMonthNumberForReadableRepresentation(this.state.month) - 1,
          +this.state.day,
          +this.state.hour,
          +this.state.minute
        ).getTime();
    }

    public render(): JSX.Element {
        let dtpClasses = ['date-time-picker', 'col-md-12'];
        this.state.valid ? dtpClasses.push('valid') : dtpClasses.push('invalid');
        return (
            <div className={dtpClasses.join(' ')}>
                <div className="date-time-picker-inputs overflow-auto">
                    <div className="date-group col-xs-12 col-mob-8 no-padding text-center">
                        <label class="day">
                            <span>{this.props.getStringTranslationByKey('day')}</span>
                            {this.generateDropdown(this.dayList(), 'day', this.state.day)}
                        </label>
                        <label class="month">
                            <span>{this.props.getStringTranslationByKey('month')}</span>
                            {this.generateDropdown(
                                this.monthList(this.getCurrentLanguage()),
                                'month',
                                this.state.month
                            )}
                        </label>
                        <label class="year">
                            <span>{this.props.getStringTranslationByKey('year')}</span>
                            {this.generateDropdown(this.yearList(), 'year', this.state.year)}
                        </label>
                    </div>
                    <div className="time-group col-xs-12 col-mob-4 no-padding text-center">
                        <label class="hour">
                            <span>{this.props.getStringTranslationByKey('hour')}</span>
                            {this.generateDropdown(this.hourList(), 'hour', this.state.hour)}
                        </label>
                        <label class="minute">
                            <span>{this.props.getStringTranslationByKey('minute')}</span>
                            {this.generateDropdown(this.minuteList(), 'minute', this.state.minute)}
                        </label>
                    </div>
                </div>
                <div className="date-time-picker-hints">
                    <p>
                        {this.props.getStringTranslationByKey('dtp_check_time')}
                    </p>
                    <p>
                        {this.props.getStringTranslationByKey('hint')}: <br/>
                        * {this.props.getStringTranslationByKey('dtp_past')}<br/>
                        * {
                            this.props.getStringTranslationByKey('dtp_earliest_time')
                            + this.parsePreparationTime(this.context.deliveryOptions.preparationTime || '')
                            + this.props.getStringTranslationByKey('dtp_minutes')
                          }
                    </p>
                </div>
            </div>
        );
    }

    /**
     * Callback for handling event of changing user choice
     *
     * @param {Event} event
     */
    private handleInputChange(event: Event) {
        const target: HTMLSelectElement = event.target as HTMLSelectElement;
        const value = target.value;
        const name = target.name;

        // Assign new value and cascade fill subvalues
        this.state[name] = value;
        const inputState = this.getCascadeFilledState(name);

        this.setState(inputState);

        this.validateTime();
    }

    /**
     * Function to generate dropdown list of given values
     *
     * @param {string[]} list
     * @param {string} name
     * @param {string} selectedValue
     * @returns {JSX.Element}
     */
    private generateDropdown(list: string[], name: string, selectedValue?: string): JSX.Element {
        let options = [];
        let primaryColour: string = getPrimaryColor(this.props);

        for (let val of list) {
            options.push(
                <option value={val} selected={val === selectedValue}>
                    {val}
                </option>
            );
        }
        return (
            <div class="select-wrapper">
                <select
                    name={name}
                    onChange={this.handleInputChange}
                    style={primaryColour ? "background: " + primaryColour + " !important;" : ""}
                >
                    {options}
                </select>
            </div>
        );
    }

    /**
     * Return list of months for current state
     *
     * @param {LanguageSymbol} language
     * @returns {string[]}
     */
    private monthList(language: LanguageSymbol): string[] {
        // map digital months to human-readable translations
        return Object.keys(this.availableTimes[this.state.year]).map(
            month => DateTimePicker.MONTHS_MAP[language][month]
        );
    }

    /**
     * Return list of days for current state
     *
     * @returns {string[]}
     */
    private dayList(): string[] {
        const month = this.getMonthNumberForReadableRepresentation(this.state.month);
        return Object.keys(this.availableTimes[this.state.year][month]).sort(DateTimePicker.sortAsNumbers);
    }

    private static sortAsNumbers(a: string, b: string) {
        return parseInt(a) - parseInt(b);
    }

    /**
     * Return list of years
     *
     * @returns {string[]}
     */
    private yearList(): string[] {
        return Object.keys(this.availableTimes).sort();
    }

    /**
     * Return list of hours for current state
     *
     * @returns {string[]}
     */
    private hourList(): string[] {
        const month = this.getMonthNumberForReadableRepresentation(this.state.month);
        return Object.keys(this.availableTimes[this.state.year][month][this.state.day]).sort();
    }

    /**
     * Return list of minutes for current state
     *
     * @returns {string[]}
     */
    private minuteList(): string[] {
        const month = this.getMonthNumberForReadableRepresentation(this.state.month);
        return this.availableTimes[this.state.year][month][this.state.day][this.state.hour].sort();
    }

    /**
     * Function to get current language setting
     *
     * @returns {LanguageSymbol}
     */
    private getCurrentLanguage(): LanguageSymbol {
        return this.props.currentLang === undefined ? LanguageSymbol.DE_DE : this.props.currentLang;
    }

    /**
     * Function to parse given collection times configuration to a suitable format for the picker
     *
     * @param {CollectionHours} collectionHours
     * @param {string} preparationTime
     */
    private parseCollectionHours(collectionHours: CollectionHours, preparationTime: string) {
        // relative moment, which serves as an iterator over the weekdays
        let relativeMoment = moment();

        // get preparation time
        const preparationTimeMinutes: number = this.parsePreparationTime(preparationTime);

        // silently fail if misconfigured
        if (preparationTimeMinutes === -1) {
            return;
        }

        // today is a special case because not whole intervals can be available
        const currentWeekday = relativeMoment.format('ddd');

        // check if hours available for today
        if (collectionHours[currentWeekday]) {
            this.fillCollectionTimeForToday(relativeMoment, collectionHours[currentWeekday], preparationTimeMinutes);
        }

        // fill remaining days if configured
        this.fillCollectionTimeForRemainingDays(collectionHours, relativeMoment);
    }

    /**
     * Function to parse preparation time using regex
     *
     * @param {string} preparationTime
     * @returns {number}
     */
    private parsePreparationTime(preparationTime: string) {
        const preparationTimeSearchArray: RegExpMatchArray | null = preparationTime.match(/PT(\d\d?)M/);

        if (!isNull(preparationTimeSearchArray)) {
            return Number(preparationTimeSearchArray[1]);
        } else {
            return -1;
        }
    }

    /**
     * Function to fill collection times for today, which is a special case, because not full day is available
     *
     * @param {moment.Moment} currentMoment
     * @param {CollectionTimeInterval[]} todaysIntervals
     * @param {number} preparationTimeMinutes
     */
    private fillCollectionTimeForToday(
        currentMoment: Moment,
        todaysIntervals: CollectionTimeInterval[],
        preparationTimeMinutes: number
    ) {
        // First hypothetical available time is now + preparation time
        const firstAbsoluteAvailableTime = currentMoment.add(preparationTimeMinutes, 'minutes');
        this.roundMomentToMinutesInterval(firstAbsoluteAvailableTime);

        let firstSegmentIndex = this.getFirstCollectionTimeSegmentIndexForToday(
            todaysIntervals,
            firstAbsoluteAvailableTime
        );
        if (firstSegmentIndex === -1) {
            return;
        }

        this.determineFirstAvailableTimeAndFillFirstInterval(
            todaysIntervals[firstSegmentIndex],
            firstAbsoluteAvailableTime
        );

        firstSegmentIndex++;
        for (; firstSegmentIndex < todaysIntervals.length; firstSegmentIndex++) {
            this.fillCollectionTimeForInterval(todaysIntervals[firstSegmentIndex], firstAbsoluteAvailableTime);
        }
    }

    /**
     * Function to get first collection time interval based on possible collection time
     *
     *
     * @param {CollectionTimeInterval[]} todaysIntervals
     * @param {moment.Moment} firstAbsoluteAvailableTime
     * @returns {number}
     */
    private getFirstCollectionTimeSegmentIndexForToday(
        todaysIntervals: CollectionTimeInterval[],
        firstAbsoluteAvailableTime: Moment
    ) {
        // find first segment
        let firstSegmentIndex = -1;
        while (
            firstSegmentIndex + 1 < todaysIntervals.length
            && firstAbsoluteAvailableTime.isSameOrBefore(moment(todaysIntervals[firstSegmentIndex + 1].till, 'HH:mm'))
            ) {
            firstSegmentIndex++;
        }

        return firstSegmentIndex;
    }

    /**
     * Function to calculate first available time based on configuration and hypothetical available time
     * and then fill first interval based on the result
     *
     * @param {CollectionTimeInterval} firstInterval
     * @param {moment.Moment} firstAbsoluteAvailableTime
     */
    private determineFirstAvailableTimeAndFillFirstInterval(
        firstInterval: CollectionTimeInterval,
        firstAbsoluteAvailableTime: Moment
    ) {
        // compare to from time from the first segment to determine first available time
        const firstConfiguredAvailableTime = moment(firstInterval.from, 'HH:mm');
        let firstAvailableTime: Moment;
        if (firstAbsoluteAvailableTime.isSameOrBefore(firstConfiguredAvailableTime)) {
            firstAvailableTime = firstConfiguredAvailableTime;
        } else {
            firstAvailableTime = firstAbsoluteAvailableTime;
        }

        this.fillCollectionTimeForInterval(
            {
                from: firstAvailableTime.format('HH:mm'),
                till: firstInterval.till
            },
            firstAvailableTime
        );
    }

    /**
     * Function to fill collection time for remaining weekdays
     *
     * @param {CollectionHours} collectionHours
     * @param {moment.Moment} relativeMoment any moment which gives indication of what day it is, used only for that
     */
    private fillCollectionTimeForRemainingDays(collectionHours: CollectionHours, relativeMoment: Moment) {
        for (let i = 1; i < DateTimePicker.DAYS_AHEAD; i++) {
            // next iteration
            relativeMoment.add(1, 'days');
            const weekday = relativeMoment.format('ddd');

            // fill hours if configured
            if (collectionHours[weekday]) {
                collectionHours[weekday].forEach(
                    (interval: CollectionTimeInterval) => this.fillCollectionTimeForInterval(interval, relativeMoment)
                );
            }
        }
    }

    /**
     * Function to to fill availableTimes object based on given time interval and relative moment which has
     * the needed date
     *
     * @param {CollectionTimeInterval} interval
     * @param {moment.Moment} relativeMoment
     */
    private fillCollectionTimeForInterval(interval: CollectionTimeInterval, relativeMoment: Moment) {
        let momentIterator = moment(interval.from, 'HH:mm');
        const tillMoment = moment(interval.till, 'HH:mm'),
            currentYear = relativeMoment.format('YYYY'),
            currentMonth = relativeMoment.format('M'),
            currentDay = relativeMoment.format('D');

        this.populateAvailableTimesIfEmpty(currentYear, currentMonth, currentDay);

        while (momentIterator.isSameOrBefore(tillMoment)) {
            const currentHour = momentIterator.format('HH');
            if (isUndefined(this.availableTimes[currentYear][currentMonth][currentDay][currentHour])) {
                this.availableTimes[currentYear][currentMonth][currentDay][currentHour] = [];
            }

            this.availableTimes[currentYear][currentMonth][currentDay][currentHour].push(momentIterator.format('mm'));

            momentIterator.add(DateTimePicker.COLLECTION_TIME_INTERVAL_MINUTES, 'minutes');
        }
    }

    /**
     * Function to insert blanks into availableTimes object in case there is no such route
     *
     * @param {string} currentYear
     * @param {string} currentMonth
     * @param {string} currentDay
     */
    private populateAvailableTimesIfEmpty(currentYear: string, currentMonth: string, currentDay: string) {
        if (isUndefined(this.availableTimes[currentYear])) {
            this.availableTimes[currentYear] = {};
        }

        if (isUndefined(this.availableTimes[currentYear][currentMonth])) {
            this.availableTimes[currentYear][currentMonth] = {};
        }

        if (isUndefined(this.availableTimes[currentYear][currentMonth][currentDay])) {
            this.availableTimes[currentYear][currentMonth][currentDay] = {};
        }
    }

    /**
     * Function to round a moment up to a given minute interval
     *
     * @param {moment.Moment} inputMoment
     * @param {number} interval
     */
    private roundMomentToMinutesInterval(
        inputMoment: Moment,
        interval: number = DateTimePicker.COLLECTION_TIME_INTERVAL_MINUTES
    ) {
        const remainder = interval - inputMoment.minutes() % interval;
        inputMoment.add(remainder, 'minutes');
    }

    /**
     * Function to convert translated human-readable month name to a month number
     *
     * @param {string} representation
     * @returns {number}
     */
    private getMonthNumberForReadableRepresentation(representation: string) {
        for (const lang in DateTimePicker.MONTHS_MAP) {
            if (DateTimePicker.MONTHS_MAP.hasOwnProperty(lang)) {
                const monthsMapForLang = DateTimePicker.MONTHS_MAP[lang];
                for (let monthNumber in monthsMapForLang) {
                    if (monthsMapForLang.hasOwnProperty(monthNumber) && representation === monthsMapForLang[monthNumber]) {
                        return Number(monthNumber);
                    }
                }
            }
        }

        return -1;
    }

    /**
     * Function to automatically choose first available time if one of values is changed
     * (e.g. hours and minutes if the day is changed)
     *
     * @param {string} startField
     * @returns {{} & Readonly<DateTimePickerState>}
     */
    private getCascadeFilledState(startField: string) {
        let inputState = Object.assign({}, this.state);
        const updateCascadeStart = DateTimePicker.CASCADE_UPDATE_ORDER.indexOf(startField) + 1;

        let currentScope: object = Object.assign({}, this.availableTimes);

        // get available times for already known data
        for (let j = 0; j < updateCascadeStart; j++) {
            let currentKey = inputState[DateTimePicker.CASCADE_UPDATE_ORDER[j]];
            if (DateTimePicker.CASCADE_UPDATE_ORDER[j] === 'month') {
                currentKey = this.getMonthNumberForReadableRepresentation(currentKey);
            }
            currentScope = currentScope[currentKey];
        }

        inputState = this.cascadeFillState(currentScope, inputState, updateCascadeStart);

        return inputState;
    }

    /**
     * Function to fill state with first available times
     *
     * @param {object} currentScope
     * @param {DateTimePickerState} inputState
     * @param {number} updateCascadeStart
     * @returns {DateTimePickerState}
     */
    private cascadeFillState(currentScope: object, inputState: DateTimePickerState, updateCascadeStart: number) {
        for (; updateCascadeStart < DateTimePicker.CASCADE_UPDATE_ORDER.length; updateCascadeStart++) {
            let firstKey: string;
            if (DateTimePicker.CASCADE_UPDATE_ORDER[updateCascadeStart] === 'minute') {
                firstKey = currentScope[0];
            } else {
                firstKey = Object.keys(currentScope)[0];
            }

            if (DateTimePicker.CASCADE_UPDATE_ORDER[updateCascadeStart] === 'month') {
                inputState[DateTimePicker.CASCADE_UPDATE_ORDER[updateCascadeStart]] = DateTimePicker.MONTHS_MAP[this.props.currentLang][firstKey];
            } else {
                inputState[DateTimePicker.CASCADE_UPDATE_ORDER[updateCascadeStart]] = firstKey;
            }
            currentScope = currentScope[firstKey];
        }

        return inputState;
    }
}