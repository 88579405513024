import { h } from 'preact';
import { CommonProps } from '../types';

export default function InfoCheckoutComponent(props: CommonProps) {
  const {getStringTranslationByKey, currentLang} = props;
  const icons = ['start-01', 'address-01', 'payment-01', 'success-01'];
  const stages = ['overview', 'address', 'payment', 'confirmation'];
  const descs = [0, 1, 2, 3].map((stage) => (
    <div class="row mv-30">
      <div class="col-xs-1">
        <img
          src={require('../../../img/header-icons/' + icons[stage] + '.svg')}
          class="stage-icon"
        />
      </div>
      <div class="col-xs-11">
        <b class="highlight-theme">{getStringTranslationByKey('desc_' + stages[stage] + '_name')}</b>
        {getStringTranslationByKey('desc_' + stages[stage])}
      </div>
    </div>
  ));
  return (
    <div>
      <h2 class="headline-theme">
        {getStringTranslationByKey('desc_headline')}
      </h2>
      <p>
        {getStringTranslationByKey('desc_snd_line')}
      </p>
      <p>
        {getStringTranslationByKey('desc_trd_line')}
      </p>
      <img
        src={require('../../../img/smart_checkout_description/desc_' + currentLang + '.svg')}
        class="mv-30"
      />
      {...descs}
    </div>
  );
}