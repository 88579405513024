import {Component, h} from 'preact';
import {
    AppStage,
    Basket,
    BasketInfo,
    BasketType,
    CommonProps, LanguageSymbol,
    MixedBasket,
    Product,
    ProductType,
    ResponseHttp,
    SubBasket
} from '../types';
import {default as BasketItemTemplate} from '../../templates/BasketItemTemplate';
import {isNullOrUndefined} from 'util';
import OrderTotalTemplate from '../../templates/OrderTotalTemplate';
import MixBasketItemTemplate from '../../templates/MixedBasketItemTemplate';
import AmountHelper from "../helpers/AmountHelper";
import {createHeadlineElement} from "../utils";

interface InfoBasketComponentProps extends CommonProps {
    additionalWrapperClass?: string;
}

interface InfoBasketComponentState {
    basket: Basket | MixedBasket;
}

export default class InfoBasketComponent extends Component<InfoBasketComponentProps, InfoBasketComponentState> {
    constructor() {

        super();

        this.state = {
            basket: {
                type: BasketType.DEFAULT,
                products: [],
                basket_info: {
                    sum: -1,
                    currency: 'EUR'
                }
            }
        };

        this.retrieveBasket = this.retrieveBasket.bind(this);
        this.setBasketData = this.setBasketData.bind(this);
        this.generateBasketItems = this.generateBasketItems.bind(this);
    }

    componentDidMount() {
        this.retrieveBasket();
    }

    public render(): JSX.Element {
        const iframeOpts = this.props.sessionData ? this.props.sessionData.payment_wizard_options : null;
        const customBasketHeader = iframeOpts ? iframeOpts.basket_headline : '';
        return (
            <div id="basket"
                 class={"container overflow-auto" + (this.props.additionalWrapperClass ? ' ' + this.props.additionalWrapperClass : '')}>
                <div class="col-xs-12">
                    {createHeadlineElement(
                        this.props,
                        customBasketHeader ? customBasketHeader.toUpperCase() : this.props.getStringTranslationByKey('basket_header')
                    )}
                    <div class="col-xs-12 basket-items pl-0 pr-0">
                        <div class="table-header overflow-auto">
                            <div class="col-xs-3 col-mob-2 quantity pl-0">
                                {this.props.getStringTranslationByKey('quantity')}
                            </div>
                            <div
                                class="col-xs-5 col-mob-8 desc"> {this.props.getStringTranslationByKey('product')} </div>
                            <div class="col-xs-4 col-mob-2 price pr-0 text-right">
                                {this.props.getStringTranslationByKey('price')}
                            </div>
                        </div>
                        {this.generateBasketItems(this.state.basket)}
                    </div>

                    <div className="col-xs-12 pl-0 pr-0">
                        <OrderTotalTemplate getStringTranslationByKey={this.props.getStringTranslationByKey}
                                            total={this.state.basket.basket_info.sum}
                                            lang={this.props.currentLang}
                                            currency={this.state.basket.basket_info.currency}
                                            wrapperExtraClasses="float-right"/>
                    </div>

                    <div id="tax-info" class="col-xs-12 pr-0">
                        <p class="float-right no-margin-top">
                            {this.props.getStringTranslationByKey('tax_info_1')}
                            {this.calculateTax()}
                            {this.props.getStringTranslationByKey('tax_info_2')}
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    private retrieveBasket() {
        this.props.callBackend(
            '/basket',
            (response: ResponseHttp) => {
                const body = JSON.parse(response.text);

                switch (body.basket.type) {
                    case BasketType.DEFAULT:
                        this.setBasketData(body.basket.products, body.basket_info);
                        break;
                    case BasketType.MIXED:
                        this.setMixedBasketData(body.basket.sub_baskets, body.basket_info);
                        break;
                    default:
                        break;
                }
            }
        );
    }

    private setBasketData(products: Product[], basketInfo: BasketInfo) {
        this.setState({
            basket: {
                products: products.map((val: Product) => ({
                    quantity: val.quantity,
                    desc: val.desc,
                    priceOne: val.priceOne,
                    tax: val.tax,
                    item_type: val.item_type
                })),
                basket_info: {
                    sum: basketInfo.sum,
                    currency: basketInfo.currency
                },
                type: BasketType.DEFAULT
            }
        });

        if (isNullOrUndefined(this.state.basket.basket_info.sum)
            || this.state.basket.basket_info.sum < 0) {
            this.props.setStage(AppStage.STAGE_ERROR);
        }
    }

    private setMixedBasketData(subBaskets: SubBasket[], basketInfo: BasketInfo) {
        this.setState({
            basket: {
                type: BasketType.MIXED,
                sub_baskets: subBaskets,
                basket_info: basketInfo
            }
        });

        if (isNullOrUndefined(this.state.basket.basket_info.sum)
            || this.state.basket.basket_info.sum < 0) {
            this.props.setStage(AppStage.STAGE_ERROR);
        }
    }

    private calculateTax(): string {
        let productsSumByTax = {};
        let productTax;
        let productTotalPrice;
        let totalTax = 0;
        let productsTotalPriceByTax;
        let allProducts: Product[] = [];

        switch (this.state.basket.type) {
            case BasketType.DEFAULT:
                (this.state.basket as Basket).products.forEach((p) => {
                    allProducts.push(p);
                });
                break;
            case BasketType.MIXED:
                (this.state.basket as MixedBasket).sub_baskets.forEach((s) => {
                    s.products.forEach((p) => {
                        allProducts.push(p);
                    });
                });
                break;
            default:
                break;
        }

        // create a table with taxes rates as keys
        allProducts.map((p: Product) => {
            productTotalPrice = p.priceOne * p.quantity;
            productTax = p.tax;
            if (productsSumByTax[productTax] === undefined) {
                productsSumByTax[productTax] = productTotalPrice;
            } else {
                // we must subtract coupon value (not omit)
                if (p.item_type !== ProductType.COUPON) {
                    productsSumByTax[productTax] += productTotalPrice;
                } else {
                    productsSumByTax[productTax] -= productTotalPrice;
                }
            }
        });

        for (let taxRatio in productsSumByTax) {
            if (taxRatio !== 'undefined') {
                productsTotalPriceByTax = productsSumByTax[taxRatio];
                totalTax += productsTotalPriceByTax - (productsTotalPriceByTax / (1 + parseInt(taxRatio, 10) / 100));
            }
        }
        return AmountHelper.convertCentsToEuro(Math.round(totalTax), this.props.currentLang);
    }

    private generateBasketItems(basket: Basket | MixedBasket): JSX.Element | null {
        if (basket.type === BasketType.DEFAULT) {
            let products = (basket as Basket).products;
            return <div class="simple-basket-section">
                {InfoBasketComponent.generateBasket(products, this.props.currentLang, this.state.basket.basket_info.currency, this.props)}
            </div>
        }
        if (basket.type === BasketType.MIXED) {
            return <div>
                {
                    (basket as MixedBasket).sub_baskets.map(
                        (val: SubBasket, index) => (
                            <MixBasketItemTemplate subbasket={val}
                                                   {...this.props}
                                                   currency={this.state.basket.basket_info.currency}
                                                   subbasketIndex={index}
                            />
                        )
                    )
                };
            </div>
        }
        return null;
    }

    public static hasBasketCoupons(products: Product[]): boolean {
        return products.filter(p => p.item_type === ProductType.COUPON).length > 0;
    }

    public static hasBasketShipping(products: Product[]): boolean {
        return products.filter(p => p.item_type === ProductType.SHIPPING).length > 0;
    }

    public static generaBasketJSXItemsByType(products: Product[], type: ProductType, lang: LanguageSymbol, currency: string): JSX.Element[] {
        return products.filter(p => p.item_type === type).map(
            (val: Product) => (
                <BasketItemTemplate product={val}
                                    lang={lang}
                                    currency={currency}/>
            )
        )
    }

    public static generateBasket(products: Product[], lang: LanguageSymbol, currency: string, props: CommonProps) {
        return <div>
            {InfoBasketComponent.generaBasketJSXItemsByType(products, ProductType.ARTICLE, lang, currency)}
            {InfoBasketComponent.hasBasketCoupons(products) ?
                <div className="coupons">
                    <p className="coupons-title">{props.getStringTranslationByKey('coupons')}:</p>
                    {InfoBasketComponent.generaBasketJSXItemsByType(products, ProductType.COUPON, lang, currency)}
                </div>
                : null}
            {InfoBasketComponent.hasBasketShipping(products) ?
                <div className="shipping">
                    <p className="shipping-title">{props.getStringTranslationByKey('shipping')}:</p>
                    {InfoBasketComponent.generaBasketJSXItemsByType(products, ProductType.SHIPPING, lang, currency)}
                </div>
                : null}
        </div>
    }
}
