import {h} from 'preact';
import AmountHelper from '../common/helpers/AmountHelper';
import {LanguageSymbol, Product, ProductType} from '../common/types';

export interface BasketItemTemplateProps {
    product: Product;
    currency: string;
    lang: LanguageSymbol;
}

function parseProductDesc(desc: string) {
    const newLineSymbol = "\n";

    // "filter" after "split" is for removing duplicated new lines, eg. \nMulti\n\nline\n
    return desc.split(newLineSymbol).filter(partName => partName !== "").map(partName => <span>{partName}</span>);
}

export default function BasketItemTemplate(props: BasketItemTemplateProps) {
    return (
        <div className={"basket-item " + props.product.item_type}>
            <div className="pl-0 col-xs-3 col-mob-2 quantity"> {props.product.quantity} x</div>
            <div className="col-xs-5 col-mob-8 desc">{parseProductDesc(props.product.desc)}</div>
            <div className="pr-0 col-xs-4 col-mob-2 price text-right">
                {props.product.item_type === ProductType.COUPON ? '-' : ''}
                {AmountHelper.getFullAmountWithCurrency(props.product.priceOne * props.product.quantity, props.currency, props.lang)}
            </div>
        </div>
    );
}
