import { h } from 'preact';
import {CommonProps, Intent} from '../../common/types';

export default function InvoiceInfo(props: CommonProps) {
    return (
        <p class="no-margin-bottom">
            {props.sessionData && props.sessionData.transaction.intent === Intent.SALE ?
                props.getStringTranslationByKey('success_invoice_intent_sale') :
                props.getStringTranslationByKey('success_invoice')}
        </p>
    );
}
