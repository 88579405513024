import {h} from 'preact';
import {CommonProps, LanguageSymbol, Product, ProductType, SubBasket} from '../common/types';
import {default as BasketItemTemplate} from './BasketItemTemplate';
import InfoMerchantLinks from '../common/info/InfoMerchantLinks';
import {isNull} from 'util';
import InfoBasketComponent from "../common/info/InfoBasketComponent";

export interface MixBasketItemTemplateProps extends CommonProps {
    subbasket: SubBasket;
    currency: string;
    subbasketIndex: number;
}

const classToggler = (event: MouseEvent) => {
    let element = event.target as HTMLElement;
    let togglerLine = element.closest('.merchant-info-toggler') as HTMLElement;
    let wrapper = element.closest('.mixed-basket-section');
    let extraInfoWrapper;

    if (!isNull(wrapper)) {
        extraInfoWrapper = wrapper.querySelector('.merchant-extra-info');
        if (!isNull(extraInfoWrapper)) {
            if (extraInfoWrapper.classList.contains('closed')) {
                extraInfoWrapper.classList.remove('closed');
                extraInfoWrapper.classList.add('opened');
                togglerLine.classList.remove('closed');
                togglerLine.classList.add('opened');
            } else {
                extraInfoWrapper.classList.remove('opened');
                extraInfoWrapper.classList.add('closed');
                togglerLine.classList.remove('opened');
                togglerLine.classList.add('closed');
            }
        }
    }
};

export default function MixBasketItemTemplate(props: MixBasketItemTemplateProps) {
    let products = props.subbasket.products;
    return (
        <div class="mixed-basket-section">
            {InfoBasketComponent.generateBasket(products, props.currentLang, props.currency, props)}
            <p class="merchant-name">
                {props.getStringTranslationByKey('sales_and_shipping_by')}
                &nbsp;
                {props.subbasket.merchant_data.merchant_name}
            </p>
            <p class="merchant-info-toggler text-center closed" onClick={classToggler}><i className="far fa-angle-down" /></p>
            <div class={props.isLoading ? "merchant-extra-info closed" : "merchant-extra-info closed "}>
                <p class="merchant-address">{props.subbasket.merchant_data.merchant_address}</p>
                <InfoMerchantLinks
                    currentLang={props.currentLang}
                    getStringTranslationByKey={props.getStringTranslationByKey}
                    legalDetails={props.subbasket.merchant_data.legal_details}
                    additionalClasses={'pl-0 pr-0'}
                    idIndex={props.subbasketIndex}
                />

            </div>
        </div>
    );
}
