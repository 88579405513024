import {h} from 'preact';
import {AppStage, CommonProps, LanguageSymbol, OrderOption} from '../common/types';
import {isNull} from "util";
import ChangePageButton from "../common/buttons/ChangePageButton";
import InfoAddressComponent from "../common/info/InfoAddressComponent";
import {createHeadlineElement} from "../common/utils";

export default function CollectionOptionTemplate(props: CommonProps): JSX.Element | null {
    let orderOptionObj = {
        type: props.sessionData ? props.sessionData.transaction.order_option : null,
        time: isNull(props.sessionData) || isNull(props.sessionData.transaction.pickup_options) ? '' : props.sessionData.transaction.pickup_options.date
    };

    const time: Date = new Date(orderOptionObj.time),
        year: number = time.getFullYear(),
        month: number = time.getMonth() + 1,
        day: number = time.getDate(),
        dateSeparator: string = props.getStringTranslationByKey('date_separator'); // region-specific date separator

    let hour: number = time.getHours(),
        suffix: string, // language-specific ending of time notation
        minute: string = time.getMinutes().toString();

    if (time.getMinutes() < 10) {
        minute = '0' + minute;
    }

    if (props.currentLang === LanguageSymbol.DE_DE) {
        suffix = 'Uhr';
    } else {
        suffix = hour >= 12 ? 'PM' : 'AM';
        hour = hour % 12;
        hour = hour ? hour : 12; // the hour '0' should be '12'
    }
    return (
        <div>
            {props.stageData.stage === AppStage.STAGE_CONFIRMATION ?
                <div id="invoice-address" className="delivery-options container">
                    <div className="col-xs-12">
                        {createHeadlineElement(props, props.getStringTranslationByKey('your_invoice_address_header').toUpperCase())}
                    </div>
                    <InfoAddressComponent {... props as CommonProps}/>
                </div>
                : null}
            {
                props.stageData.stage === AppStage.STAGE_CONFIRMATION
                    ? props.stageData.direct.indexOf(AppStage.STAGE_ADDRESS) !== -1
                        ? <ChangePageButton {...props} stage={AppStage.STAGE_ADDRESS}
                                  title={props.getStringTranslationByKey('change_invoice_address')} />
                        : <div class="col-xs-12 mt-30"/>
                    : null
            }
            <div id="collection-info" class="delivery-options container">
                <div className="col-xs-12">
                    {createHeadlineElement(props, props.getStringTranslationByKey('collection_data_header').toUpperCase())}
                </div>
                <div class="col-xs-12">
                    <div class="col-xs-4 col-mob-2 pl-0">
                        <strong>{props.getStringTranslationByKey('collection_date')}</strong>
                    </div>
                    <div class="col-xs-8 col-mob-10">
                        <strong>{props.getStringTranslationByKey('collection_time')}</strong>
                    </div>
                    <div class="col-xs-4 col-mob-2 pl-0">
                        {day + dateSeparator + month + dateSeparator + year}
                    </div>
                    <div class="col-xs-8 col-mob-10">
                        {hour + ':' + minute + ' ' + suffix}
                    </div>
                </div>

            </div>
            {props.stageData.stage === AppStage.STAGE_CONFIRMATION ?
                <ChangePageButton {...props} stage={AppStage.STAGE_SHIPPING_COLLECTION}
                                  title={props.getStringTranslationByKey('change_delivery_options_button_text')} /> : null}
        </div>
    );
}
