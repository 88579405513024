import {h} from 'preact';
import {CommonProps, Intent} from "../../common/types";

export function CreditCardInfo(props: CommonProps) {
    return (
        <p class="no-margin-bottom">
            {
                props.sessionData && props.sessionData.transaction.intent === Intent.SALE ?
                    props.getStringTranslationByKey('credit_card_success_info_intent_sale') :
                    props.getStringTranslationByKey('credit_card_success_info')
            }
        </p>);
}