import { h } from 'preact';
import { LanguageSymbol } from '../../common/types';

export interface DirectDebitTemplateProps {
    iban: string;
    owner: string;
    currentLang: LanguageSymbol;
}

export default function DirectDebitTemplate(props: DirectDebitTemplateProps): JSX.Element {
    let currentLang: LanguageSymbol = props.currentLang;
    let directDebitIconName: string = 'direct-debit-de';

    if (currentLang === LanguageSymbol.EN_GB) {
        directDebitIconName = 'direct-debit-en';
    }
    return (
        <div class="col-xs-12 col-mob-3">
            <div class="row">
                <div class="col-xs-12 pl-0">
                    <img src={require('../../../img/payment-methods/' + directDebitIconName + '.png')}
                         class="img-responsive mv-6 max-h-45"/>
                    <p class="mv-6">IBAN: {props.iban}</p>
                </div>
            </div>
        </div>
    );
}