import {h, Component} from 'preact';
import {InputTemplate} from '../../templates/InputTemplate';
import ValidationInstances, {streetRegex} from '../../common/input/ValidationInstances';
import FilterInstances from '../../common/input/FilterInstances';
import HTMLInputElementDecorator from '../../common/input/HTMLInputElementDecorator';
import {
    AppStage,
    CommonProps, ErrorMessageKey,
    InputAttributes,
    InputName, LanguageSymbol,
    PaymentMethod,
    PaymentWindow, PayPalFlowType,
    SessionData,
} from '../../common/types';
import {isNull, isNullOrUndefined} from 'util';
import InputContainer from "../../common/input/InputContainer";
import {createHeadlineElement, getEnumValues, getPrimaryColor, startPayPalPayment} from '../../common/utils';
import {allTranslations} from "../../translations/translations";

interface AddressComponentProps extends CommonProps {
}

interface AddressComponentState {
}

interface AddressObject {
    companyname: string,
    salutation: number,
    first_name: string,
    surname: string,
    street: string,
    street_number: string,
    country: number,
    town: string,
    additional_address: string,
    zipcode: string,
    phone: string
}

export default class AddressComponent extends Component<AddressComponentProps, AddressComponentState> {

    private readonly countries: string[] = [
        'DE',
        'AT'
    ];

    // to keep order
    private readonly salutationKeys: string[] = [
        'salutation_male',
        'salutation_female'
    ];

    private inputContainer: InputContainer = new InputContainer(this);
    private inputParams: InputAttributes[] = [];
    private form: HTMLFormElement;
    readonly formRefCallback = (form: HTMLFormElement) => this.form = form;

    private salutations: string[] = this.getTranslatedSalutationList();

    constructor(props: AddressComponentProps) {
        super(props);

        // Order is important
        this.inputParams = [
            {
                containerClassName: 'col-xs-12 col-mob-6 non-mandatory',
                name: InputName.COMPANY_NAME,
                placeholder: props.getStringTranslationByKey(InputName.COMPANY_NAME),
                autocomplete: 'org',
                required: false
            },
            {
                containerClassName: 'col-xs-12 col-mob-6 hidden-xs visibility-hidden',
                name: InputName.EMPTY,
                required: false,
                customProps: {
                    disabled: true
                }
            },
            {
                containerClassName: 'col-xs-12 col-mob-2',
                name: InputName.SALUTATION,
                placeholder: props.getStringTranslationByKey(InputName.SALUTATION),
                autocomplete: 'honorific-prefix',
                required: false,
                optionsList: this.salutations,
                list: 'salutations',
                id: 'salutation'
            },
            {
                containerClassName: 'col-xs-12 col-mob-4',
                name: InputName.FIRST_NAME,
                placeholder: props.getStringTranslationByKey(InputName.FIRST_NAME),
                autocomplete: 'given-name',
                validationInstance: ValidationInstances.getAlphaOnlyValidationInstance(),
            },
            {
                containerClassName: 'col-xs-12 col-mob-6',
                name: InputName.SURNAME,
                placeholder: props.getStringTranslationByKey(InputName.SURNAME),
                autocomplete: 'family-name',
                validationInstance: ValidationInstances.getAlphaOnlyValidationInstance()
            },
            {
                containerClassName: 'col-xs-12 col-mob-2 ',
                name: InputName.COUNTRY,
                placeholder: props.getStringTranslationByKey(InputName.COUNTRY),
                autocomplete: 'country',
                optionsList: this.countries,
                list: 'countries',
                id: 'country'
            },
            {
                containerClassName: 'col-xs-12 col-mob-4',
                name: InputName.ZIP_CODE,
                placeholder: props.getStringTranslationByKey(InputName.ZIP_CODE),
                autocomplete: 'postal-code',
                validationInstance: ValidationInstances.getZipcodeValidationInstance(),
                onBlurPaste: FilterInstances.removeAllIntermediateWhitespacesAndTrimFilterInstance(),
                customProps: {
                    inputmode: 'numeric'
                }
            },
            {
                containerClassName: 'col-xs-12 col-mob-6',
                name: InputName.TOWN,
                placeholder: props.getStringTranslationByKey(InputName.TOWN),
                autocomplete: 'address-level2',
                validationInstance: ValidationInstances.getAlphaOnlyValidationInstance()
            },
            {
                containerClassName: 'col-xs-12 col-mob-6',
                name: InputName.STREET_AND_STREET_NUMBER,
                placeholder: props.getStringTranslationByKey(InputName.STREET_AND_STREET_NUMBER),
                autocomplete: 'address-line1',
                validationInstance: ValidationInstances.getStreetStreetNumberValidationInstance()
            },
            {
                containerClassName: 'col-xs-12 col-mob-6',
                name: InputName.ADDITIONAL_ADDRESS_DATA,
                placeholder: props.getStringTranslationByKey(InputName.ADDITIONAL_ADDRESS_DATA),
                autocomplete: 'address-line2',
                required: false
            },
            {
                containerClassName: 'col-xs-12 col-mob-6',
                name: InputName.PHONE,
                placeholder: props.getStringTranslationByKey(InputName.PHONE),
                autocomplete: 'tel',
                required: false,
                validationInstance: ValidationInstances.getPhoneNumberValidationInstance(),
                type: 'tel'
            },
        ];

        this.inputParams = this.attachAutofillMethods(this.inputParams);

        this.goToNextStage = this.goToNextStage.bind(this);
        this.constructAddressRequestBody = this.constructAddressRequestBody.bind(this);
        this.props.setButton(this.goToNextStage, 'next_step_button', true);
        this.props.showMessageBox(false);
    }

    componentDidMount() {
        this.form.reset();
        let sessionData: SessionData | null = this.props.sessionData;
        const decorators = this.inputContainer.getDecorators();
        if (!isNull(sessionData) &&
            !isNullOrUndefined(sessionData.transaction.customer) &&
            !isNullOrUndefined(sessionData.transaction.customer.contact.address)) {
            const contact = sessionData.transaction.customer.contact;
            decorators[InputName.COMPANY_NAME].getField().value = contact.companyname;
            decorators[InputName.SALUTATION].getField().value = (this.getSalutationIndexByTranslation(contact.salutation) + 1).toString();
            decorators[InputName.FIRST_NAME].getField().value = contact.forename;
            decorators[InputName.SURNAME].getField().value = contact.surname;
            decorators[InputName.COUNTRY].getField().value = (this.countries.indexOf(contact.address.country) + 1).toString();
            decorators[InputName.STREET_AND_STREET_NUMBER].getField().value = ((contact.address.street || '') + ' ' + (contact.address.street_number || '')).trim();
            decorators[InputName.ZIP_CODE].getField().value = contact.address.postal_code;
            decorators[InputName.TOWN].getField().value = contact.address.city;
            decorators[InputName.PHONE].getField().value = contact.phone;
            decorators[InputName.ADDITIONAL_ADDRESS_DATA].getField().value = contact.address.additional_address_data;
        } else {
            decorators[InputName.COUNTRY].getField().value = (this.countries.indexOf('DE') + 1).toString();
        }
        (document.querySelector(`[name="${InputName.FIRST_NAME}"]`) as HTMLInputElement).focus();
    }

    public componentWillReceiveProps(props: AddressComponentProps) {
        const salutations: string[] = this.getTranslatedSalutationList();
        this.inputParams.forEach(
            (value: InputAttributes, index: number) => {
                this.inputParams[index].placeholder = props.getStringTranslationByKey(value.name);
            }
        );
        this.inputParams[this.inputParams.findIndex(obj => obj.name === InputName.SALUTATION)].optionsList = salutations;
    }

    public render() {
        return (
            <div>
                <div class="container">
                    <div class="col-sm-12">
                        {createHeadlineElement(this.props, this.props.getStringTranslationByKey('address_header').toUpperCase())}
                    </div>
                </div>
                <form class="container pl-mob-5 pr-mob-5" ref={this.formRefCallback}>
                    {this.inputParams.map(
                        (val: InputAttributes, index) => {
                            return (
                                <InputTemplate
                                    key={index}
                                    inputAttributes={val}
                                    container={this.inputContainer}
                                    colorMandatory={getPrimaryColor(this.props)}/>
                            );
                        })}
                </form>

                <div class="container mb-10">
                    <div class="col-sm-12">
                        <div class="input-mandatory mt-10"
                             style={getPrimaryColor(this.props) ? "border-left-color: " + getPrimaryColor(this.props) + " !important" : ""}>
                            <div class="ml-5">
                                {this.props.getStringTranslationByKey('mandatory_field')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private constructAddressRequestBody(): AddressObject {
        const inputs: { [key: string]: HTMLInputElementDecorator } = this.inputContainer.getDecorators();
        const matchedStreet = inputs[InputName.STREET_AND_STREET_NUMBER].getField().value.match(streetRegex);

        return {
            companyname: inputs[InputName.COMPANY_NAME].getField().value,
            salutation: +inputs[InputName.SALUTATION].getField().value,
            first_name: inputs[InputName.FIRST_NAME].getField().value,
            surname: inputs[InputName.SURNAME].getField().value,
            street: matchedStreet![1],
            street_number: matchedStreet![2],
            country: +inputs[InputName.COUNTRY].getField().value,
            town: inputs[InputName.TOWN].getField().value,
            additional_address: inputs[InputName.ADDITIONAL_ADDRESS_DATA].getField().value,
            zipcode: inputs[InputName.ZIP_CODE].getField().value,
            phone: inputs[InputName.PHONE].getField().value
        };
    }

    private goToNextStage(): void {

        // setting filter instance for phone before filterAllInputs()
        this.setPhoneFilterInstance();

        this.inputContainer.filterAllInputs();
        if (!this.inputContainer.validateInputs()) {
            return;
        }

        this.props.setLoading(true);

        this.props.callBackend(
            '/address',
            () => {
                if (
                    this.props.stageData.next !== AppStage.STAGE_PAYMENT_INPUT
                    && this.props.stageData.next !== AppStage.STAGE_CONFIRMATION
                ) {
                    this.props.setStage(this.props.stageData.next as AppStage);
                } else {
                    const paymentMethods = this.props.getAvailablePaymentMethods();
                    this.props.callBackend(
                        '/payment-method',
                        () => {
                            switch (paymentMethods[0]) {
                                case PaymentMethod.DIRECT_DEBIT:
                                    this.props.setStage(AppStage.STAGE_PAYMENT_INPUT, PaymentWindow.WINDOW_DIRECT_DEBIT);
                                    break;
                                case PaymentMethod.CREDIT_CARD:
                                    this.props.setStage(AppStage.STAGE_PAYMENT_INPUT, PaymentWindow.WINDOW_CREDIT_CARD);
                                    break;
                                case PaymentMethod.INVOICE:
                                    this.props.callBackend(
                                        '/prepare',
                                        () => this.props.setStage(AppStage.STAGE_CONFIRMATION),
                                        'POST',
                                        {},
                                        () => {
                                            this.props.setStage(AppStage.STAGE_ERROR, ErrorMessageKey.NO_PAYMENT_METHOD_REMAINING);
                                        }
                                    );
                                    break;
                                case PaymentMethod.PAY_IN_ADVANCE:
                                    this.props.setStage(AppStage.STAGE_CONFIRMATION);
                                    break;
                                case PaymentMethod.PAYPAL:
                                    startPayPalPayment(this.props, PayPalFlowType.FLOW_TYPE_REGULAR);
                                    break;
                                default:
                                    break;
                            }
                        },
                        'POST',
                        {type: paymentMethods[0]}
                    );
                }
            },
            'POST',
            this.constructAddressRequestBody()
        );
    }

    private setPhoneFilterInstance(): void {
        this.inputParams[this.inputParams.length - 1].onSubmitFilter =
            FilterInstances.phoneFilterInstance(+this.inputContainer.getDecorators()['country'].getField().value);
        this.forceUpdate();
    }

    private getTranslatedSalutationList(): string[] {
        return this.salutationKeys.map(e => this.props.getStringTranslationByKey(e));
    }

    // return values -1, 0, 1, because later -1 will become Salutation (empty) value + 1
    private getSalutationIndexByTranslation(translation: string | null): number {
        let defaultIndex = 0;

        if (isNull(translation)) {
            return -1;
        } else {
            if (!isNaN(parseInt(translation))) {
                return parseInt(translation);
            } else {
                let langSymbols = getEnumValues(LanguageSymbol);
                for (const l of langSymbols) {
                    for (let k = 0; k < this.salutationKeys.length; k++) {
                        if (translation === allTranslations[l][this.salutationKeys[k]]) {
                            return k;
                        }
                    }
                }
                return defaultIndex;
            }
        }
    }

    private attachAutofillMethods(ia: InputAttributes[]): InputAttributes[] {
        return ia.map(el => {
            el['onAnimationStart'] = (event: AnimationEvent) => {
                this.handleAutofill(event)
            }
            // Currently is not needed here
            // el['onAnimationEnd'] = (event: AnimationEvent) => {this.handleAutofill(event)}
            return el;
        })
    }

    private handleAutofill(event: AnimationEvent): void {

        let target = event.target as HTMLInputElement;

        // only for one input because autofill event is fired for each input
        if (target && target.name === InputName.FIRST_NAME) {
            let decorators = this.inputContainer.getDecorators();
            let inputStreet = decorators[InputName.STREET_AND_STREET_NUMBER].getField();
            let inputAdditional = decorators[InputName.ADDITIONAL_ADDRESS_DATA].getField();

            switch (event.animationName) {
                case 'animationAutofill':
                    break;
                case 'animationAutofillStopped':
                    inputStreet.value = (inputStreet.value.trim() + ' ' + inputAdditional.value).trim();
                    inputAdditional.value = '';
                    break;
            }
        }
    }
}
