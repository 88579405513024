import { h } from 'preact';

interface PayPalBasicInfoProps {
    getStringTranslationByKey(key: string): string;
}

export function PayPalBasicInfo(props: PayPalBasicInfoProps) {
    return (
        <div>
            {props.getStringTranslationByKey('success_paypal_basic')}
        </div>);
}