import {
    LanguageSymbol,
    RequestOptions,
    ResponseHttp,
    ViewMode,
    ParamFromUrl,
    AppStage,
    PayPalMode,
    PaymentMethod,
    CommonProps,
    PayPalFlowType,
    PaymentWindow,
    WizardFlow,
    ParamInAttr,
    MainButtonType, SessionData
} from './types';
import {isNull, isNullOrUndefined, isUndefined} from 'util';
import {apiPath} from '../index';
import {h} from 'preact';
import HTMLInputElementDecorator from './input/HTMLInputElementDecorator';
import Spinner from './components/Spinner';

const http = require('iso-http');

export function getLangFromUrl(): LanguageSymbol {
    let langFromUrl = getParamFromUrl(ParamFromUrl.LANG),
        langBrowser = navigator.language;

    if (!langFromUrl) {
        if (langBrowser !== LanguageSymbol.EN_GB && langBrowser !== LanguageSymbol.DE_DE) {
            return LanguageSymbol.DE_DE;
        } else {
            return getLanguageSymbolEnumByString(langBrowser);
        }
    }

    if (decodeURIComponent(langFromUrl!.replace(/\+/g, ' ')) !== LanguageSymbol.EN_GB
        && decodeURIComponent(langFromUrl.replace(/\+/g, ' ')) !== LanguageSymbol.DE_DE) {
        return LanguageSymbol.DE_DE;
    }

    return getLanguageSymbolEnumByString(decodeURIComponent(langFromUrl.replace(/\+/g, ' ')));
}

export function transformYear(year: string): string {
    if (year.length !== 4) {
        if (year.length === 2) {
            year =
                (new Date(Date.now()).getFullYear() % 100 - +year >= 0 ? '20' : '19') + year;
        } else {
            return '';
        }
    }
    return year;
}

export function isoHttpRequestToPromise(
    options: RequestOptions,
    callbackfn: (response: ResponseHttp) => void
): Promise<{}> {
    return new Promise(
        (resolve) => {
            http.request(
                options,
                (response: ResponseHttp) => {
                    callbackfn(response);
                    resolve();
                }
            );
        }
    );
}

// Function is mark field as "with error" and return true
// If response not contains field name or field doesn't not exist, function will return false
export function setFieldError(response: ResponseHttp) {
    if (response.text.toString() === '') {
        return false;
    }
    let fieldId = JSON.parse(response.text.toString()).field_id,
        field = document.getElementById(fieldId);
    if (!isUndefined(fieldId) && !isNull(field)) {
        field.classList.add('input-invalid');
        field.classList.remove('input-valid');
        return true;
    } else {
        return false;
    }
}

// this function is workaround because in TypeScript we can't use reverse-mapping for string enums
export function getLanguageSymbolEnumByString(language: string): LanguageSymbol {
    let languageMapper = {
        de: LanguageSymbol.DE_DE,
        en: LanguageSymbol.EN_GB
    };

    if (language in languageMapper) {
        return languageMapper[language];
    } else {
        return LanguageSymbol.DE_DE;
    }
}

/**
 *  Exit Smart Checkout by sending a request to custom endpoint ("/finish" by default), which cleans up everything
 *  and posts a message to the parent window which contains the iframe
 *  @param data  Data for the request to custom endpoint
 *  @param redirectToThePage
 *  @param viewMode
 *  @param endpoint
 *  @param loadingFunction  During waiting for response user should see loader instead of freezed (nothing happen) view
 */
export function exitSmartCheckout(
    redirectToThePage: string | null,
    data: any = {},
    viewMode: ViewMode = ViewMode.LAYER,
    endpoint: string = '/finish',
    loadingFunction?: (isLoading: boolean, isDelayed: boolean) => void
) {
    let requestOptions: RequestOptions = {
        url: apiPath + endpoint,
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        withCredentials: true,
        data: data
    };
    if (loadingFunction) {
        loadingFunction(true, false);
    }
    http.request(
        requestOptions,
        () => {
            exitSmartCheckoutDirectly(viewMode, redirectToThePage);
        }
    );
}

// just close Smartcheckout wizard and go to URL
export function exitSmartCheckoutDirectly(view: ViewMode, url: string | null) {
    switch (view) {
        case ViewMode.LAYER:
            if (url !== null && url !== '') {
                parent.window.postMessage({redirectToThePage: url}, '*');
            } else {
                parent.window.postMessage('reloadPage', '*');
            }
            break;
        case ViewMode.FULLSCREEN:
            window.location.replace(url || '');
            break;
        default:
            break;
    }
}

// this function check only IBAN syntax without checksum control
export function isIBANsyntaxCorrect(iban: string): boolean {
    if (iban === '') {
        return false;
    } else {
        let IBANcodesWithRegex = {
                'AD': '\d{10}[[:alnum:]]{12}',
                'AE': '\d{21}',
                'AL': '\d{10}[[:alnum:]]{16}',
                'AT': '\d{18}',
                'AZ': '\d{2}[[:alpha:]]{4}[[:alnum:]]{20}',
                'BA': '\d{18}',
                'BE': '\d{14}',
                'BG': '\d{2}[[:alpha:]]{4}\d{6}[[:alnum:]]{8}',
                'BH': '\d{2}[[:alpha:]]{4}[[:alnum:]]{14}',
                'BR': '\d{25}[[:alpha:]][[:alnum:]]',
                'CH': '\d{7}[0-9A-Z]{12}',
                'CR': '\d{19}',
                'CY': '\d{10}[[:alnum:]]{16}',
                'CZ': '\d{22}',
                'DE': '\d{20}',
                'DK': '\d{16}',
                'DO': '\d{2}[[:alnum:]]{4}\d{20}',
                'EE': '\d{18}',
                'ES': '\d{22}',
                'FI': '\d{16}',
                'FR': '\d{12}[[:alnum:]]{11}\d{2}',
                'FO': '\d{16}',
                'GB': '\d{2}[[:alpha:]]{4}\d{14}',
                'GE': '\d{2}[[:alpha:]]{2}\d{16}',
                'GI': '\d{2}[[:alpha:]]{4}[[:alnum:]]{15}',
                'GL': '\d{16}',
                'GR': '\d{9}[[:alnum:]]{16}',
                'GT': '\d{2}[[:alnum:]]{12}[[:alnum:]]{12}',
                'HR': '\d{19}',
                'HU': '\d{26}',
                'IE': '\d{2}[[:alpha:]]{4}\d{14}',
                'IL': '\d{21}',
                'IS': '\d{24}',
                'IT': '\d{2}[[:alpha:]]\d{10}[[:alnum:]]{12}',
                'JO': '\d{2}[[:alpha:]]{4}\d{4}[[:alnum:]]{18}',
                'KW': '\d{2}[[:alpha:]]{4}[[:alnum:]]{22}',
                'KZ': '\d{5}[[:alnum:]]{13}',
                'LB': '\d{6}[[:alnum:]]{20}',
                'LI': '\d{7}[[:alnum:]]{12}',
                'LT': '\d{18}',
                'LU': '\d{5}[[:alnum:]]{13}',
                'LV': '\d{2}[[:alpha:]]{4}[[:alnum:]]{13}',
                'MC': '\d{12}[0-9A-Z]{11}[0-9]{2}',
                'MD': '\d{2}[[:alnum:]]{20}',
                'ME': '\d{20}',
                'MK': '\d{5}[[:alnum:]]{10}\d{2}',
                'MR': '13\d{23}',
                'MT': '\d{2}[[:alpha:]]{4}\d{5}[[:alnum:]]{18}',
                'MU': '\d{2}[[:alpha:]]{4}\d{19}[[:alpha:]]{2}[[:alpha:]]',
                'NL': '\d{2}[[:alpha:]]{4}\d{10}',
                'NO': '\d{13}',
                'PK': '\d{2}[[:alpha:]]{4}[[:alnum:]]{16}',
                'PL': '\d{26}',
                'PS': '\d{2}[[:alpha:]]{4}[[:alnum:]]{21}',
                'PT': '50\d{21}',
                'QA': '\d{2}[[:alpha:]]{4}[[:alnum:]]{21}',
                'RO': '\d{2}[[:alpha:]]{4}[[:alnum:]]{16}',
                'RS': '\d{20}',
                'SA': '\d{4}[[:alnum:]]{18}',
                'SE': '\d{22}',
                'SI': '\d{17}',
                'SK': '\d{22}',
                'SM': '\d{2}[A-Z][0-9]{10}[0-9A-Z]{12}',
                'TN': '59\d{20}',
                'TR': '\d{7}[[:alnum:]]{17}',
                'VG': '\d{2}[[:alpha:]]{4}\d{16}'
            },
            prefix = iban.substring(0, 2).toUpperCase(),
            numbers = iban.substring(2, iban.length),
            regex;
        if (IBANcodesWithRegex[prefix] === undefined) {
            return false;
        } else {
            regex = new RegExp(IBANcodesWithRegex[prefix].replace('\d', '\\d'), 'i');
            return numbers.match(regex) !== null;
        }
    }
}

// IE11 does not support Array.find() function
// Workaround from:
// https://developer.mozilla.org/pl/docs/Web/JavaScript/Referencje/Obiekty/Array/find
export function ArrayFindPolyfill() {
    if (!Array.prototype.find) {
        Array.prototype.find = function (
            predicate: (value: any, index: number, obj: any[]) => boolean,
            thisArg?: any
        ): any | undefined {
            let list, length, value, i;
            if (this == null) {
                throw new TypeError('Array.prototype.find called on null or undefined');
            }
            list = Object(this);
            length = list.length >>> 0;

            for (i = 0; i < length; i++) {
                value = list[i];
                if (predicate.call(thisArg, value, i, list)) {
                    return value;
                }
            }
            return undefined;
        };
    }
}

export function findIndexPolyfill() {
    // https://tc39.github.io/ecma262/#sec-array.prototype.findindex
    if (!Array.prototype.findIndex) {
        Object.defineProperty(Array.prototype, 'findIndex', {
            value: function (predicate: any) {
                // 1. Let O be ? ToObject(this value).
                if (this == null) {
                    throw new TypeError('"this" is null or not defined');
                }

                var o = Object(this);

                // 2. Let len be ? ToLength(? Get(O, "length")).
                var len = o.length >>> 0;

                // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                if (typeof predicate !== 'function') {
                    throw new TypeError('predicate must be a function');
                }

                // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                var thisArg = arguments[1];

                // 5. Let k be 0.
                var k = 0;

                // 6. Repeat, while k < len
                while (k < len) {
                    // a. Let Pk be ! ToString(k).
                    // b. Let kValue be ? Get(O, Pk).
                    // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                    // d. If testResult is true, return k.
                    var kValue = o[k];
                    if (predicate.call(thisArg, kValue, k, o)) {
                        return k;
                    }
                    // e. Increase k by 1.
                    k++;
                }

                // 7. Return -1.
                return -1;
            },
            configurable: true,
            writable: true
        });
    }
}

export function closestPolyfill() {
    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector ||
            Element.prototype.webkitMatchesSelector;
    }

    if (!Element.prototype.closest) {
        Element.prototype.closest = function (s: any) {
            var el = this;

            do {
                if (el.matches(s)) return el;
                el = (el.parentElement || el.parentNode) as Element;
            } while (el !== null && el.nodeType === 1);
            return null;
        };
    }
}

export function assignPolyfill() {
    if (typeof Object.assign != 'function') {
        Object.assign = function (target: any, varArgs: any) { // .length of function is 2
            'use strict';
            if (target == null) { // TypeError if undefined or null
                throw new TypeError('Cannot convert undefined or null to object');
            }

            var to = Object(target);

            for (var index = 1; index < arguments.length; index++) {
                var nextSource = arguments[index];

                if (nextSource != null) { // Skip over if undefined or null
                    for (var nextKey in nextSource) {
                        // Avoid bugs when hasOwnProperty is shadowed
                        if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                            to[nextKey] = nextSource[nextKey];
                        }
                    }
                }
            }
            return to;
        };
    }
}

export function includesPolyfill() {
    if (!Array.prototype.includes) {
        Array.prototype.includes = function (searchElement /*, fromIndex*/) {
            'use strict';
            var O = Object(this);
            var len = parseInt(O.length) || 0;
            if (len === 0) {
                return false;
            }
            var n = parseInt(arguments[1]) || 0;
            var k;
            if (n >= 0) {
                k = n;
            } else {
                k = len + n;
                if (k < 0) {
                    k = 0;
                }
            }
            var currentElement;
            while (k < len) {
                currentElement = O[k];
                if (searchElement === currentElement ||
                    (searchElement !== searchElement && currentElement !== currentElement)) { // NaN !== NaN
                    return true;
                }
                k++;
            }
            return false;
        };
    }
}

export function showDelayedText(text: string) {
    return text === '' ? <Spinner/> : text;
}

export function parseDate(date: HTMLInputElementDecorator): string[] {
    const separators: string[] = ['/', '.', '-'];

    for (let index in separators) {
        if (date.getField().value.indexOf(separators[index]) !== -1) {
            const parsedDate: string[] = date.getField().value.split(separators[index]);

            return separators[index] === '-' ? parsedDate.reverse() : parsedDate;
        }
    }
    return [];
}

export function getParamFromUrl(name: string): string | null {
    var url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function startPayPalPayment(props: CommonProps, flowType: PayPalFlowType) {
    props.showMessageBox(false);
    props.setLoading(true, false);
    props.callBackend(
        '/payment-method',
        () => {
            props.callBackend(
                '/prepare',
                (response: ResponseHttp) => {
                    let urls = JSON.parse(response.text);
                    if (!isNullOrUndefined(urls.iframe_url)) {
                        window.top.location.href = urls.iframe_url;
                        // set last visited page to PayPal checkout page
                        props.callBackend(
                            '/stage',
                            () => {
                                return;
                            },
                            'POST',
                            {oldStage: props.stageData.stage, newStage: AppStage.STAGE_PAYPAL_CHECKOUT}
                        );

                    } else {
                        props.showMessageBox(true, 'pay_pal_express_start_not_started', true);
                    }
                },
                'POST',
                {
                    mode: props.wizardFlow === WizardFlow.PAYMENT ? PayPalMode.MODE_PAYMENT : PayPalMode.MODE_CHECKOUT,
                    flowType: flowType,
                },
                () => {
                    props.setLoading(false);
                    props.showMessageBox(true, 'pay_pal_express_start_not_started', true);
                }
            );
        },
        'POST',
        {type: PaymentMethod.PAYPAL}
    );

}

export function getEnumValues(enumObject: Object) {
    var values = Object.keys(enumObject).map(function (e) {
        return enumObject[e]
    })
    return values;
}

export function getParamFromUrlOrSetDefault(paramFromUrl: ParamFromUrl, enumType: any, defaultValue: any) {
    let urlParam = getParamFromUrl(paramFromUrl);
    let enumKeyByValue = getEnumKeyFromValue(enumType, urlParam);
    let value;
    if (isNullOrUndefined(urlParam) || urlParam === '' || isNull(enumKeyByValue)) {
        value = enumType[getEnumKeyFromValue(enumType, defaultValue) || ''];
    } else {
        value = enumType[enumKeyByValue];
    }
    setDataAttrInBody(paramFromUrl, value);
    return value;
}

export function getEnumKeyFromValue(enumType: any, value: string | null): any /* enumType */ | null {
    return Object.keys(enumType).find((key) => enumType[key] === value) || null;
}

export const paymentWindowBasedOnFlow = {
    [WizardFlow.CHECKOUT]: {
        [PaymentMethod.SECUCARD]: PaymentWindow.WINDOW_SECUCARD,
        [PaymentMethod.DIRECT_DEBIT]: PaymentWindow.WINDOW_DIRECT_DEBIT,
        [PaymentMethod.CREDIT_CARD]: PaymentWindow.WINDOW_CREDIT_CARD,
        [PaymentMethod.SOFORT]: {stage: AppStage.STAGE_ERROR},
    },
    [WizardFlow.PAYMENT]: {
        [PaymentMethod.PAY_IN_ADVANCE]: PaymentWindow.WINDOW_PAY_IN_ADVANCE,
        [PaymentMethod.INVOICE]: PaymentWindow.WINDOW_PAY_ON_INVOICE,
        [PaymentMethod.DIRECT_DEBIT]: PaymentWindow.WINDOW_DIRECT_DEBIT,
        [PaymentMethod.CREDIT_CARD]: PaymentWindow.WINDOW_CREDIT_CARD
    }
};

export function setDataAttrInBody(paramName: string, value: string | null) {
    document.body.setAttribute(ParamInAttr.PREFIX + paramName.toLowerCase(), (value || '').toLowerCase());
}

export function handleKeyboardEvent(event: KeyboardEvent) {
    let element = event.target as HTMLElement;
    if (event.code === 'Enter') {
        element.click();
    }
}

// Go to success page
// without any action
// without any buttons
export function goToSuccessPage(props: CommonProps) {
    props.setLoading(false);
    props.setButton(() => {
        return;
    }, 'next_step_button', false, false, true);
    props.setButton(() => {
        return;
    }, 'next_step_button', false, false, true, MainButtonType.CANCEL);
    props.setStageWithEmptySteps(AppStage.STAGE_SUCCESS);
}

export function getPrimaryColor(props: CommonProps): string {
    let sessionData = props.sessionData;
    let primaryColour: string | null;
    if (sessionData && (primaryColour = sessionData.payment_wizard_options.primary_colour)) {
        return primaryColour;
    } else {
        return '';
    }
}

export function createHeadlineElement(props: CommonProps, text: string, additionalClasses: string = ''): JSX.Element {
    let sessionData = props.sessionData;
    let primaryColour: string = getPrimaryColor(props);
    let computedClass = "headline-theme" + (additionalClasses ? " " + additionalClasses : "");
    if (sessionData && primaryColour) {
        return <h2 className={computedClass} style={"color: " + primaryColour + " !important;"}>
            {text}
        </h2>;
    } else {
        return <h2 className={computedClass}>
            {text}
        </h2>;
    }
}

export function lightenDarkenColor(color: string, value: number) {

    var useHashChar = false;

    if (color[0] == "#") {
        color = color.slice(1);
        useHashChar = true;
    }

    var num = parseInt(color, 16);

    var r = (num >> 16) + value;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00FF) + value;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000FF) + value;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (useHashChar ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

export function hexToRgb(color: string): { r: number, g: number, b: number } {
    return {
        r: parseInt(color.substr(1, 2), 16),
        g: parseInt(color.substr(3, 2), 16),
        b: parseInt(color.substr(5, 2), 16)
    }
}

export function isIE11() {
    return !!(window as any).MSInputMethodContext && !!(document as any).documentMode;
}

export function isSafari() {
    var isSafariBrowser = !!navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent && !navigator.userAgent.match('CriOS');

    return isSafariBrowser;
}

export function getOwnerFromSessionData(data: SessionData) {
    return data.transaction.customer.contact.forename + ' ' + data.transaction.customer.contact.surname;
}

export function getCountryTranslationByIsoCode(isoSymbol: string, lang: LanguageSymbol): string {
    const iso = isoSymbol.toUpperCase();

    const countryTranslation = {
        "AF": {[LanguageSymbol.EN_GB]: "Afghanistan", [LanguageSymbol.DE_DE]: "Afghanistan"},
        "AX": {[LanguageSymbol.EN_GB]: "Åland Islands", [LanguageSymbol.DE_DE]: "Åland"},
        "AL": {[LanguageSymbol.EN_GB]: "Albania", [LanguageSymbol.DE_DE]: "Albanien"},
        "DZ": {[LanguageSymbol.EN_GB]: "Algeria", [LanguageSymbol.DE_DE]: "Algerien"},
        "AS": {[LanguageSymbol.EN_GB]: "American Samoa", [LanguageSymbol.DE_DE]: "Amerikanisch-Samoa"},
        "AD": {[LanguageSymbol.EN_GB]: "Andorra", [LanguageSymbol.DE_DE]: "Andorra"},
        "AO": {[LanguageSymbol.EN_GB]: "Angola", [LanguageSymbol.DE_DE]: "Angola"},
        "AI": {[LanguageSymbol.EN_GB]: "Anguilla", [LanguageSymbol.DE_DE]: "Anguilla"},
        "AQ": {
            [LanguageSymbol.EN_GB]: "Antarctica",
            [LanguageSymbol.DE_DE]: "Antarktis (Sonderstatus durch Antarktisvertrag)"
        },
        "AG": {[LanguageSymbol.EN_GB]: "Antigua and Barbuda", [LanguageSymbol.DE_DE]: "Antigua und Barbuda"},
        "AR": {[LanguageSymbol.EN_GB]: "Argentina", [LanguageSymbol.DE_DE]: "Argentinien"},
        "AM": {[LanguageSymbol.EN_GB]: "Armenia", [LanguageSymbol.DE_DE]: "Armenien"},
        "AW": {[LanguageSymbol.EN_GB]: "Aruba", [LanguageSymbol.DE_DE]: "Aruba"},
        "AU": {[LanguageSymbol.EN_GB]: "Australia", [LanguageSymbol.DE_DE]: "Australien"},
        "AT": {[LanguageSymbol.EN_GB]: "Austria", [LanguageSymbol.DE_DE]: "Österreich"},
        "AZ": {[LanguageSymbol.EN_GB]: "Azerbaijan", [LanguageSymbol.DE_DE]: "Aserbaidschan"},
        "BS": {[LanguageSymbol.EN_GB]: "Bahamas", [LanguageSymbol.DE_DE]: "Bahamas"},
        "BH": {[LanguageSymbol.EN_GB]: "Bahrain", [LanguageSymbol.DE_DE]: "Bahrain"},
        "BD": {[LanguageSymbol.EN_GB]: "Bangladesh", [LanguageSymbol.DE_DE]: "Bangladesch"},
        "BB": {[LanguageSymbol.EN_GB]: "Barbados", [LanguageSymbol.DE_DE]: "Barbados"},
        "BY": {[LanguageSymbol.EN_GB]: "Belarus", [LanguageSymbol.DE_DE]: "Weißrussland (Belarus)"},
        "BE": {[LanguageSymbol.EN_GB]: "Belgium", [LanguageSymbol.DE_DE]: "Belgien"},
        "BZ": {[LanguageSymbol.EN_GB]: "Belize", [LanguageSymbol.DE_DE]: "Belize"},
        "BJ": {[LanguageSymbol.EN_GB]: "Benin", [LanguageSymbol.DE_DE]: "Benin"},
        "BM": {[LanguageSymbol.EN_GB]: "Bermuda", [LanguageSymbol.DE_DE]: "Bermuda"},
        "BT": {[LanguageSymbol.EN_GB]: "Bhutan", [LanguageSymbol.DE_DE]: "Bhutan"},
        "BO": {[LanguageSymbol.EN_GB]: "Bolivia (Plurinational State of)", [LanguageSymbol.DE_DE]: "Bolivien"},
        "BQ": {
            [LanguageSymbol.EN_GB]: "Bonaire, Sint Eustatius and Saba",
            [LanguageSymbol.DE_DE]: "Bonaire, Saba, Sint Eustatius"
        },
        "BA": {[LanguageSymbol.EN_GB]: "Bosnia and Herzegovina", [LanguageSymbol.DE_DE]: "Bosnien und Herzegowina"},
        "BW": {[LanguageSymbol.EN_GB]: "Botswana", [LanguageSymbol.DE_DE]: "Botswana"},
        "BV": {[LanguageSymbol.EN_GB]: "Bouvet Island", [LanguageSymbol.DE_DE]: "Bouvetinsel"},
        "BR": {[LanguageSymbol.EN_GB]: "Brazil", [LanguageSymbol.DE_DE]: "Brasilien"},
        "IO": {
            [LanguageSymbol.EN_GB]: "British Indian Ocean Territory",
            [LanguageSymbol.DE_DE]: "Britisches Territorium im Indischen Ozean"
        },
        "BN": {[LanguageSymbol.EN_GB]: "Brunei Darussalam", [LanguageSymbol.DE_DE]: "Brunei"},
        "BG": {[LanguageSymbol.EN_GB]: "Bulgaria", [LanguageSymbol.DE_DE]: "Bulgarien"},
        "BF": {[LanguageSymbol.EN_GB]: "Burkina Faso", [LanguageSymbol.DE_DE]: "Burkina Faso"},
        "BI": {[LanguageSymbol.EN_GB]: "Burundi", [LanguageSymbol.DE_DE]: "Burundi"},
        "CV": {[LanguageSymbol.EN_GB]: "Cabo Verde", [LanguageSymbol.DE_DE]: "Kap Verde (Cabo Verde)"},
        "KH": {[LanguageSymbol.EN_GB]: "Cambodia", [LanguageSymbol.DE_DE]: "Kambodscha"},
        "CM": {[LanguageSymbol.EN_GB]: "Cameroon", [LanguageSymbol.DE_DE]: "Kamerun"},
        "CA": {[LanguageSymbol.EN_GB]: "Canada", [LanguageSymbol.DE_DE]: "Kanada"},
        "KY": {[LanguageSymbol.EN_GB]: "Cayman Islands", [LanguageSymbol.DE_DE]: "Cayman Islands (Kaimaninseln)"},
        "CF": {
            [LanguageSymbol.EN_GB]: "Central African Republic",
            [LanguageSymbol.DE_DE]: "Zentralafrikanische Republik"
        },
        "TD": {[LanguageSymbol.EN_GB]: "Chad", [LanguageSymbol.DE_DE]: "Tschad"},
        "CL": {[LanguageSymbol.EN_GB]: "Chile", [LanguageSymbol.DE_DE]: "Chile"},
        "CN": {[LanguageSymbol.EN_GB]: "China", [LanguageSymbol.DE_DE]: "Volksrepublik China"},
        "CX": {[LanguageSymbol.EN_GB]: "Christmas Island", [LanguageSymbol.DE_DE]: "Weihnachtsinsel"},
        "CC": {[LanguageSymbol.EN_GB]: "Cocos (Keeling) Islands", [LanguageSymbol.DE_DE]: "Kokosinseln"},
        "CO": {[LanguageSymbol.EN_GB]: "Colombia", [LanguageSymbol.DE_DE]: "Kolumbien"},
        "KM": {[LanguageSymbol.EN_GB]: "Comoros", [LanguageSymbol.DE_DE]: "Komoren"},
        "CG": {[LanguageSymbol.EN_GB]: "Congo", [LanguageSymbol.DE_DE]: "Republik Kongo (ehem. Kongo-Brazzaville)"},
        "CD": {
            [LanguageSymbol.EN_GB]: "Congo, Democratic Republic of the",
            [LanguageSymbol.DE_DE]: "Demokratische Republik Kongo (ehem. Zaire)"
        },
        "CK": {[LanguageSymbol.EN_GB]: "Cook Islands", [LanguageSymbol.DE_DE]: "Cookinseln"},
        "CR": {[LanguageSymbol.EN_GB]: "Costa Rica", [LanguageSymbol.DE_DE]: "Costa Rica"},
        "CI": {[LanguageSymbol.EN_GB]: "Côte d'Ivoire", [LanguageSymbol.DE_DE]: "Elfenbeinküste (Côte d’Ivoire)"},
        "HR": {[LanguageSymbol.EN_GB]: "Croatia", [LanguageSymbol.DE_DE]: "Kroatien"},
        "CU": {[LanguageSymbol.EN_GB]: "Cuba", [LanguageSymbol.DE_DE]: "Kuba"},
        "CW": {[LanguageSymbol.EN_GB]: "Curaçao", [LanguageSymbol.DE_DE]: "Curaçao"},
        "CY": {[LanguageSymbol.EN_GB]: "Cyprus", [LanguageSymbol.DE_DE]: "Zypern"},
        "CZ": {[LanguageSymbol.EN_GB]: "Czechia", [LanguageSymbol.DE_DE]: "Tschechien"},
        "DK": {[LanguageSymbol.EN_GB]: "Denmark", [LanguageSymbol.DE_DE]: "Dänemark"},
        "DJ": {[LanguageSymbol.EN_GB]: "Djibouti", [LanguageSymbol.DE_DE]: "Dschibuti"},
        "DM": {[LanguageSymbol.EN_GB]: "Dominica", [LanguageSymbol.DE_DE]: "Dominica"},
        "DO": {[LanguageSymbol.EN_GB]: "Dominican Republic", [LanguageSymbol.DE_DE]: "Dominikanische Republik"},
        "EC": {[LanguageSymbol.EN_GB]: "Ecuador", [LanguageSymbol.DE_DE]: "Ecuador"},
        "EG": {[LanguageSymbol.EN_GB]: "Egypt", [LanguageSymbol.DE_DE]: "Ägypten"},
        "SV": {[LanguageSymbol.EN_GB]: "El Salvador", [LanguageSymbol.DE_DE]: "El Salvador"},
        "GQ": {[LanguageSymbol.EN_GB]: "Equatorial Guinea", [LanguageSymbol.DE_DE]: "Äquatorialguinea"},
        "ER": {[LanguageSymbol.EN_GB]: "Eritrea", [LanguageSymbol.DE_DE]: "Eritrea"},
        "EE": {[LanguageSymbol.EN_GB]: "Estonia", [LanguageSymbol.DE_DE]: "Estland"},
        "SZ": {[LanguageSymbol.EN_GB]: "Eswatini", [LanguageSymbol.DE_DE]: "Eswatini[3]"},
        "ET": {[LanguageSymbol.EN_GB]: "Ethiopia", [LanguageSymbol.DE_DE]: "Äthiopien"},
        "FK": {[LanguageSymbol.EN_GB]: "Falkland Islands (Malvinas)", [LanguageSymbol.DE_DE]: "Falklandinseln"},
        "FO": {[LanguageSymbol.EN_GB]: "Faroe Islands", [LanguageSymbol.DE_DE]: "Färöer"},
        "FJ": {[LanguageSymbol.EN_GB]: "Fiji", [LanguageSymbol.DE_DE]: "Fidschi"},
        "FI": {[LanguageSymbol.EN_GB]: "Finland", [LanguageSymbol.DE_DE]: "Finnland"},
        "FR": {[LanguageSymbol.EN_GB]: "France", [LanguageSymbol.DE_DE]: "Frankreich"},
        "GF": {[LanguageSymbol.EN_GB]: "French Guiana", [LanguageSymbol.DE_DE]: "Französisch-Guayana"},
        "PF": {[LanguageSymbol.EN_GB]: "French Polynesia", [LanguageSymbol.DE_DE]: "Französisch-Polynesien"},
        "TF": {
            [LanguageSymbol.EN_GB]: "French Southern Territories",
            [LanguageSymbol.DE_DE]: "Französische Süd- und Antarktisgebiete"
        },
        "GA": {[LanguageSymbol.EN_GB]: "Gabon", [LanguageSymbol.DE_DE]: "Gabun"},
        "GM": {[LanguageSymbol.EN_GB]: "Gambia", [LanguageSymbol.DE_DE]: "Gambia"},
        "GE": {[LanguageSymbol.EN_GB]: "Georgia", [LanguageSymbol.DE_DE]: "Georgien"},
        "DE": {[LanguageSymbol.EN_GB]: "Germany", [LanguageSymbol.DE_DE]: "Deutschland"},
        "GH": {[LanguageSymbol.EN_GB]: "Ghana", [LanguageSymbol.DE_DE]: "Ghana"},
        "GI": {[LanguageSymbol.EN_GB]: "Gibraltar", [LanguageSymbol.DE_DE]: "Gibraltar"},
        "GR": {[LanguageSymbol.EN_GB]: "Greece", [LanguageSymbol.DE_DE]: "Griechenland"},
        "GL": {[LanguageSymbol.EN_GB]: "Greenland", [LanguageSymbol.DE_DE]: "Grönland"},
        "GD": {[LanguageSymbol.EN_GB]: "Grenada", [LanguageSymbol.DE_DE]: "Grenada"},
        "GP": {[LanguageSymbol.EN_GB]: "Guadeloupe", [LanguageSymbol.DE_DE]: "Guadeloupe"},
        "GU": {[LanguageSymbol.EN_GB]: "Guam", [LanguageSymbol.DE_DE]: "Guam"},
        "GT": {[LanguageSymbol.EN_GB]: "Guatemala", [LanguageSymbol.DE_DE]: "Guatemala"},
        "GG": {[LanguageSymbol.EN_GB]: "Guernsey", [LanguageSymbol.DE_DE]: "Guernsey (Kanalinsel)"},
        "GN": {[LanguageSymbol.EN_GB]: "Guinea", [LanguageSymbol.DE_DE]: "Guinea"},
        "GW": {[LanguageSymbol.EN_GB]: "Guinea-Bissau", [LanguageSymbol.DE_DE]: "Guinea-Bissau"},
        "GY": {[LanguageSymbol.EN_GB]: "Guyana", [LanguageSymbol.DE_DE]: "Guyana"},
        "HT": {[LanguageSymbol.EN_GB]: "Haiti", [LanguageSymbol.DE_DE]: "Haiti"},
        "HM": {
            [LanguageSymbol.EN_GB]: "Heard Island and McDonald Islands",
            [LanguageSymbol.DE_DE]: "Heard und McDonaldinseln"
        },
        "VA": {[LanguageSymbol.EN_GB]: "Holy See", [LanguageSymbol.DE_DE]: "Vatikanstadt"},
        "HN": {[LanguageSymbol.EN_GB]: "Honduras", [LanguageSymbol.DE_DE]: "Honduras"},
        "HK": {[LanguageSymbol.EN_GB]: "Hong Kong", [LanguageSymbol.DE_DE]: "Hongkong"},
        "HU": {[LanguageSymbol.EN_GB]: "Hungary", [LanguageSymbol.DE_DE]: "Ungarn"},
        "IS": {[LanguageSymbol.EN_GB]: "Iceland", [LanguageSymbol.DE_DE]: "Island"},
        "IN": {[LanguageSymbol.EN_GB]: "India", [LanguageSymbol.DE_DE]: "Indien"},
        "ID": {[LanguageSymbol.EN_GB]: "Indonesia", [LanguageSymbol.DE_DE]: "Indonesien"},
        "IR": {[LanguageSymbol.EN_GB]: "Iran (Islamic Republic of)", [LanguageSymbol.DE_DE]: "Iran"},
        "IQ": {[LanguageSymbol.EN_GB]: "Iraq", [LanguageSymbol.DE_DE]: "Irak"},
        "IE": {[LanguageSymbol.EN_GB]: "Ireland", [LanguageSymbol.DE_DE]: "Irland"},
        "IM": {[LanguageSymbol.EN_GB]: "Isle of Man", [LanguageSymbol.DE_DE]: "Isle of Man"},
        "IL": {[LanguageSymbol.EN_GB]: "Israel", [LanguageSymbol.DE_DE]: "Israel"},
        "IT": {[LanguageSymbol.EN_GB]: "Italy", [LanguageSymbol.DE_DE]: "Italien"},
        "JM": {[LanguageSymbol.EN_GB]: "Jamaica", [LanguageSymbol.DE_DE]: "Jamaika"},
        "JP": {[LanguageSymbol.EN_GB]: "Japan", [LanguageSymbol.DE_DE]: "Japan"},
        "JE": {[LanguageSymbol.EN_GB]: "Jersey", [LanguageSymbol.DE_DE]: "Jersey (Kanalinsel)"},
        "JO": {[LanguageSymbol.EN_GB]: "Jordan", [LanguageSymbol.DE_DE]: "Jordanien"},
        "KZ": {[LanguageSymbol.EN_GB]: "Kazakhstan", [LanguageSymbol.DE_DE]: "Kasachstan"},
        "KE": {[LanguageSymbol.EN_GB]: "Kenya", [LanguageSymbol.DE_DE]: "Kenia"},
        "KI": {[LanguageSymbol.EN_GB]: "Kiribati", [LanguageSymbol.DE_DE]: "Kiribati"},
        "KP": {
            [LanguageSymbol.EN_GB]: "Korea (Democratic People's Republic of)",
            [LanguageSymbol.DE_DE]: "Nordkorea"
        },
        "KR": {[LanguageSymbol.EN_GB]: "Korea, Republic of", [LanguageSymbol.DE_DE]: "Südkorea"},
        "KW": {[LanguageSymbol.EN_GB]: "Kuwait", [LanguageSymbol.DE_DE]: "Kuwait"},
        "KG": {[LanguageSymbol.EN_GB]: "Kyrgyzstan", [LanguageSymbol.DE_DE]: "Kirgisistan"},
        "LA": {[LanguageSymbol.EN_GB]: "Lao People's Democratic Republic", [LanguageSymbol.DE_DE]: "Laos"},
        "LV": {[LanguageSymbol.EN_GB]: "Latvia", [LanguageSymbol.DE_DE]: "Lettland"},
        "LB": {[LanguageSymbol.EN_GB]: "Lebanon", [LanguageSymbol.DE_DE]: "Libanon"},
        "LS": {[LanguageSymbol.EN_GB]: "Lesotho", [LanguageSymbol.DE_DE]: "Lesotho"},
        "LR": {[LanguageSymbol.EN_GB]: "Liberia", [LanguageSymbol.DE_DE]: "Liberia"},
        "LY": {[LanguageSymbol.EN_GB]: "Libya", [LanguageSymbol.DE_DE]: "Libyen"},
        "LI": {[LanguageSymbol.EN_GB]: "Liechtenstein", [LanguageSymbol.DE_DE]: "Liechtenstein"},
        "LT": {[LanguageSymbol.EN_GB]: "Lithuania", [LanguageSymbol.DE_DE]: "Litauen"},
        "LU": {[LanguageSymbol.EN_GB]: "Luxembourg", [LanguageSymbol.DE_DE]: "Luxemburg"},
        "MO": {[LanguageSymbol.EN_GB]: "Macao", [LanguageSymbol.DE_DE]: "Macau"},
        "MG": {[LanguageSymbol.EN_GB]: "Madagascar", [LanguageSymbol.DE_DE]: "Madagaskar"},
        "MW": {[LanguageSymbol.EN_GB]: "Malawi", [LanguageSymbol.DE_DE]: "Malawi"},
        "MY": {[LanguageSymbol.EN_GB]: "Malaysia", [LanguageSymbol.DE_DE]: "Malaysia"},
        "MV": {[LanguageSymbol.EN_GB]: "Maldives", [LanguageSymbol.DE_DE]: "Malediven"},
        "ML": {[LanguageSymbol.EN_GB]: "Mali", [LanguageSymbol.DE_DE]: "Mali"},
        "MT": {[LanguageSymbol.EN_GB]: "Malta", [LanguageSymbol.DE_DE]: "Malta"},
        "MH": {[LanguageSymbol.EN_GB]: "Marshall Islands", [LanguageSymbol.DE_DE]: "Marshallinseln"},
        "MQ": {[LanguageSymbol.EN_GB]: "Martinique", [LanguageSymbol.DE_DE]: "Martinique"},
        "MR": {[LanguageSymbol.EN_GB]: "Mauritania", [LanguageSymbol.DE_DE]: "Mauretanien"},
        "MU": {[LanguageSymbol.EN_GB]: "Mauritius", [LanguageSymbol.DE_DE]: "Mauritius"},
        "YT": {[LanguageSymbol.EN_GB]: "Mayotte", [LanguageSymbol.DE_DE]: "Mayotte"},
        "MX": {[LanguageSymbol.EN_GB]: "Mexico", [LanguageSymbol.DE_DE]: "Mexiko"},
        "FM": {[LanguageSymbol.EN_GB]: "Micronesia (Federated States of)", [LanguageSymbol.DE_DE]: "Mikronesien"},
        "MD": {[LanguageSymbol.EN_GB]: "Moldova, Republic of", [LanguageSymbol.DE_DE]: "Moldau (Moldawien)"},
        "MC": {[LanguageSymbol.EN_GB]: "Monaco", [LanguageSymbol.DE_DE]: "Monaco"},
        "MN": {[LanguageSymbol.EN_GB]: "Mongolia", [LanguageSymbol.DE_DE]: "Mongolei"},
        "ME": {[LanguageSymbol.EN_GB]: "Montenegro", [LanguageSymbol.DE_DE]: "Montenegro"},
        "MS": {[LanguageSymbol.EN_GB]: "Montserrat", [LanguageSymbol.DE_DE]: "Montserrat"},
        "MA": {[LanguageSymbol.EN_GB]: "Morocco", [LanguageSymbol.DE_DE]: "Marokko"},
        "MZ": {[LanguageSymbol.EN_GB]: "Mozambique", [LanguageSymbol.DE_DE]: "Mosambik"},
        "MM": {[LanguageSymbol.EN_GB]: "Myanmar", [LanguageSymbol.DE_DE]: "Myanmar (Burma)"},
        "NA": {[LanguageSymbol.EN_GB]: "Namibia", [LanguageSymbol.DE_DE]: "Namibia"},
        "NR": {[LanguageSymbol.EN_GB]: "Nauru", [LanguageSymbol.DE_DE]: "Nauru"},
        "NP": {[LanguageSymbol.EN_GB]: "Nepal", [LanguageSymbol.DE_DE]: "Nepal"},
        "NL": {[LanguageSymbol.EN_GB]: "Netherlands", [LanguageSymbol.DE_DE]: "Niederlande"},
        "NC": {[LanguageSymbol.EN_GB]: "New Caledonia", [LanguageSymbol.DE_DE]: "Neukaledonien"},
        "NZ": {[LanguageSymbol.EN_GB]: "New Zealand", [LanguageSymbol.DE_DE]: "Neuseeland"},
        "NI": {[LanguageSymbol.EN_GB]: "Nicaragua", [LanguageSymbol.DE_DE]: "Nicaragua"},
        "NE": {[LanguageSymbol.EN_GB]: "Niger", [LanguageSymbol.DE_DE]: "Niger"},
        "NG": {[LanguageSymbol.EN_GB]: "Nigeria", [LanguageSymbol.DE_DE]: "Nigeria"},
        "NU": {[LanguageSymbol.EN_GB]: "Niue", [LanguageSymbol.DE_DE]: "Niue"},
        "NF": {[LanguageSymbol.EN_GB]: "Norfolk Island", [LanguageSymbol.DE_DE]: "Norfolkinsel"},
        "MK": {[LanguageSymbol.EN_GB]: "North Macedonia", [LanguageSymbol.DE_DE]: "Nordmazedonien"},
        "MP": {[LanguageSymbol.EN_GB]: "Northern Mariana Islands", [LanguageSymbol.DE_DE]: "Nördliche Marianen"},
        "NO": {[LanguageSymbol.EN_GB]: "Norway", [LanguageSymbol.DE_DE]: "Norwegen"},
        "OM": {[LanguageSymbol.EN_GB]: "Oman", [LanguageSymbol.DE_DE]: "Oman"},
        "PK": {[LanguageSymbol.EN_GB]: "Pakistan", [LanguageSymbol.DE_DE]: "Pakistan"},
        "PW": {[LanguageSymbol.EN_GB]: "Palau", [LanguageSymbol.DE_DE]: "Palau"},
        "PS": {[LanguageSymbol.EN_GB]: "Palestine, State of", [LanguageSymbol.DE_DE]: "Palästina"},
        "PA": {[LanguageSymbol.EN_GB]: "Panama", [LanguageSymbol.DE_DE]: "Panama"},
        "PG": {[LanguageSymbol.EN_GB]: "Papua New Guinea", [LanguageSymbol.DE_DE]: "Papua-Neuguinea"},
        "PY": {[LanguageSymbol.EN_GB]: "Paraguay", [LanguageSymbol.DE_DE]: "Paraguay"},
        "PE": {[LanguageSymbol.EN_GB]: "Peru", [LanguageSymbol.DE_DE]: "Peru"},
        "PH": {[LanguageSymbol.EN_GB]: "Philippines", [LanguageSymbol.DE_DE]: "Philippinen"},
        "PN": {[LanguageSymbol.EN_GB]: "Pitcairn", [LanguageSymbol.DE_DE]: "Pitcairninseln"},
        "PL": {[LanguageSymbol.EN_GB]: "Poland", [LanguageSymbol.DE_DE]: "Polen"},
        "PT": {[LanguageSymbol.EN_GB]: "Portugal", [LanguageSymbol.DE_DE]: "Portugal"},
        "PR": {[LanguageSymbol.EN_GB]: "Puerto Rico", [LanguageSymbol.DE_DE]: "Puerto Rico"},
        "QA": {[LanguageSymbol.EN_GB]: "Qatar", [LanguageSymbol.DE_DE]: "Katar"},
        "RE": {[LanguageSymbol.EN_GB]: "Réunion", [LanguageSymbol.DE_DE]: "Réunion"},
        "RO": {[LanguageSymbol.EN_GB]: "Romania", [LanguageSymbol.DE_DE]: "Rumänien"},
        "RU": {[LanguageSymbol.EN_GB]: "Russian Federation", [LanguageSymbol.DE_DE]: "Russland"},
        "RW": {[LanguageSymbol.EN_GB]: "Rwanda", [LanguageSymbol.DE_DE]: "Ruanda"},
        "BL": {[LanguageSymbol.EN_GB]: "Saint Barthélemy", [LanguageSymbol.DE_DE]: "Saint-Barthélemy"},
        "SH": {
            [LanguageSymbol.EN_GB]: "Saint Helena, Ascension and Tristan da Cunha",
            [LanguageSymbol.DE_DE]: "St. Helena, Ascension und Tristan da Cunha"
        },
        "KN": {[LanguageSymbol.EN_GB]: "Saint Kitts and Nevis", [LanguageSymbol.DE_DE]: "St. Kitts und Nevis"},
        "LC": {[LanguageSymbol.EN_GB]: "Saint Lucia", [LanguageSymbol.DE_DE]: "St. Lucia"},
        "MF": {
            [LanguageSymbol.EN_GB]: "Saint Martin (French part)",
            [LanguageSymbol.DE_DE]: "Saint-Martin (französischer Teil)"
        },
        "PM": {
            [LanguageSymbol.EN_GB]: "Saint Pierre and Miquelon",
            [LanguageSymbol.DE_DE]: "Saint-Pierre und Miquelon"
        },
        "VC": {
            [LanguageSymbol.EN_GB]: "Saint Vincent and the Grenadines",
            [LanguageSymbol.DE_DE]: "St. Vincent und die Grenadinen"
        },
        "WS": {[LanguageSymbol.EN_GB]: "Samoa", [LanguageSymbol.DE_DE]: "Samoa"},
        "SM": {[LanguageSymbol.EN_GB]: "San Marino", [LanguageSymbol.DE_DE]: "San Marino"},
        "ST": {[LanguageSymbol.EN_GB]: "Sao Tome and Principe", [LanguageSymbol.DE_DE]: "São Tomé und Príncipe"},
        "SA": {[LanguageSymbol.EN_GB]: "Saudi Arabia", [LanguageSymbol.DE_DE]: "Saudi-Arabien"},
        "SN": {[LanguageSymbol.EN_GB]: "Senegal", [LanguageSymbol.DE_DE]: "Senegal"},
        "RS": {[LanguageSymbol.EN_GB]: "Serbia", [LanguageSymbol.DE_DE]: "Serbien"},
        "SC": {[LanguageSymbol.EN_GB]: "Seychelles", [LanguageSymbol.DE_DE]: "Seychellen"},
        "SL": {[LanguageSymbol.EN_GB]: "Sierra Leone", [LanguageSymbol.DE_DE]: "Sierra Leone"},
        "SG": {[LanguageSymbol.EN_GB]: "Singapore", [LanguageSymbol.DE_DE]: "Singapur"},
        "SX": {[LanguageSymbol.EN_GB]: "Sint Maarten (Dutch part)", [LanguageSymbol.DE_DE]: "Sint Maarten"},
        "SK": {[LanguageSymbol.EN_GB]: "Slovakia", [LanguageSymbol.DE_DE]: "Slowakei"},
        "SI": {[LanguageSymbol.EN_GB]: "Slovenia", [LanguageSymbol.DE_DE]: "Slowenien"},
        "SB": {[LanguageSymbol.EN_GB]: "Solomon Islands", [LanguageSymbol.DE_DE]: "Salomonen"},
        "SO": {[LanguageSymbol.EN_GB]: "Somalia", [LanguageSymbol.DE_DE]: "Somalia"},
        "ZA": {[LanguageSymbol.EN_GB]: "South Africa", [LanguageSymbol.DE_DE]: "Südafrika"},
        "GS": {
            [LanguageSymbol.EN_GB]: "South Georgia and the South Sandwich Islands",
            [LanguageSymbol.DE_DE]: "Südgeorgien und die Südlichen Sandwichinseln"
        },
        "SS": {[LanguageSymbol.EN_GB]: "South Sudan", [LanguageSymbol.DE_DE]: "Südsudan"},
        "ES": {[LanguageSymbol.EN_GB]: "Spain", [LanguageSymbol.DE_DE]: "Spanien"},
        "LK": {[LanguageSymbol.EN_GB]: "Sri Lanka", [LanguageSymbol.DE_DE]: "Sri Lanka"},
        "SD": {[LanguageSymbol.EN_GB]: "Sudan", [LanguageSymbol.DE_DE]: "Sudan"},
        "SR": {[LanguageSymbol.EN_GB]: "Suriname", [LanguageSymbol.DE_DE]: "Suriname"},
        "SJ": {
            [LanguageSymbol.EN_GB]: "Svalbard and Jan Mayen",
            [LanguageSymbol.DE_DE]: "Spitzbergen und Jan Mayen"
        },
        "SE": {[LanguageSymbol.EN_GB]: "Sweden", [LanguageSymbol.DE_DE]: "Schweden"},
        "CH": {[LanguageSymbol.EN_GB]: "Switzerland", [LanguageSymbol.DE_DE]: "Schweiz (Confoederatio Helvetica)"},
        "SY": {[LanguageSymbol.EN_GB]: "Syrian Arab Republic", [LanguageSymbol.DE_DE]: "Syrien"},
        "TW": {[LanguageSymbol.EN_GB]: "Taiwan, Province of China", [LanguageSymbol.DE_DE]: "Taiwan"},
        "TJ": {[LanguageSymbol.EN_GB]: "Tajikistan", [LanguageSymbol.DE_DE]: "Tadschikistan"},
        "TZ": {[LanguageSymbol.EN_GB]: "Tanzania, United Republic of", [LanguageSymbol.DE_DE]: "Tansania"},
        "TH": {[LanguageSymbol.EN_GB]: "Thailand", [LanguageSymbol.DE_DE]: "Thailand"},
        "TL": {[LanguageSymbol.EN_GB]: "Timor-Leste", [LanguageSymbol.DE_DE]: "Osttimor (Timor-Leste)"},
        "TG": {[LanguageSymbol.EN_GB]: "Togo", [LanguageSymbol.DE_DE]: "Togo"},
        "TK": {[LanguageSymbol.EN_GB]: "Tokelau", [LanguageSymbol.DE_DE]: "Tokelau"},
        "TO": {[LanguageSymbol.EN_GB]: "Tonga", [LanguageSymbol.DE_DE]: "Tonga"},
        "TT": {[LanguageSymbol.EN_GB]: "Trinidad and Tobago", [LanguageSymbol.DE_DE]: "Trinidad und Tobago"},
        "TN": {[LanguageSymbol.EN_GB]: "Tunisia", [LanguageSymbol.DE_DE]: "Tunesien"},
        "TR": {[LanguageSymbol.EN_GB]: "Turkey", [LanguageSymbol.DE_DE]: "Türkei"},
        "TM": {[LanguageSymbol.EN_GB]: "Turkmenistan", [LanguageSymbol.DE_DE]: "Turkmenistan"},
        "TC": {
            [LanguageSymbol.EN_GB]: "Turks and Caicos Islands",
            [LanguageSymbol.DE_DE]: "Turks- und Caicosinseln"
        },
        "TV": {[LanguageSymbol.EN_GB]: "Tuvalu", [LanguageSymbol.DE_DE]: "Tuvalu"},
        "UG": {[LanguageSymbol.EN_GB]: "Uganda", [LanguageSymbol.DE_DE]: "Uganda"},
        "UA": {[LanguageSymbol.EN_GB]: "Ukraine", [LanguageSymbol.DE_DE]: "Ukraine"},
        "AE": {
            [LanguageSymbol.EN_GB]: "United Arab Emirates",
            [LanguageSymbol.DE_DE]: "Vereinigte Arabische Emirate"
        },
        "GB": {
            [LanguageSymbol.EN_GB]: "United Kingdom of Great Britain and Northern Ireland",
            [LanguageSymbol.DE_DE]: "Vereinigtes Königreich (Großbritannien und Nordirland)"
        },
        "US": {[LanguageSymbol.EN_GB]: "United States of America", [LanguageSymbol.DE_DE]: "Vereinigte Staaten"},
        "UM": {
            [LanguageSymbol.EN_GB]: "United States Minor Outlying Islands",
            [LanguageSymbol.DE_DE]: "United States Minor Outlying Islands"
        },
        "UY": {[LanguageSymbol.EN_GB]: "Uruguay", [LanguageSymbol.DE_DE]: "Uruguay"},
        "UZ": {[LanguageSymbol.EN_GB]: "Uzbekistan", [LanguageSymbol.DE_DE]: "Usbekistan"},
        "VU": {[LanguageSymbol.EN_GB]: "Vanuatu", [LanguageSymbol.DE_DE]: "Vanuatu"},
        "VE": {[LanguageSymbol.EN_GB]: "Venezuela (Bolivarian Republic of)", [LanguageSymbol.DE_DE]: "Venezuela"},
        "VN": {[LanguageSymbol.EN_GB]: "Viet Nam", [LanguageSymbol.DE_DE]: "Vietnam"},
        "VG": {
            [LanguageSymbol.EN_GB]: "Virgin Islands (British)",
            [LanguageSymbol.DE_DE]: "Britische Jungferninseln"
        },
        "VI": {
            [LanguageSymbol.EN_GB]: "Virgin Islands (U.S.)",
            [LanguageSymbol.DE_DE]: "Amerikanische Jungferninseln"
        },
        "WF": {[LanguageSymbol.EN_GB]: "Wallis and Futuna", [LanguageSymbol.DE_DE]: "Wallis und Futuna"},
        "EH": {[LanguageSymbol.EN_GB]: "Western Sahara", [LanguageSymbol.DE_DE]: "Westsahara"},
        "YE": {[LanguageSymbol.EN_GB]: "Yemen", [LanguageSymbol.DE_DE]: "Jemen"},
        "ZM": {[LanguageSymbol.EN_GB]: "Zambia", [LanguageSymbol.DE_DE]: "Sambia"},
        "ZW": {[LanguageSymbol.EN_GB]: "Zimbabwe", [LanguageSymbol.DE_DE]: "Simbabwe"}
    }

    if(countryTranslation[iso]) {
        return countryTranslation[iso][lang];
    } else {
        console.warn('Unknown country ISO code');
        return '';

    }
}
