import {Component, h} from 'preact';
import {AppStage, Bank, CommonProps, Intent, LanguageSymbol, MainButtonType, WizardFlow,} from '../../../common/types';
import PayInAdvanceInfo from "../../success/PayInAdvanceInfo";
import AmountHelper from "../../../common/helpers/AmountHelper";
import {isNull} from "util";
import {exitSmartCheckout, exitSmartCheckoutDirectly, goToSuccessPage} from "../../../common/utils";
import PaymentMethodHeadlineWithAssigmentAndScoring
    from "../../../templates/PaymentMethodHeadlineWithAssigmentAndScoring";
import {ProjectNameAndLogo} from "../../../templates/ProjectNameAndLogo";
import {BasketOrOrderTotal} from "../../../templates/BasketOrOrderTotal";
import {PaymentHints} from "../../../templates/PaymentHints";

interface PrepaidComponentProps extends CommonProps {
    total: number;
    currency: string;
}

export interface PrepaidComponentState {
    bank: Bank;
}

export default class PrepaidComponent
    extends Component<PrepaidComponentProps, PrepaidComponentState> {

    public constructor(props: PrepaidComponentProps) {
        super(props);
        this.ctaButton = this.ctaButton.bind(this);
        this.cancelButton = this.cancelButton.bind(this);
        this.setBankDetails = this.setBankDetails.bind(this);

        let label: string = 'close';
        if (this.props.sessionData && this.props.sessionData.transaction.intent === Intent.AUTHORIZATION) {
            label = 'continue';
        }
        this.props.setButton(this.ctaButton, label);
    }

    public componentDidMount() {
        this.showBackButton();
    }

    public render() {
        return (
            <div>
                <ProjectNameAndLogo {...this.props} />
                <BasketOrOrderTotal {...this.props} total={this.props.total} currency={this.props.currency}/>
                <PaymentHints {...this.props} />
                <PaymentMethodHeadlineWithAssigmentAndScoring {...this.props} />
                <div class="container">
                    <div className="col-xs-12">
                        {this.generateAssignmentInfo(this.props.currentLang)}
                    </div>

                    <div className="col-xs-12">
                        <PayInAdvanceInfo {...this.props as CommonProps}
                                          passBankDetails={this.setBankDetails}
                                          showInfoText={false}
                                          hideRows={['amount']}
                        />
                    </div>
                </div>
            </div>

        );
    }

    private ctaButton(): void {
        let viewMode = this.props.viewMode;
        let successUrl = this.context.returnUrls.urlSuccess;
        if (successUrl) {
            exitSmartCheckoutDirectly(viewMode, successUrl);
        } else {
            goToSuccessPage(this.props);
        }
    }

    private cancelButton(): void {
        let abortUrl = this.context.returnUrls.urlAbort;

        if (this.props.getAvailablePaymentMethods().length > 1 && isNull(this.props.initPaymentMethod)) {
            // in this case we can skip /session-data request
            this.props.setStage(AppStage.STAGE_PAYMENT_SELECTION, undefined, true, false);
            this.props.setButton(() => undefined, '', false, false, true, MainButtonType.CANCEL);
        } else {
            exitSmartCheckout(abortUrl, {}, this.props.viewMode, '/cancel', this.props.setLoading);
        }
    }

    // We generate text here, because translations have different parameters
    private generateAssignmentInfo(lang: LanguageSymbol) {
        switch (lang) {
            case LanguageSymbol.EN_GB:
                return <p class="assignment-info no-margin-top">
                    Please transfer the amount of <strong>{this.getAmount()}</strong> to the following bank account,
                    stating the purpose <strong>{this.getTaNumber()}</strong>.
                </p>;
            default:
                return <p class="assignment-info no-margin-bottom">
                    Überweisen Sie den Rechnungsbetrag daher mit dem
                    Verwendungszweck <strong>{this.getTaNumber()}</strong> auf die unten stehende Bankverbindung.
                </p>
        }
    }

    private getAmount() {
        return this.props.sessionData ? AmountHelper.getFullAmountWithCurrency(
            this.props.sessionData.transaction.basket_info.sum, this.props.sessionData.transaction.basket_info.currency, this.props.currentLang
        ) : null
    }

    private setBankDetails(details: Bank) {
        this.setState({
            bank: details
        })
    }

    private getTaNumber() {
        return this.state.bank ? this.state.bank.reference : null;
    }

    private getShopName() {
        return !isNull(this.props.sessionData) ? this.props.sessionData.merchant.company.companyname : null;
    }

    private showBackButton() {
        if (this.props.wizardFlow === WizardFlow.PAYMENT) {
            // started from "general" payment link or abort url is available
            if ((this.props.getAvailablePaymentMethods().length > 1 && isNull(this.props.initPaymentMethod)) || this.context.returnUrls.urlAbort) {
                this.props.setButton(this.cancelButton, 'back', false, false, false, MainButtonType.CANCEL);
            }
        }
    }
}
