import {LanguageSymbol, LegalDetailType, InputName, ProgressStep, ErrorMessageKey, Intent} from '../common/types';
import { imprint_en, imprint_de } from './imprintSecupay';
import { privacy_en, privacy_de } from './policySecupay';
import { h } from 'preact';

export const decimalSeparators = {
    [LanguageSymbol.DE_DE]: ',',
    [LanguageSymbol.EN_GB]: '.',
};

export let allTranslations = {
    en: {
        // GENERAL
        next_step_button: 'NEXT STEP',
        bad_input_hint: 'Please check your data and try again.',
        day: 'day',
        month: 'month',
        year: 'year',
        hour: 'hour',
        minute: 'minute',
        hint: 'information',
        payment_hint: 'Hint',
        date: 'date',
        time: 'time',
        loading_hint: 'LOADING...',
        note: 'Note',
        good_bye: 'See you soon!',
        country_DE: 'Germany',
        country_AT: 'Austria',
        hide: 'Hide',
        buy_now: 'BUY NOW',
        buy_now_2: 'BUY NOW',
        pay_now: 'PAY NOW',
        order_now: 'Order now',
        authorize: 'CONTINUE',
        continue: 'CONTINUE',
        cancel: 'Cancel',
        cancel_order: 'Cancel order',
        close: 'Close',
        back: 'BACK',
        project_title: 'project',

        // PROGRESS STEPS
        [ProgressStep.ADDRESS]: 'Address',
        [ProgressStep.DELIVERY]: 'Delivery',
        [ProgressStep.PAYMENT]: 'Payment',
        [ProgressStep.SUMMARY]: 'Summary',

        // EXIT MODAL
        exit_question: 'Are you sure you want to abort the SMART CHECKOUT?',
        exit_no: 'NO | CONTINUE CHECKOUT',
        exit_yes: 'YES | ABORT CHECKOUT',

        // DateTimePicker
        dtp_check_time: 'Please check your entered time and try again.',
        dtp_past: 'The collection date can not be in the past.',
        dtp_earliest_time: 'The earliest possible collection time is in ',
        dtp_minutes: ' minutes.',

        // IMPRINT ETC..
        [LegalDetailType.TYPE_IMPRINT]: 'Imprint',
        [LegalDetailType.TYPE_REVOCATION_INSTRUCTION]: 'Cancellation Policy',
        [LegalDetailType.TYPE_PRIVACY_POLICY]: ' Privacy Policy',
        [LegalDetailType.TYPE_TERMS_AND_CONDITIONS]: 'Terms and Conditions of Sale',
        smart_description: 'SMART CHECKOUT Description',

        // CHECKIN WELCOME
        welcome_header: 'WELCOME',
        welcome_text: 'at the smart checkout by secupay - your online payment provider',

        // MERCHANT BLOCK
        merchant_header: 'SALE AND SHIPPING IS DONE BY',
        merchant: 'MERCHANT',
        country_zip_code_place: 'Country, zip code, registered seat',

        // BASKET
        basket_header: 'YOUR BASKET',
        quantity: 'Quantity',
        product: 'Product',
        price: 'Price',
        total_amount: 'Total amount',
        overview: 'Overview',
        tax_info_1: 'The total amount includes VAT of ',
        tax_info_2: ' €.',
        sales_by: 'Sales by',
        sales_and_shipping_by: 'Sales and shipping by',
        arranged_by: 'Arranged by',
        coupons: 'Coupons',

        // BANK
        bank_details: 'Bank details',
        iban: 'IBAN',
        bank_name: 'Bank name',

        // CHECKIN
        checkin_header: 'start with the checkout now',
        dateformat: 'Date of birth (DD.MM.YYYY)',
        email_address: 'E-mail address',
        checkin_privacy_1: 'With the confirmation of my data by clicking the' +
        ' "next step" button, I agree to the collection' +
        ' and usage of my personal data according' +
        ' to the ',
        checkin_privacy_2: '.',
        pay_pal_confirmation_1: 'With the confirmation of my data by clicking the "PayPal Express Checkout"' +
        ' button, I agree to the collection and usage of my personal data according to the ',
        pay_pal_confirmation_2: '.',
        pay_pal_express_start_fail: 'Your PayPal Express checkout could not be started. Please try it again,' +
            ' or use the checkout by secupay.',
        pay_pal_express_start_not_started: 'The PayPal checkout could not be started. Please try it again, or use our Smart Checkout by secupay.',
        pay_pal_express_start_not_completed: 'The PayPal checkout could not be completed. Please try it again, or use our Smart Checkout by secupay.',
        [InputName.EMAIL + '_warning']: (
                <span>Please enter a valid e-mail address.</span>
        ),
        [InputName.DATE + '_warning']: (
                <span>Please enter your date of birth as follows: DD.MM.YYYY</span>
        ),
        date_too_young_warning: 'You must be at least 18 years old.',

        // PAYMENT METHODS
        pay_with: 'Pay with',
        payment: 'Payment',
        payment_to: 'Payment to',
        payment_method_headline: 'PAYMENT METHOD',
        payment_method_secucard: 'Secucard',
        payment_method_direct_debit: 'SEPA direct debit',
        payment_method_direct_debit_payment: 'SEPA DIRECT DEBIT MANDATE',
        payment_method_invoice: 'Pay on invoice',
        payment_method_invoice_payment: 'Invoice payment',
        payment_method_credit_card: 'Credit card',
        payment_method_credit_card_payment: 'Credit card payment',
        payment_method_pay_in_advance: 'Pay in advance',
        payment_method_advance_payment: 'Advance payment',
        payment_method_paypal: 'PayPal',
        payment_method_sofort: 'Sofort',
        payment_method_one_available_scoring_failed: 'Authorisation failed. Please try it again with another' +
        ' means of payment.',
        payment_method_more_available_scoring_failed: 'Authorisation failed. Please try it again with another' +
        ' payment method or another means of payment.',
        payment_method_invoice_fail_1: 'Authorisation failed. We have changed the payment method to ',
        payment_method_invoice_fail_2: '.',

        // ADDRESS
        [InputName.SALUTATION]: 'Salutation',
        salutation_male: 'Mr',
        salutation_female: 'Mrs',
        [InputName.COUNTRY]: 'Country',
        address_header: 'Invoice and shipping address',
        [InputName.FIRST_NAME]: 'First name',
        [InputName.SURNAME]: 'Surname',
        [InputName.STREET_AND_STREET_NUMBER]: 'Street, house number',
        [InputName.ADDITIONAL_ADDRESS_DATA]: 'Additional address information',
        [InputName.ZIP_CODE]: 'ZIP code',
        [InputName.TOWN]: 'City',
        [InputName.PHONE]: 'Phone',
        name: 'Name',
        [InputName.COMPANY_NAME]: 'Company name',
        [InputName.EMPTY]: '',
        mandatory_field: 'Mandatory fields',
        [InputName.COMPANY_NAME + '_warning']: 'Please enter a valid company name',
        [InputName.SALUTATION + '_warning']: 'Please choose from list',
        [InputName.FIRST_NAME + '_warning']: 'Please enter a valid first name.',
        [InputName.SURNAME + '_warning']: 'Please enter a valid surname.',
        [InputName.COUNTRY + '_warning']: 'Please choose from list.',
        [InputName.TOWN + '_warning']: 'Please enter a valid city.',
        [InputName.STREET_AND_STREET_NUMBER + '_warning']: 'Please enter a valid street and house number.',
        [InputName.ZIP_CODE + '_warning']: 'Please enter a valid zip code.',
        [InputName.ADDITIONAL_ADDRESS_DATA + '_warning']: 'Please enter a valid information',
        [InputName.PHONE + '_warning']: (
            <span>
                Please enter a valid phone number, for instance 0555 / 555-55 or +49 555 555-55.
            </span>
        ),
        [InputName.EMPTY + '_warning']: '',
        // DELIVERY
        delivery_header: 'DELIVERY OPTIONS',
        delivery_time: 'Please choose your desired collection time.',
        shipping: 'Shipment',
        shipping_headline: 'Your shipping address:',
        collection: 'COLLECTION',
        change_address: 'Change entered address',

        // CONFIRMATION
        confirm_button_text: 'ORDER AND PAY',
        address_data_header: 'YOUR ADDRESS ',
        change_address_button_text: 'CHANGE ADDRESS',
        delivery_address_data_header: 'Your invoice and shipping address',
        your_invoice_address_header: 'Your invoice address',
        your_shipment_address_header: 'Your shipping address',
        collection_data_header: 'YOUR COLLECTION INFORMATION',
        collection_date: 'Date',
        collection_time: 'Time',
        date_separator: '/',
        change_delivery_options_button_text: 'CHANGE DELIVERY OPTIONS',
        change_invoice_address: 'Change invoice address',
        payment_data_header: 'PAYMENT DATA',
        change_method_button_text: 'CHANGE PAYMENT METHOD',
        change_payment_method_button_details: 'Change payment details',
        change_payment_method_button_bank_account: 'Change bank account',
        change_payment_method_button_credit_card: 'Change credit card',
        change_payment_method_button_to_in_advance: 'Change to payment in advance',
        change_payment_method_button_to_invoice: 'Change to invoice payment',
        confirm_text_1: 'By clicking on the button, I confirm that I have read the ',
        confirm_text_2: ' and confirm the ',
        confirm_text_3: ' and ',
        confirm_text_4: ' of the merchant.',
        confirm_text_mixed_basket: 'By clicking on ORDER AND PAY, I confirm that I have read, and accepted, the terms and conditions, cancellation policies, and privacy policies of the suppliers listed above.',
        [InputName.OWNER + '_warning']: 'Please enter a valid account owner.',
        [InputName.IBAN + '_warning']: 'Please enter a valid IBAN.',

        // SHOW SECU CREDIT
        payment_amount_header: 'PAYMENT AMOUNT',
        credit: 'credit',
        remaining_total_amount: 'Remaining total amount',
        not_enough_money_hint: 'Your credit is unsatisfactory! Please choose another' +
        ' secucard or add another payment method.',
        change_secucard_button_text: 'CHANGE SECUCARD',
        add_another_method_button_text: 'ADD ANOTHER PAYMENT METHOD',
        pay_secucard_button_text: 'PAY BY SECUCARD',

        // SECUCARD
        pin_not_correct_hint: 'Your entered card number or PIN is not correct.',
        secucard_number: 'secucard number',
        secucard_number_hint1: 'You can find your personal secucard number and PIN on the back of the secucard.',
        secucard_number_hint2: '(the position of the number and PIN may vary)',
        enter_another_method: 'ENTER ANOTHER PAYMENT METHOD',
        secucard_header: 'SECUCARD',
        your_secucards_header: 'YOUR SECUCARDS',

        // DIRECT DEBIT
        direct_debit_agreement: (
            <div><p>Type of payment recurrent payment. Mandate reference by separate
                notification.</p>

                <p>I authorize <a href="https://secupay.com/de/impressum" target="_blank">secupay AG, Goethestr. 6, 01896 Pulsnitz</a> (creditor identifier DE17ZZZ00000011109), to
                    withdraw payments from my bank account by way of direct debit. I also instruct my financial
                    institute to honour the direct debits drawn by secupay AG.</p>

                <p>Note: I can demand a return debit within eight weeks after the direct debit payment has been placed.
                    Thereby, the agreed conditions of my financial institute apply. The prenotification period for
                    direct debits generated under this mandate shall be reduced to two banking days prior to debiting
                    date.
                </p>
            </div>
        ),
        direct_debit_header: 'PLEASE ENTER YOUR PAYMENT ACCOUNT DATA',
        direct_debit_cardholder: 'Account owner',
        direct_debit_button: 'CONFIRM AND CONTINUE',
        direct_debit_headline: 'SEPA direct debit mandate',
        confirm_mandate: 'CONFIRM MANDATE',
        direct_debit_assignment_formal: <span>Please note that the payment claim has been assigned to and the payment will be processed by <a href="https://secupay.com/de/impressum" target="_blank">secupay AG, Goethestr. 6 in D-01896 Pulsnitz</a>. By confirming your order, you authorize secupay AG to use and store all data related to this order for the purpose of completing the payment process.</span>,
        direct_debit_assignment_informal: <span>Please note that the payment claim has been assigned to and the payment will be processed by <a href="https://secupay.com/de/impressum" target="_blank">secupay AG, Goethestr. 6 in D-01896 Pulsnitz</a>. By confirming your order, you authorize secupay AG to use and store all data related to this order for the purpose of completing the payment process.</span>,
        direct_debit_scoring_formal: <span>We obtain information on your previous payment history, based on mathematical-statistical analysis under inclusion of address data (scoring), from the following service providers: Creditreform Boniversum GmbH, Hammfelddamm 13, 41460 Neuss, Creditreform Dresden Aumüller KG, Augsburger Str. 4, 01309 Dresden and infoscore Consumer Data GmbH, Rheinstraße 99, 76532 Baden-Baden.</span>,
        direct_debit_scoring_informal: <span>We obtain information on your previous payment history, based on mathematical-statistical analysis under inclusion of address data (scoring), from the following service providers: Creditreform Boniversum GmbH, Hammfelddamm 13, 41460 Neuss, Creditreform Dresden Aumüller KG, Augsburger Str. 4, 01309 Dresden and infoscore Consumer Data GmbH, Rheinstraße 99, 76532 Baden-Baden.</span>,

        // CREDIT CARD
        confirm_payment: 'CONFIRM PAYMENT',
        credit_card: 'Credit card',
        credit_card_success_info: 'We want to inform you that the invoice amount of this order was assigned' +
        ' to the secupay AG, Goethestraße 6, 01896 Pulsnitz. Your credit card will be charged with the amount to pay.',
        credit_card_success_info_intent_sale: 'We want to inform you that the invoice amount of this payment was assigned' +
            ' to the secupay AG, Goethestraße 6, 01896 Pulsnitz. Your credit card will be charged with the amount to pay.',
        credit_card_assignment_formal: <span>Please note that the payment claim has been assigned to and the payment will be processed by <a href="https://secupay.com/de/impressum" target="_blank">secupay AG, Goethestr. 6 in D-01896 Pulsnitz</a>. By confirming your order, you authorize secupay AG to use and store all data related to this order for the purpose of completing the payment process.</span>,
        credit_card_assignment_informal: <span>Please note that the payment claim has been assigned to and the payment will be processed by <a href="https://secupay.com/de/impressum" target="_blank">secupay AG, Goethestr. 6 in D-01896 Pulsnitz</a>. By confirming your order, you authorize secupay AG to use and store all data related to this order for the purpose of completing the payment process.</span>,
        credit_card_scoring_formal: <span>We obtain information on your previous payment history, based on mathematical-statistical analysis under inclusion of address data (scoring), from the following service providers: Creditreform Boniversum GmbH, Hammfelddamm 13, 41460 Neuss, Creditreform Dresden Aumüller KG, Augsburger Str. 4, 01309 Dresden and infoscore Consumer Data GmbH, Rheinstraße 99, 76532 Baden-Baden.</span>,
        credit_card_scoring_informal: <span>We obtain information on your previous payment history, based on mathematical-statistical analysis under inclusion of address data (scoring), from the following service providers: Creditreform Boniversum GmbH, Hammfelddamm 13, 41460 Neuss, Creditreform Dresden Aumüller KG, Augsburger Str. 4, 01309 Dresden and infoscore Consumer Data GmbH, Rheinstraße 99, 76532 Baden-Baden.</span>,

        // PAY IN ADVANCE
        pay_in_advance_section: (
            <div>
                <p>Payment in advance</p>
                <p class="mb-5">You will receive detailed instructions after your confirmation.</p>
            </div>
        ),
        account_holder: 'Account holder',
        reference: 'Reference',
        transfer_amount: 'Transfer amount',
        pay_in_advance_assignment_formal: <span>Please note that the payment claim has been assigned to and the payment will be processed by <a href="https://secupay.com/de/impressum" target="_blank">secupay AG, Goethestr. 6 in D-01896 Pulsnitz</a>. By confirming your order, you authorize secupay AG to use and store all data related to this order for the purpose of completing the payment process.</span>,
        pay_in_advance_assignment_informal: <span>Please note that the payment claim has been assigned to and the payment will be processed by <a href="https://secupay.com/de/impressum" target="_blank">secupay AG, Goethestr. 6 in D-01896 Pulsnitz</a>. By confirming your order, you authorize secupay AG to use and store all data related to this order for the purpose of completing the payment process.</span>,

        // INVOICE
        invoice_section: (
            <div>
                <p>Payment on invoice (10 days)</p>
            </div>
        ),
        invoice_assignment_formal: <span>Please note that the payment claim has been assigned to and the payment will be processed by <a href="https://secupay.com/de/impressum" target="_blank">secupay AG, Goethestr. 6 in D-01896 Pulsnitz</a>. By confirming your order, you authorize secupay AG to use and store all data related to this order for the purpose of completing the payment process.</span>,
        invoice_assignment_informal: <span>Please note that the payment claim has been assigned to and the payment will be processed by <a href="https://secupay.com/de/impressum" target="_blank">secupay AG, Goethestr. 6 in D-01896 Pulsnitz</a>. By confirming your order, you authorize secupay AG to use and store all data related to this order for the purpose of completing the payment process.</span>,
        invoice_scoring_formal: <span>We obtain information on your previous payment history, based on mathematical-statistical analysis under inclusion of address data (scoring), from the following service providers: Creditreform Boniversum GmbH, Hammfelddamm 13, 41460 Neuss, Creditreform Dresden Aumüller KG, Augsburger Str. 4, 01309 Dresden and infoscore Consumer Data GmbH, Rheinstraße 99, 76532 Baden-Baden.</span>,
        invoice_scoring_informal: <span>We obtain information on your previous payment history, based on mathematical-statistical analysis under inclusion of address data (scoring), from the following service providers: Creditreform Boniversum GmbH, Hammfelddamm 13, 41460 Neuss, Creditreform Dresden Aumüller KG, Augsburger Str. 4, 01309 Dresden and infoscore Consumer Data GmbH, Rheinstraße 99, 76532 Baden-Baden.</span>,

        // PAYPAL
        paypal_section: 'E-mail address',

        // ERROR PAGE
        error_headline: 'AN ERROR HAS OCCURRED',
        ['error_headline_' + ErrorMessageKey.MISCELLANEOUS_ERROR]: 'APPLICATION ERROR',
        ['error_message_' + ErrorMessageKey.MISCELLANEOUS_ERROR]: <div>
            <p>An application error occured. Please try it again later. If this fails to solve the problem, please get in touch with the merchant.</p>
            <p>We apologize for the inconvenience.</p>
        </div>,
        ['error_headline_' + ErrorMessageKey.STX_ALREADY_STARTED]: 'ALREADY COMPLETED',
        ['error_message_' + ErrorMessageKey.STX_ALREADY_STARTED]: <div>
            <p>This order has already been checked out. We sent you an e-mail with all necessary details.</p>
            <p>In order to obtain further information, get in touch with the merchant please.</p>
        </div>,
        ['error_headline_' + ErrorMessageKey.STX_ALREADY_STARTED + '_' + Intent.SALE]: 'ALREADY COMPLETED',
        ['error_message_' + ErrorMessageKey.STX_ALREADY_STARTED + '_' + Intent.SALE]: <div>
            <p>This payment had already been completed at some point in the past.</p>
            <p>In order to obtain further information, get in touch with the merchant please.</p>
        </div>,
        ['error_headline_' + ErrorMessageKey.STX_DELETED]: 'TRANSACTION DELETED',
        ['error_message_' + ErrorMessageKey.STX_DELETED]: <div>
            <p>This transaction cannot be completed anymore.</p>
        </div>,
        ['error_headline_' + ErrorMessageKey.STX_START_PROBLEM]: 'PAYMENT PROBLEM',
        ['error_message_' + ErrorMessageKey.STX_START_PROBLEM]: <div>
            <p>A problem occured during the payment process. Please get in touch with the merchant.</p>
            <p>We apologize for the inconvenience.</p>
        </div>,
        ['error_headline_' + ErrorMessageKey.NO_PAYMENT_METHOD_REMAINING]: 'PAYMENT PROBLEM',
        ['error_message_' + ErrorMessageKey.NO_PAYMENT_METHOD_REMAINING]: <div>
            <p>The payment process cannot be continued. In order to obtain further information, get in touch with the merchant please.</p>
            <p>Your account will not be charged.</p>
            <p>We apologize for the inconvenience.</p>
        </div>,
        ['error_headline_' + ErrorMessageKey.COLLECTION_MISCONFIGURATION]: 'NO STORE AVAILABLE',
        ['error_message_' + ErrorMessageKey.COLLECTION_MISCONFIGURATION]: <div>
            <p>Currently there is no store available for collection. The checkout process cannot be continued. In order to obtain further information, get in touch with the merchant please.</p>
            <p>We apologize for the inconvenience.</p>
        </div>,
        ['error_headline_' + ErrorMessageKey.BOOT_PROBLEM]: 'APPLICATION ERROR',
        ['error_message_' + ErrorMessageKey.BOOT_PROBLEM]: <div>
            <p>The checkout process cannot be started. In order to obtain further information, get in touch with the merchant please.</p>
            <p>We apologize for the inconvenience.</p>
        </div>,
        ['error_headline_' + ErrorMessageKey.ARBITRARY_SESSION_PROBLEM]: 'SESSION PROBLEM',
        ['error_message_' + ErrorMessageKey.ARBITRARY_SESSION_PROBLEM]: <div>
            <p>Your session cannot be continued. Most likely it has timed out after a period of inactivity.</p>
            <p>Please start the checkout again, in order to complete your order. If this fails to solve the problem, please get in touch with the merchant.</p>
        </div>,
        ['error_headline_' + ErrorMessageKey.SESSION_TIMEOUT]: 'SESSION TIMEOUT',
        ['error_message_' + ErrorMessageKey.SESSION_TIMEOUT]: <div>
            <p>Your session cannot be continued after a period of inactivity.</p>
            <p>Start the checkout process again in order to complete your order. If this fails to solve the problem, please get in touch with the merchant.</p>
        </div>,
        error_session_expired: (
            <div>
                <p>The received session token was invalid. It is likely that your session timed out after a period of
                    inactivity.</p>
                <br/>
                <p>Please start the checkout process again in order to complete your purchase order.</p>
                <p>For further information, please contact the merchant.</p>
            </div>
        ),
        error_delivery_options_misconfiguration: (
            <div>
                <p>Currently there is no store available for collection. The checkout process cannot be continued.
                    For further information please get in touch with the merchant.</p>
                <br/>
                <p>We apologize for the inconvenience.</p>
            </div>
        ),
        error_missing_legal_details: (
            <div>
                <p>Missing legal details documents</p>
            </div>
        ),
        error_other: (
            <div>
                <p>Your order could not be completed. Please excuse the inconvenience and try again.</p>
                <br/>
                <p>Your account will not be charged.</p>
                <p>For further information, please contact the merchant.</p>
            </div>
        ),
        session_expired: 'SESSION EXPIRED',
        error_back_to_the_shop_button: 'BACK TO THE SHOP',

        // SUCCESS PAGE
        success_page_header: 'ORDER CONFIRMATION',
        success_page_header_intent_sale: 'CONFIRMATION',
        success_your_pick_up_code: 'Your personal pick up code',
        success_back_to_the_shop_button: 'BACK TO THE SHOP',
        success_thanks: 'Thank you for shopping with the smart checkout by secupay. We wish you much' +
        ' enjoyment with your order.',        // success_direct_debit_
        success_thanks_intent_sale: 'Thanks for your payment.',
        success_direct_debit_assign_1st_part: 'We want to inform you that the invoice amount of this order was' +
        ' assigned to the secupay AG, Goethestraße 6, 01896 Pulsnitz. You have granted us the following' +
        ' SEPA mandate on ',
        success_direct_debit_assign_2nd_part: ':',
        success_direct_debit_assign_1st_part_intent_sale: 'We want to inform you that the invoice amount of the payment was' +
            ' assigned to the secupay AG, Goethestraße 6, 01896 Pulsnitz. You have granted us the following' +
            ' SEPA mandate on ',
        success_direct_debit_assign_2nd_part_intent_sale: ':',
        creditor_identification_number: 'Creditor identification number',
        mandate_reference: 'Mandate reference',
        mandate_type: 'Mandate type',
        recurring_payments: 'recurring payments',
        success_email_note: 'In addition, you will receive all important details via e-mail.',
        success_pay_in_advance_assign: 'We want to inform you that the shipment takes place only when the advance' +
        ' payment is received. Please transfer the amount to the following escrow account of the secupay AG:',
        success_pay_in_advance_assign_intent_sale: 'Please transfer the amount to the following escrow account of the secupay AG:',
        success_invoice: 'We would like to point out that the invoice amount of this order was assigned to the secupay AG, ' +
        'Goethestraße 6, 01896 Pulsnitz. The invoice amount is payable within 10 days from delivery. ' +
        'You will receive the payment information with the invoice.',
        success_invoice_intent_sale: 'We would like to point out that the invoice amount of this payment was assigned to the secupay AG, ' +
            'Goethestraße 6, 01896 Pulsnitz. The invoice amount is payable within 10 days from delivery. ' +
            'You will receive the payment information with the invoice.',
        success_paypal_basic: 'You have paid the amount with PayPal to the merchant.',
        success_collection_hint: 'Do not miss to collect your order in time at ',

        // DESCRIPTION
        desc_headline: 'SMART CHECKOUT DESCRIPTION',
        desc_snd_line: 'WELCOME TO THE SECUPAY SMART CHECKOUT',
        desc_trd_line: ' How it works:',
        desc_overview_name: 'Overview',
        desc_overview: ' of merchant data, your shopping basket and your personal checkout data.',
        desc_address_name: 'Address data input',
        desc_address: ', here you enter your ' +
        ' billing and delivery address. By agreeing to' +
        ' save your data, you are enabled to skip this step on your' +
        ' next purchase.',
        desc_payment_name: 'Payment data input',
        desc_payment: ', here you choose your desired payment' +
        ' method and enter all necessary data. By agreeing' +
        ' to save your data, you are enabled to skip this step on your' +
        ' next purchase.',
        desc_confirmation_name: 'Order confirmation',
        desc_confirmation: ', here you find a summary of all data entered. ' +
        ' You can review and manage the merchant data, your' +
        ' shopping cart and your personal order information.' +
        ' After confirming your order, you have the opportunity' +
        ' to store your data for the next purchases.',

        // IMPRINT SECUPAY
        imprint_text_secupay: imprint_en,

        // PRIVACY POLICY SECUPAY
        privacy_policy_text_secupay: privacy_en
    },
    de: {
        // GENERAL
        next_step_button: 'WEITER',
        bad_input_hint: 'Bitte prüfen Sie Ihre Eingaben.',
        day: 'Tag',
        month: 'Monat',
        year: 'Jahr',
        hour: 'Stunde',
        minute: 'Minute',
        hint: 'Hinweis',
        payment_hint: 'Hinweis',
        date: 'Datum',
        time: 'Zeit',
        loading_hint: 'WIRD GELADEN...',
        note: 'Hinweis',
        good_bye: 'Bis bald!',
        country_DE: 'Deutschland',
        country_AT: 'Österreich',
        hide: 'Ausblenden',
        buy_now: 'JETZT KAUFEN',
        buy_now_2: 'JETZT ZAHLEN',
        pay_now: 'JETZT ZAHLEN',
        order_now: 'Jetzt bestellen',
        authorize: 'WEITER',
        continue: 'WEITER',
        cancel: 'Widerrufen',
        cancel_order: 'Abbrechen',
        close: 'Schliessen',
        back: 'ZURÜCK',
        project_title: 'projekt',

        // PROGRESS STEPS
        [ProgressStep.ADDRESS]: 'Adresse',
        [ProgressStep.DELIVERY]: 'Versand / Abholung',
        [ProgressStep.PAYMENT]: 'Bezahlung',
        [ProgressStep.SUMMARY]: 'Übersicht',

        // EXIT MODAL
        exit_question: 'Möchten Sie den SMART CHECKOUT wirklich abbrechen?',
        exit_no: 'NEIN | CHECKOUT FORTSETZEN',
        exit_yes: 'JA | CHECKOUT ABBRECHEN',

        // DateTimePicker
        dtp_check_time: 'Bitte prüfen Sie Ihre Eingabe.',
        dtp_past: 'Der Zeitpunkt darf nicht in der Vergangenheit liegen.',
        dtp_earliest_time: 'Der frühestmögliche Abholtermin ist in ',
        dtp_minutes: ' Minuten.',

        // IMPRINT ETC..
        [LegalDetailType.TYPE_IMPRINT]: 'Impressum',
        [LegalDetailType.TYPE_REVOCATION_INSTRUCTION]: 'Widerrufsbelehrung',
        [LegalDetailType.TYPE_PRIVACY_POLICY]: ' Datenschutzerklärung',
        [LegalDetailType.TYPE_TERMS_AND_CONDITIONS]: 'Allgemeine Geschäftsbedingungen',
        imprint_big_letter: 'Impressum',
        privacy_policy_big_letter: ' Datenschutzerklärung',
        smart_description: 'SMART CHECKOUT Erläuterung',

        // CHECKIN WELCOME
        welcome_header: 'Willkommen',
        welcome_text: 'bei dem Smart Checkout von secupay - Ihr Online-Zahlungsanbieter',

        // MERCHANT BLOCK
        merchant_header: 'VERKAUF UND VERSAND DURCH',
        merchant: 'VERKÄUFER',
        country_zip_code_place: 'Land, PLZ, Ort',

        // BASKET
        basket_header: 'IHR WARENKORB',
        quantity: 'Anzahl',
        product: 'Artikel',
        price: 'Preis',
        total_amount: 'Gesamtbetrag',
        overview: 'Übersicht',
        tax_info_1: 'Der Gesamtbetrag enthält ',
        tax_info_2: ' € Ust.',
        sales_by: 'Verkauf durch',
        sales_and_shipping_by: 'Verkauf und Versand durch',
        arranged_by: 'Verkauf vermittelt durch',
        coupons: 'Gutscheine',

        // BANK
        bank_details: 'Bankverbindung',
        iban: 'IBAN',
        bank_name: 'Kreditinstitut',

        // PAYMENT METHODS
        pay_with: 'Bezahlen mit',
        payment: 'Zahlung',
        payment_to: 'Zahlung an',
        payment_method_headline: 'BEZAHLEN MIT',
        payment_method_secucard: 'Secucard',
        payment_method_direct_debit: 'SEPA-Lastschrift',
        payment_method_direct_debit_payment: 'SEPA-LASTSCHRIFTMANDAT',
        payment_method_invoice: 'Rechnungskauf',
        payment_method_invoice_payment: 'Rechnungskauf',
        payment_method_credit_card: 'Kreditkarte',
        payment_method_credit_card_payment: 'Kreditkarte zahlung',
        payment_method_pay_in_advance: 'Vorkasse',
        payment_method_advance_payment: 'Vorkasse',
        payment_method_paypal: 'PayPal',
        payment_method_sofort: 'Sofort',
        payment_method_one_available_scoring_failed: 'Autorisierung fehlgeschlagen. Bitte versuchen Sie es' +
        ' noch einmal mit einem anderen Zahlungsmittel.',
        payment_method_more_available_scoring_failed: 'Autorisierung fehlgeschlagen. Bitte versuchen Sie es' +
        ' noch einmal mit einer anderen Zahlunsgmethode oder einem anderen Zahlungsmittel.',
        payment_method_invoice_fail_1: 'Autorisierung fehlgeschlagen. Wir haben die Zahlungsmethode für Sie auf ',
        payment_method_invoice_fail_2: ' geändert.',

        // CHECKIN
        checkin_header: 'STARTEN SIE JETZT MIT DEM CHECKOUT',
        dateformat: 'Geburtsdatum (TT.MM.JJJJ)',
        email_address: 'E-Mail-Adresse',
        checkin_privacy_1: 'Durch Angabe meiner Daten und klick' +
        ' auf "Weiter" stimme ich der Erhebung, Verarbeitung ' +
        'und Nutzung meiner personenbezogenen Daten gemäß der ',
        checkin_privacy_2: ' zu.',
        pay_pal_confirmation_1: 'Durch Angabe meiner Daten und klick auf "PayPal Express Checkout" stimme ich der' +
        ' Erhebung, Verarbeitung und Nutzung meiner personenbezogenen Daten gemäß der  ',
        pay_pal_confirmation_2: ' zu.',
        pay_pal_express_start_fail: 'Der PayPal Express Checkout konnte nicht gestartet werden. Bitte versuchen' +
            ' Sie es noch einmal oder nutzen Sie den secupay Checkout.',
        pay_pal_express_start_not_started: 'Der PayPal-Checkout konnte nicht gestartet werden. Bitte versuchen Sie es noch einmal oder nutzen Sie unseren secupay Smart Checkout.',
        pay_pal_express_start_not_completed: 'Der PayPal-Checkout konnte nicht abgeschlossen werden. Bitte versuchen Sie es noch einmal oder nutzen Sie unseren secupay Smart Checkout.',
        [InputName.EMAIL + '_warning']: (
                <span>Bitte geben Sie eine gültige E-Mail-Adresse ein.</span>
            ),
        [InputName.DATE + '_warning']: (
            <span>Bitte geben Sie Ihr Geburtsdatum im Format TT.MM.JJJJ an.</span>
        ),
        date_too_young_warning: 'Sie müssen mindestens 18 Jahre alt sein.',

        // ADDRESS
        country: 'Land',
        address_header: 'Rechnungs- und Versandadresse',
        salutation: 'Anrede',
        salutation_male: 'Herr',
        salutation_female: 'Frau',
        [InputName.FIRST_NAME]: 'Vorname',
        [InputName.SURNAME]: 'Nachname',
        [InputName.STREET_AND_STREET_NUMBER]: 'Straße, Hausnummer',
        [InputName.ADDITIONAL_ADDRESS_DATA]: 'Adresszusatz',
        [InputName.ZIP_CODE]: 'PLZ',
        [InputName.TOWN]: 'Ort',
        phone: 'Telefon',
        name: 'Name',
        [InputName.COMPANY_NAME]: 'Firma',
        [InputName.EMPTY]: '',
        mandatory_field: 'Pflichtfelder',
        [InputName.SALUTATION + '_warning']: 'Bitte wählen Sie aus der Liste.',
        [InputName.FIRST_NAME + '_warning']: 'Bitte geben Sie einen korrekten Vornamen an.',
        [InputName.SURNAME + '_warning']: 'Bitte geben Sie einen korrekten Nachnamen an.',
        [InputName.COUNTRY + '_warning']: 'Bitte wählen Sie aus der Liste.',
        [InputName.TOWN + '_warning']: 'Bitte geben Sie eine korrekte Stadt an.',
        [InputName.STREET_AND_STREET_NUMBER + '_warning']: 'Bitte geben Sie eine korrekte Straße und Hausnummer an.',
        [InputName.ZIP_CODE + '_warning']: 'Bitte geben sie eine korrekte PLZ an',
        [InputName.ADDITIONAL_ADDRESS_DATA + '_warning']: 'Bitte geben Sie eine gültige Information ein.',
        [InputName.PHONE + '_warning']: (
            <span>
                Bitte geben Sie eine gültige Telefonummer an, zum Beispiel 0555 / 555-55 or +49 555 555-55.
            </span>
        ),
        [InputName.COMPANY_NAME + '_warning']: 'Bitte geben Sie eine gültige Information ein',
        [InputName.EMPTY + '_warning']: '',

        // DELIVERY
        delivery_header: 'LIEFEROPTIONEN',
        delivery_time: 'Bitte wählen Sie Ihre gewünschte Abholzeit.',
        shipping: 'Versand',
        shipping_headline: 'Ihre Versandadresse:',
        collection: 'ABHOLUNG',
        change_address: 'Ändern der angegebenen Adresse',

        // CONFIRMATION
        confirm_button_text: 'ZAHLUNGSPFLICHTIG BESTELLEN',
        payment_data_header: 'ZAHLUNGSINFORMATIONEN',
        address_data_header: 'ADRESSINFORMATIONEN',
        change_address_button_text: 'ADRESSE ÄNDERN',
        delivery_address_data_header: 'Ihre Rechnungs- und Versandadresse',
        your_invoice_address_header: 'Ihre Rechnungsadresse',
        your_shipment_address_header: 'Ihre Versandadresse',
        collection_data_header: 'IHRE ABHOLDATEN',
        collection_date: 'Datum',
        collection_time: 'Uhrzeit',
        date_separator: '.',
        change_delivery_options_button_text: 'LIEFEROPTIONEN ÄNDERN',
        change_invoice_address: 'Rechnungsadresse ändern',
        change_method_button_text: 'ZAHLUNGSMETHODE ÄNDERN',
        change_payment_method_button_details: 'Zahlungsdaten ändern',
        change_payment_method_button_bank_account: 'Bankkonto ändern',
        change_payment_method_button_credit_card: 'Kreditkarte ändern',
        change_payment_method_button_to_in_advance: 'Auf Vorkasse ändern',
        change_payment_method_button_to_invoice: 'Auf Rechnungskauf ändern',
        confirm_text_1: 'Mit dem Klick auf die Schaltfläche ZAHLUNGSPFLICHTIG BESTELLEN bestätigen Sie, dass Sie die ',
        confirm_text_2: ', die ',
        confirm_text_3: ' und die ',
        confirm_text_4: ' des Händlers gelesen und akzeptiert haben.',
        confirm_text_mixed_basket: 'Mit dem Klick auf die Schaltfläche ZAHLUNGSPFLICHTIG BESTELLEN bestätige ich, dass ich die Allgemeinen Geschäftsbedingungen, die Widerrufsbelehrung(en) und die Datenschutzerklärung(en) der oben genannten Lieferanten gelesen und akzeptiert habe.',
        [InputName.OWNER + '_warning']: 'Bitte geben Sie einen korrekten Kontoinhaber an.',
        [InputName.IBAN + '_warning']: 'Bitte geben Sie eine korrekte IBAN an.',

        // SHOW SECU CREDIT
        payment_amount_header: 'ZAHLUNGSBETRAG',
        credit: 'Guthaben',
        remaining_total_amount: 'Restbetrag',
        not_enough_money_hint: 'Ihr Guthaben ist nicht' +
        ' ausreichend! Bitte wählen Sie eine andere secucard' +
        ' oder eine andere Zahlungsmethode aus.',
        change_secucard_button_text: 'ANDERE SECUCARD WÄHLEN',
        add_another_method_button_text: 'WEITERE ZAHLUNGSMETHODE HINZUFÜGEN',
        pay_secucard_button_text: 'BEZAHLEN MIT SECUCARD',

        // SECUCARD
        pin_not_correct_hint: 'Ihre Kartennummer oder PIN ist nicht korrekt.',
        secucard_number: 'secucard Nummer',
        secucard_number_hint: 'Sie finden die secucard Nummer und die PIN auf der Rückseite Ihrer secucard.' +
        '(die Position auf der Karte kann variieren)"',
        enter_another_method: 'ANDERE ZAHLUNGSMETHODE WÄHLEN',
        secucard_header: 'SECUCARD',
        your_secucards_header: 'IHRE SECUCARDs',

        // DIRECT DEBIT
        direct_debit_agreement: (
            <div><p>Zahlungsart wiederkehrende Zahlungen. Die Mandatsreferenz wird separat
                mitgeteilt.</p>
                <p>
                    Ich ermächtige die <a href="https://secupay.com/de/impressum" target="_blank">secupay AG, Goethestr. 6, 01896 Pulsnitz</a> (Gläubiger-Identifikationsnummer
                    DE17ZZZ00000011109), Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich
                    weise ich mein Kreditinstitut an, die von der secupay AG auf mein Konto gezogenen
                    Lastschriften einzulösen.
                </p>
                <p>Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des
                    belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten
                    Bedingungen. Für dieses Mandat wird eine Reduzierung der Frist zur Vorankündigung
                    (Pre-Notification) auf 2 Bankarbeitstage vereinbart.
                </p>
            </div>
        ),
        direct_debit_header: 'TRAGEN SIE BITTE IHRE KONTODATEN EIN',
        direct_debit_cardholder: 'Kontoinhaber',
        direct_debit_button: 'MANDAT BESTÄTIGEN UND FORTFAHREN',
        direct_debit_headline: 'SEPA-Lastschriftmandat',
        confirm_mandate: 'MANDAT BESTÄTIGEN',
        direct_debit_assignment_formal: <span>Wir weisen Sie darauf hin, dass die Forderung an die <a href="https://secupay.com/de/impressum" target="_blank">secupay AG, Goethestraße 6, 01896 Pulsnitz</a> abgetreten wurde und die Zahlung durch diese abgewickelt wird. Mit der Bestellung ermächtigen Sie die secupay AG, die auftragsbezogenen Daten für den Zweck der Zahlungsabwicklung zu speichern und zu nutzen.</span>,
        direct_debit_assignment_informal: <span>Wir weisen dich darauf hin, dass die Forderung an die <a href="https://secupay.com/de/impressum" target="_blank">secupay AG, Goethestraße 6, 01896 Pulsnitz</a> abgetreten wurde und die Zahlung durch diese abgewickelt wird. Mit der Bestellung ermächtigst du die secupay AG, die auftragsbezogenen Daten für den Zweck der Zahlungsabwicklung zu speichern und zu nutzen.</span>,
        direct_debit_scoring_formal: <span>Informationen zu Ihrem bisherigen Zahlungsverhalten und Bonitätsinformationen auf Basis mathematisch-statistischer Verfahren unter Anwendung von Anschriftendaten (Scoring) bezieht die secupay AG von der Creditreform Boniversum GmbH, Hammfelddamm 13, 41460 Neuss, der Creditreform Dresden Aumüller KG, Augsburger Str. 4, 01309 Dresden sowie der infoscore Consumer Data GmbH, Rheinstraße 99, 76532 Baden-Baden.</span>,
        direct_debit_scoring_informal: <span>Informationen zu Deinem bisherigen Zahlungsverhalten und Bonitätsinformationen auf Basis mathematisch-statistischer Verfahren unter Anwendung von Anschriftendaten (Scoring) bezieht die secupay AG von der Creditreform Boniversum GmbH, Hammfelddamm 13, 41460 Neuss, der Creditreform Dresden Aumüller KG, Augsburger Str. 4, 01309 Dresden sowie der infoscore Consumer Data GmbH, Rheinstraße 99, 76532 Baden-Baden.</span>,

        // CREDIT CARD
        confirm_payment: 'ZUSTIMMUNG ZUR KREDITKARTENZAHLUNG',
        credit_card: 'Kreditkarte',
        credit_card_success_info: 'Wir weisen Sie darauf hin, dass der Rechnungsbetrag aus dieser Bestellung' +
        ' an die secupay AG Goethestraße 6, 01896 Pulsnitz abgetreten wurde. Die Zahlung erfolgt per Kreditkarte.',
        credit_card_success_info_intent_sale: 'Wir weisen Sie darauf hin, dass der Rechnungsbetrag dieser Zahlung' +
            ' an die secupay AG Goethestraße 6, 01896 Pulsnitz abgetreten wurde. Die Zahlung erfolgt per Kreditkarte.',
        credit_card_assignment_formal: <span>Wir weisen Sie darauf hin, dass die Forderung an die <a href="https://secupay.com/de/impressum" target="_blank">secupay AG, Goethestraße 6, 01896 Pulsnitz</a> abgetreten wurde und die Zahlung durch diese abgewickelt wird. Mit der Bestellung ermächtigen Sie die secupay AG, die auftragsbezogenen Daten für den Zweck der Zahlungsabwicklung zu speichern und zu nutzen.</span>,
        credit_card_assignment_informal: <span>Wir weisen dich darauf hin, dass die Forderung an die <a href="https://secupay.com/de/impressum" target="_blank">secupay AG, Goethestraße 6, 01896 Pulsnitz</a> abgetreten wurde und die Zahlung durch diese abgewickelt wird. Mit der Bestellung ermächtigst du die secupay AG, die auftragsbezogenen Daten für den Zweck der Zahlungsabwicklung zu speichern und zu nutzen.</span>,
        credit_card_scoring_formal: <span>Informationen zu Ihrem bisherigen Zahlungsverhalten und Bonitätsinformationen auf Basis mathematisch-statistischer Verfahren unter Anwendung von Anschriftendaten (Scoring) bezieht die secupay AG von der Creditreform Boniversum GmbH, Hammfelddamm 13, 41460 Neuss, der Creditreform Dresden Aumüller KG, Augsburger Str. 4, 01309 Dresden sowie der infoscore Consumer Data GmbH, Rheinstraße 99, 76532 Baden-Baden.</span>,
        credit_card_scoring_informal: <span>Informationen zu Deinem bisherigen Zahlungsverhalten und Bonitätsinformationen auf Basis mathematisch-statistischer Verfahren unter Anwendung von Anschriftendaten (Scoring) bezieht die secupay AG von der Creditreform Boniversum GmbH, Hammfelddamm 13, 41460 Neuss, der Creditreform Dresden Aumüller KG, Augsburger Str. 4, 01309 Dresden sowie der infoscore Consumer Data GmbH, Rheinstraße 99, 76532 Baden-Baden.</span>,

        // PAY IN ADVANCE
        pay_in_advance_section: (
            <div>
                <p>Zahlung per Vorkasse</p>
                <p class="mb-5">Detailierte Informationen erhalten Sie beim Abschluss der Bestellung.</p>
            </div>
        ),
        account_holder: 'Empfänger',
        reference: 'Verwendungszweck',
        transfer_amount: 'Überweisungsbetrag',
        pay_in_advance_assignment_formal: <span>Wir weisen Sie darauf hin, dass die Forderung an die <a href="https://secupay.com/de/impressum" target="_blank">secupay AG, Goethestraße 6, 01896 Pulsnitz</a> abgetreten wurde und die Zahlung durch diese abgewickelt wird. Mit der Bestellung ermächtigen Sie die secupay AG, die auftragsbezogenen Daten für den Zweck der Zahlungsabwicklung zu speichern und zu nutzen.</span>,
        pay_in_advance_assignment_informal: <span>Wir weisen dich darauf hin, dass die Forderung an die <a href="https://secupay.com/de/impressum" target="_blank">secupay AG, Goethestraße 6, 01896 Pulsnitz</a> abgetreten wurde und die Zahlung durch diese abgewickelt wird. Mit der Bestellung ermächtigst du die secupay AG, die auftragsbezogenen Daten für den Zweck der Zahlungsabwicklung zu speichern und zu nutzen.</span>,

        // INVOICE
        invoice_section: (
            <div>
                <p>Rechnungskauf (Zahlungsziel 10 Tage)</p>
            </div>
        ),
        invoice_assignment_formal: <span>Wir weisen Sie darauf hin, dass die Forderung an die <a href="https://secupay.com/de/impressum" target="_blank">secupay AG, Goethestraße 6, 01896 Pulsnitz</a> abgetreten wurde und die Zahlung durch diese abgewickelt wird. Mit der Bestellung ermächtigen Sie die secupay AG, die auftragsbezogenen Daten für den Zweck der Zahlungsabwicklung zu speichern und zu nutzen.</span>,
        invoice_assignment_informal: <span>Wir weisen dich darauf hin, dass die Forderung an die <a href="https://secupay.com/de/impressum" target="_blank">secupay AG, Goethestraße 6, 01896 Pulsnitz</a> abgetreten wurde und die Zahlung durch diese abgewickelt wird. Mit der Bestellung ermächtigst du die secupay AG, die auftragsbezogenen Daten für den Zweck der Zahlungsabwicklung zu speichern und zu nutzen.</span>,
        invoice_scoring_formal: <span>Informationen zu Ihrem bisherigen Zahlungsverhalten und Bonitätsinformationen auf Basis mathematisch-statistischer Verfahren unter Anwendung von Anschriftendaten (Scoring) bezieht die secupay AG von der Creditreform Boniversum GmbH, Hammfelddamm 13, 41460 Neuss, der Creditreform Dresden Aumüller KG, Augsburger Str. 4, 01309 Dresden sowie der infoscore Consumer Data GmbH, Rheinstraße 99, 76532 Baden-Baden.</span>,
        invoice_scoring_informal: <span>Informationen zu Deinem bisherigen Zahlungsverhalten und Bonitätsinformationen auf Basis mathematisch-statistischer Verfahren unter Anwendung von Anschriftendaten (Scoring) bezieht die secupay AG von der Creditreform Boniversum GmbH, Hammfelddamm 13, 41460 Neuss, der Creditreform Dresden Aumüller KG, Augsburger Str. 4, 01309 Dresden sowie der infoscore Consumer Data GmbH, Rheinstraße 99, 76532 Baden-Baden.</span>,

        // PAYPAL
        paypal_section: 'E-Mail-Adresse',

        // ERROR PAGE
        error_headline: 'ES IST EIN FEHLER AUFGETRETEN',
        ['error_headline_' + ErrorMessageKey.MISCELLANEOUS_ERROR]: 'ANWENDUNGSFEHLER',
        ['error_message_' + ErrorMessageKey.MISCELLANEOUS_ERROR]: <div>
            <p>Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal. Sollte sich das Problem so nicht beheben lassen, nehmen Sie bitte Kontakt zum Händler auf.</p>
            <p>Bitte entschuldigen Sie die Unannehmlichkeiten.
            </p>
        </div>,
        ['error_headline_' + ErrorMessageKey.STX_ALREADY_STARTED]: 'BEREITS FRÜHER ABGESCHLOSSEN',
        ['error_message_' + ErrorMessageKey.STX_ALREADY_STARTED]: <div>
            <p>Die Bestellung wurde bereits abgeschlossen. Sie haben dazu eine E-Mail mit allen wichtigen Details von uns erhalten.</p>
            <p>Für weitere Informationen nehmen Sie bitte Kontakt zum Händler auf.</p>
        </div>,
        ['error_headline_' + ErrorMessageKey.STX_ALREADY_STARTED + '_' + Intent.SALE]: 'BEREITS FRÜHER ABGESCHLOSSEN',
        ['error_message_' + ErrorMessageKey.STX_ALREADY_STARTED + '_' + Intent.SALE]: <div>
            <p>Die Zahlung wurde bereits zu einem früheren Zeitpunkt abgeschlossen.</p>
            <p>Für weitere Informationen nehmen Sie bitte Kontakt zum Händler auf.</p>
        </div>,
        ['error_headline_' + ErrorMessageKey.STX_DELETED]: 'VORGANG GELÖSCHT',
        ['error_message_' + ErrorMessageKey.STX_DELETED]: <div>
            <p>Dieser Vorgang kann nicht mehr abgeschlossen werden.</p>
        </div>,
        ['error_headline_' + ErrorMessageKey.STX_START_PROBLEM]: 'PROBLEM BEIM BEZAHLVORGANG',
        ['error_message_' + ErrorMessageKey.STX_START_PROBLEM]: <div>
            <p>Es gibt ein Problem während des Zahlungsprozesses. Bitte nehmen Sie Kontakt zum Händler auf.</p>
            <p>Bitte entschuldigen Sie die Unannehmlichkeiten.</p>
        </div>,
        ['error_headline_' + ErrorMessageKey.NO_PAYMENT_METHOD_REMAINING]: 'PROBLEM BEIM BEZAHLVORGANG',
        ['error_message_' + ErrorMessageKey.NO_PAYMENT_METHOD_REMAINING]: <div>
            <p>Der Vorgang kann nicht abgeschlossen werden. Für weitere Informationen nehmen Sie bitte Kontakt zum Händler auf.</p>
            <p>Ihr Konto wurde nicht belastet.</p>
            <p>Bitte entschuldigen Sie die Unannehmlichkeiten.</p>
        </div>,
        ['error_headline_' + ErrorMessageKey.COLLECTION_MISCONFIGURATION]: 'KEINE ABHOLFILIALE',
        ['error_message_' + ErrorMessageKey.COLLECTION_MISCONFIGURATION]: <div>
            <p>Derzeit ist keine Filiale des Händlers für die Abholung der Ware eingerichtet. Der Vorgang kann nicht fortgesetzt werden. Für weitere Informationen nehmen Sie bitte Kontakt zum Händler auf.</p>
            <p>Bitte entschuldigen Sie die Unannehmlichkeiten.</p>
        </div>,
        ['error_headline_' + ErrorMessageKey.BOOT_PROBLEM]: 'ANWENDUNGSFEHLER',
        ['error_message_' + ErrorMessageKey.BOOT_PROBLEM]: <div>
            <p>Der Vorgang kann nicht gestartet werden. Für weitere Informationen nehmen Sie bitte Kontakt zum Händler auf.</p>
            <p>Bitte entschuldigen Sie die Unannehmlichkeiten.</p>
        </div>,
        ['error_headline_' + ErrorMessageKey.ARBITRARY_SESSION_PROBLEM]: 'PROBLEM MIT BENUTZERSITZUNG',
        ['error_message_' + ErrorMessageKey.ARBITRARY_SESSION_PROBLEM]: <div>
            <p>Die Benutzersitzung kann nicht fortgesetzt werden. Wahrscheinlich ist ihre Sitzung nach längerer Inaktivität abgelaufen.</p>
            <p>Um Ihre Bestellung abzuschließen, starten Sie den Vorgang noch einmal. Sollte sich das Problem so nicht beheben lassen, nehmen Sie bitte Kontakt zum Händler auf.</p>
        </div>,
        ['error_headline_' + ErrorMessageKey.SESSION_TIMEOUT]: 'BENUTZERSITZUNG ABGELAUFEN',
        ['error_message_' + ErrorMessageKey.SESSION_TIMEOUT]: <div>
            <p>Die Benutzersitzung kann nach längerer Inaktivität nicht fortgesetzt werden.</p>
            <p>Um Ihre Bestellung abzuschließen, starten Sie den Vorgang noch einmal. Sollte sich das Problem so nicht beheben lassen, nehmen Sie bitte Kontakt zum Händler auf.</p>
        </div>,
        error_session_expired: (
            <div>
                <p>Das gesendete Sitzungstoken ist ungültig. Wahrscheinlich ist ihre Sitzung nach längerer Inaktivität
                    abgelaufen.</p>
                <br/>
                <p>Um Ihre Bestellung abzuschließen, starten Sie den Checkout-Vorgang bitte erneut.</p>
                <p>Für Fragen kontaktieren Sie bitte den Händler.</p>
            </div>
        ),
        error_delivery_options_misconfiguration: (
            <div>
                <p>Derzeit ist keine Filiale des Händlers für die Abholung eingerichtet. Der Vorgang kann nicht fortgesetzt werden.
                    Für weitere Informationen nehmen Sie bitte Kontakt zum Händler auf.</p>
                <br/>
                <p>Bitte entschuldigen Sie die Unannehmlichkeiten.</p>
            </div>
        ),
        error_missing_legal_details: (
            <div>
                <p>Fehlende Dokumente zu rechtlichen Details</p>
            </div>
        ),
        error_other: (
            <div>
                <p>Ihre Bestellung konnte nicht abgeschlossen werden. Wir entschuldigen uns für die Unannehmlichkeiten.
                    Bitte versuchen Sie es erneut.</p>
                <br/>
                <p>Ihr Konto wird nicht belastet.</p>
                <p>Für Fragen kontaktieren Sie bitte den Händler.</p>
            </div>
        ),
        session_expired: 'SESSION ABGELAUFEN',
        error_back_to_the_shop_button: 'ZURÜCK ZUM SHOP',

        // SUCCESS PAGE
        success_your_pick_up_code: 'Ihr persönlicher Abholcode',
        success_back_to_the_shop_button: 'ZURÜCK ZUM SHOP',
        success_page_header: 'BESTELLBESTÄTIGUNG',
        success_page_header_intent_sale: 'BESTÄTIGUNG',
        success_thanks: 'Vielen Dank für Ihren Einkauf mit dem secupay smart checkout. Wir wünschen Ihnen viel ' +
        ' Freude mit Ihrer Bestellung.',
        success_thanks_intent_sale: 'Vielen Dank für die Zahlung.',
        success_direct_debit_assign_1st_part: 'Wir weisen Sie darauf hin, dass der Rechnungsbetrag aus dieser' +
        ' Bestellung an die secupay AG,' +
        ' Goethestraße 6, 01896 Pulsnitz abgetreten wurde. Sie haben uns am ',
        success_direct_debit_assign_2nd_part: ' dazu folgendes SEPA-Mandat erteilt:',
        success_direct_debit_assign_1st_part_intent_sale: 'Wir weisen Sie darauf hin, dass der Rechnungsbetrag dieser Zahlung' +
            ' an die secupay AG,' +
            ' Goethestraße 6, 01896 Pulsnitz abgetreten wurde. Sie haben uns am ',
        success_direct_debit_assign_2nd_part_intent_sale: ' dazu folgendes SEPA-Mandat erteilt:',
        creditor_identification_number: 'Gläubiger-Identifikationsnummer',
        mandate_reference: 'Mandatsreferenz',
        mandate_type: 'Mandatstyp',
        recurring_payments: 'wiederkehrende Zahlungen',
        success_direct_debit_authorization: 'Ich ermächtige die secupay AG, Goethestr. 6, 01896 Pulsnitz, Zahlungen ' +
        'von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der' +
        ' secupay AG auf mein Konto gezogenen Lastschriften einzulösen.',
        success_email_note: 'Sie erhalten alle wichtigen Informationen zusätzlich per E-Mail.',
        success_pay_in_advance_assign: 'Wir weisen Sie darauf hin, dass der Versand der Ware erst nach Eingang der' +
        ' Zahlung erfolgt. Bitte zahlen Sie auf folgendes Treuhandkonto der secupay AG:',
        success_pay_in_advance_assign_intent_sale: 'Bitte zahlen Sie auf folgendes Treuhandkonto der secupay AG:',
        success_invoice: 'Wir weisen Sie darauf hin, dass der Rechnungsbetrag aus dieser Bestellung an die secupay AG, ' +
        'Goethestraße 6, 01896 Pulsnitz abgetreten wurde. Der Rechnungsbetrag ist zahlbar binnen 10 Tagen ab Lieferung. ' +
        'Sie erhalten die Zahlungsinformationen mit der Rechnung.',
        success_invoice_intent_sale: 'Wir weisen Sie darauf hin, dass der Rechnungsbetrag dieser Zahlung an die secupay AG, ' +
            'Goethestraße 6, 01896 Pulsnitz abgetreten wurde. Der Rechnungsbetrag ist zahlbar binnen 10 Tagen ab Lieferung. ' +
            'Sie erhalten die Zahlungsinformationen mit der Rechnung.',
        success_paypal_basic: 'Die Zahlung erfolgt per PayPal an den Händler.',
        success_collection_hint: 'Vergessen Sie nicht, Ihre Bestellung pünktlich abzuholen bei ',

        // DESCRIPTION
        desc_headline: 'SMART CHECKOUT BESCHREIBUNG',
        desc_snd_line: 'WILLKOMMEN BEIM SMART CHECKOUT VON SECUPAY',
        desc_trd_line: 'Und so funktioniert es:',
        desc_overview_name: 'Übersicht',
        desc_overview: ' der Händlerdaten, Ihres Warenkorbes und Ihrer persönlichen Bestelldaten.',
        desc_address_name: 'Adresseingabe',
        desc_address: ', hier hinterlegen Sie Ihre persönliche' +
        ' Rechnungs- und Lieferadresse. Bei Einwilligung des Speicherns' +
        ' Ihrer Daten können Sie bei Ihrem nächsten Einkauf' +
        ' diesen Schritt überspringen.',
        desc_payment_name: 'Zahlungsmitteleingabe',
        desc_payment: ', hier wählen Sie Ihre gewünschte Zahlungsart' +
        ' und hinterlegen Ihre dazugehörigen Zahlungsdaten. Bei' +
        ' Einwilligung des Speicherns Ihrer Daten können Sie bei' +
        ' Ihrem nächsten Einkauf diesen Schritt überspringen.',
        desc_confirmation_name: 'Bestellbestätigung',
        desc_confirmation: ', hier finden Sie Ihre Daten noch einmal' +
        ' zusammengefasst. Sie können die Händlerdaten, Ihren Warenkorb' +
        ' sowie Ihre persönlichen Bestelldaten einsehen und verwalten.' +
        ' Nach Bestätigung Ihrer Bestellung, haben Sie die Möglichkeit' +
        ' Ihre eingegebenen Daten für die nächsten Einkäufe zu hinterlegen.',

        // IMPRINT SECUPAY
        imprint_text_secupay: imprint_de,

        // PRIVACY POLICY SECUPAY
        privacy_policy_text_secupay: privacy_de
    }
};
