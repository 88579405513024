import {Component, h} from 'preact';
import {InputTemplate} from '../../templates/InputTemplate';
import InputContainer from '../../common/input/InputContainer';
import {AppStage, CommonProps, InputAttributes, InputName, PaymentMethod, SessionData, ViewMode,} from '../../common/types';
import InfoMerchantComponent from '../../common/info/InfoMerchantComponent';

import HTMLInputElementDecorator from '../../common/input/HTMLInputElementDecorator';
import {createHeadlineElement, getPrimaryColor, handleKeyboardEvent, parseDate, transformYear} from '../../common/utils';
import ValidationInstances from '../../common/input/ValidationInstances';
import InfoBasket from '../../common/info/InfoBasketComponent';
import FilterInstances from '../../common/input/FilterInstances';
import Spinner from '../../common/components/Spinner';
import {isNull} from 'util';

interface CheckInComponentProps extends CommonProps {
    isFullscreen: boolean;
    dataKey: string;
    isInitialized: boolean;

    setInitialized(isInitialized: boolean): void;
    setPayPalExpressAvailability(state: boolean): void;
    getPayPalExpressAvailability(): boolean;
}

interface CheckInComponentState {
    sessionData: SessionData | undefined;
}

export default class CheckInComponent extends Component<CheckInComponentProps, CheckInComponentState> {

    private inputContainer: InputContainer = new InputContainer(this);
    private inputParams: InputAttributes[] = [];
    private form: HTMLFormElement;
    readonly formRefCallback = (form: HTMLFormElement) => this.form = form;

    constructor(props: CheckInComponentProps) {
        super(props);

        this.state = {
            sessionData: undefined
        };

        this.inputParams = [
            {
                containerClassName: 'col-mob-6',
                name: InputName.EMAIL,
                autocomplete: 'email',
                placeholder: this.props.getStringTranslationByKey('email_address'),
                onSubmitFilter: FilterInstances.emailFilterInstance(),
                onBlurPaste: FilterInstances.removeAllIntermediateWhitespacesAndTrimFilterInstance(),
                validationInstance: ValidationInstances.getEmailValidationInstance(),
                customProps: {
                    autofocus: this.props.viewMode === ViewMode.FULLSCREEN,
                    inputmode: 'email'
                }
            },
            {
                containerClassName: 'col-mob-6',
                name: InputName.DATE,
                required: false,
                autocomplete: 'bday',
                placeholder: this.props.getStringTranslationByKey('dateformat'),
                validationInstance: ValidationInstances.getBirthdateValidationInstance(),
                onBlurPaste: FilterInstances.birthDateFilterInstance()
            }
        ];

        this.goToNextStage = this.goToNextStage.bind(this);
        this.constructCheckinRequestBody = this.constructCheckinRequestBody.bind(this);
        this.getInputContainer = this.getInputContainer.bind(this);
        this.isPayPalExpressAvailable = this.isPayPalExpressAvailable.bind(this);
        this.props.setButton(this.goToNextStage, 'next_step_button');
        this.initializationCompleted = this.initializationCompleted.bind(this);
    }

    public getInputContainer(): InputContainer { // for testing purposes
        return this.inputContainer;
    }

    public componentDidMount() {
        this.form.reset();
        if (this.props.checkoutFromStx) {
            let sessionData: SessionData | null = this.props.sessionData;
            if(!isNull(sessionData)) {
                this.props.setOrderOptions(sessionData.merchant.order_options);
                this.props.setPayPalExpressAvailability(this.isPayPalExpressAvailable());
            }
        } else {
            this.initializationCompleted();
        }
        // Work only in fullscreen mode
        // First focus action inside iframe should be from user
        if(this.props.viewMode === ViewMode.FULLSCREEN) {
            (document.querySelector(`[name="${InputName.EMAIL}"]`) as HTMLInputElement).focus();
        }
    }

    public componentWillReceiveProps() {
        this.inputParams[0].placeholder = this.props.getStringTranslationByKey('email_address');
        this.inputParams[1].placeholder = this.props.getStringTranslationByKey('dateformat');
    }

    public render(): JSX.Element {
        return (
            <div>
                {this.props.isInitialized
                    ? <InfoMerchantComponent
                            isShippingEnabled={this.context.deliveryOptions.isShippingEnabled}
                            {...this.props as CommonProps}
                            additionalWrapperClass={'mb-30'}
                        />
                    : <div className="col-xs-12">
                        {createHeadlineElement(this.props, this.props.getStringTranslationByKey('merchant_header'))}
                    <Spinner/>
                </div>}

                {this.props.isInitialized ? <InfoBasket
                    {...this.props as CommonProps}
                    additionalWrapperClass={'mb-30'}
                /> : <div className="container">
                    <div className="col-sm-12">
                        {createHeadlineElement(this.props, this.props.getStringTranslationByKey('basket_header'))}
                        <Spinner/>
                    </div>
                </div>}

                <div className="container" id="checkin-inputs">
                    <div className="col-sm-12">
                        {createHeadlineElement(this.props, this.props.getStringTranslationByKey('checkin_header').toUpperCase())}
                    </div>
                    <form class="full-width" ref={this.formRefCallback}>
                        {this.inputParams.map(
                            (val: InputAttributes, index) => {
                                return (
                                    <InputTemplate
                                        key={index}
                                        inputAttributes={val}
                                        container={this.inputContainer}
                                        colorMandatory={getPrimaryColor(this.props)}
                                    />
                                );
                            })}
                    </form>
                </div>

                <div class="container mb-5">
                    <div class="col-sm-12">
                        {this.props.getStringTranslationByKey('checkin_privacy_1')}
                        <label for="privacySecuPay" class="info-link-theme" tabIndex={0} onKeyUp={handleKeyboardEvent}>
                            {this.props.getStringTranslationByKey('policy')}
                        </label>
                        {this.props.getStringTranslationByKey('checkin_privacy_2')}
                    </div>
                </div>
            </div>
        );
    }

    private constructCheckinRequestBody(): { [key: string]: string | object } {
        const inputs: { [key: string]: HTMLInputElementDecorator } = this.inputContainer.getDecorators();
        /*tslint:disable:no-string-literal*/

        const result = {
            [InputName.EMAIL]: inputs[InputName.EMAIL].getField().value,
        };

        if (inputs[InputName.DATE].getField().value) {
            let parsedDate: string[];
            parsedDate = parseDate(inputs[InputName.DATE]);
            const dateObj = new Date(
                Date.UTC(
                    +transformYear(parsedDate[2]),
                    +parsedDate[1] - 1,
                    +parsedDate[0],
                    0,
                    0,
                    0,
                    0
                )
            );
            result['dob'] = dateObj.toISOString().replace(/\.[0-9]{3}/, ''); // Remove milliseconds
        }

        return result;
        /*tslint:enable:no-string-literal*/
    }

    private goToNextStage(): void {
        this.inputContainer.filterAllInputs();
        if (!this.inputContainer.validateInputs()) {
            parent.window.postMessage('JumpToTheTop', '*');

            if (window.scrollY) {
                window.scroll(0, 0);  // reset the scroll position to the top left of the document.
            }

            (document.getElementById('content') as HTMLInputElement).scrollTop = 0;
            return;
        }
        this.props.setLoading(true);

        this.props.callBackend(
            '/checkin',
            () => this.props.setStage(this.props.stageData.next as AppStage),
            'POST',
            this.constructCheckinRequestBody()
        );
    }

    private initializationCompleted() {
        this.props.setLoading(false);
        this.props.setInitialized(true);
        this.props.setPayPalExpressAvailability(this.isPayPalExpressAvailable());
    }

    private isPayPalExpressAvailable() {
        let pm = this.props.getAvailablePaymentMethods();
        return pm.indexOf(PaymentMethod.PAYPAL) !== -1;
    }
}
