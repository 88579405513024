import {Component, h} from 'preact';
import {CommonProps, Creditor, Intent, ResponseHttp} from '../../common/types';
import {isNull} from 'util';

interface DirectDebitInfoProps extends CommonProps {
}

interface DirectDebitInfoState {
    creditor: Creditor;
}

export default class DirectDebitInfo extends Component<DirectDebitInfoProps, DirectDebitInfoState> {
    private readonly dummyMandate = {
        identification: 'OTM/TESTMANDATE123456',
        mandate_date: '1970-01-01',
    };

    constructor() {

        super();

        this.state = {
            creditor: {
                identifier: '',
                mandateReference: '',
                mandateDate: '',
            }
        };

        this.getCreditorInfo = this.getCreditorInfo.bind(this);
    }

    componentDidMount() {
        this.getCreditorInfo();
    }

    public render(): JSX.Element {
        return (
            <div>
                <p class="no-margin-bottom">
                    {this.props.sessionData && this.props.sessionData.transaction.intent === Intent.SALE ?
                        this.props.getStringTranslationByKey('success_direct_debit_assign_1st_part_intent_sale') :
                        this.props.getStringTranslationByKey('success_direct_debit_assign_1st_part')}

                    {this.state.creditor.mandateDate && this.showProperDateFormat(this.state.creditor.mandateDate)}

                    {this.props.sessionData && this.props.sessionData.transaction.intent === Intent.SALE ?
                        this.props.getStringTranslationByKey('success_direct_debit_assign_2nd_part_intent_sale') :
                        this.props.getStringTranslationByKey('success_direct_debit_assign_2nd_part')}
                </p>

                <table class="table table-payment-info mt-20">
                    <tr>
                        <td>{this.props.getStringTranslationByKey('creditor_identification_number')}</td>
                        <td>{this.state.creditor.identifier}</td>
                    </tr>
                    <tr>
                        <td>{this.props.getStringTranslationByKey('mandate_reference')}</td>
                        <td>{this.state.creditor.mandateReference}</td>
                    </tr>
                    <tr>
                        <td>{this.props.getStringTranslationByKey('mandate_type')}</td>
                        <td>{this.props.getStringTranslationByKey('recurring_payments')}</td>
                    </tr>
                </table>
            </div>);
    }

    private getCreditorInfo() {
        this.props.callBackend(
            '/creditor-info',
            (response: ResponseHttp) => {
                let creditorInfo = JSON.parse(response.text);

                // this may happen only in dev environment, so we do this just for aesthetics purposes
                if (isNull(creditorInfo.mandate)) {
                    creditorInfo.mandate = this.dummyMandate;
                }

                this.setState({
                    creditor: {
                        identifier: creditorInfo.creditorId,
                        mandateReference: creditorInfo.mandate.identification,
                        // Assuming we receive date in ISO string format
                        mandateDate: creditorInfo.mandate.mandate_date.substr(0, 10),
                    }
                });
            }
        );
    }

    private showProperDateFormat(date: string): JSX.Element {
        const time: Date = new Date(date.toString().replace(/-/g, '/')), // collection time
            year: number = time.getFullYear(),
            month: number = time.getMonth() + 1,
            day: number = time.getDate(),
            // region-specific date separator
            dateSeparator: string = this.props.getStringTranslationByKey('date_separator');

        return <span>{day + dateSeparator + month + dateSeparator + year}</span>;
    }
}