import { h } from 'preact';
import { CommonProps } from '../../common/types';

export default function InvoiceTemplate(props: CommonProps) {
    return (
        <div>
            <img src={require('../../../img/payment-methods/invoice.png')}/>
            {props.getJSXTranslationByKey('invoice_section')}
        </div>
    );
}