import {Component, h} from 'preact';
import {
    AppStage,
    CommonProps,
    ErrorMessageKey,
    LanguageSymbol,
    PaymentMethod,
    PayPalFlowType,
    ResponseHttp,
    SessionData,
    WizardFlow
} from '../../common/types';
import {isNull, isNullOrUndefined, isUndefined} from 'util';
import PaymentIconTemplate from './PaymentIconTemplate';
import {
    createHeadlineElement,
    getPrimaryColor,
    hexToRgb,
    paymentWindowBasedOnFlow,
    startPayPalPayment
} from "../../common/utils";
import {PaymentLinkInfoHeader} from "../../templates/PaymentLinkInfoHeader";

interface PaymentMethodsComponentState {
    paymentMethods: PaymentMethod[];
}

export default class PaymentMethodsComponent
    extends Component<CommonProps, PaymentMethodsComponentState> {

    public constructor(props: CommonProps) {
        super(props);

        this.state = {paymentMethods: []};

        this.props.setButton(() => {
            return;
        }, 'next_step_button', false, false, true);
    }

    public setPaymentView(paymentMethods: PaymentMethod[]) {
        if (paymentMethods.length === 0) {
            this.props.setStage(AppStage.STAGE_ERROR, ErrorMessageKey.NO_PAYMENT_METHOD_REMAINING);
        } else if (paymentMethods.length === 1) {
            this.proceedByMethod(paymentMethods[0]);
        } else {
            this.setState({paymentMethods: paymentMethods});
            this.props.setLoading(false);
        }
    }

    public componentDidMount() {
        let sessionData = this.props.sessionData;
        if (!isNull(sessionData)) {
            this.setPaymentView(sessionData.payment_methods);
        }
    }

    public componentDidUpdate() {
        if(this.props.isLoading === false) {
            PaymentMethodsComponent.removeFocus();
        }
    }

    public render(): JSX.Element {

        return (
            <div class="content-text-theme" id="payment-methods">
                {this.props.isPaymentLinkFlow() ?
                    <PaymentLinkInfoHeader
                        {...this.props}
                        isShippingEnabled={this.context.deliveryOptions.isShippingEnabled}
                        additionalWrapperClass={'mb-30'}/>
                    : null}
                <section>
                    <div class="container">
                        <div class="col-xs-12">
                            {createHeadlineElement(this.props, this.props.getStringTranslationByKey('pay_with').toUpperCase())}
                        </div>
                        {this.state.paymentMethods.map(
                            (method: PaymentMethod, index) => {
                                return (
                                    <div key={index}
                                         className={"col-mob-6 overflow-auto " + "payment-method-wrapper-" + index}>
                                        <div class="payment-method-button">
                                            <button
                                                class={
                                                    'col-xs-12'
                                                    + ' payment-method-'
                                                    + method.toLowerCase().replace(/ /g, '-')
                                                }
                                                onClick={(event) => this.proceedByMethod(method, event)}
                                                onMouseOver={(event) => PaymentMethodsComponent.customHover(event, this.props)}
                                                onMouseDown={(event) => PaymentMethodsComponent.customHover(event, this.props)}
                                                onFocus={(event) => PaymentMethodsComponent.customHover(event, this.props)}
                                                onMouseOut={(event) => PaymentMethodsComponent.customMouseOut(event, this.props)}
                                                onBlur={(event) => PaymentMethodsComponent.customBlur(event, this.props)}
                                            >
                                                <PaymentIconTemplate
                                                    paymentMethod={method}
                                                    currentLang={this.props.currentLang}/>
                                                <span
                                                    onMouseOver={(event) => PaymentMethodsComponent.customHover(event, this.props)}
                                                    onMouseDown={(event) => PaymentMethodsComponent.customHover(event, this.props)}
                                                    onFocus={(event) => PaymentMethodsComponent.customHover(event, this.props)}
                                                    onMouseOut={(event) => PaymentMethodsComponent.customMouseOut(event, this.props)}
                                                    onBlur={(event) => PaymentMethodsComponent.customBlur(event, this.props)}
                                                >
                                                    {this.getPaymentMethodDescription(method)}
                                                </span>
                                            </button>
                                        </div>
                                    </div>);
                            })
                        }
                    </div>
                </section>
            </div>
        );
    }

    private getPaymentMethodDescription(paymentMethod: PaymentMethod | null): string {
        let translatedPaymentMethod = {
            [PaymentMethod.SECUCARD]: this.props.getStringTranslationByKey('payment_method_secucard'),
            [PaymentMethod.DIRECT_DEBIT]: this.props.getStringTranslationByKey('payment_method_direct_debit'),
            [PaymentMethod.CREDIT_CARD]: this.props.getStringTranslationByKey('payment_method_credit_card'),
            [PaymentMethod.PAYPAL]: this.props.getStringTranslationByKey('payment_method_paypal'),
            [PaymentMethod.SOFORT]: this.props.getStringTranslationByKey('payment_method_credit_card'),
            [PaymentMethod.PAY_IN_ADVANCE]: this.props.getStringTranslationByKey('payment_method_pay_in_advance'),
            [PaymentMethod.INVOICE]: this.props.getStringTranslationByKey('payment_method_invoice')
        };

        if (isNullOrUndefined(paymentMethod)
            || (paymentMethod === PaymentMethod.ERROR)
            || isUndefined(translatedPaymentMethod[paymentMethod])
        ) {
            return '';
        } else {
            return translatedPaymentMethod[paymentMethod];
        }
    }

    private proceedByMethod(method: PaymentMethod, event?: Event) {

        if (event) {
            PaymentMethodsComponent.customHover(event, this.props);
        }

        this.props.showMessageBox(false);
        this.props.setLoading(true, false);
        if (this.props.wizardFlow === WizardFlow.PAYMENT) {
            this.props.setPaymentMethod(method);
        }
        const proceed = (response: ResponseHttp) => {
            if (this.props.wizardFlow === WizardFlow.PAYMENT && method === PaymentMethod.PAY_IN_ADVANCE) {
                this.props.callBackend(
                    '/start',
                    () => {
                        this.props.setStage(JSON.parse(response.text).route.next, paymentWindowBasedOnFlow[this.props.wizardFlow][method]);
                    },
                    'POST',
                    {}
                );
            } else {
                this.props.setStage(JSON.parse(response.text).route.next, paymentWindowBasedOnFlow[this.props.wizardFlow][method]);
            }
        };
        this.props.callBackend(
            '/payment-method',
            response => {

                switch (method) {
                    case PaymentMethod.SECUCARD:
                    case PaymentMethod.DIRECT_DEBIT:
                    case PaymentMethod.CREDIT_CARD:
                    case PaymentMethod.SOFORT:
                    case PaymentMethod.PAY_IN_ADVANCE:
                        proceed(response);
                        return;
                    case PaymentMethod.INVOICE:
                        this.props.callBackend(
                            '/prepare',
                            () => proceed(response),
                            'POST',
                            {},
                            () => {
                                this.props.callBackend(
                                    '/session-data',
                                    (responseSessionData: ResponseHttp) => {
                                        let paymentMethod: PaymentMethod;
                                        let pmLongName: string = '';
                                        const paymentMethods = (JSON.parse(responseSessionData.text) as SessionData).payment_methods;

                                        if (paymentMethods.length === 1) {
                                            paymentMethod = paymentMethods[0];
                                            this.props.setBoxTextMessageAtTheBeginning((lang: LanguageSymbol) => {
                                                let boxTextMessageAtTheBeginning: string;
                                                switch (paymentMethod) {
                                                    case PaymentMethod.DIRECT_DEBIT:
                                                        pmLongName = this.props.getStringTranslationByKey('payment_method_direct_debit');
                                                        break;
                                                    case PaymentMethod.CREDIT_CARD:
                                                        pmLongName = lang === LanguageSymbol.EN_GB ? this.props.getStringTranslationByKey('payment_method_credit_card').toLowerCase() : this.props.getStringTranslationByKey('payment_method_credit_card');
                                                        break;
                                                    case PaymentMethod.PAY_IN_ADVANCE:
                                                        pmLongName = lang === LanguageSymbol.EN_GB ? this.props.getStringTranslationByKey('payment_method_pay_in_advance').toLowerCase() : this.props.getStringTranslationByKey('payment_method_pay_in_advance');
                                                        break;
                                                }

                                                boxTextMessageAtTheBeginning =
                                                    this.props.getStringTranslationByKey('payment_method_invoice_fail_1') +
                                                    pmLongName +
                                                    this.props.getStringTranslationByKey('payment_method_invoice_fail_2');
                                                return boxTextMessageAtTheBeginning;
                                            });
                                        } else if (paymentMethods.length > 1) {
                                            this.props.showMessageBox(true, 'payment_method_more_available_scoring_failed', true);
                                        }
                                        this.setPaymentView(paymentMethods);
                                    }
                                );
                            }
                        );
                        return;
                    case PaymentMethod.PAYPAL:
                        startPayPalPayment(this.props, PayPalFlowType.FLOW_TYPE_REGULAR);
                        return;
                    default:
                        return;
                }
            },
            'POST',
            {type: method},
            () => {
                const paymentMethods = this.props.getAvailablePaymentMethods();
                if (paymentMethods.length === 1) {
                    this.props.setBoxTextMessageAtTheBeginning(this.props.getStringTranslationByKey('payment_method_one_available_scoring_failed'));
                } else if (paymentMethods.length > 1) {
                    this.props.showMessageBox(true, 'payment_method_more_available_scoring_failed', true);
                }
            }
        );

        // TODO: this switch will disapperar soon ;)

    }

    private static customHover(event: Event, props: CommonProps) {
        let primaryColour: string = getPrimaryColor(props);
        let rgb, r, g, b, bg;

        if (primaryColour) {
            rgb = hexToRgb(primaryColour);
            r = rgb.r;
            g = rgb.g;
            b = rgb.b;
            bg = `rgba(${r}, ${g}, ${b}, 0.3)`;
            PaymentMethodsComponent.getPaymentButton(event).style.backgroundColor = bg;
        }
    }

    private static customHoverOut(event: Event, props: CommonProps) {
        PaymentMethodsComponent.getPaymentButton(event).style.backgroundColor = "";
    }

    private static customBlur(event: Event, props: CommonProps) {
        if (!props.isLoading) {
            PaymentMethodsComponent.customHoverOut(event, props);
        }
    }

    private static customMouseOut(event: Event, props: CommonProps) {
        if (!props.isLoading) {
            PaymentMethodsComponent.customHoverOut(event, props);
        }
    }

    private static getPaymentButton(event: Event): HTMLElement {
        let element: HTMLElement = event.target as HTMLElement;
        let button: HTMLElement;

        button = ((element.closest('.payment-method-button') as HTMLElement).querySelector('button') as HTMLElement);
        return button;
    }

    // no button should have focus at the beginning
    private static removeFocus(): void {
        let activeElement  = document.activeElement as HTMLElement;
        if(activeElement && activeElement.blur) {
            activeElement.blur();
        }
    }
}
