import {h} from 'preact';
import {CommonProps, LanguageSymbol, LegalDetailType, WizardFlow} from '../types';
import InfoCheckoutComponent from '../info/InfoCheckoutComponent';
import InfoModal from '../info/InfoModal';
import BackButton from '../buttons/BackButton';
import {handleKeyboardEvent} from "../utils";

interface FooterProps extends CommonProps {
    setLanguage(lang: string): void;
}

const SmartCheckoutDescription = (props: CommonProps) => {
    return (
        <InfoModal modalId="smart_checkout_description">
            <InfoCheckoutComponent {...props}/>
        </InfoModal>
    );
};

const ImprintSecuPay = (props: CommonProps) => {
    return (
        <InfoModal modalId="imprintSecuPay">
            {props.getJSXTranslationByKey('imprint_text_secupay')}
        </InfoModal>
    );
};

const PrivacySecuPay = (props: CommonProps) => {
    return (
        <InfoModal modalId="privacySecuPay">
            {props.getJSXTranslationByKey('privacy_policy_text_secupay')}
        </InfoModal>
    );
};

const LanguageToggler = (props: FooterProps) => {
    let alternative = '';
    if (props.currentLang === LanguageSymbol.DE_DE) {
        alternative = LanguageSymbol.EN_GB;
    } else {
        alternative = LanguageSymbol.DE_DE;
    }
    return (
        <img tabIndex={0} src={require('../../../img/' + alternative + '.svg')} alt="language icon" onClick={() => {
            props.setLanguage(alternative);
        }}
             onKeyUp={handleKeyboardEvent}
             class="img-lang-switch"/>
    );
};

function prepareLinkScDescription(props: CommonProps) {
    switch (props.wizardFlow) {
        case WizardFlow.PAYMENT:
            return null;
        default:
            return <div class="link-text-theme"> | <label class="display-inline no-wrap"
                                                          for="smart_checkout_description"
                                                          tabIndex={0}
                                                          onKeyUp={handleKeyboardEvent}>
                {props.getStringTranslationByKey('smart_description')} </label>
            </div>
    }
}

function prepareModalScDescription(props: CommonProps) {
    switch (props.wizardFlow) {
        case WizardFlow.PAYMENT:
            return null;
        default:
            return <SmartCheckoutDescription {...props}/>
    }
}

function prepareLinkPrivatePolicy(props: CommonProps) {
    switch (props.wizardFlow) {
        // prepare like other links
        //yes I know, now it's only default state
        default:
            return <div class="link-text-theme">
                <label class="display-inline" for="privacySecuPay" tabIndex={0} onKeyUp={handleKeyboardEvent}>
                    {props.getStringTranslationByKey(LegalDetailType.TYPE_PRIVACY_POLICY)}
                </label>
            </div>
    }
}

function prepareModalPrivatePolicy(props: CommonProps) {
    switch (props.wizardFlow) {
        default:
            return <PrivacySecuPay {...props}/>
    }
}

function prepareLinkImprint(props: CommonProps) {
    switch (props.wizardFlow) {
        // prepare like other links
        //yes I know, now it's only default state
        default:
            return <div class="link-text-theme">
                <label class="display-inline" for="imprintSecuPay" tabIndex={0} onKeyUp={handleKeyboardEvent}>
                    {props.getStringTranslationByKey(LegalDetailType.TYPE_IMPRINT)} |
                </label>
            </div>
    }
}

function prepareModalImprint(props: CommonProps) {
    switch (props.wizardFlow) {
        default:
            return <ImprintSecuPay {...props}/>
    }
}

export default function Footer(props: FooterProps) {
    return (
        props.isInitialized ?
        <section id="footer" class="content-text-theme col-sm-12">
            <hr class="line-header-footer"/>
            <div class="container no-margin-bottom mt-30">

                <div class="col-xs-1 pl-0 pr-0">
                    <BackButton {...props} />
                </div>

                <div class="col-xs-10 text-center">
                    {prepareLinkImprint(props)}
                    {prepareLinkPrivatePolicy(props)}
                    {prepareLinkScDescription(props)}
                </div>

                <div class="col-xs-1 pl-0 pr-0">
                    <LanguageToggler {...props}/>
                </div>
            </div>

            {prepareModalImprint(props)}
            {prepareModalPrivatePolicy(props)}
            {prepareModalScDescription(props)}
        </section>
            : null
    );
}
