import {Component, h} from 'preact';
import {AppStage, CommonProps, PaymentMethod, ResponseHttp, ViewMode, WizardFlow} from '../types';
import {isUndefined} from 'util';
import InfoExitModal from '../info/InfoExitModal';
import {exitSmartCheckout, exitSmartCheckoutDirectly, handleKeyboardEvent} from '../utils';
import {ProgressBar} from "../helpers/ProgressBar";

interface HeaderState {
    backendUrl: String;
}

export default class Header extends Component<CommonProps, HeaderState> {

    public constructor() {
        super();

        this.getDirectExitAction = this.getDirectExitAction.bind(this);
    }

    componentDidUpdate(prevProps: CommonProps, prevState: HeaderState, snapshot: boolean) {
        if (this.props.stageData.stage !== AppStage.STAGE_ERROR && !prevProps.isInitialized && this.props.isInitialized && isUndefined(prevState.backendUrl)) {
            this.retrieveBackendUrl();
        }
    }

    public render(): JSX.Element | null {
        let exitLabel: JSX.Element | null = null;
        const exitPseudoButtonClass = 'close-button-header display-inline-block';

        // Show exit label if and only if:
        // 1) User is on the success page and urlSuccess is defined
        // 2) User is on the error page and urlError is defined
        // 3) User is on any other page and urlAbort is defined
        if ((this.props.stageData.stage === AppStage.STAGE_SUCCESS && this.context.returnUrls.urlSuccess)
            || (this.props.stageData.stage === AppStage.STAGE_ERROR && this.context.returnUrls.urlError)
            || (
                this.props.stageData.stage !== AppStage.STAGE_SUCCESS
                && this.props.stageData.stage !== AppStage.STAGE_ERROR
                && this.context.returnUrls.urlAbort
            )
            // we can also use close X when SC is inside iframe.
            // Then we will return to shop (urlAbort is not set)
            || (
                this.props.viewMode === ViewMode.LAYER
                && this.props.stageData.stage !== AppStage.STAGE_SUCCESS
                && this.props.stageData.stage !== AppStage.STAGE_ERROR
                && !this.context.returnUrls.urlAbort
            )
        ) {
            if (this.showAbortModal()) {
                exitLabel = (
                    <label for="exitModal" onKeyUp={handleKeyboardEvent}>
                        <div for="exitModal" class={exitPseudoButtonClass} tabIndex={0}/>
                    </label>
                );
            } else {
                exitLabel = (
                    <div
                        onClick={this.getDirectExitAction()}
                        class={exitPseudoButtonClass}
                        tabIndex={0}
                        onKeyUp={handleKeyboardEvent}
                    />
                );
            }
        }

        return (
            this.props.isInitialized ?
                <section id="header" class="content-text-theme">
                    <div class="container no-margin">
                        <div className="col-xs-12" id="server-url">
                            <div>{this.state.backendUrl}</div>
                        </div>
                        <div class="col-xs-8">
                            {this.prepareLogo()}
                        </div>
                        <div class="col-xs-4">
                            <div class="float-right header-right-block">
                                {exitLabel}
                            </div>
                        </div>
                        <ProgressBar {...this.props} />
                        <div class="col-xs-12 pl-0 pr-0">
                            <hr/>
                        </div>
                    </div>
                    <InfoExitModal {...this.props as CommonProps} />
                </section>
                : null
        );
    }

    private showAbortModal(): boolean {
        return !(this.props.stageData.stage === AppStage.STAGE_ERROR
            || this.props.stageData.stage === AppStage.STAGE_SUCCESS
            || this.props.wizardFlow === WizardFlow.PAYMENT);
    }

    private getDirectExitAction() {
        if (this.props.wizardFlow === WizardFlow.PAYMENT && this.props.stageData.stage !== AppStage.STAGE_ERROR) {
            if (this.props.stageData.stage === AppStage.STAGE_PAYMENT_INPUT) {
                switch (this.props.paymentMethod) {
                    case PaymentMethod.PAY_IN_ADVANCE:
                        return exitSmartCheckoutDirectly.bind(
                            this,
                            this.props.viewMode,
                            this.context.returnUrls.urlSuccess
                        );
                    case PaymentMethod.DIRECT_DEBIT:
                        return exitSmartCheckout.bind(
                            this,
                            this.context.returnUrls.urlAbort,
                            {},
                            this.props.viewMode,
                            '/abort',
                            this.props.setLoading);
                    case PaymentMethod.INVOICE:
                        return exitSmartCheckout.bind(
                            this,
                            this.context.returnUrls.urlAbort,
                            {},
                            this.props.viewMode,
                            '/abort',
                            this.props.setLoading);
                    case PaymentMethod.CREDIT_CARD:
                        return exitSmartCheckout.bind(
                            this,
                            this.context.returnUrls.urlAbort,
                            {},
                            this.props.viewMode,
                            '/abort',
                            this.props.setLoading);
                    default:
                        return () => {
                            console.warn("Unhandled exit action");
                        };
                }
            } else {
                // In current situation for PaymentSelectionPage
                return exitSmartCheckout.bind(
                    this,
                    this.context.returnUrls.urlAbort,
                    {},
                    this.props.viewMode,
                    '/abort',
                    this.props.setLoading);
            }
        } else {
            switch (this.props.stageData.stage) {
                case AppStage.STAGE_ERROR:
                    return exitSmartCheckout.bind(
                        this,
                        this.context.returnUrls.urlError,
                        {},
                        this.props.viewMode,
                        '/finish',
                        this.props.setLoading
                    );
                case AppStage.STAGE_SUCCESS:
                    return exitSmartCheckout.bind(
                        this,
                        this.context.returnUrls.urlSuccess,
                        {},
                        this.props.viewMode,
                        '/finish',
                        this.props.setLoading
                    );
                default:
                    return () => {
                        console.warn("Unhandled exit action");
                    };
            }
        }
    }

    private retrieveBackendUrl(): void {
        this.props.callBackend(
            '/server-url',
            (response: ResponseHttp) => {
                if (JSON.parse(response.text).url !== 'https://connect.secucard.com') {
                    this.setState({backendUrl: JSON.parse(response.text).url});
                }
            }
        );
    }

    private prepareLogo() {
        switch (this.props.wizardFlow) {
            case WizardFlow.PAYMENT:
                return <div class="header-payment-info">
                    <img src={require('../../../img/header-icons/sp-shield.svg')}
                         alt="logo" class="secupay-shield"/>
                    <img src={require('../../../img/header-icons/secupay.svg')}
                         alt="secupay" class="secupay-word"/>
                </div>;
            default:
                return <img src={require('../../../img/header-icons/logo-01.svg')} class="logo" alt="logo"/>
        }
    }
}
